import React, { useState, useEffect, useRef } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../../api/firebase/firebaseConfig';
import { getAuth } from 'firebase/auth';
import { QRCodeSVG } from 'qrcode.react';
import styles from './AttendanceQRGen.module.css';

const AttendanceQRGen = () => {
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [attendanceUrl, setAttendanceUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [selectedWorkplace, setSelectedWorkplace] = useState(null);
  const auth = getAuth();
  const qrRef = useRef(null);

  // 직원 목록 불러오기
  useEffect(() => {
    fetchEmployees();
  }, []);

  const fetchEmployees = async () => {
    setIsLoading(true);
    try {
      if (!auth.currentUser) {
        console.error('로그인이 필요합니다.');
        setIsLoading(false);
        return;
      }
      
      const employeeDocRef = doc(db, 'employees', auth.currentUser.uid);
      const employeeDoc = await getDoc(employeeDocRef);
      
      if (employeeDoc.exists()) {
        const data = employeeDoc.data();
        setEmployees(data.employeeList || []);
      } else {
        setEmployees([]);
      }
    } catch (error) {
      console.error('직원 목록 불러오기 실패:', error);
      setEmployees([]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEmployeeSelect = (employee) => {
    setSelectedEmployee(employee);
    setAttendanceUrl(''); // 직원 변경 시 기존 QR 코드 초기화
    
    // 직원에게 근무지가 있으면 첫 번째 근무지 선택, 없으면 null
    if (employee.workplaces && employee.workplaces.length > 0) {
      // 기본 근무지가 있으면 기본 근무지 선택, 없으면 첫 번째 근무지
      const defaultWorkplace = employee.workplaces.find(wp => wp.isDefault === true);
      setSelectedWorkplace(defaultWorkplace || employee.workplaces[0]);
    } else {
      setSelectedWorkplace(null);
    }
  };

  const generateQRCode = () => {
    if (!selectedEmployee) return;
    
    setIsLoading(true);
    try {
      // 인코딩
      const timestamp = new Date().getTime();
      const tokenData = `${auth.currentUser.uid}:${timestamp}`;
      const token = btoa(tokenData); // Base64 인코딩
      
      // URL에 직원 ID와 토큰만 포함 (근무지가 선택된 경우 추가)
      let url = `${window.location.origin}/attendance/${token}/${selectedEmployee.id}`;
      
      // 근무지가 선택된 경우 URL에 추가
      if (selectedWorkplace) {
        url += `/${encodeURIComponent(selectedWorkplace.name)}`;
      }
      
      setAttendanceUrl(url);
    } catch (error) {
      console.error('QR 코드 생성 실패:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const downloadQRCode = () => {
    if (!qrRef.current || !selectedEmployee) return;
    
    try {
      // SVG를 Canvas로 변환
      const svgElement = qrRef.current.querySelector('svg');
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      
      // 캔버스 크기 설정 (QR 코드보다 약간 크게)
      canvas.width = 200;
      canvas.height = 200;
      
      // 배경을 흰색으로 설정
      ctx.fillStyle = '#ffffff';
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      
      // SVG를 이미지로 변환
      const svgData = new XMLSerializer().serializeToString(svgElement);
      const img = new Image();
      
      img.onload = () => {
        // 이미지를 캔버스 중앙에 그리기
        ctx.drawImage(img, 30, 30, 140, 140);
        
        // 직원 이름 추가
        ctx.font = 'bold 12px Arial';
        ctx.fillStyle = '#000000';
        ctx.textAlign = 'center';
        ctx.fillText(`${selectedEmployee.name}`, canvas.width / 2, 185);
        
        // 다운로드 링크 생성
        const dataUrl = canvas.toDataURL('image/png');
        const link = document.createElement('a');
        link.download = `출결QR_${selectedEmployee.name}.png`;
        link.href = dataUrl;
        link.click();
      };
      
      img.src = 'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(svgData)));
    } catch (error) {
      console.error('QR 코드 다운로드 실패:', error);
    }
  };

  return (
    <div className={styles.qrGenerateContainer}>
      <h2>출결 QR 생성</h2>
      
      <div className={styles.qrGenerateLayout}>
        <div className={styles.employeeSelectionPanel}>
          <h3>직원 선택</h3>
          <div className={styles.employeeGrid}>
            {isLoading ? (
              <p className={styles.loading}>직원 목록을 불러오는 중...</p>
            ) : employees.length > 0 ? (
              employees.map(employee => (
                <div 
                  key={employee.id} 
                  className={`${styles.employeeCard} ${selectedEmployee?.id === employee.id ? styles.selected : ''}`}
                  onClick={() => handleEmployeeSelect(employee)}
                >
                  <div className={styles.employeeAvatar}>
                    {employee.name.charAt(0)}
                  </div>
                  <div className={styles.employeeInfo}>
                    <span className={styles.employeeName}>{employee.name}</span>
                    <span className={styles.employeePosition}>{employee.position || '직책 미지정'}</span>
                  </div>
                </div>
              ))
            ) : (
              <p className={styles.noData}>등록된 직원이 없습니다.</p>
            )}
          </div>
        </div>
        
        <div className={styles.qrDisplayPanel}>
          <h3>QR 코드</h3>
          <div className={styles.qrContent}>
            {selectedEmployee ? (
              <>
                <p><strong>{selectedEmployee.name}</strong> 님의 출결 QR 코드를 생성합니다.</p>
                
                {/* 근무지 선택 드롭다운 추가 */}
                {selectedEmployee.workplaces && selectedEmployee.workplaces.length > 0 && (
                  <div className={styles.workplaceSelector}>
                    <label htmlFor="workplace-select">근무지 선택:</label>
                    <select 
                      id="workplace-select"
                      value={selectedWorkplace ? selectedWorkplace.name : ''}
                      onChange={(e) => {
                        const selected = selectedEmployee.workplaces.find(wp => wp.name === e.target.value);
                        setSelectedWorkplace(selected || null);
                        setAttendanceUrl(''); // 근무지 변경 시 기존 QR 코드 초기화
                      }}
                    >
                      {selectedEmployee.workplaces.map((workplace, index) => (
                        <option key={index} value={workplace.name}>
                          {workplace.name} {workplace.isDefault ? '(기본)' : ''} - {workplace.hourlyWage}원/시간
                        </option>
                      ))}
                    </select>
                  </div>
                )}
              </>
            ) : (
              <p>왼쪽에서 직원을 선택해주세요.</p>
            )}
            
            <div className={styles.qrPlaceholder} ref={qrRef}>
              {attendanceUrl ? (
                <QRCodeSVG 
                  value={attendanceUrl}
                  size={140}
                  bgColor={"#ffffff"}
                  fgColor={"#000000"}
                  level={"L"}
                  includeMargin={false}
                />
              ) : (
                <span>QR 코드 영역</span>
              )}
            </div>
            
            <div className={styles.qrButtons}>
              <button 
                className={styles.qrButton} 
                onClick={generateQRCode}
                disabled={isLoading || !auth.currentUser || !selectedEmployee || 
                  (selectedEmployee?.workplaces?.length > 0 && !selectedWorkplace)}
              >
                {isLoading ? '처리 중...' : 'QR 코드 생성하기'}
              </button>
              
              {attendanceUrl && (
                <button 
                  className={styles.qrDownloadButton} 
                  onClick={downloadQRCode}
                >
                  이미지로 저장
                </button>
              )}
            </div>
          </div>
          
          {attendanceUrl && (
            <div className={styles.qrInstructions}>
              <h4>사용 방법</h4>
              <ol>
                <li>생성된 QR 코드를 <strong>{selectedEmployee.name}</strong> 님에게 제공합니다.</li>
                {selectedWorkplace && (
                  <li>선택된 근무지: <strong>{selectedWorkplace.name}</strong> (시급: {selectedWorkplace.hourlyWage}원)</li>
                )}
                <li>직원이 QR 코드를 스캔하면 자동으로 출결이 처리됩니다.</li>
                <li>이 QR 코드는 재사용 가능하며, 스캔 시점의 날짜와 시간으로 출결이 기록됩니다.</li>
                <li>출결 현황 페이지에서 직원들의 출결 상태를 확인할 수 있습니다.</li>
              </ol>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AttendanceQRGen; 