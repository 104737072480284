import * as InsuranceRatesModule from './calculatePayroll_insurance.js';
import * as taxRatesModule from './calculatePayroll_tax.js';

// 객체로 변환
const InsuranceRates = InsuranceRatesModule.default || InsuranceRatesModule;
const taxRates = taxRatesModule.default || taxRatesModule;

/**
 * 과세수당 변경 시 사회보험 및 세금을 재계산하는 함수
 * 
 * @param {Object} payrollData - 급여 계산 결과 데이터
 * @param {number} taxableAllowance - 과세수당 금액
 * @param {string} yearMonth - 계산 기준 년월 (YYYYMM 형식)
 * @param {Array} dependents - 부양가족 정보 [성인, 아동]
 * @returns {Object} 재계산된 공제 정보
 */
export const recalculateDeductions = (payrollData, taxableAllowance, yearMonth, dependents = [1, 0]) => {
  // 기존 급여 데이터에서 필요한 정보 추출
  const { 
    finalPay, 
    nightAllowance, 
    holidayAllowance, 
    overtimeAllowance, 
    nonTaxableOpt,
    // 과세 사용자정의 수당 (5개)
    customPay1 = 0,
    customPay2 = 0,
    customPay3 = 0,
    customPay4 = 0,
    customPay5 = 0,
    // 비과세 사용자정의 수당 (5개)
    customNonTaxPay1 = 0,
    customNonTaxPay2 = 0,
    customNonTaxPay3 = 0,
    customNonTaxPay4 = 0,
    customNonTaxPay5 = 0,
    // 기타 수당
    mealAllowance = 0,
    carAllowance = 0,
    rewardPay = 0,
    bonusPay = 0,
    etcAllowance = 0,
    // 직원별 설정값
    employeeSettings = {}
  } = payrollData;
  
  // 보험 적용 여부 확인
  const {
    healthInsuranceApplied = true,
    nationalPensionApplied = true,
    employmentInsuranceApplied = true,
    industrialAccidentInsuranceApplied = true,
    incomeTaxDeduction = true
  } = employeeSettings;
  
  // 보험요율 정보 가져오기
  const getInsuranceRates = (yearMonth) => {
    // 디버깅을 위한 로그 추가
    console.log(`요청된 연도-월: ${yearMonth}`);
    
    // yearMonth 형식 확인 및 정규화
    let targetYM;
    if (typeof yearMonth === 'string') {
      // YYYY-MM 형식인 경우 YYYYMM으로 변환
      if (yearMonth.includes('-')) {
        const [year, month] = yearMonth.split('-');
        targetYM = parseInt(`${year}${month.padStart(2, '0')}`);
      } else {
        targetYM = parseInt(yearMonth);
      }
    } else {
      targetYM = yearMonth;
    }
    
    console.log(`변환된 연도-월 숫자: ${targetYM}`);
    
    // 모든 구간을 확인하여 적합한 요율 찾기
    let selectedRates = null;
    
    for (const period in InsuranceRates) {
      const [startPeriod, endPeriod] = period.split('-');
      const startYM = parseInt(startPeriod);
      const endYM = parseInt(endPeriod);
      
      console.log(`검사 중인 구간: ${period}, 시작: ${startYM}, 종료: ${endYM}`);
      
      // 주어진 yearMonth가 해당 구간에 속하는지 확인
      if (targetYM >= startYM && targetYM <= endYM) {
        console.log(`✓ 보험요율 적용: ${period} 구간이 ${targetYM}에 적용됨`);
        selectedRates = InsuranceRates[period];
        break;
      }
    }
    
    // 해당 기간의 요율을 찾지 못한 경우
    if (!selectedRates) {
      console.error(`오류: ${targetYM}에 해당하는 보험요율 구간을 찾지 못했습니다.`);
    }
    
    console.log(`선택된 보험요율 정보:`, selectedRates);
    return selectedRates;
  };

  const insuranceRates = getInsuranceRates(yearMonth);
  
  // 과세소득 계산
  // 기본 과세소득 (최종 지급 임금)
  let taxableIncome = finalPay;
  
  // 과세 대상 추가 수당 합산 (5개의 과세 사용자정의 수당)
  taxableIncome += customPay1 + customPay2 + customPay3 + customPay4 + customPay5;
  taxableIncome += rewardPay + bonusPay + etcAllowance;
  
  // 비과세 소득 계산 (5개의 비과세 사용자정의 수당)
  let nonTaxableIncome = customNonTaxPay1 + customNonTaxPay2 + customNonTaxPay3 + customNonTaxPay4 + customNonTaxPay5;
  nonTaxableIncome += mealAllowance + carAllowance;
  
  // 야간/휴일/연장 수당이 비과세 처리되는 경우
  if (nonTaxableOpt == 1) {
    taxableIncome = taxableIncome - (nightAllowance + holidayAllowance + overtimeAllowance);
    nonTaxableIncome += (nightAllowance + holidayAllowance + overtimeAllowance);
  }
  
  // 사회보험 계산
  // 국민연금 (원단위 절사)
  const pensionBase = Math.max(taxableIncome, insuranceRates.insur1Min);
  console.log(`국민연금 계산 기준액: ${pensionBase}`);
  const pensionInsurance = nationalPensionApplied ? 
    Math.floor(pensionBase * insuranceRates.insur1 / 10) * 10 : 
    0;
  console.log(`국민연금 계산액: ${pensionInsurance} (요율: ${insuranceRates.insur1}, 적용: ${nationalPensionApplied})`);
  
  // 건강보험 (원단위 절사)
  const healthBase = Math.max(taxableIncome, insuranceRates.insur2Min);
  console.log(`건강보험 계산 기준액: ${healthBase}`);
  const healthInsurance = healthInsuranceApplied ? 
    Math.floor(healthBase * insuranceRates.insur2 / 10) * 10 : 
    0;
  console.log(`건강보험 계산액: ${healthInsurance} (요율: ${insuranceRates.insur2}, 적용: ${healthInsuranceApplied})`);
  
  // 장기요양보험 (원단위 절사)
  const longTermInsurance = healthInsuranceApplied ? 
    Math.floor(healthInsurance * insuranceRates.insur2Long / 10) * 10 : 
    0;
  
  // 고용보험 (원단위 절사)
  const employmentInsurance = employmentInsuranceApplied ? 
    Math.floor(taxableIncome * insuranceRates.insur3 / 10) * 10 : 
    0;
  
  // 산재보험 (원단위 절사)
  const industrialInsurance = industrialAccidentInsuranceApplied ? 
    Math.floor(taxableIncome * insuranceRates.insur4 / 10) * 10 : 
    0;
  
  // 세금 계산
  const { incomeTax, localTax } = incomeTaxDeduction ? 
    calculateTax(taxableIncome, dependents) : 
    { incomeTax: 0, localTax: 0 };
  
  // 총 공제액 계산
  const totalDeductions = pensionInsurance + 
                        healthInsurance + 
                        longTermInsurance + 
                        employmentInsurance +
                        incomeTax +
                        localTax;
  
  // 실수령액 계산
  const totalPay = taxableIncome + nonTaxableIncome;
  const netPay = totalPay - totalDeductions;
  
  // 디버깅 정보 출력
  console.log('===== 보험료 계산 디버깅 =====');
  console.log('적용 보험요율:', insuranceRates);
  console.log('국민연금 하한액:', insuranceRates.insur1Min);
  console.log('건강보험 하한액:', insuranceRates.insur2Min);
  console.log('과세소득:', taxableIncome);
  console.log('국민연금 계산액:', pensionInsurance);
  console.log('건강보험 계산액:', healthInsurance);
  console.log('=========================');
  
  return {
    taxableAllowanceTotal: taxableIncome,
    nonTaxableAllowanceTotal: nonTaxableIncome,
    pensionInsurance,
    healthInsurance,
    longTermInsurance,
    employmentInsurance,
    industrialInsurance,
    incomeTax,
    localTax,
    totalDeductions,
    netPay
  };
};

/**
 * 세금 계산 함수
 * 
 * @param {number} taxableIncome - 과세소득
 * @param {Array} dependents - 부양가족 정보 [성인, 아동]
 * @returns {Object} 소득세 및 지방소득세 정보
 */
const calculateTax = (taxableIncome, dependents) => {
  const [adults, children] = dependents;
  let totalDependents = adults + children;
  
  // 최대 11명까지만 고려
  if (totalDependents > 11) {
    totalDependents = 11;
  }

  // 과세 구간 찾기 (과세소득을 1000으로 나누어 계산)
  let taxRatesEntry = null;
  const taxableIncomeInThousands = (taxableIncome / 1000);
  
  for (const rate of taxRates) {
    if (taxableIncomeInThousands >= rate.이상 && taxableIncomeInThousands < rate.미만) {
      taxRatesEntry = rate;
      break;
    }
  }

  // 해당하는 과세 구간을 찾지 못한 경우
  if (!taxRatesEntry) {
    return {
      incomeTax: 0,
      localTax: 0
    };
  }

  // 공제 인원수에 따른 세금 계산
  const incomeTax = taxRatesEntry[totalDependents.toString()] || 0;
  
  // 지방소득세는 소득세의 10%이며 원단위 절사
  const localTax = Math.floor(incomeTax * 0.1 / 10) * 10;

  return {
    incomeTax,
    localTax
  };
};

/**
 * 급여 항목 변경 시 전체 급여 정보를 재계산하는 함수
 * 
 * @param {Object} payrollData - 기존 급여 데이터
 * @param {Object} updatedValues - 변경된 값들 {필드명: 값}
 * @param {string} yearMonth - 계산 기준 년월 (YYYYMM 형식)
 * @param {Array} dependents - 부양가족 정보 [성인, 아동]
 * @returns {Object} 재계산된 급여 정보
 */
export const recalculatePayroll = (payrollData, updatedValues, yearMonth, dependents = [1, 0]) => {
  // 기존 데이터와 업데이트된 값을 병합
  const updatedPayroll = { ...payrollData, ...updatedValues };
  
  // 법정임금 합계 재계산
  const legalPayTotal = 
    (updatedPayroll.basicPay || 0) + 
    (updatedPayroll.weeklyHolidayPay || 0) +
    (updatedPayroll.annualLeavePay || 0) + 
    (updatedPayroll.nightAllowance || 0) +
    (updatedPayroll.holidayAllowance || 0) +
    (updatedPayroll.overtimeAllowance || 0);
  
  // 포괄임금과 법정임금 비교
  const inclusivePay = updatedPayroll.inclusivePay || 0;
  
  // 조정/보전수당 계산
  const adjustmentPay = inclusivePay > legalPayTotal ? inclusivePay - legalPayTotal : 0;
  
  // 최종 지급 임금 계산
  const finalPay = Math.max(inclusivePay, legalPayTotal);
  
  // 업데이트된 값 설정
  updatedPayroll.legalPayTotal = legalPayTotal;
  updatedPayroll.adjustmentPay = adjustmentPay;
  updatedPayroll.finalPay = finalPay;
  
  // 과세 및 비과세 수당 계산
  const deductions = recalculateDeductions(updatedPayroll, 0, yearMonth, dependents);
  
  // 결과 병합
  return {
    ...updatedPayroll,
    ...deductions
  };
};

/**
 * 과세 수당 계산 함수
 * 
 * @param {Object} employee - 직원 데이터
 * @returns {number} 과세 수당 합계
 */
export const calculateTaxableAmount = (employee) => {
  // 비과세 옵션이 1인 경우 야간/휴일/연장 수당은 비과세
  const nonTaxableOpt = employee.nonTaxableOpt || 0;
  
  // 기본급과 주휴수당, 연차수당은 항상 과세
  let taxable = (employee.basicPay || 0) + 
                (employee.weeklyHolidayPay || 0) + 
                (employee.annualLeavePay || 0) +
                (employee.adjustmentPay || 0);
  
  // 비과세 옵션이 0인 경우 모든 수당이 과세 대상
  if (nonTaxableOpt === 0) {
    taxable += (employee.nightAllowance || 0) + 
               (employee.holidayAllowance || 0) + 
               (employee.overtimeAllowance || 0);
  }
  
  // 사용자 정의 과세 수당 추가 (1~5로 확장)
  taxable += (employee.customPay1 || 0) + 
             (employee.customPay2 || 0) + 
             (employee.customPay3 || 0) + 
             (employee.customPay4 || 0) +
             (employee.customPay5 || 0) +
             (employee.rewardPay || 0) +
             (employee.bonusPay || 0) +
             (employee.etcAllowance || 0);
  
  return taxable;
};

/**
 * 비과세 수당 계산 함수
 * 
 * @param {Object} employee - 직원 데이터
 * @returns {number} 비과세 수당 합계
 */
export const calculateNonTaxableAmount = (employee) => {
  // 비과세 옵션이 1인 경우 야간/휴일/연장 수당은 비과세
  const nonTaxableOpt = employee.nonTaxableOpt || 0;
  
  let nonTaxable = 0;
  
  // 비과세 옵션이 1인 경우 야간/휴일/연장 수당은 비과세
  if (nonTaxableOpt === 1) {
    nonTaxable += (employee.nightAllowance || 0) + 
                  (employee.holidayAllowance || 0) + 
                  (employee.overtimeAllowance || 0);
  }
  
  // 사용자 정의 비과세 수당 추가 (1~5로 확장)
  nonTaxable += (employee.customNonTaxPay1 || 0) + 
                (employee.customNonTaxPay2 || 0) + 
                (employee.customNonTaxPay3 || 0) + 
                (employee.customNonTaxPay4 || 0) +
                (employee.customNonTaxPay5 || 0) +
                (employee.mealAllowance || 0) +
                (employee.carAllowance || 0);
  
  return nonTaxable;
};

/**
 * 현재 연도-월 값을 가져오는 함수
 * 
 * @returns {string} YYYYMM 형식의 연도-월
 */
export const getCurrentYearMonth = () => {
  // 1. DOM에서 직접 선택 요소 찾기
  const yearMonthSelect = document.querySelector('.year-month-select-small');
  
  if (yearMonthSelect) {
    // 선택 요소의 값 가져오기
    const selectedValue = yearMonthSelect.value;
    console.log('DOM에서 찾은 연도-월 선택 값:', selectedValue);
    
    // YYYY-MM 형식을 YYYYMM 형식으로 변환
    if (selectedValue && selectedValue.includes('-')) {
      const [year, month] = selectedValue.split('-');
      return `${year}${month.padStart(2, '0')}`;
    }
    
    return selectedValue;
  }
  
  // 2. URL 파라미터에서 찾기 (URL에 연도-월 정보가 있는 경우)
  const urlParams = new URLSearchParams(window.location.search);
  const yearMonthParam = urlParams.get('yearMonth');
  
  if (yearMonthParam) {
    console.log('URL에서 찾은 연도-월 파라미터:', yearMonthParam);
    return yearMonthParam;
  }
  
  // 3. 로컬 스토리지에서 찾기 (이전에 선택한 값이 저장되어 있는 경우)
  const storedYearMonth = localStorage.getItem('selectedYearMonth');
  
  if (storedYearMonth) {
    console.log('로컬 스토리지에서 찾은 연도-월:', storedYearMonth);
    return storedYearMonth;
  }
  
  // 4. 현재 날짜 기준으로 기본값 설정 (최후의 수단)
  const now = new Date();
  const currentYear = now.getFullYear();
  const currentMonth = (now.getMonth() + 1).toString().padStart(2, '0');
  const defaultYearMonth = `${currentYear}${currentMonth}`;
  
  console.log('현재 날짜 기준 기본 연도-월:', defaultYearMonth);
  return defaultYearMonth;
};

/**
 * 직원 급여 초기 계산 함수
 * 
 * @param {Object} employee - 직원 데이터
 * @param {string} yearMonth - 계산 기준 년월 (YYYYMM 형식)
 * @returns {Object} 계산된 급여 정보
 */
export const initializeEmployeePayroll = (employee, yearMonth) => {
  // 백엔드에서 계산된 값이 없는 경우를 대비한 기본값 설정
  const legalPayTotal = employee.legalPayTotal || (
    (employee.basicPay || 0) + 
    (employee.weeklyHolidayPay || 0) + 
    (employee.annualLeavePay || 0) + 
    (employee.nightAllowance || 0) + 
    (employee.holidayAllowance || 0) + 
    (employee.overtimeAllowance || 0)
  );
  
  const inclusivePay = employee.inclusivePay || 0;
  const adjustmentPay = employee.adjustmentPay || (inclusivePay > legalPayTotal ? inclusivePay - legalPayTotal : 0);
  const finalPay = employee.finalPay || Math.max(inclusivePay, legalPayTotal);
  
  // 과세 및 비과세 수당 계산
  const taxableAmount = employee.taxableAmount || calculateTaxableAmount(employee);
  const nonTaxableAmount = employee.nonTaxableAmount || calculateNonTaxableAmount(employee);
  
  // 재계산 시 현재 연도-월 적용
  const recalculatedDeductions = recalculateDeductions(
    employee,
    0,
    yearMonth,
    employee.dependents || [1, 0]
  );
  
  return {
    ...employee,
    ...recalculatedDeductions,
    legalPayTotal,
    adjustmentPay,
    finalPay,
    taxableAmount,
    nonTaxableAmount
  };
};

/**
 * 공제 항목 직접 수정 시 실수령액 재계산 함수
 * 
 * @param {Object} employee - 직원 데이터
 * @param {string} field - 변경된 필드명
 * @param {number} value - 변경된 값
 * @returns {Object} 재계산된 공제 정보
 */
export const recalculateDeductionField = (employee, field, value) => {
  // 공제액 합계 재계산
  const totalDeductions = 
    (field === 'pensionInsurance' ? value : (employee.pensionInsurance || 0)) +
    (field === 'healthInsurance' ? value : (employee.healthInsurance || 0)) +
    (field === 'longTermInsurance' ? value : (employee.longTermInsurance || 0)) +
    (field === 'employmentInsurance' ? value : (employee.employmentInsurance || 0)) +
    (field === 'incomeTax' ? value : (employee.incomeTax || 0)) +
    (field === 'localTax' ? value : (employee.localTax || 0));
  
  // 총 지급액 계산 (10개의 사용자정의 수당으로 확장)
  const totalPay = 
    (employee.finalPay || 0) +
    // 과세 사용자정의 수당
    (employee.customPay1 || 0) +
    (employee.customPay2 || 0) +
    (employee.customPay3 || 0) +
    (employee.customPay4 || 0) +
    (employee.customPay5 || 0) +
    // 비과세 사용자정의 수당
    (employee.customNonTaxPay1 || 0) + 
    (employee.customNonTaxPay2 || 0) + 
    (employee.customNonTaxPay3 || 0) + 
    (employee.customNonTaxPay4 || 0) +
    (employee.customNonTaxPay5 || 0) +
    // 기타 수당
    (employee.mealAllowance || 0) +
    (employee.carAllowance || 0) +
    (employee.rewardPay || 0) +
    (employee.bonusPay || 0) +
    (employee.etcAllowance || 0);
  
  // 실수령액 재계산
  const netPay = totalPay - totalDeductions;
  
  return {
    [field]: value,
    totalDeductions,
    netPay
  };
};

/**
 * 주 이름 포맷팅 함수
 * 
 * @param {string} weekKey - 주 키 (예: 'week1')
 * @returns {string} 포맷된 주 이름 (예: '1주차')
 */
export const formatWeekName = (weekKey) => {
  const weekNumber = weekKey.replace('week', '');
  return `${weekNumber}주차`;
};

/**
 * 숫자 포맷팅 함수 (천 단위 콤마)
 * 
 * @param {number} num - 포맷할 숫자
 * @returns {string} 포맷된 문자열
 */
export const formatNumber = (num) => {
  return (num || 0).toLocaleString();
}; 