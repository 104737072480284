import React from 'react';
import { formatWeekName } from '../../../../../utils/payrollCalculator';
import styles from './EmployeeDetailInfo.module.css';

const EmployeeDetailInfo = ({ 
  employee, 
  index, 
  formatNumber, 
  formatInputValue, 
  handleAllowanceChange, 
  handleCustomPayNameChange, 
  isCompleted, 
  handleSave, 
  handleComplete,
  savingStatus,
  getSaveButtonText,
  getCompleteButtonText
}) => {
  return (
    <div className={styles.payrollDetails}>
      <div className={styles.detailsGrid}>
        <div className={styles.basicInfoColumn}>
          <h4>기본정보</h4>
          <div className={styles.basicInfoGrid}>
            <div className={styles.basicInfoItem}>
              <span className={styles.basicInfoLabel}>근로일:</span>
              <span className={styles.basicInfoValue}>{employee.totalWorkDays || 0}일</span>
            </div>
            <div className={styles.basicInfoItem}>
              <span className={styles.basicInfoLabel}>근로시간:</span>
              <span className={styles.basicInfoValue}>{((employee.totalWorkTime / 60) || 0).toFixed(2)}시간</span>
            </div>
            <div className={styles.basicInfoItem}>
              <span className={styles.basicInfoLabel}>휴일근로:</span>
              <span className={styles.basicInfoValue}>
                {(Math.floor(
                  Object.values(employee.workplaceDetails || {}).reduce(
                    (sum, workplace) => sum + Object.values(workplace.weeklyDetails || {}).reduce(
                      (weekSum, week) => weekSum + (week.holidayWorkMinutes || 0), 0
                    ), 0
                  ) / 60
                ) || 0).toFixed(2)}시간
              </span>
            </div>
            <div className={styles.basicInfoItem}>
              <span className={styles.basicInfoLabel}>휴가일수:</span>
              <span className={styles.basicInfoValue}>{employee.totalLeaveValue || 0}일</span>
            </div>
          </div>
          
          {/* 직원별 설정 정보 */}
          <h4>직원별 설정</h4>
          <div className={styles.employeeSettingsGrid}>
            <div className={styles.basicInfoItem}>
              <span className={styles.basicInfoLabel}>기준시급:</span>
              <span className={styles.basicInfoValue}>
                {employee.employeeSettings?.hourlyWage ? formatNumber(employee.employeeSettings.hourlyWage) : '기본값'}
              </span>
            </div>
            <div className={styles.basicInfoItem}>
              <span className={styles.basicInfoLabel}>포괄시급:</span>
              <span className={styles.basicInfoValue}>
                {employee.employeeSettings?.isComprehensiveWage ? 
                  formatNumber(employee.employeeSettings.comprehensiveWage) : '미적용'}
              </span>
            </div>
            <div className={styles.basicInfoItem}>
              <span className={styles.basicInfoLabel}>시간당 주휴:</span>
              <span className={styles.basicInfoValue}>
                {employee.employeeSettings?.weeklyHolidayAllowance > 0 ? 
                  formatNumber(employee.employeeSettings.weeklyHolidayAllowance) : '기본값'}
              </span>
            </div>
            <div className={styles.basicInfoItem}>
              <span className={styles.basicInfoLabel}>시간당 연차:</span>
              <span className={styles.basicInfoValue}>
                {employee.employeeSettings?.annualLeaveAllowance > 0 ? 
                  formatNumber(employee.employeeSettings.annualLeaveAllowance) : '기본값'}
              </span>
            </div>
          </div>
          
          {/* 사회보험 적용 정보 */}
          <h4>사회보험 적용</h4>
          <div className={styles.insuranceStatusGrid}>
            <div className={styles.insuranceStatusItem}>
              <span className={styles.insuranceStatusLabel}>건강보험:</span>
              <span className={`${styles.insuranceStatusValue} ${employee.employeeSettings?.healthInsuranceApplied ? styles.applied : styles.notApplied}`}>
                {employee.employeeSettings?.healthInsuranceApplied ? '적용' : '미적용'}
              </span>
            </div>
            <div className={styles.insuranceStatusItem}>
              <span className={styles.insuranceStatusLabel}>국민연금:</span>
              <span className={`${styles.insuranceStatusValue} ${employee.employeeSettings?.nationalPensionApplied ? styles.applied : styles.notApplied}`}>
                {employee.employeeSettings?.nationalPensionApplied ? '적용' : '미적용'}
              </span>
            </div>
            <div className={styles.insuranceStatusItem}>
              <span className={styles.insuranceStatusLabel}>고용보험:</span>
              <span className={`${styles.insuranceStatusValue} ${employee.employeeSettings?.employmentInsuranceApplied ? styles.applied : styles.notApplied}`}>
                {employee.employeeSettings?.employmentInsuranceApplied ? '적용' : '미적용'}
              </span>
            </div>
            <div className={styles.insuranceStatusItem}>
              <span className={styles.insuranceStatusLabel}>산재보험:</span>
              <span className={`${styles.insuranceStatusValue} ${employee.employeeSettings?.industrialAccidentInsuranceApplied ? styles.applied : styles.notApplied}`}>
                {employee.employeeSettings?.industrialAccidentInsuranceApplied ? '적용' : '미적용'}
              </span>
            </div>
            <div className={styles.insuranceStatusItem}>
              <span className={styles.insuranceStatusLabel}>소득세:</span>
              <span className={`${styles.insuranceStatusValue} ${employee.employeeSettings?.incomeTaxDeduction ? styles.applied : styles.notApplied}`}>
                {employee.employeeSettings?.incomeTaxDeduction ? '적용' : '미적용'}
              </span>
            </div>
          </div>
          
          {/* 근무지별 정보 */}
          <h4 className={styles.weeklyTitle}>근무지별 정보</h4>
          <div className={styles.weeklyInfoContainer}>
            {Object.entries(employee.workplaceDetails || {}).map(([workplaceKey, workplaceData]) => (
              <div key={workplaceKey} className={styles.weeklyInfoBox}>
                <div className={styles.weeklyInfoHeader}>{workplaceData.workplaceName}</div>
                <div className={styles.weeklyInfoItem}>
                  <span className={styles.weeklyInfoLabel}>근무일:</span>
                  <span className={styles.weeklyInfoValue}>{workplaceData.totalWorkDays || 0}일</span>
                </div>
                <div className={styles.weeklyInfoItem}>
                  <span className={styles.weeklyInfoLabel}>근무시간:</span>
                  <span className={styles.weeklyInfoValue}>{((workplaceData.totalWorkTime / 60) || 0).toFixed(2)}시간</span>
                </div>
                {workplaceData.totalLeaveValue > 0 && (
                  <div className={styles.weeklyInfoItem}>
                    <span className={styles.weeklyInfoLabel}>휴가:</span>
                    <span className={styles.weeklyInfoValue}>{workplaceData.totalLeaveValue}일</span>
                  </div>
                )}
                {workplaceData.annualLeavePay > 0 && (
                  <div className={styles.weeklyInfoItem}>
                    <span className={styles.weeklyInfoLabel}>연차수당:</span>
                    <span className={styles.weeklyInfoValue}>{formatNumber(workplaceData.annualLeavePay)}원</span>
                  </div>
                )}
                {workplaceData.overtimeAllowance > 0 && (
                  <div className={styles.weeklyInfoItem}>
                    <span className={styles.weeklyInfoLabel}>연장수당:</span>
                    <span className={styles.weeklyInfoValue}>{formatNumber(workplaceData.overtimeAllowance)}원</span>
                  </div>
                )}
                {workplaceData.adjustmentPay > 0 && (
                  <div className={styles.weeklyInfoItem}>
                    <span className={styles.weeklyInfoLabel}>조정수당:</span>
                    <span className={styles.weeklyInfoValue}>{formatNumber(workplaceData.adjustmentPay)}원</span>
                  </div>
                )}
              </div>
            ))}
          </div>
          
          {/* 주간 근무정보 */}
          <h4 className={styles.weeklyTitle}>주간 근무정보</h4>
          <div className={styles.weeklyInfoContainer}>
            {Object.entries(employee.workplaceDetails || {}).map(([workplaceKey, workplaceData]) => (
              Object.entries(workplaceData.weeklyDetails || {}).map(([weekKey, weekData]) => (
                weekData.totalWorkMinutes > 0 && (
                  <div key={`${workplaceKey}-${weekKey}`} className={styles.weeklyInfoBox}>
                    <div className={styles.weeklyInfoHeader}>
                      {workplaceData.workplaceName} - {formatWeekName(weekKey)}
                    </div>
                    <div className={styles.weeklyInfoItem}>
                      <span className={styles.weeklyInfoLabel}>총 근무:</span>
                      <span className={styles.weeklyInfoValue}>{((weekData.totalWorkMinutes || 0) / 60).toFixed(2)}시간</span>
                    </div>
                    {weekData.overtimeMinutes > 0 && (
                      <div className={styles.weeklyInfoItem}>
                        <span className={styles.weeklyInfoLabel}>연장:</span>
                        <span className={styles.weeklyInfoValue}>{((weekData.overtimeMinutes || 0) / 60).toFixed(2)}시간</span>
                      </div>
                    )}
                    {weekData.nightWorkMinutes > 0 && (
                      <div className={styles.weeklyInfoItem}>
                        <span className={styles.weeklyInfoLabel}>야간:</span>
                        <span className={styles.weeklyInfoValue}>{((weekData.nightWorkMinutes || 0) / 60).toFixed(2)}시간</span>
                      </div>
                    )}
                    {weekData.holidayWorkMinutes > 0 && (
                      <div className={styles.weeklyInfoItem}>
                        <span className={styles.weeklyInfoLabel}>휴일:</span>
                        <span className={styles.weeklyInfoValue}>{((weekData.holidayWorkMinutes || 0) / 60).toFixed(2)}시간</span>
                      </div>
                    )}
                  </div>
                )
              ))
            ))}
          </div>
        </div>
        <div className={styles.allowanceInfoColumn}>
          <h4>법정수당</h4>
          <div className={styles.allowanceGrid}>
            <div className={styles.allowanceInfoItem}>
              <span className={styles.allowanceLabel}>기본급:</span>
              <div className={styles.allowanceInputWrapper}>
                <input
                  type="text"
                  className={styles.allowanceInput}
                  value={formatInputValue(employee.basicPay)}
                  onChange={(e) => handleAllowanceChange(index, 'basicPay', e.target.value)}
                  disabled={isCompleted}
                />
              </div>
            </div>
            <div className={styles.allowanceInfoItem}>
              <span className={styles.allowanceLabel}>포괄임금:</span>
              <div className={styles.allowanceInputWrapper}>
                <input
                  type="text"
                  className={styles.allowanceInput}
                  value={formatInputValue(employee.inclusivePay)}
                  onChange={(e) => handleAllowanceChange(index, 'inclusivePay', e.target.value)}
                  disabled={isCompleted}
                />
              </div>
            </div>
            <div className={styles.allowanceInfoItem}>
              <span className={styles.allowanceLabel}>주휴:</span>
              <div className={styles.allowanceInputWrapper}>
                <input
                  type="text"
                  className={styles.allowanceInput}
                  value={formatInputValue(employee.weeklyHolidayPay)}
                  onChange={(e) => handleAllowanceChange(index, 'weeklyHolidayPay', e.target.value)}
                  disabled={isCompleted}
                />
              </div>
            </div>
            <div className={styles.allowanceInfoItem}>
              <span className={styles.allowanceLabel}>연차:</span>
              <div className={styles.allowanceInputWrapper}>
                <input
                  type="text"
                  className={styles.allowanceInput}
                  value={formatInputValue(employee.annualLeavePay)}
                  onChange={(e) => handleAllowanceChange(index, 'annualLeavePay', e.target.value)}
                  disabled={isCompleted}
                />
              </div>
            </div>
            <div className={styles.allowanceInfoItem}>
              <span className={styles.allowanceLabel}>연장:</span>
              <div className={styles.allowanceInputWrapper}>
                <input
                  type="text"
                  className={styles.allowanceInput}
                  value={formatInputValue(employee.overtimeAllowance)}
                  onChange={(e) => handleAllowanceChange(index, 'overtimeAllowance', e.target.value)}
                  disabled={isCompleted}
                />
              </div>
            </div>
            <div className={styles.allowanceInfoItem}>
              <span className={styles.allowanceLabel}>야간:</span>
              <div className={styles.allowanceInputWrapper}>
                <input
                  type="text"
                  className={styles.allowanceInput}
                  value={formatInputValue(employee.nightAllowance)}
                  onChange={(e) => handleAllowanceChange(index, 'nightAllowance', e.target.value)}
                  disabled={isCompleted}
                />
              </div>
            </div>
            <div className={styles.allowanceInfoItem}>
              <span className={styles.allowanceLabel}>휴일:</span>
              <div className={styles.allowanceInputWrapper}>
                <input
                  type="text"
                  className={styles.allowanceInput}
                  value={formatInputValue(employee.holidayAllowance)}
                  onChange={(e) => handleAllowanceChange(index, 'holidayAllowance', e.target.value)}
                  disabled={isCompleted}
                />
              </div>
            </div>
            <div className={styles.allowanceInfoItem}>
              <span className={styles.allowanceLabel}>조정/보전:</span>
              <div className={styles.allowanceInputWrapper}>
                <input
                  type="text"
                  className={styles.allowanceInput}
                  value={formatInputValue(employee.adjustmentPay)}
                  disabled
                />
              </div>
            </div>
          </div>
          
          <h4>공제정보</h4>
          <div className={styles.allowanceGrid}>
            <div className={styles.allowanceInfoItem}>
              <span className={styles.allowanceLabel}>국민연금:</span>
              <div className={styles.allowanceInputWrapper}>
                <input
                  type="text"
                  className={styles.allowanceInput}
                  value={formatInputValue(employee.pensionInsurance)}
                  onChange={(e) => handleAllowanceChange(index, 'pensionInsurance', e.target.value)}
                  disabled={isCompleted}
                />
              </div>
            </div>
            <div className={styles.allowanceInfoItem}>
              <span className={styles.allowanceLabel}>건강보험:</span>
              <div className={styles.allowanceInputWrapper}>
                <input
                  type="text"
                  className={styles.allowanceInput}
                  value={formatInputValue(employee.healthInsurance)}
                  onChange={(e) => handleAllowanceChange(index, 'healthInsurance', e.target.value)}
                  disabled={isCompleted}
                />
              </div>
            </div>
            <div className={styles.allowanceInfoItem}>
              <span className={styles.allowanceLabel}>장기요양:</span>
              <div className={styles.allowanceInputWrapper}>
                <input
                  type="text"
                  className={styles.allowanceInput}
                  value={formatInputValue(employee.longTermInsurance)}
                  onChange={(e) => handleAllowanceChange(index, 'longTermInsurance', e.target.value)}
                  disabled={isCompleted}
                />
              </div>
            </div>
            <div className={styles.allowanceInfoItem}>
              <span className={styles.allowanceLabel}>고용보험:</span>
              <div className={styles.allowanceInputWrapper}>
                <input
                  type="text"
                  className={styles.allowanceInput}
                  value={formatInputValue(employee.employmentInsurance)}
                  onChange={(e) => handleAllowanceChange(index, 'employmentInsurance', e.target.value)}
                  disabled={isCompleted}
                />
              </div>
            </div>
            <div className={styles.allowanceInfoItem}>
              <span className={styles.allowanceLabel}>산재보험:</span>
              <div className={styles.allowanceInputWrapper}>
                <input
                  type="text"
                  className={styles.allowanceInput}
                  value={formatInputValue(employee.industrialInsurance)}
                  onChange={(e) => handleAllowanceChange(index, 'industrialInsurance', e.target.value)}
                  disabled={isCompleted}
                />
              </div>
            </div>
            <div className={styles.allowanceInfoItem}>
              <span className={styles.allowanceLabel}>소득세:</span>
              <div className={styles.allowanceInputWrapper}>
                <input
                  type="text"
                  className={styles.allowanceInput}
                  value={formatInputValue(employee.incomeTax)}
                  onChange={(e) => handleAllowanceChange(index, 'incomeTax', e.target.value)}
                  disabled={isCompleted}
                />
              </div>
            </div>
            <div className={styles.allowanceInfoItem}>
              <span className={styles.allowanceLabel}>지방세:</span>
              <div className={styles.allowanceInputWrapper}>
                <input
                  type="text"
                  className={styles.allowanceInput}
                  value={formatInputValue(employee.localTax)}
                  onChange={(e) => handleAllowanceChange(index, 'localTax', e.target.value)}
                  disabled={isCompleted}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.additionalAllowanceColumn}>
          <h4>추가수당</h4>
          <div className={styles.allowanceGrid}>
            <div className={styles.allowanceInfoItem}>
              <span className={styles.allowanceLabel}>비식비:</span>
              <div className={styles.allowanceInputWrapper}>
                <input
                  type="text"
                  className={styles.allowanceInput}
                  value={formatInputValue(employee.mealAllowance)}
                  onChange={(e) => handleAllowanceChange(index, 'mealAllowance', e.target.value)}
                  disabled={isCompleted}
                />
              </div>
            </div>
            <div className={styles.allowanceInfoItem}>
              <span className={styles.allowanceLabel}>비자가운전:</span>
              <div className={styles.allowanceInputWrapper}>
                <input
                  type="text"
                  className={styles.allowanceInput}
                  value={formatInputValue(employee.carAllowance)}
                  onChange={(e) => handleAllowanceChange(index, 'carAllowance', e.target.value)}
                  disabled={isCompleted}
                />
              </div>
            </div>
            <div className={styles.allowanceInfoItem}>
              <span className={styles.allowanceLabel}>포상금:</span>
              <div className={styles.allowanceInputWrapper}>
                <input
                  type="text"
                  className={styles.allowanceInput}
                  value={formatInputValue(employee.rewardPay)}
                  onChange={(e) => handleAllowanceChange(index, 'rewardPay', e.target.value)}
                  disabled={isCompleted}
                />
              </div>
            </div>
            <div className={styles.allowanceInfoItem}>
              <span className={styles.allowanceLabel}>상여금:</span>
              <div className={styles.allowanceInputWrapper}>
                <input
                  type="text"
                  className={styles.allowanceInput}
                  value={formatInputValue(employee.bonusPay)}
                  onChange={(e) => handleAllowanceChange(index, 'bonusPay', e.target.value)}
                  disabled={isCompleted}
                />
              </div>
            </div>
            <div className={styles.allowanceInfoItem}>
              <span className={styles.allowanceLabel}>기타:</span>
              <div className={styles.allowanceInputWrapper}>
                <input
                  type="text"
                  className={styles.allowanceInput}
                  value={formatInputValue(employee.etcAllowance)}
                  onChange={(e) => handleAllowanceChange(index, 'etcAllowance', e.target.value)}
                  disabled={isCompleted}
                />
              </div>
            </div>
          </div>
          
          <h4>사용자 정의 수당</h4>
          <div className={styles.allowanceGrid}>
            {/* 과세 사용자정의 수당 (customPay1~5) */}
            {[1, 2, 3, 4, 5].map(num => (
              <div key={`customPay${num}`} className={styles.allowanceInfoItem}>
                <div className={styles.customAllowanceLabelWrapper}>
                  <div className={styles.customAllowanceLabelContainer}>
                    <input
                      type="text"
                      className={styles.customAllowanceLabelInput}
                      value={employee[`customPayName${num}`] || ''}
                      onChange={(e) => handleCustomPayNameChange(index, `customPayName${num}`, e.target.value)}
                      disabled={isCompleted}
                      placeholder={`과세${num}`}
                    />
                    <span className={styles.customAllowanceLabelText}>
                      {employee[`customPayName${num}`]}
                    </span>
                  </div>
                  <span className={styles.allowanceTaxType}>과세</span>
                </div>
                <div className={styles.allowanceInputWrapper}>
                  <input
                    type="text"
                    className={styles.allowanceInput}
                    value={formatInputValue(employee[`customPay${num}`] || 0)}
                    onChange={(e) => handleAllowanceChange(index, `customPay${num}`, e.target.value)}
                    disabled={isCompleted}
                  />
                </div>
              </div>
            ))}
            
            {/* 비과세 사용자정의 수당 (customNonTaxPay1~5) */}
            {[1, 2, 3, 4, 5].map(num => (
              <div key={`customNonTaxPay${num}`} className={styles.allowanceInfoItem}>
                <div className={styles.customAllowanceLabelWrapper}>
                  <div className={styles.customAllowanceLabelContainer}>
                    <input
                      type="text"
                      className={styles.customAllowanceLabelInput}
                      value={employee[`customNonTaxPayName${num}`] || ''}
                      onChange={(e) => handleCustomPayNameChange(index, `customNonTaxPayName${num}`, e.target.value)}
                      disabled={isCompleted}
                      placeholder={`비과세 ${num}`}
                    />
                    <span className={styles.customAllowanceLabelText}>
                      {employee[`customNonTaxPayName${num}`]}
                    </span>
                  </div>
                  <span className={styles.allowanceTaxType}>비과세</span>
                </div>
                <div className={styles.allowanceInputWrapper}>
                  <input
                    type="text"
                    className={styles.allowanceInput}
                    value={formatInputValue(employee[`customNonTaxPay${num}`] || 0)}
                    onChange={(e) => handleAllowanceChange(index, `customNonTaxPay${num}`, e.target.value)}
                    disabled={isCompleted}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={styles.summarySection}>
        <div className={styles.summaryRow}>
          <div className={styles.summaryGroup}>
            <div className={styles.summaryGroupTitle}>법정수당</div>
            <div className={styles.summaryGroupValue}>{formatNumber(
              employee.legalPayTotal + (employee.adjustmentPay || 0)
            )}원</div>
          </div>
          <div className={styles.summaryGroup}>
            <div className={styles.summaryGroupTitle}>추가수당</div>
            <div className={styles.summaryGroupValue}>{formatNumber(
              (employee.mealAllowance || 0) +
              (employee.carAllowance || 0) +
              (employee.rewardPay || 0) +
              (employee.bonusPay || 0) +
              (employee.etcAllowance || 0)
            )}원</div>
          </div>
          <div className={styles.summaryGroup}>
            <div className={styles.summaryGroupTitle}>과세/비과세</div>
            <div className={styles.summaryGroupItem}>
              <span>과세:</span>
              <span>{formatNumber(employee.taxableAmount)}원</span>
            </div>
            <div className={styles.summaryGroupItem}>
              <span>비과세:</span>
              <span>{formatNumber(employee.nonTaxableAmount)}원</span>
            </div>
          </div>
          <div className={styles.summaryGroup}>
            <div className={styles.summaryGroupTitle}>공제</div>
            <div className={styles.summaryGroupItem}>
              <span>사회보험:</span>
              <span>{formatNumber(
                (employee.pensionInsurance || 0) + 
                (employee.healthInsurance || 0) + 
                (employee.longTermInsurance || 0) + 
                (employee.employmentInsurance || 0)
              )}원</span>
            </div>
            <div className={styles.summaryGroupItem}>
              <span>세금:</span>
              <span>{formatNumber((employee.incomeTax || 0) + (employee.localTax || 0))}원</span>
            </div>
          </div>
          <div className={styles.summaryGroup}>
            <div className={styles.summaryGroupTitle}>실지급액</div>
            <div className={styles.summaryGroupValue}>{formatNumber(employee.netPay)}원</div>
          </div>
        </div>
      </div>
      <div className={styles.actionsSection}>
        <button 
          className={`${styles.actionButton} ${styles.saveButton} ${savingStatus === 'saved' ? styles.success : ''} ${savingStatus === 'error' ? styles.error : ''}`}
          onClick={handleSave}
          disabled={savingStatus === 'saving'}
        >
          {getSaveButtonText()}
        </button>
        <button 
          className={`${styles.actionButton} ${styles.completeButton} ${savingStatus === 'completed' ? styles.success : ''} ${savingStatus === 'uncompleted' ? styles.success : ''} ${savingStatus === 'error' ? styles.error : ''}`}
          onClick={handleComplete}
          disabled={savingStatus === 'saving'}
        >
          {getCompleteButtonText()}
        </button>
        <button className={`${styles.actionButton} ${styles.printButton}`}>출력</button>
      </div>
    </div>
  );
};

export default EmployeeDetailInfo; 