import React, { useState } from 'react';
import styles from './CustomerManagement.module.css';
import { CUSTOMER_CATEGORIES } from '../../documents/DocumentsModalContent';

const CustomerManagement = ({ customerData, updateCustomerData }) => {
  const [customerForm, setCustomerForm] = useState({
    id: Date.now(),
    name: '',
    contact: '',
    birthdate: '',
    category: '일반',
    lastVisit: '',
    memo: '',
    spending: 0,
    visitCount: 0,
    preferences: '',
    spouse: {
      name: '',
      contact: '',
      birthdate: ''
    },
    children: []
  });
  
  const [isEditing, setIsEditing] = useState(false);
  const [editingId, setEditingId] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  
  const [childForm, setChildForm] = useState({
    name: '',
    birthdate: '',
    gender: '남자'
  });
  
  const [expandedSections, setExpandedSections] = useState({
    basicInfo: true,
    additionalInfo: false,
    familyInfo: false
  });
  
  // 입력 양식 변경 처리
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCustomerForm({
      ...customerForm,
      [name]: value
    });
  };
  
  // 배우자 정보 입력 핸들러
  const handleSpouseChange = (e) => {
    const { name, value } = e.target;
    setCustomerForm({
      ...customerForm,
      spouse: {
        ...customerForm.spouse,
        [name]: value
      }
    });
  };
  
  // 자녀 정보 입력 핸들러
  const handleChildFormChange = (e) => {
    const { name, value } = e.target;
    setChildForm({
      ...childForm,
      [name]: value
    });
  };
  
  // 자녀 추가 핸들러
  const handleAddChild = () => {
    if (!childForm.name) {
      alert('자녀 이름은 필수입력 항목입니다.');
      return;
    }
    
    const newChild = {
      id: Date.now(),
      ...childForm
    };
    
    setCustomerForm({
      ...customerForm,
      children: [...customerForm.children, newChild]
    });
    
    // 자녀 폼 초기화
    setChildForm({
      name: '',
      birthdate: '',
      gender: '남자'
    });
  };
  
  // 자녀 삭제 핸들러
  const handleRemoveChild = (childId) => {
    setCustomerForm({
      ...customerForm,
      children: customerForm.children.filter(child => child.id !== childId)
    });
  };
  
  // 고객 추가 처리
  const handleAddCustomer = (e) => {
    e.preventDefault();
    
    if (!customerForm.name || !customerForm.contact) {
      alert('고객명과 연락처는 필수입력 항목입니다.');
      return;
    }
    
    const newCustomerList = [...customerData, { ...customerForm, id: Date.now() }];
    updateCustomerData(newCustomerList);
    
    // 폼 초기화
    resetForm();
  };
  
  // 고객 수정 모드 전환
  const handleEditCustomer = (customer) => {
    setCustomerForm(customer);
    setIsEditing(true);
    setEditingId(customer.id);
  };
  
  // 고객 수정 적용
  const handleUpdateCustomer = (e) => {
    e.preventDefault();
    
    if (!customerForm.name || !customerForm.contact) {
      alert('고객명과 연락처는 필수입력 항목입니다.');
      return;
    }
    
    const updatedCustomerList = customerData.map(customer => 
      customer.id === editingId ? customerForm : customer
    );
    
    updateCustomerData(updatedCustomerList);
    setIsEditing(false);
    setEditingId(null);
    
    // 폼 초기화
    resetForm();
  };
  
  // 고객 삭제
  const handleDeleteCustomer = (id) => {
    if (window.confirm('정말로 이 고객 정보를 삭제하시겠습니까?')) {
      const filteredCustomers = customerData.filter(customer => customer.id !== id);
      updateCustomerData(filteredCustomers);
    }
  };
  
  // 검색어에 맞는 고객 필터링
  const filteredCustomers = customerData.filter(customer => 
    customer.name.includes(searchTerm) || 
    customer.contact.includes(searchTerm) ||
    customer.category.includes(searchTerm) ||
    customer.memo.includes(searchTerm)
  );
  
  // 폼 초기화 함수 수정
  const resetForm = () => {
    setCustomerForm({
      id: Date.now(),
      name: '',
      contact: '',
      birthdate: '',
      category: '일반',
      lastVisit: '',
      memo: '',
      spending: 0,
      visitCount: 0,
      preferences: '',
      spouse: {
        name: '',
        contact: '',
        birthdate: ''
      },
      children: []
    });
    
    setChildForm({
      name: '',
      birthdate: '',
      gender: '남자'
    });
  };
  
  // 섹션 접기/펼치기 토글 함수
  const toggleSection = (section) => {
    setExpandedSections({
      ...expandedSections,
      [section]: !expandedSections[section]
    });
  };
  
  return (
    <div className={styles.customerManagement}>
      <div className={styles.customerHeader}>
        <div className={styles.searchBar}>
          <input
            type="text"
            placeholder="고객명, 연락처, 카테고리 검색..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <span className={styles.searchIcon}>🔍</span>
        </div>
      </div>
      
      <div className={styles.customerContent}>
        <div className={styles.formContainer}>
          <h3>{isEditing ? '고객 정보 수정' : '새로운 고객 등록'}</h3>
          <form onSubmit={isEditing ? handleUpdateCustomer : handleAddCustomer}>
            {/* 기본 정보 섹션 */}
            <div className={styles.formSection}>
              <div className={styles.sectionHeader} onClick={() => toggleSection('basicInfo')}>
                <h4>기본 정보</h4>
                <span className={styles.toggleIcon}>{expandedSections.basicInfo ? '▼' : '▶'}</span>
              </div>
              
              {expandedSections.basicInfo && (
                <div className={styles.sectionContent}>
                  <div className={styles.formRow}>
                    <div className={styles.formGroup}>
                      <label>고객명 *</label>
                      <input 
                        type="text" 
                        name="name" 
                        value={customerForm.name} 
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <div className={styles.formGroup}>
                      <label>연락처 *</label>
                      <input 
                        type="text" 
                        name="contact" 
                        value={customerForm.contact} 
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                  </div>
                  
                  <div className={styles.formRow}>
                    <div className={styles.formGroup}>
                      <label>생년월일</label>
                      <input 
                        type="date" 
                        name="birthdate" 
                        value={customerForm.birthdate} 
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className={styles.formGroup}>
                      <label>고객 분류</label>
                      <select 
                        name="category" 
                        value={customerForm.category} 
                        onChange={handleInputChange}
                      >
                        {Object.values(CUSTOMER_CATEGORIES).map(category => (
                          <option key={category.code} value={category.code}>
                            {category.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              )}
            </div>
            
            {/* 추가 정보 섹션 */}
            <div className={styles.formSection}>
              <div className={styles.sectionHeader} onClick={() => toggleSection('additionalInfo')}>
                <h4>추가 정보</h4>
                <span className={styles.toggleIcon}>{expandedSections.additionalInfo ? '▼' : '▶'}</span>
              </div>
              
              {expandedSections.additionalInfo && (
                <div className={styles.sectionContent}>
                  <div className={styles.formRow}>
                    <div className={styles.formGroup}>
                      <label>최근 방문일</label>
                      <input 
                        type="date" 
                        name="lastVisit" 
                        value={customerForm.lastVisit} 
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className={styles.formGroup}>
                      <label>누적 지출액</label>
                      <input 
                        type="number" 
                        name="spending" 
                        value={customerForm.spending} 
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  
                  <div className={styles.formRow}>
                    <div className={styles.formGroup}>
                      <label>방문 횟수</label>
                      <input 
                        type="number" 
                        name="visitCount" 
                        value={customerForm.visitCount} 
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  
                  <div className={styles.formRow}>
                    <div className={styles.formGroup}>
                      <label>선호사항</label>
                      <input 
                        type="text" 
                        name="preferences" 
                        value={customerForm.preferences} 
                        onChange={handleInputChange}
                        placeholder="고객의 선호 제품, 서비스, 특이사항 등"
                      />
                    </div>
                  </div>
                  
                  <div className={styles.formRow}>
                    <div className={styles.formGroup}>
                      <label>고객 메모</label>
                      <textarea 
                        name="memo" 
                        value={customerForm.memo} 
                        onChange={handleInputChange}
                        rows="3"
                      ></textarea>
                    </div>
                  </div>
                </div>
              )}
            </div>
            
            {/* 가족 정보 섹션 */}
            <div className={styles.formSection}>
              <div className={styles.sectionHeader} onClick={() => toggleSection('familyInfo')}>
                <h4>가족 정보</h4>
                <span className={styles.toggleIcon}>{expandedSections.familyInfo ? '▼' : '▶'}</span>
              </div>
              
              {expandedSections.familyInfo && (
                <div className={styles.sectionContent}>
                  {/* 배우자 정보 */}
                  <div className={styles.formSubsection}>
                    <h5>배우자</h5>
                    <div className={styles.formRow}>
                      <div className={styles.formGroup}>
                        <label>이름</label>
                        <input 
                          type="text" 
                          name="name" 
                          value={customerForm.spouse.name} 
                          onChange={handleSpouseChange}
                        />
                      </div>
                      <div className={styles.formGroup}>
                        <label>연락처</label>
                        <input 
                          type="text" 
                          name="contact" 
                          value={customerForm.spouse.contact} 
                          onChange={handleSpouseChange}
                        />
                      </div>
                    </div>
                    <div className={styles.formRow}>
                      <div className={styles.formGroup}>
                        <label>생년월일</label>
                        <input 
                          type="date" 
                          name="birthdate" 
                          value={customerForm.spouse.birthdate} 
                          onChange={handleSpouseChange}
                        />
                      </div>
                    </div>
                  </div>
                  
                  {/* 자녀 정보 */}
                  <div className={styles.formSubsection}>
                    <h5>자녀</h5>
                    
                    {/* 자녀 목록 */}
                    {customerForm.children.length > 0 && (
                      <div className={styles.childrenList}>
                        {customerForm.children.map(child => (
                          <div key={child.id} className={styles.childItem}>
                            <div className={styles.childInfo}>
                              <span className={styles.childName}>{child.name}</span>
                              <span className={styles.childDetail}>
                                {child.gender}, {child.birthdate && `${child.birthdate}`}
                              </span>
                            </div>
                            <button 
                              type="button" 
                              className={styles.btnRemoveChild}
                              onClick={() => handleRemoveChild(child.id)}
                            >
                              삭제
                            </button>
                          </div>
                        ))}
                      </div>
                    )}
                    
                    {/* 자녀 추가 폼 */}
                    <div className={styles.formRow}>
                      <div className={styles.formGroup}>
                        <label>이름</label>
                        <input 
                          type="text" 
                          name="name" 
                          value={childForm.name} 
                          onChange={handleChildFormChange}
                        />
                      </div>
                      <div className={styles.formGroup}>
                        <label>성별</label>
                        <select 
                          name="gender" 
                          value={childForm.gender} 
                          onChange={handleChildFormChange}
                        >
                          <option value="남자">남자</option>
                          <option value="여자">여자</option>
                        </select>
                      </div>
                    </div>
                    <div className={styles.formRow}>
                      <div className={styles.formGroup}>
                        <label>생년월일</label>
                        <input 
                          type="date" 
                          name="birthdate" 
                          value={childForm.birthdate} 
                          onChange={handleChildFormChange}
                        />
                      </div>
                      <div className={styles.formGroup}>
                        <button 
                          type="button" 
                          className={styles.btnAddChild}
                          onClick={handleAddChild}
                        >
                          자녀 추가
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            
            <div className={styles.formActions}>
              {isEditing ? (
                <>
                  <button type="submit" className={styles.btnPrimary}>수정 완료</button>
                  <button 
                    type="button" 
                    className={styles.btnSecondary}
                    onClick={() => {
                      setIsEditing(false);
                      setEditingId(null);
                      resetForm();
                    }}
                  >
                    취소
                  </button>
                </>
              ) : (
                <button type="submit" className={styles.btnPrimary}>고객 등록</button>
              )}
            </div>
          </form>
        </div>
        
        <div className={styles.customerList}>          
          <div className={styles.customerList}>
            {filteredCustomers.length > 0 ? (
              filteredCustomers.map(customer => (
                <div key={customer.id} className={styles.customerCard}>
                  <div className={styles.customerCardHeader}>
                    <div className={styles.customerInfo}>
                      <h4>{customer.name}</h4>
                      <span 
                        className={styles.categoryBadge} 
                        style={{ backgroundColor: CUSTOMER_CATEGORIES[customer.category]?.color }}
                      >
                        {CUSTOMER_CATEGORIES[customer.category]?.name || customer.category}
                      </span>
                    </div>
                    <div className={styles.customerActions}>
                      <button 
                        className={styles.btnEdit} 
                        onClick={() => handleEditCustomer(customer)}
                      >
                        수정
                      </button>
                      <button 
                        className={styles.btnDelete} 
                        onClick={() => handleDeleteCustomer(customer.id)}
                      >
                        삭제
                      </button>
                    </div>
                  </div>
                  
                  <div className={styles.customerDetails}>
                    <div className={styles.detailRow}>
                      <span className={styles.detailLabel}>연락처:</span>
                      <span className={styles.detailValue}>{customer.contact}</span>
                    </div>
                    
                    {customer.birthdate && (
                      <div className={styles.detailRow}>
                        <span className={styles.detailLabel}>생년월일:</span>
                        <span className={styles.detailValue}>{customer.birthdate}</span>
                      </div>
                    )}
                    
                    {customer.lastVisit && (
                      <div className={styles.detailRow}>
                        <span className={styles.detailLabel}>최근 방문:</span>
                        <span className={styles.detailValue}>{customer.lastVisit}</span>
                      </div>
                    )}
                    
                    <div className={styles.detailRow}>
                      <span className={styles.detailLabel}>방문 횟수:</span>
                      <span className={styles.detailValue}>{customer.visitCount}회</span>
                    </div>
                    
                    <div className={styles.detailRow}>
                      <span className={styles.detailLabel}>누적 지출:</span>
                      <span className={styles.detailValue}>{customer.spending.toLocaleString()}원</span>
                    </div>
                    
                    {customer.preferences && (
                      <div className={styles.detailRow}>
                        <span className={styles.detailLabel}>선호사항:</span>
                        <span className={styles.detailValue}>{customer.preferences}</span>
                      </div>
                    )}
                    
                    {customer.memo && (
                      <div className={styles.detailRow}>
                        <span className={styles.detailLabel}>메모:</span>
                        <span className={styles.detailValue}>{customer.memo}</span>
                      </div>
                    )}
                    
                    {/* 가족 정보 표시 */}
                    {(customer.spouse && customer.spouse.name) || (customer.children && customer.children.length > 0) ? (
                      <div className={styles.detailSection}>
                        <h5 className={styles.sectionTitle}>가족 정보</h5>
                        
                        {customer.spouse && customer.spouse.name && (
                          <div className={styles.detailRow}>
                            <span className={styles.detailLabel}>배우자:</span>
                            <span className={styles.detailValue}>
                              {customer.spouse.name} 
                              {customer.spouse.contact && ` (${customer.spouse.contact})`}
                              {customer.spouse.birthdate && ` - ${customer.spouse.birthdate}`}
                            </span>
                          </div>
                        )}
                        
                        {customer.children && customer.children.length > 0 && (
                          <div className={styles.detailRow}>
                            <span className={styles.detailLabel}>자녀:</span>
                            <div className={styles.detailValue}>
                              {customer.children.map(child => (
                                <div key={child.id} className={styles.childInfoItem}>
                                  {child.name} ({child.gender})
                                  {child.birthdate && ` - ${child.birthdate}`}
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}
                  </div>
                </div>
              ))
            ) : (
              <div className={styles.noCustomers}>
                <p>등록된 고객이 없습니다.</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerManagement; 