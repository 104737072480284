import React from 'react';
import styles from './ScheduleList.module.css';

// 일정 목록
const ScheduleList = ({ 
  schedules, 
  selectedDate, 
  onEditSchedule, 
  onDeleteSchedule 
}) => {
  // 선택된 날짜의 일정만 필터링
  const filteredSchedules = schedules.filter(schedule => 
    new Date(schedule.date.toDate()).toDateString() === selectedDate.toDateString()
  );

  return (
    <div className={styles.scheduleList}>
      <h3>예약된 일정 ({selectedDate.toLocaleDateString()})</h3>
      {filteredSchedules.length > 0 ? (
        filteredSchedules.map(schedule => (
          <div key={schedule.id} className={styles.scheduleCard}>
            <div className={styles.scheduleTime}>
              {schedule.time}
              {schedule.endTime && <span className={styles.scheduleTimeDivider}>~</span>}
              {schedule.endTime && <span className={styles.scheduleEndTime}>{schedule.endTime}</span>}
            </div>
            <div className={styles.scheduleInfo}>
              <div className={styles.scheduleName}>{schedule.name}</div>
              <div className={styles.scheduleContact}>{schedule.contact}</div>
              {schedule.amount > 0 && <div className={styles.scheduleAmount}>{schedule.amount.toLocaleString()}원</div>}
              {schedule.employeeName && (
                <div className={styles.scheduleEmployee}>
                  <span className={styles.employeeBadge}>담당: {schedule.employeeName}</span>
                </div>
              )}
              {schedule.note && <div className={styles.scheduleNote}>{schedule.note}</div>}
            </div>
            <div className={styles.scheduleActions}>
              <button 
                className={styles.btnEditSchedule} 
                onClick={() => onEditSchedule(schedule)}
              >
                <i className={styles.iconEdit}></i>
                <span>수정</span>
              </button>
              <button 
                className={styles.btnDeleteSchedule} 
                onClick={() => onDeleteSchedule(schedule.id, schedule)}
              >
                <i className={styles.iconDelete}></i>
                <span>삭제</span>
              </button>
            </div>
          </div>
        ))
      ) : (
        <div className={styles.noSchedules}>예약된 일정이 없습니다.</div>
      )}
    </div>
  );
};

export default ScheduleList; 