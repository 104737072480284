import React, { useState, useEffect } from 'react';
import './Contracts.css';

const BaseContract = ({ 
  children, 
  title, 
  onSave, 
  party,
  existingData,
  contractType,
  isValid = true 
}) => {
  // 기본 계약서 데이터 구조
  const [formData, setFormData] = useState({
    // 계약 기본 정보
    contractType: contractType,    // 계약 종류 (EMP, SVC, SUP, OUT)
    contractDate: new Date().toISOString(),  // 계약 체결일
    status: 'draft',              // 계약 상태 (draft, active, completed, terminated)
    
    // 당사자 정보
    partyType: party?.type || '', // 계약 당사자 유형 (customer, employee)
    partyId: party?.id || '',     // 계약 당사자 ID
    partyName: party?.name || '', // 계약 당사자 이름
    
    // 계약 기간
    startDate: '',                // 계약 시작일
    endDate: '',                  // 계약 종료일
    
    // 계약 조건
    terms: {                      // 계약 조건들
      amount: '',                 // 계약 금액
      paymentTerms: '',          // 지급 조건
      paymentSchedule: '',       // 지급 일정
      specialConditions: '',     // 특별 조건
    },
    
    // 계약 이행 관련
    scheduleConfig: {            // 스케줄 설정
      type: '',                  // 스케줄 유형
      frequency: '',            // 주기
      details: ''               // 상세 설정
    },
    
    // 메타 데이터
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    createdBy: '',              // 작성자
    attachments: [],            // 첨부파일 목록
    
    // 추가 필드 (하위 계약서별 확장용)
    customFields: {}
  });

  useEffect(() => {
    if (existingData) {
      setFormData(prev => ({
        ...prev,
        ...existingData
      }));
    }
  }, [existingData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    
    // 중첩 객체 처리 (terms., scheduleConfig. 등)
    if (name.includes('.')) {
      const [parent, child] = name.split('.');
      setFormData(prev => ({
        ...prev,
        [parent]: {
          ...prev[parent],
          [child]: value
        }
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isValid) {
      onSave({
        ...formData,
        updatedAt: new Date().toISOString()
      });
    }
  };

  // 기본 입력 필드 렌더링
  const renderBaseFields = () => (
    <>
      <div className="contract-section">
        <h3>계약 기본 정보</h3>
        <div className="form-group">
          <label>계약 상태</label>
          <select
            name="status"
            value={formData.status}
            onChange={handleChange}
            required
          >
            <option value="draft">임시저장</option>
            <option value="active">활성</option>
            <option value="completed">완료</option>
            <option value="terminated">해지</option>
          </select>
        </div>
      </div>

      <div className="contract-section">
        <h3>계약 기간</h3>
        <div className="form-group">
          <label>시작일</label>
          <input
            type="date"
            name="startDate"
            value={formData.startDate}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label>종료일</label>
          <input
            type="date"
            name="endDate"
            value={formData.endDate}
            onChange={handleChange}
          />
        </div>
      </div>

      <div className="contract-section">
        <h3>계약 조건</h3>
        <div className="form-group">
          <label>계약 금액</label>
          <input
            type="number"
            name="terms.amount"
            value={formData.terms.amount}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label>지급 조건</label>
          <textarea
            name="terms.paymentTerms"
            value={formData.terms.paymentTerms}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label>지급 일정</label>
          <textarea
            name="terms.paymentSchedule"
            value={formData.terms.paymentSchedule}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>특별 조건</label>
          <textarea
            name="terms.specialConditions"
            value={formData.terms.specialConditions}
            onChange={handleChange}
          />
        </div>
      </div>
    </>
  );

  return (
    <div className="contract-container">
      <h2 className="contract-title">{title}</h2>
      <form onSubmit={handleSubmit} className="contract-form">
        <div className="contract-header">
          <p>계약일: {new Date(formData.contractDate).toLocaleDateString()}</p>
          <p>계약 당사자: {formData.partyName}</p>
        </div>
        
        {renderBaseFields()}
        {children}
        
        <div className="contract-actions">
          <button type="submit" disabled={!isValid}>
            계약서 저장
          </button>
        </div>
      </form>
    </div>
  );
};

export default BaseContract; 