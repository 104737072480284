import React from 'react';
import styles from './EmployeeBasicInfo.module.css';

const EmployeeBasicInfo = ({ employee, formatNumber, toggleDetails, isCompleted }) => {
  return (
    <div className={styles.payrollHeader} onClick={toggleDetails}>
      <div className={styles.employeeBasicInfo}>
        <span className={styles.employeeName}>
          {employee.name}
          {isCompleted && 
            <span className={styles.completedBadge}>완료</span>
          }
        </span>
        <span className={styles.workTime}>
          {employee.totalWorkDays || 0}일 / {((employee.totalWorkTime / 60) || 0).toFixed(2)}시간
          {employee.totalLeaveValue > 0 && ` / 휴가 ${employee.totalLeaveValue}일`}
        </span>
      </div>
      <div className={styles.payrollSummaryRow}>
        <div className={styles.summaryItem}>
          <span className={styles.summaryLabel}>수당</span>
          <span className={styles.summaryValue}>{formatNumber(
            employee.legalPayTotal + (employee.adjustmentPay || 0)
          )}</span>
        </div>
        <div className={styles.summaryItem}>
          <span className={styles.summaryLabel}>추가수당</span>
          <span className={styles.summaryValue}>{formatNumber(
            (employee.mealAllowance || 0) +
            (employee.carAllowance || 0) +
            (employee.rewardPay || 0) +
            (employee.bonusPay || 0) +
            (employee.etcAllowance || 0)
          )}</span>
        </div>
        <div className={styles.summaryItem}>
          <span className={styles.summaryLabel}>공제</span>
          <span className={styles.summaryValue}>
            {formatNumber(
              (employee.pensionInsurance || 0) + 
              (employee.healthInsurance || 0) + 
              (employee.longTermInsurance || 0) + 
              (employee.employmentInsurance || 0) +
              (employee.incomeTax || 0) + 
              (employee.localTax || 0)
            )}
          </span>
        </div>
        <div className={`${styles.summaryItem} ${styles.highlightSummary}`}>
          <span className={styles.summaryLabel}>실지급액</span>
          <span className={styles.summaryValue}>{formatNumber(employee.netPay)}</span>
        </div>
      </div>
    </div>
  );
};

export default EmployeeBasicInfo; 