// 필요한 SDK 함수들을 가져오기
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from 'firebase/storage';

// 파이어베이스 설정
const firebaseConfig = {
  apiKey: "AIzaSyALb-p4EeckSjOFjqxiKrg5pSq3cc_awvo",
  authDomain: "caresalaryrp.firebaseapp.com",
  projectId: "caresalaryrp",
  storageBucket: "caresalaryrp.appspot.com",
  messagingSenderId: "1022386277859",
  appId: "1:1022386277859:web:84a8323c8fa3b83d7d64d6",
  measurementId: "G-1FN7FLSNLB"
};

// 파이어베이스 초기화
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const firestore = getFirestore(app);
const analytics = getAnalytics(app);

// Firestore 초기화
const db = getFirestore(app);

// Storage 초기화
const storage = getStorage(app);

// 필요한 모듈 내보내기
export { auth, db, storage, firestore, analytics };