module.exports = {
    '202501-203001': {
        insur1: 0.045,
        insur2: 0.03545,
        insur2Long: 0.1295,
        insur3: 0.009,
        insur3Sub: 0.0025,
        insur4: 0.0072,
        ret: 0.08333,
        insur1Min: 390000,
        insur2Min: 278990
    },
    '202407-202412': {
        insur1: 0.045,
        insur2: 0.03545,
        insur2Long: 0.1295,
        insur3: 0.009,
        insur3Sub: 0.0025,
        insur4: 0.0072,
        ret: 0.08333,
        insur1Min: 390000,
        insur2Min: 278990
    },
    '202401-202406': {
        insur1: 0.045,
        insur2: 0.03545,
        insur2Long: 0.1295,
        insur3: 0.009,
        insur3Sub: 0.0025,
        insur4: 0.0072,
        ret: 0.08333,
        insur1Min: 370000,
        insur2Min: 278990
    },
    '202307-202312': {
        insur1: 0.045,
        insur2: 0.03545,
        insur2Long: 0.1281,
        insur3: 0.009,
        insur3Sub: 0.0025,
        insur4: 0.00763,
        ret: 0.08333,
        insur1Min: 370000,
        insur2Min: 278990
    },
    '202301-202306': {
        insur1: 0.045,
        insur2: 0.03545,
        insur2Long: 0.1281,
        insur3: 0.009,
        insur3Sub: 0.0025,
        insur4: 0.00763,
        ret: 0.08333,
        insur1Min: 350000,
        insur2Min: 278990
    },
    '202207-202212': {
        insur1: 0.045,
        insur2: 0.03495,
        insur2Long: 0.1227,
        insur3: 0.009,
        insur3Sub: 0.0025,
        insur4: 0.0076,
        ret: 0.08333,
        insur1Min: 350000,
        insur2Min: 278990
    },
    '202201-202206': {
        insur1: 0.045,
        insur2: 0.03495,
        insur2Long: 0.1227,
        insur3: 0.008,
        insur3Sub: 0.0025,
        insur4: 0.0076,
        ret: 0.08333,
        insur1Min: 9999999,
        insur2Min: 9999999
    },
    '202101-202112': {
        insur1: 0.045,
        insur2: 0.03430,
        insur2Long: 0.1152,
        insur3: 0.008,
        insur3Sub: 0.0025,
        insur4: 0.0076,
        ret: 0.08333,
        insur1Min: 9999999,
        insur2Min: 9999999
    },
    '202001-202012': {
        insur1: 0.045,
        insur2: 0.03335,
        insur2Long: 0.1025,
        insur3: 0.008,
        insur3Sub: 0.0025,
        insur4: 0.0076,
        ret: 0.08333,
        insur1Min: 9999999,
        insur2Min: 9999999
    },
    '201910-201912': {
        insur1: 0.045,
        insur2: 0.03230,
        insur2Long: 0.0851,
        insur3: 0.008,
        insur3Sub: 0.0025,
        insur4: 0.0076,
        ret: 0.08333,
        insur1Min: 9999999,
        insur2Min: 9999999
    },
    '201901-201909': {
        insur1: 0.045,
        insur2: 0.03230,
        insur2Long: 0.0851,
        insur3: 0.0065,
        insur3Sub: 0.0025,
        insur4: 0.0076,
        ret: 0.08333,
        insur1Min: 9999999,
        insur2Min: 9999999
    }
};