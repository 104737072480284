import React from 'react';
import styles from './Loading.module.css';

const Loading = ({ message = '불러오는 중...' }) => {
  return (
    <div className={styles.diaryLoadingContainer}>
      <div className={styles.diaryLoadingSpinner}></div>
      <p className={styles.diaryLoadingText}>{message}</p>
    </div>
  );
};

export default Loading; 