import React from 'react';
import CustomerSearchDropdown from './CustomerSearchDropdown';
import EmployeeSearchDropdown from './EmployeeSearchDropdown';
import styles from './ScheduleForm.module.css';

// 일정 입력 수정 폼
const ScheduleForm = ({
  schedule,
  isEditing,
  onInputChange,
  onAddSchedule,
  onUpdateSchedule,
  onCancelEdit,
  showCustomerList,
  setShowCustomerList,
  searchTerm,
  setSearchTerm,
  customerData,
  onSelectCustomer,
  showEmployeeList,
  setShowEmployeeList,
  employeeSearchTerm,
  setEmployeeSearchTerm,
  employees,
  onSelectEmployee,
  onClearEmployee
}) => {
  return (
    <div className={styles.scheduleForm}>
      <h3>{isEditing ? '일정 수정' : '새 일정 추가'}</h3>
      <div className={styles.formRow}>
        <div className={styles.formGroup}>
          <label>시작 시간 *</label>
          <input 
            type="text" 
            name="time" 
            placeholder="시작 시간 (필수)" 
            value={schedule.time} 
            onChange={onInputChange}
            required 
          />
        </div>
        <div className={styles.formGroup}>
          <label>종료 시간</label>
          <input 
            type="text" 
            name="endTime" 
            placeholder="종료 시간 (선택)" 
            value={schedule.endTime} 
            onChange={onInputChange} 
          />
        </div>
      </div>
      <div className={`${styles.formGroup} ${styles.customerSearchGroup}`}>
        <label>예약자명</label>
        <div className={styles.customerSearchInput}>
          <input 
            type="text" 
            name="name" 
            placeholder="예약자명" 
            value={schedule.name} 
            onChange={onInputChange}
            onClick={() => !isEditing && setShowCustomerList(true)}
          />
          <button 
            type="button" 
            className={styles.btnSearchCustomer}
            onClick={() => !isEditing && setShowCustomerList(!showCustomerList)}
          >
            {showCustomerList ? '닫기' : '고객 검색'}
          </button>
        </div>
        <CustomerSearchDropdown
          visible={showCustomerList && !isEditing}
          searchTerm={searchTerm}
          onSearchChange={setSearchTerm}
          customers={customerData}
          onSelectCustomer={onSelectCustomer}
        />
      </div>
      <div className={styles.formGroup}>
        <label>연락처</label>
        <input 
          type="text" 
          name="contact" 
          placeholder="연락처" 
          value={schedule.contact} 
          onChange={onInputChange} 
        />
      </div>
      <div className={styles.formGroup}>
        <label>금액</label>
        <input 
          type="number" 
          name="amount" 
          placeholder="금액" 
          value={schedule.amount} 
          onChange={onInputChange} 
        />
      </div>
      <div className={styles.formGroup}>
        <label>비고</label>
        <input 
          type="text" 
          name="note" 
          placeholder="비고" 
          value={schedule.note} 
          onChange={onInputChange} 
        />
      </div>
      <div className={`${styles.formGroup} ${styles.employeeSearchGroup}`}>
        <label>담당 근로자</label>
        <div className={styles.employeeSearchInput}>
          {schedule.employeeId ? (
            <div className={styles.selectedEmployee}>
              <span>{schedule.employeeName}</span>
              <button
                type="button"
                className={styles.btnClearEmployee}
                onClick={onClearEmployee}
              >
                ✕
              </button>
            </div>
          ) : (
            <>
              <input
                type="text"
                placeholder="담당 근로자 선택..."
                value={schedule.employeeName}
                readOnly
                onClick={() => setShowEmployeeList(!showEmployeeList)}
              />
              <button
                type="button"
                className={styles.btnSearchEmployee}
                onClick={() => setShowEmployeeList(!showEmployeeList)}
              >
                {showEmployeeList ? '닫기' : '근로자 검색'}
              </button>
            </>
          )}
        </div>
        <EmployeeSearchDropdown
          visible={showEmployeeList}
          searchTerm={employeeSearchTerm}
          onSearchChange={setEmployeeSearchTerm}
          employees={employees}
          onSelectEmployee={onSelectEmployee}
        />
      </div>
      <div className={styles.formActions}>
        {isEditing ? (
          <>
            <button className={`${styles.scheduleButton} ${styles.update}`} onClick={onUpdateSchedule}>수정 완료</button>
            <button className={`${styles.scheduleButton} ${styles.cancel}`} onClick={onCancelEdit}>취소</button>
          </>
        ) : (
          <button className={styles.scheduleButton} onClick={onAddSchedule}>일정 추가</button>
        )}
      </div>
    </div>
  );
};

export default ScheduleForm; 