import React, { useState, useEffect } from 'react';
import BaseContract from './BaseContract';
import './Contracts.css';

const ServiceContract = ({ party, existingData, onSave }) => {
  const [formData, setFormData] = useState({
    serviceDetails: '',
    servicePrice: '',
    servicePeriod: '',
    paymentTerms: '',
    responsibilities: '',
    terminationTerms: '',
    otherConditions: '',
  });

  useEffect(() => {
    if (existingData) {
      setFormData(existingData);
    }
  }, [existingData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSave = () => {
    onSave(formData);
  };

  return (
    <BaseContract 
      title="서비스 제공 계약서" 
      onSave={handleSave}
      isValid={formData.serviceDetails && formData.servicePrice}
    >
      <div className="contract-header">
        <p>제공자: [회사명] (이하 "제공자"라 함)</p>
        <p>이용자: {party.name} (이하 "이용자"라 함)</p>
      </div>

      <div className="contract-section">
        <h3>제1조 (서비스 내용)</h3>
        <div className="form-group">
          <textarea
            name="serviceDetails"
            value={formData.serviceDetails}
            onChange={handleChange}
            placeholder="제공할 서비스의 상세 내용"
            required
          />
        </div>
      </div>

      <div className="contract-section">
        <h3>제2조 (서비스 요금)</h3>
        <div className="form-group">
          <input
            type="number"
            name="servicePrice"
            value={formData.servicePrice}
            onChange={handleChange}
            placeholder="서비스 요금"
            required
          />
        </div>
      </div>

      <div className="contract-section">
        <h3>제3조 (서비스 기간)</h3>
        <div className="form-group">
          <input
            type="text"
            name="servicePeriod"
            value={formData.servicePeriod}
            onChange={handleChange}
            placeholder="서비스 제공 기간"
            required
          />
        </div>
      </div>

      <div className="contract-section">
        <h3>제4조 (대금 지급 조건)</h3>
        <div className="form-group">
          <textarea
            name="paymentTerms"
            value={formData.paymentTerms}
            onChange={handleChange}
            placeholder="지급 조건 및 방법"
            required
          />
        </div>
      </div>

      <div className="contract-section">
        <h3>제5조 (당사자의 의무)</h3>
        <div className="form-group">
          <textarea
            name="responsibilities"
            value={formData.responsibilities}
            onChange={handleChange}
            placeholder="각 당사자의 의무사항"
          />
        </div>
      </div>

      <div className="contract-section">
        <h3>제6조 (계약의 해지)</h3>
        <div className="form-group">
          <textarea
            name="terminationTerms"
            value={formData.terminationTerms}
            onChange={handleChange}
            placeholder="계약 해지 조건"
          />
        </div>
      </div>

      <div className="contract-section">
        <h3>제7조 (기타)</h3>
        <div className="form-group">
          <textarea
            name="otherConditions"
            value={formData.otherConditions}
            onChange={handleChange}
            placeholder="기타 계약조건"
          />
        </div>
      </div>
    </BaseContract>
  );
};

export default ServiceContract; 