import React, { useState, useEffect } from 'react';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../../../api/firebase/firebaseConfig';
import { getAuth } from 'firebase/auth';
import styles from './Diary.module.css';
import PhotoBook from './PhotoBook';
import Bookmark from './Bookmark';
import DiaryModal from './DiaryModal';
import Loading from '../../../modals/Loading';

// 다이어리 컴포넌트
export default function Diary() {
  const [diaryData, setDiaryData] = useState({});
  const [selectedMonth, setSelectedMonth] = useState(new Date());
  const [isLoading, setIsLoading] = useState(true);
  const [modalInfo, setModalInfo] = useState(null);
  const [view, setView] = useState('calendar'); // 'calendar' 또는 'photobook'
  const auth = getAuth();

  // 다이어리 데이터 불러오기
  useEffect(() => {
    const fetchDiary = async () => {
      setIsLoading(true);
      try {
        if (!auth.currentUser) {
          console.error('로그인이 필요합니다.');
          setIsLoading(false);
          return;
        }

        const year = selectedMonth.getFullYear();
        const month = selectedMonth.getMonth() + 1;
        const monthKey = `${year}${month.toString().padStart(2, '0')}`;
        
        const diaryDocRef = doc(db, 'diary', auth.currentUser.uid);
        const diaryDoc = await getDoc(diaryDocRef);

        if (diaryDoc.exists()) {
          const monthData = diaryDoc.data()[monthKey] || {};
          setDiaryData(monthData);
        } else {
          await setDoc(diaryDocRef, { [monthKey]: {} });
          setDiaryData({});
        }
      } catch (error) {
        console.error('다이어리 데이터 로딩 실패:', error);
        setDiaryData({});
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchDiary();
  }, [selectedMonth, auth.currentUser]);

  // 선택된 월의 날짜 배열 생성
  const getDaysInMonth = () => {
    const year = selectedMonth.getFullYear();
    const month = selectedMonth.getMonth();
    const firstDay = new Date(year, month, 1).getDay(); // 이번 달 1일의 요일 (0: 일, 1: 월, ...)
    const lastDay = new Date(year, month + 1, 0).getDate(); // 이번 달 마지막 날짜
    
    // 이전 달의 마지막 몇 일을 표시할지 계산
    const prevMonthLastDate = new Date(year, month, 0).getDate();
    const prevMonthDays = firstDay === 0 ? [] : Array.from(
      {length: firstDay}, 
      (_, i) => ({ day: prevMonthLastDate - firstDay + i + 1, currentMonth: false, prevMonth: true })
    );
    
    // 이번 달 날짜
    const currentMonthDays = Array.from(
      {length: lastDay}, 
      (_, i) => ({ day: i + 1, currentMonth: true })
    );
    
    // 다음 달 첫 몇 일을 표시할지 계산
    const totalCells = Math.ceil((firstDay + lastDay) / 7) * 7;
    const nextMonthDays = Array.from(
      {length: totalCells - (prevMonthDays.length + currentMonthDays.length)}, 
      (_, i) => ({ day: i + 1, currentMonth: false, nextMonth: true })
    );
    
    return [...prevMonthDays, ...currentMonthDays, ...nextMonthDays];
  };

  // 다이어리 데이터 업데이트
  const updateDiaryData = async (date, data) => {
    try {
      const year = selectedMonth.getFullYear();
      const month = selectedMonth.getMonth() + 1;
      const monthKey = `${year}${month.toString().padStart(2, '0')}`;
      const dateKey = date.toString().padStart(2, '0');
      
      const diaryDocRef = doc(db, 'diary', auth.currentUser.uid);
      
      await updateDoc(diaryDocRef, {
        [`${monthKey}.${dateKey}`]: data
      });
      
      // 로컬 상태 업데이트
      setDiaryData(prev => ({
        ...prev,
        [dateKey]: data
      }));
      
      // 커스텀 이벤트 발생 - 다이어리 데이터 업데이트 알림 --- 차후 리덕스로 교체
      const diaryUpdateEvent = new CustomEvent('diaryDataUpdated', {
        detail: { monthKey, dateKey, data }
      });
      window.dispatchEvent(diaryUpdateEvent);
    } catch (error) {
      console.error('다이어리 데이터 업데이트 실패:', error);
    }
  };

  // 금액을 단위에 맞게 축약하는 함수 (자릿수 표시 보장)
  const formatCurrency = (amount) => {
    if (amount >= 10000000) { // 1천만원 이상
      return `${(amount / 10000000).toFixed(1).replace(/\.0$/, '')}천만`;
    } else if (amount >= 10000) { // 1만원 이상
      return `${(amount / 10000).toFixed(1).replace(/\.0$/, '')}만`;
    } else if (amount >= 1000) { // 1천원 이상
      return `${Math.floor(amount / 1000)}천`;
    } else {
      return `${amount}원`;
    }
  };

  return (
    <div className={styles.diaryContainer}>
      <div className={styles.diaryInner}>
        <div className={`${styles.diaryDoodle} ${styles.topLeft}`}></div>
        <div className={`${styles.diaryDoodle} ${styles.topRight}`}></div>
        <div className={`${styles.diaryDoodle} ${styles.bottomLeft}`}></div>
        <div className={`${styles.diaryDoodle} ${styles.bottomRight}`}></div>
        
        <div className={styles.diaryContent}>
          {isLoading ? (
            <Loading message="다이어리 불러오는 중..." />
          ) : view === 'calendar' ? (
            <>
              <div className={styles.diaryHeader}>
                <div className={`${styles.diaryHeaderDecoration} ${styles.left}`}></div>
                <h2>나의 다이어리</h2>
                <div className={`${styles.diaryHeaderDecoration} ${styles.right}`}></div>
                
                <div className={styles.diaryMonthSelector}>
                  <button 
                    className={`${styles.monthButton} ${styles.prevMonth}`}
                    onClick={() => {
                      const year = selectedMonth.getFullYear();
                      const month = selectedMonth.getMonth();
                      
                      // 이전 달로 이동 (0월이면 이전 연도 12월로)
                      const newMonth = month === 0 ? 11 : month - 1;
                      const newYear = month === 0 ? year - 1 : year;
                      
                      // 새 날짜 생성 (이전 버그 방지를 위해 1일로 고정)
                      const newDate = new Date(newYear, newMonth, 1);
                      console.log(`이전 달 버튼: ${year}년 ${month+1}월 -> ${newYear}년 ${newMonth+1}월`, newDate);
                      
                      setSelectedMonth(newDate);
                    }}
                  >
                    <span className={styles.arrowIcon}>◀</span>
                    <span>이전 달</span>
                  </button>
                  <div className={styles.currentMonth}>
                    <div className={styles.monthBubble}>
                      {selectedMonth.getFullYear()}년 {selectedMonth.getMonth() + 1}월
                    </div>
                  </div>
                  <button 
                    className={`${styles.monthButton} ${styles.nextMonth}`}
                    onClick={() => {
                      const year = selectedMonth.getFullYear();
                      const month = selectedMonth.getMonth();
                      
                      // 다음 달로 이동 (11월이면 다음 연도 1월로)
                      const newMonth = month === 11 ? 0 : month + 1;
                      const newYear = month === 11 ? year + 1 : year;
                      
                      // 새 날짜 생성 (이전 버그 방지를 위해 1일로 고정)
                      const newDate = new Date(newYear, newMonth, 1);
                      console.log(`다음 달 버튼: ${year}년 ${month+1}월 -> ${newYear}년 ${newMonth+1}월`, newDate);
                      
                      setSelectedMonth(newDate);
                    }}
                  >
                    <span>다음 달</span>
                    <span className={styles.arrowIcon}>▶</span>
                  </button>
                </div>
              </div>
              
              <div className={styles.diaryCalendarWrapper}>
                <div className={styles.diaryCalendar}>
                  <div className={styles.diaryWeekdays}>
                    {['일', '월', '화', '수', '목', '금', '토'].map((day, index) => (
                      <div 
                        key={day} 
                        className={`${styles.diaryWeekday} 
                          ${index === 0 ? styles.sunday : ''} 
                          ${index === 6 ? styles.saturday : ''}`}
                      >
                        {day}
                      </div>
                    ))}
                  </div>
                  
                  <div className={styles.diaryDays}>
                    {getDaysInMonth().map((dayInfo, index) => {
                      const dateKey = dayInfo.currentMonth ? dayInfo.day.toString().padStart(2, '0') : null;
                      const dayData = dateKey ? diaryData[dateKey] : null;
                      
                      return (
                        <div 
                          key={index} 
                          className={`${styles.diaryDay}
                            ${!dayInfo.currentMonth ? styles.otherMonth : ''} 
                            ${dayData ? styles.hasData : ''} 
                            ${dayInfo.currentMonth && index % 7 === 0 ? styles.sunday : ''} 
                            ${dayInfo.currentMonth && index % 7 === 6 ? styles.saturday : ''}`
                          }
                          onClick={() => {
                            if (dayInfo.currentMonth) {
                              setModalInfo({
                                date: dayInfo.day,
                                data: dayData || {}
                              });
                            }
                          }}
                        >
                          <div className={styles.diaryDayNumber}>{dayInfo.day}</div>
                          {dayData && dayData.memoList && dayData.memoList.length > 0 && (
                            <div className={styles.diaryDayContent}>
                              {dayData.memoList.some(item => item.sales) && (
                                <div className={styles.diarySales}>
                                  <div className={`${styles.diaryIconBubble} ${styles.salesBubble}`}>
                                    매{formatCurrency(dayData.memoList.reduce((sum, item) => sum + (Number(item.sales) || 0), 0))}
                                  </div>
                                </div>
                              )}
                              
                              {dayData.memoList.some(item => item.expense) && (
                                <div className={styles.diaryExpense}>
                                  <div className={`${styles.diaryIconBubble} ${styles.expenseBubble}`}>
                                    지{formatCurrency(dayData.memoList.reduce((sum, item) => sum + (Number(item.expense) || 0), 0))}
                                  </div>
                                </div>
                              )}
                              
                              {dayData.memoList.length > 0 && (
                                <div className={styles.diaryMemoCount}>
                                  <div className={styles.memoSticker}>
                                    메모{dayData.memoList.length}
                                  </div>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <PhotoBook />
          )}
        </div>
        
        <Bookmark view={view} setView={setView} />
      </div>

      {modalInfo && (
        <DiaryModal
          info={modalInfo}
          onClose={() => setModalInfo(null)}
          selectedMonth={selectedMonth}
          updateDiaryData={updateDiaryData}
        />
      )}
    </div>
  );
}
