import React, { useState } from 'react';
import { auth } from '../../api/firebase/firebaseConfig';
import { signInWithEmailAndPassword, setPersistence, browserSessionPersistence, signOut } from 'firebase/auth';
import { useAuth } from '../../hooks/useAuth';
import style from './Login.module.css';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const { setIsAuthenticated } = useAuth();
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setErrorMessage('');

    try {
      // 세션 지속성을 "세션 지속" 모드로 설정 (브라우저 닫으면 로그아웃)
      await setPersistence(auth, browserSessionPersistence);

      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const token = await userCredential.user.getIdToken();

      localStorage.setItem('authToken', token);
      setIsAuthenticated(true);
      navigate('/', { replace: true });

      // 1시간 후 자동 로그아웃 (강제 만료)
      setTimeout(() => {
        signOut(auth).then(() => {
          console.log('1시간 후 자동 로그아웃됨!');
          setIsAuthenticated(false);
          navigate('/auth', { replace: true });
        });
      }, 60 * 60 * 1000); // 1시간 (3600000ms)

    } catch (error) {
      console.error('로그인 실패:', error);
      let message = '';
      switch (error.code) {
        case 'auth/invalid-email':
          message = '유효하지 않은 이메일입니다.';
          break;
        case 'auth/user-disabled':
          message = '사용이 중지된 계정입니다.';
          break;
        case 'auth/user-not-found':
          message = '존재하지 않는 계정입니다.';
          break;
        case 'auth/wrong-password':
          message = '잘못된 비밀번호입니다.';
          break;
        case 'auth/invalid-credential':
          message = '유효하지 않은 정보입니다.';
          break;
        default:
          message = error.message;
      }
      setErrorMessage(message);
    }
  };

  const handleDemoLogin = async (e) => {
    e.preventDefault();
    setErrorMessage('');
    
    // 테스트 계정 정보 설정
    const demoEmail = 'test@caresalary.co.kr';
    const demoPassword = '123456';
    
    try {
      // 세션 지속성을 "세션 지속" 모드로 설정 (브라우저 닫으면 로그아웃)
      await setPersistence(auth, browserSessionPersistence);

      const userCredential = await signInWithEmailAndPassword(auth, demoEmail, demoPassword);
      const token = await userCredential.user.getIdToken();

      localStorage.setItem('authToken', token);
      setIsAuthenticated(true);
      navigate('/', { replace: true });

      // 1시간 후 자동 로그아웃 (강제 만료)
      setTimeout(() => {
        signOut(auth).then(() => {
          console.log('1시간 후 자동 로그아웃됨!');
          setIsAuthenticated(false);
          navigate('/auth', { replace: true });
        });
      }, 60 * 60 * 1000); // 1시간 (3600000ms)

    } catch (error) {
      console.error('둘러보기 로그인 실패:', error);
      let message = '';
      switch (error.code) {
        case 'auth/invalid-email':
          message = '유효하지 않은 이메일입니다.';
          break;
        case 'auth/user-disabled':
          message = '사용이 중지된 계정입니다.';
          break;
        case 'auth/user-not-found':
          message = '존재하지 않는 계정입니다.';
          break;
        case 'auth/wrong-password':
          message = '잘못된 비밀번호입니다.';
          break;
        case 'auth/invalid-credential':
          message = '유효하지 않은 정보입니다.';
          break;
        default:
          message = error.message;
      }
      setErrorMessage(message);
    }
  };

  return (
    <form onSubmit={handleLogin} className={style.loginForm}>
      <div className={style.formGroup}>
        <label>이메일:</label>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          className={style.formInput}
        />
      </div>
      <div className={style.formGroup}>
        <label>비밀번호:</label>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          className={style.formInput}
        />
      </div>
      <button type="submit" className={style.loginButton}>로그인</button>
      <button type="button" onClick={handleDemoLogin} className={style.demoButton}>둘러보기</button>
      {errorMessage && <div className={style.errorMessage}>{errorMessage}</div>}
    </form>
  );
};

export default Login;
