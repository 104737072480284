import React, { useState } from 'react';
import { getBizAnalysis } from '../../../../api/get/getBizAnalysis';
import styles from './BizAnalysis.module.css';

// 사업 분석 페이지
const BizAnalysis = () => {
  const [selectedArea, setSelectedArea] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [analysisData, setAnalysisData] = useState(null);

  // TODO 분석 데이터 요청 함수
  const requestAnalysis = async () => {
    try {
      // API 호출
      const response = await getBizAnalysis({
        area: selectedArea,
        category: selectedCategory
      });
      
      setAnalysisData(response.data);
    } catch (error) {
      console.error('분석 요청 실패:', error);
      // 에러 처리 로직 추가 필요
    }
  };

  return (
    <div className={styles.bizAnalysisContent}>
      <h1 className={styles.analysisTitle}>사업 분석</h1>
      
      <div className={styles.analysisFilters}>
        <div className={styles.filterSection}>
          <h3>지역 선택</h3>
          <select 
            value={selectedArea} 
            onChange={(e) => setSelectedArea(e.target.value)}
            className={styles.areaSelect}
          >
            <option value="">지역을 선택하세요</option>
            <option value="gangnam">강남구</option>
            <option value="mapo">마포구</option>
            <option value="jongno">종로구</option>
            <option value="songpa">송파구</option>
            <option value="yongsan">용산구</option>
          </select>
        </div>

        <div className={styles.filterSection}>
          <h3>업종 선택</h3>
          <select 
            value={selectedCategory} 
            onChange={(e) => setSelectedCategory(e.target.value)}
            className={styles.categorySelect}
          >
            <option value="">업종을 선택하세요</option>
            <option value="restaurant">음식점</option>
            <option value="cafe">카페</option>
            <option value="retail">소매업</option>
            <option value="beauty">미용실</option>
            <option value="fitness">헬스장</option>
          </select>
        </div>

        <button 
          className={styles.analysisButton}
          onClick={requestAnalysis}
          disabled={!selectedArea || !selectedCategory}
        >
          분석하기
        </button>
      </div>

      {analysisData && (
        <div className={styles.analysisResults}>
          <h2>분석 결과</h2>
          
          <div className={styles.resultsGrid}>
            <div className={styles.resultCard}>
              <h3>인구 통계</h3>
              <p className={styles.resultValue}>{analysisData.population.toLocaleString()}명</p>
              <p className={styles.resultDesc}>해당 지역 상주 인구</p>
            </div>
            
            <div className={styles.resultCard}>
              <h3>평균 소득</h3>
              <p className={styles.resultValue}>{analysisData.avgIncome.toLocaleString()}원</p>
              <p className={styles.resultDesc}>지역 평균 월 소득</p>
            </div>
            
            <div className={styles.resultCard}>
              <h3>경쟁업체</h3>
              <p className={styles.resultValue}>{analysisData.competitors}개</p>
              <p className={styles.resultDesc}>반경 500m 내 경쟁업체 수</p>
            </div>
            
            <div className={styles.resultCard}>
              <h3>유동인구</h3>
              <p className={styles.resultValue}>{analysisData.footTraffic.toLocaleString()}명</p>
              <p className={styles.resultDesc}>일평균 유동인구</p>
            </div>
            
            <div className={styles.resultCard}>
              <h3>성장률</h3>
              <p className={styles.resultValue}>{analysisData.growthRate}%</p>
              <p className={styles.resultDesc}>연간 매출 성장률 예상</p>
            </div>
          </div>
          
          <div className={styles.analysisChart}>
            <h3>월별 매출 예상 그래프</h3>
            <div className={styles.chartPlaceholder}>
              {/* 차트 라이브러리 사용 필요 (예: Chart.js, Recharts 등) */}
              {/* 서버에서 받은 monthlyRevenue 데이터를 사용하여 차트 표시 */}
              <p>그래프가 표시될 영역입니다</p>
            </div>
          </div>
          
          <div className={styles.analysisRecommendations}>
            <h3>사업 추천 사항</h3>
            <ul>
              {analysisData.recommendations && analysisData.recommendations.map((recommendation, index) => (
                <li key={index}>{recommendation}</li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default BizAnalysis;
