import React from 'react';
import styles from './Bookmark.module.css';

// 북마크 컴포넌트
export default function Bookmark({ view, setView }) {
  return (
    <div className={styles.diaryBookmark}>
      <div 
        className={`${styles.bookmarkItem} ${view === 'calendar' ? styles.active : ''}`}
        onClick={() => setView('calendar')}
      >
        <div className={`${styles.bookmarkIcon} ${styles.calendarIcon}`}></div>
        <span>달력</span>
      </div>
      {/* <div 
        className={`${styles.bookmarkItem} ${view === 'photobook' ? styles.active : ''}`}
        onClick={() => setView('photobook')}
      >
        <div className={`${styles.bookmarkIcon} ${styles.photoIcon}`}></div>
        <span>사진첩</span>
      </div> */}
    </div>
  );
} 