import React, { useState, useEffect } from 'react';
import styles from './DiaryModal.module.css';

// 다이어리 입력 모달 컴포넌트
export default function DiaryModal({ info, onClose, selectedMonth, updateDiaryData }) {
  const dateData = info.data || {};
  const [memoList, setMemoList] = useState(dateData.memoList || []);
  const [activeTab, setActiveTab] = useState(0);
  const [memo, setMemo] = useState('');
  
  // 새 메모 추가 시 기본값
  const emptyMemo = {
    sales: '',
    salesMemo: '',
    expense: '',
    expenseMemo: '',
    memo: '',
    timestamp: Date.now()
  };
  
  // 컴포넌트 마운트 시 메모 목록이 없으면 빈 메모 추가
  useEffect(() => {
    if (memoList.length === 0) {
      setMemoList([{ ...emptyMemo }]);
    } else if (memoList.length > 0) {
      setMemo(memoList[activeTab].memo || '');
    }
  }, []);
  
  // 탭 변경 시 해당 탭의 메모 내용으로 업데이트
  useEffect(() => {
    if (memoList.length > activeTab) {
      setMemo(memoList[activeTab].memo || '');
    }
  }, [activeTab, memoList]);
  
  // 새 탭 추가
  const addNewTab = () => {
    setMemoList([...memoList, { ...emptyMemo }]);
    setActiveTab(memoList.length);
  };
  
  // 현재 탭의 메모 데이터 업데이트
  const updateCurrentTab = (field, value) => {
    const updatedList = [...memoList];
    updatedList[activeTab] = {
      ...updatedList[activeTab],
      [field]: value
    };
    setMemoList(updatedList);
  };
  
  // 탭 삭제
  const removeTab = (index, e) => {
    e.stopPropagation();
    if (memoList.length === 1) return; // 최소 1개의 탭은 유지
    
    const newList = memoList.filter((_, i) => i !== index);
    setMemoList(newList);
    
    // 삭제된 탭이 현재 활성화된 탭이면 첫 번째 탭으로 이동
    if (activeTab === index) {
      setActiveTab(0);
    } else if (activeTab > index) {
      // 삭제된 탭 이후의 탭을 보고 있었다면 인덱스 조정
      setActiveTab(activeTab - 1);
    }
  };
  
  // 탭 제목 생성 (매출/지출 정보와 간략한 메모)
  const getTabTitle = (memoItem) => {
    let title = '';
    if (memoItem.sales) title += '(매) ';
    if (memoItem.expense) title += '(지) ';
    
    // 메모에서 첫 5글자만 표시
    if (memoItem.memo) {
      const shortMemo = memoItem.memo.substring(0, 5);
      title += shortMemo + (memoItem.memo.length > 5 ? '...' : '');
    } else if (!title) {
      title = '새 메모';
    }
    
    return title;
  };
  
  // 저장 버튼 클릭 시 실행
  const handleSubmit = (e) => {
    e.preventDefault();
    
    const diaryData = {
      memoList: memoList,
      timestamp: Date.now()
    };
    
    updateDiaryData(info.date, diaryData);
    onClose();
  };
  
  return (
    <div className={styles.diaryModalOverlay} onClick={onClose}>
      <div className={styles.diaryModalContent} onClick={e => e.stopPropagation()}>
        <div className={styles.diaryModalHeader}>
          <h3>{selectedMonth.getFullYear()}년 {selectedMonth.getMonth() + 1}월 {info.date}일</h3>
          <button className={styles.closeButton} onClick={onClose}>×</button>
        </div>
        
        <div className={styles.diaryTabs}>
          {memoList.map((item, index) => (
            <div 
              key={index} 
              className={`${styles.diaryTab} ${activeTab === index ? styles.active : ''}`}
              onClick={() => setActiveTab(index)}
            >
              <span>{getTabTitle(item)}</span>
              {memoList.length > 1 && (
                <button 
                  className={styles.tabCloseBtn} 
                  onClick={(e) => removeTab(index, e)}
                >×</button>
              )}
            </div>
          ))}
          <button className={styles.diaryAddTab} onClick={addNewTab}>+</button>
        </div>
        
        <form onSubmit={handleSubmit}>
          <div className={styles.diaryFormGroup}>
            <div className={styles.diaryFormRow}>
              <div className={styles.diaryFormCol}>
                <label>매출 (원)</label>
                <input
                  type="number"
                  value={memoList[activeTab]?.sales || ''}
                  onChange={(e) => updateCurrentTab('sales', e.target.value)}
                  placeholder="0"
                />
              </div>
              <div className={styles.diaryFormCol}>
                <label>지출 (원)</label>
                <input
                  type="number"
                  value={memoList[activeTab]?.expense || ''}
                  onChange={(e) => updateCurrentTab('expense', e.target.value)}
                  placeholder="0"
                />
              </div>
            </div>
            
            <div className={styles.diaryFormRow}>
              <div className={styles.diaryFormCol}>
                <label>매출 메모</label>
                <textarea
                  value={memoList[activeTab]?.salesMemo || ''}
                  onChange={(e) => updateCurrentTab('salesMemo', e.target.value)}
                  placeholder="매출 관련 메모"
                />
              </div>
              <div className={styles.diaryFormCol}>
                <label>지출 메모</label>
                <textarea
                  value={memoList[activeTab]?.expenseMemo || ''}
                  onChange={(e) => updateCurrentTab('expenseMemo', e.target.value)}
                  placeholder="지출 관련 메모"
                />
              </div>
            </div>
            
            <div className={`${styles.diaryFormRow} ${styles.fullWidth}`}>
              <textarea
                value={memoList[activeTab]?.memo || ''}
                onChange={(e) => updateCurrentTab('memo', e.target.value)}
                placeholder="오늘 있었던 일"
                className={styles.diaryMemoTextarea}
              />
            </div>
          </div>
          
          <div className={styles.diaryModalActions}>
            <button type="submit" className={styles.saveButton}>저장</button>
            <button type="button" className={styles.cancelButton} onClick={onClose}>취소</button>
          </div>
        </form>
      </div>
    </div>
  );
} 