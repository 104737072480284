import React, { useState, useEffect } from 'react';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../../api/firebase/firebaseConfig';
import { getAuth } from 'firebase/auth';
import styles from './AttendanceStatus.module.css';
import Loading from '../../modals/Loading';

const AttendanceStatus = () => {
  const [employees, setEmployees] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedMonth, setSelectedMonth] = useState(new Date());
  const [attendanceData, setAttendanceData] = useState({});
  const [modalInfo, setModalInfo] = useState(null);
  const auth = getAuth();

  // 직원 목록 불러오기
  useEffect(() => {
    fetchEmployees();
  }, []);

  const fetchEmployees = async () => {
    setIsLoading(true);
    try {
      if (!auth.currentUser) {
        console.error('로그인이 필요합니다.');
        setIsLoading(false);
        return;
      }
      
      // 사용자 UID를 문서 ID로 사용
      const employeeDocRef = doc(db, 'employees', auth.currentUser.uid);
      const employeeDoc = await getDoc(employeeDocRef);
      
      if (employeeDoc.exists()) {
        // 문서가 존재하면 직원 목록 가져오기
        const data = employeeDoc.data();
        setEmployees(data.employeeList || []);
      } else {
        // 문서가 없으면 빈 배열 설정
        setEmployees([]);
      }
    } catch (error) {
      console.error('직원 목록 불러오기 실패:', error);
      setEmployees([]);
    } finally {
      setIsLoading(false);
    }
  };

  // 선택된 월의 날짜 배열 생성
  const getDaysInMonth = () => {
    const year = selectedMonth.getFullYear();
    const month = selectedMonth.getMonth();
    const lastDay = new Date(year, month + 1, 0).getDate();
    return Array.from({length: lastDay}, (_, i) => i + 1);
  };

  // 출결 데이터 가져오기
  const fetchAttendanceData = async () => {
    setIsLoading(true);
    try {
      if (!auth.currentUser) return;

      const year = selectedMonth.getFullYear();
      const month = selectedMonth.getMonth() + 1;
      const monthKey = `${year}${month.toString().padStart(2, '0')}`;
      
      const attendanceDocRef = doc(db, 'attendance', auth.currentUser.uid);
      const attendanceDoc = await getDoc(attendanceDocRef);

      if (attendanceDoc.exists()) {
        const monthData = attendanceDoc.data()[monthKey] || {};
        
        // 데이터 구조 정규화 - 모든 날짜 데이터를 배열로 변환
        const normalizedData = {};
        
        for (const employeeId in monthData) {
          normalizedData[employeeId] = {};
          
          for (const dateKey in monthData[employeeId]) {
            const dateData = monthData[employeeId][dateKey];
            
            // 배열인지 확인하고 아니면 배열로 변환
            if (Array.isArray(dateData)) {
              normalizedData[employeeId][dateKey] = dateData;
            } else if (dateData && typeof dateData === 'object') {
              // 객체인 경우 배열로 변환
              // recordId가 없으면 생성
              if (!dateData.recordId) {
                dateData.recordId = Date.now().toString();
              }
              // timestamp가 없으면 생성
              if (!dateData.timestamp) {
                dateData.timestamp = Date.now();
              }
              normalizedData[employeeId][dateKey] = [dateData];
            } else {
              // 유효하지 않은 데이터는 빈 배열로 설정
              normalizedData[employeeId][dateKey] = [];
            }
          }
        }
        
        setAttendanceData(normalizedData);
      } else {
        await setDoc(attendanceDocRef, { [monthKey]: {} });
        setAttendanceData({});
      }
    } catch (error) {
      console.error('출결 데이터 로딩 실패:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // 출결 상태 업데이트
  const updateAttendanceStatus = async (employeeId, date, timeData) => {
    try {
      const year = selectedMonth.getFullYear();
      const month = selectedMonth.getMonth() + 1;
      const monthKey = `${year}${month.toString().padStart(2, '0')}`;
      const dateKey = date.toString().padStart(2, '0');
      
      const attendanceDocRef = doc(db, 'attendance', auth.currentUser.uid);
      const attendanceDoc = await getDoc(attendanceDocRef);
      
      // 기존 데이터 가져오기
      let existingData = {};
      if (attendanceDoc.exists()) {
        existingData = attendanceDoc.data()[monthKey] || {};
      }
      
      // 해당 날짜의 기존 출결 데이터 가져오기
      const existingDateData = existingData[employeeId]?.[dateKey] || [];
      
      // 배열이 아닌 경우 배열로 변환 (기존 데이터 호환성)
      const recordsArray = Array.isArray(existingDateData) ? existingDateData : 
        (existingDateData && typeof existingDateData === 'object' ? [existingDateData] : []);
      
      // 새 데이터 생성 (배열 형태로)
      let newDateData = [];
      
      if (timeData.records) {
        // 전체 기록 업데이트
        newDateData = timeData.records;
      } else if (timeData.recordId !== undefined) {
        // 기존 기록 수정
        newDateData = recordsArray.map(record => 
          record && record.recordId === timeData.recordId ? { ...record, ...timeData } : record
        );
      } else if (timeData.status === '미출근' && recordsArray.length > 0) {
        // 미출근으로 변경 시 모든 기록 삭제
        newDateData = [];
      } else {
        // 새 기록 추가
        const newRecord = {
          recordId: Date.now().toString(), // 고유 ID 생성
          status: timeData.status || '미출근',
          checkIn: timeData.checkIn || null,
          checkOut: timeData.checkOut || null,
          leaveValue: timeData.leaveValue || null,
          leaveNote: timeData.leaveNote || null,
          workplace: timeData.workplace || null,
          timestamp: Date.now() // 타임스탬프 추가
        };
        newDateData = [...recordsArray, newRecord];
      }
      
      // Firestore 업데이트
      await updateDoc(attendanceDocRef, {
        [`${monthKey}.${employeeId}.${dateKey}`]: newDateData
      });
      
      // 로컬 상태 업데이트
      setAttendanceData(prev => ({
        ...prev,
        [employeeId]: {
          ...(prev[employeeId] || {}),
          [dateKey]: newDateData
        }
      }));
    } catch (error) {
      console.error('출결 상태 업데이트 실패:', error);
    }
  };

  const TimeInputModal = ({ info, onClose }) => {
    // 선택된 기록 ID (여러 기록 중 현재 편집 중인 기록)
    const [selectedRecordId, setSelectedRecordId] = useState(null);
    
    // 기존 상태 값 가져오기 또는 기본값 설정
    const records = Array.isArray(info.data) ? info.data : 
      (info.data && typeof info.data === 'object' ? [info.data] : []);
    
    const [isNewRecord, setIsNewRecord] = useState(records.length === 0);
    
    const selectedRecord = selectedRecordId 
      ? records.find(r => r && r.recordId === selectedRecordId) 
      : (records.length > 0 ? records[0] : null);
    
    const initialStatus = selectedRecord?.status || '출근';
    const [checkIn, setCheckIn] = useState(selectedRecord?.checkIn || '');
    const [checkOut, setCheckOut] = useState(selectedRecord?.checkOut || '');
    const [attendanceType, setAttendanceType] = useState(initialStatus);
    const [leaveNote, setLeaveNote] = useState(selectedRecord?.leaveNote || '');
    const [selectedWorkplace, setSelectedWorkplace] = useState(selectedRecord?.workplace || '');
    const [workplaces, setWorkplaces] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    
    // 첫 번째 기록 자동 선택
    useEffect(() => {
      if (records.length > 0 && !selectedRecordId && !isNewRecord) {
        const firstValidRecord = records.find(r => r && r.recordId);
        if (firstValidRecord) {
          setSelectedRecordId(firstValidRecord.recordId);
        }
      }
    }, [records, selectedRecordId, isNewRecord]);
    
    // 기록 선택 시 폼 업데이트
    useEffect(() => {
      if (selectedRecordId && !isNewRecord) {
        const record = records.find(r => r && r.recordId === selectedRecordId);
        if (record) {
          setAttendanceType(record.status || '출근');
          setCheckIn(record.checkIn || '');
          setCheckOut(record.checkOut || '');
          setLeaveNote(record.leaveNote || '');
          setSelectedWorkplace(record.workplace || '');
        }
      }
    }, [selectedRecordId, records, isNewRecord]);

    // 직원의 근무지 정보 가져오기
    useEffect(() => {
      const fetchWorkplaces = async () => {
        setIsLoading(true);
        try {
          if (!auth.currentUser) return;
          
          const employeeDocRef = doc(db, 'employees', auth.currentUser.uid);
          const employeeDoc = await getDoc(employeeDocRef);
          
          if (employeeDoc.exists()) {
            const employeeList = employeeDoc.data().employeeList || [];
            const employee = employeeList.find(emp => emp.id === info.employeeId);
            
            if (employee && employee.workplaces && employee.workplaces.length > 0) {
              setWorkplaces(employee.workplaces);
              
              // 기존 근무지가 없으면 기본 근무지 또는 첫 번째 근무지 선택
              if (!selectedWorkplace) {
                const defaultWorkplace = employee.workplaces.find(wp => wp.isDefault);
                setSelectedWorkplace(defaultWorkplace ? defaultWorkplace.name : employee.workplaces[0].name);
              }
            } else {
              // 근무지가 없는 경우 기본 근무지 생성
              const defaultWorkplace = { name: '기본 근무지', isDefault: true };
              setWorkplaces([defaultWorkplace]);
              setSelectedWorkplace(defaultWorkplace.name);
            }
          }
        } catch (error) {
          console.error('근무지 정보 불러오기 실패:', error);
          // 오류 발생 시 기본 근무지 설정
          const defaultWorkplace = { name: '기본 근무지', isDefault: true };
          setWorkplaces([defaultWorkplace]);
          setSelectedWorkplace(defaultWorkplace.name);
        } finally {
          setIsLoading(false);
        }
      };
      
      fetchWorkplaces();
    }, [info.employeeId]);

    // 컴포넌트 마운트 시 한 번만 실행
    useEffect(() => {
      // 모달이 처음 열릴 때 출근 상태이면 시간 입력 필드 표시
      if (initialStatus === '출근') {
        // 시간 입력 필드에 포커스
        const timeInput = document.querySelector('.time-input-group input');
        if (timeInput) {
          setTimeout(() => {
            timeInput.focus();
          }, 100);
        }
      }
    }, [initialStatus]);

    const handleSubmit = (e) => {
      e.preventDefault();
      
      // 근무지가 선택되지 않았으면 기본 근무지 사용
      const workplace = selectedWorkplace || (workplaces.length > 0 ? 
        (workplaces.find(wp => wp.isDefault)?.name || workplaces[0].name) : '기본 근무지');
      
      let leaveValue = null;
      if (attendanceType === '연차') leaveValue = 1;
      if (attendanceType === '반차') leaveValue = 0.5;
      
      if (isNewRecord) {
        // 새 기록 추가
        const newRecord = {
          recordId: Date.now().toString(), // 고유 ID 생성
          status: attendanceType,
          checkIn: attendanceType === '출근' ? checkIn : null,
          checkOut: attendanceType === '출근' ? checkOut : null,
          leaveValue: leaveValue,
          leaveNote: attendanceType !== '출근' && attendanceType !== '연차' && attendanceType !== '반차' ? leaveNote : null,
          workplace: workplace,
          timestamp: Date.now() // 타임스탬프 추가
        };
        
        // 기존 기록에 새 기록 추가
        const updatedRecords = [...records, newRecord];
        
        updateAttendanceStatus(info.employeeId, info.date, {
          records: updatedRecords
        });
      } else if (selectedRecordId) {
        // 기존 기록 수정
        updateAttendanceStatus(info.employeeId, info.date, {
          recordId: selectedRecordId,
          checkIn: attendanceType === '출근' ? checkIn : null,
          checkOut: attendanceType === '출근' ? checkOut : null,
          status: attendanceType,
          leaveValue: leaveValue,
          leaveNote: attendanceType !== '출근' && attendanceType !== '연차' && attendanceType !== '반차' ? leaveNote : null,
          workplace: workplace
        });
      }
      
      onClose();
    };

    // 새 출근 기록 추가
    const handleAddNewRecord = () => {
      // 기본값으로 새 폼 설정
      setIsNewRecord(true);
      setSelectedRecordId(null);
      setAttendanceType('출근');
      setCheckIn('');
      setCheckOut('');
      setLeaveNote('');
      
      // 기본 근무지 선택
      const defaultWorkplace = workplaces.find(wp => wp.isDefault);
      setSelectedWorkplace(defaultWorkplace ? defaultWorkplace.name : (workplaces.length > 0 ? workplaces[0].name : '기본 근무지'));
    };

    // 기록 삭제
    const handleDeleteRecord = () => {
      if (!selectedRecordId) return;
      
      if (window.confirm('이 출근 기록을 삭제하시겠습니까?')) {
        // 선택된 기록만 제외한 새 배열 생성
        const newRecords = records.filter(record => record && record.recordId !== selectedRecordId);
        
        // 기록이 남아있으면 첫 번째 기록 선택, 없으면 null
        const newSelectedId = newRecords.length > 0 ? newRecords[0].recordId : null;
        
        // 상태 업데이트
        if (newRecords.length === 0) {
          // 모든 기록 삭제 시 미출근으로 설정
          updateAttendanceStatus(info.employeeId, info.date, { status: '미출근' });
        } else {
          // 선택된 기록만 제외하고 업데이트
          updateAttendanceStatus(info.employeeId, info.date, { records: newRecords });
        }
        
        // 다른 기록 선택 또는 모달 닫기
        if (newSelectedId) {
          setSelectedRecordId(newSelectedId);
          setIsNewRecord(false);
          
          // 로컬 상태 업데이트 추가
          setModalInfo(prevInfo => ({
            ...prevInfo,
            data: newRecords
          }));
        } else {
          onClose();
        }
      }
    };

    // 미출근으로 변경 함수
    const handleSetAbsent = () => {
      if (window.confirm('정말 모든 출근 기록을 미출근으로 변경하시겠습니까?')) {
        updateAttendanceStatus(info.employeeId, info.date, {
          status: '미출근'
        });
        onClose();
      }
    };

    return (
      <div className={styles.modalOverlay} onClick={onClose}>
        <div className={styles.modalContentAttendance} onClick={e => e.stopPropagation()}>
          <h3>{info.employeeName} - {selectedMonth.getMonth() + 1}월 {info.date}일</h3>
          
          {/* 여러 출근 기록 선택 UI */}
          <div className={styles.recordSelector}>
            <label>출근 기록:</label>
            <div className={styles.recordButtons}>
              {records.map((record, index) => {
                // 유효한 기록인지 확인
                if (!record || !record.recordId) return null;
                
                return (
                  <button
                    key={record.recordId}
                    type="button"
                    className={`${styles.recordButton} ${selectedRecordId === record.recordId && !isNewRecord ? styles.selected : ''}`}
                    onClick={() => {
                      setIsNewRecord(false);
                      setSelectedRecordId(record.recordId);
                      setAttendanceType(record.status || '출근');
                      setCheckIn(record.checkIn || '');
                      setCheckOut(record.checkOut || '');
                      setLeaveNote(record.leaveNote || '');
                      setSelectedWorkplace(record.workplace || '');
                    }}
                  >
                    {index + 1}. {record.workplace || '기본 근무지'} 
                    {record.checkIn ? ` (${record.checkIn})` : ''}
                    {record.checkOut ? `-${record.checkOut}` : ''}
                  </button>
                );
              })}
              <button 
                type="button" 
                className={`${styles.addRecordButton} ${isNewRecord ? styles.selected : ''}`}
                onClick={handleAddNewRecord}
              >
                + 새 기록
              </button>
            </div>
          </div>
          
          <form onSubmit={handleSubmit}>
            <div className={styles.attendanceTypeGroup}>
              <label>출결 유형</label>
              <select 
                value={attendanceType} 
                onChange={(e) => setAttendanceType(e.target.value)}
              >
                <option value="출근">출근</option>
                <option value="연차">연차 (1일)</option>
                <option value="반차">반차 (0.5일)</option>
                <option value="기타">기타 휴가</option>
                <option value="미출근">미출근</option>
              </select>
            </div>
            
            <div className={styles.workplaceSelectGroup}>
              <label>근무지</label>
              {isLoading ? (
                <div className={styles.workplaceLoading}>로딩 중...</div>
              ) : (
                <select
                  value={selectedWorkplace}
                  onChange={(e) => setSelectedWorkplace(e.target.value)}
                  className={styles.workplaceSelect}
                >
                  {workplaces.map((workplace, index) => (
                    <option key={index} value={workplace.name}>
                      {workplace.name}{workplace.isDefault ? ' (기본)' : ''}
                    </option>
                  ))}
                </select>
              )}
            </div>
            
            {attendanceType === '출근' ? (
              <>
                <div className={styles.timeInputGroup}>
                  <label>출근 시간</label>
                  <input
                    type="time"
                    value={checkIn}
                    onChange={(e) => setCheckIn(e.target.value)}
                  />
                </div>
                <div className={styles.timeInputGroup}>
                  <label>퇴근 시간</label>
                  <input
                    type="time"
                    value={checkOut}
                    onChange={(e) => setCheckOut(e.target.value)}
                  />
                </div>
              </>
            ) : attendanceType === '기타' ? (
              <div className={styles.leaveNoteGroup}>
                <label>휴가 사유</label>
                <input
                  type="text"
                  value={leaveNote}
                  onChange={(e) => setLeaveNote(e.target.value)}
                  placeholder="휴가 사유를 입력하세요"
                />
              </div>
            ) : null}
            
            <div className={styles.modalActions}>
              <button type="submit" className={styles.saveButton}>저장</button>
              {selectedRecordId && !isNewRecord && (
                <button type="button" className={styles.deleteButton} onClick={handleDeleteRecord}>삭제</button>
              )}
              <button type="button" className={styles.cancelButton} onClick={onClose}>취소</button>
              {records.length > 0 && (
                <button type="button" className={styles.absentButton} onClick={handleSetAbsent}>모두 미출근으로</button>
              )}
            </div>
          </form>
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (auth.currentUser) {
      fetchAttendanceData();
    }
  }, [selectedMonth, auth.currentUser]);

  // 근무지별 색상 생성 함수
  const generateWorkplaceColors = () => {
    const colors = [
      '#4CAF50', '#2196F3', '#9C27B0', '#FF9800', '#795548',
      '#607D8B', '#E91E63', '#3F51B5', '#009688', '#FFC107'
    ];
    
    const workplaces = employees.flatMap(employee => employee.workplaces || [])
      .filter((workplace, index, self) => 
        index === self.findIndex(wp => wp.name === workplace.name)
      );
    
    // 동적으로 CSS 변수 생성
    const style = document.createElement('style');
    workplaces.forEach((workplace, index) => {
      const colorIndex = index % colors.length;
      const className = `.workplace-${workplace.name.replace(/\s+/g, '-')}`;
      style.innerHTML += `${className} { background-color: ${colors[colorIndex]}; color: white; }\n`;
    });
    
    document.head.appendChild(style);
  };
  
  // 직원 목록이 로드되면 근무지별 색상 생성
  useEffect(() => {
    if (employees.length > 0) {
      generateWorkplaceColors();
    }
  }, [employees]);

  // 달 변경 핸들러 함수 추가
  const handleMonthChange = (increment) => {
    setSelectedMonth(new Date(selectedMonth.setMonth(selectedMonth.getMonth() + increment)));
  };

  return (
    <div className={styles.qrStatusContainer}>
      <h2>출결 현황</h2>
      
      <div className={styles.monthSelector}>
        <button onClick={() => handleMonthChange(-1)}>
          이전 달
        </button>
        <span>{selectedMonth.getFullYear()}년 {selectedMonth.getMonth() + 1}월</span>
        <button onClick={() => handleMonthChange(1)}>
          다음 달
        </button>
      </div>

      {isLoading ? (
        <Loading message="출결 데이터 로딩 중..." />
      ) : (
        <div className={styles.attendanceTable}>
          <div className={styles.tableHeader}>
            <div className={styles.employeeColumn}>직원명</div>
            {getDaysInMonth().map(day => (
              <div key={day} className={styles.dateColumn}>{day}</div>
            ))}
          </div>
          
          {employees.map(employee => (
            <div key={employee.id} className={styles.tableRow}>
              <div className={styles.employeeColumn}>{employee.name}</div>
              {getDaysInMonth().map(day => {
                const dateKey = day.toString().padStart(2, '0');
                const attendanceRecords = attendanceData[employee.id]?.[dateKey] || [];
                
                // 배열이 아닌 경우 배열로 변환 (기존 데이터 호환성)
                const recordsArray = Array.isArray(attendanceRecords) ? attendanceRecords : 
                  (attendanceRecords && typeof attendanceRecords === 'object' ? [attendanceRecords] : []);
                
                // 출근 기록이 없으면 미출근 상태로 표시
                if (recordsArray.length === 0) {
                  return (
                    <div 
                      key={day} 
                      className={`${styles.dateColumn} ${styles.status미출근}`}
                      onClick={() => setModalInfo({
                        employeeId: employee.id,
                        employeeName: employee.name,
                        date: day,
                        data: []
                      })}
                      title="클릭하여 시간 입력"
                    >
                      ×
                    </div>
                  );
                }
                
                // 여러 출근 기록이 있는 경우
                const multipleRecords = recordsArray.length > 1;
                
                // 출근 기록 개수 계산
                const workRecords = recordsArray.filter(r => r && r.status === '출근');
                const leaveRecords = recordsArray.filter(r => r && r.status !== '출근');
                
                // 상태 표시 결정
                let statusSymbol = '×';
                let statusClass = 'status-미출근';
                
                if (workRecords.length > 0) {
                  // 출근 기록이 있는 경우
                  statusSymbol = multipleRecords ? `${workRecords.length}` : '○';
                  statusClass = 'status-출근';
                  
                  // 첫 번째 출근 기록의 근무지 정보 사용
                  const firstWorkRecord = workRecords[0];
                  if (firstWorkRecord && firstWorkRecord.workplace) {
                    statusClass += ` workplace-${firstWorkRecord.workplace.replace(/\s+/g, '-')}`;
                  }
                } else if (leaveRecords.length > 0) {
                  // 출근 기록은 없고 휴가 기록만 있는 경우
                  const firstLeaveRecord = leaveRecords[0];
                  
                  if (firstLeaveRecord.status === '연차') {
                    statusSymbol = '휴';
                    statusClass = 'status-연차';
                  } else if (firstLeaveRecord.status === '반차') {
                    statusSymbol = '반';
                    statusClass = 'status-반차';
                  } else if (firstLeaveRecord.status === '기타') {
                    statusSymbol = '기';
                    statusClass = 'status-기타';
                  }
                }
                
                // 툴팁 내용 생성
                let title = '클릭하여 시간 입력';
                
                if (recordsArray.length > 0) {
                  title = recordsArray.map((record, index) => {
                    if (!record) return `${index + 1}. 데이터 오류`;
                    
                    let recordInfo = '';
                    
                    if (record.status === '출근') {
                      recordInfo = `${index + 1}. ${record.workplace || '기본 근무지'}\n`;
                      recordInfo += `   출근: ${record.checkIn || '미등록'}\n`;
                      recordInfo += `   퇴근: ${record.checkOut || '미등록'}`;
                    } else if (record.status === '연차') {
                      recordInfo = `${index + 1}. 연차 (1일)`;
                      if (record.workplace) {
                        recordInfo += `\n   근무지: ${record.workplace}`;
                      }
                    } else if (record.status === '반차') {
                      recordInfo = `${index + 1}. 반차 (0.5일)`;
                      if (record.workplace) {
                        recordInfo += `\n   근무지: ${record.workplace}`;
                      }
                    } else if (record.leaveNote) {
                      recordInfo = `${index + 1}. 기타 휴가: ${record.leaveNote}`;
                      if (record.workplace) {
                        recordInfo += `\n   근무지: ${record.workplace}`;
                      }
                    } else {
                      recordInfo = `${index + 1}. ${record.status || '미출근'}`;
                    }
                    
                    return recordInfo;
                  }).join('\n\n');
                }
                
                return (
                  <div 
                    key={day} 
                    className={`${styles.dateColumn} ${styles[`status${statusClass}`]} ${multipleRecords ? styles.multipleRecords : ''}`}
                    onClick={() => setModalInfo({
                      employeeId: employee.id,
                      employeeName: employee.name,
                      date: day,
                      data: recordsArray
                    })}
                    title={title}
                  >
                    {statusSymbol}
                  </div>
                );
              })}
            </div>
          ))}
        </div>
      )}

      {/* 근무지 범례 추가 */}
      {!isLoading && (
        <div className={styles.workplaceLegend}>
          <h4>근무지 범례</h4>
          <div className={styles.legendItems}>
            {employees.flatMap(employee => employee.workplaces || [])
              .filter((workplace, index, self) => 
                index === self.findIndex(wp => wp.name === workplace.name)
              )
              .map((workplace, index) => (
                <div key={index} className={styles.legendItem}>
                  <span className={`${styles.legendColor} workplace-${workplace.name.replace(/\s+/g, '-')}`}>
                    {workplace.name.charAt(0)}
                  </span>
                  <span className={styles.legendLabel}>{workplace.name}</span>
                </div>
              ))
            }
            <div className={styles.legendItem}>
              <span className={`${styles.legendColor} status-출근`}>○</span>
              <span className={styles.legendLabel}>근무지 미지정</span>
            </div>
          </div>
        </div>
      )}

      {modalInfo && (
        <TimeInputModal
          info={modalInfo}
          onClose={() => setModalInfo(null)}
        />
      )}
    </div>
  );
};

export default AttendanceStatus; 