import React from 'react';
import styles from './SalarySettings.module.css';

// 급여 설정 컴포넌트
const SalarySettings = ({ config, handleConfigChange, saveConfig }) => {
  return (
    <div className={styles.settingsContainer}>
      <h3>급여 계산 설정</h3>
      <div className={styles.settingsForm}>
        <div className={styles.settingsGroup}>
          <h4>기본 설정</h4>
          <div className={styles.settingsRow}>
            <label>
              주휴일:
              <select name="weekEnds" value={config.weekEnds} onChange={handleConfigChange}>
                <option value={7}>일요일</option>
                <option value={6}>토요일</option>
                <option value={1}>월요일</option>
                <option value={2}>화요일</option>
                <option value={3}>수요일</option>
                <option value={4}>목요일</option>
                <option value={5}>금요일</option>
              </select>
            </label>
          </div>
          <div className={styles.settingsRow}>
            <label>
              야간 시작 시간:
              <input type="time" name="nightStart" value={config.nightStart} onChange={handleConfigChange} />
            </label>
          </div>
          <div className={styles.settingsRow}>
            <label>
              야간 종료 시간:
              <input type="time" name="nightEnd" value={config.nightEnd} onChange={handleConfigChange} />
            </label>
          </div>
          <div className={styles.settingsRow}>
            <label>
              연장근로 옵션:
              <select name="extendedOpt" value={config.extendedOpt} onChange={handleConfigChange}>
                <option value={1}>1시간 이상</option>
                <option value={2}>30분 이상</option>
                <option value={3}>1분 이상</option>
                <option value={4}>분 단위 계산</option>
              </select>
            </label>
          </div>
          <div className={styles.settingsRow}>
            <label>
              근로자 기준:
              <select name="baseWorker" value={config.baseWorker} onChange={handleConfigChange}>
                <option value={0}>모든 근로자</option>
                <option value={1}>정규직 근로자</option>
                <option value={2}>계약직 근로자</option>
                <option value={3}>아르바이트</option>
              </select>
            </label>
          </div>
          <div className={styles.settingsRow}>
            <label>
              근로자 옵션:
              <select name="baseWorkerOpt" value={config.baseWorkerOpt} onChange={handleConfigChange}>
                <option value={0}>모든 근로자 포함</option>
                <option value={1}>지정 근로자만 포함</option>
                <option value={2}>지정 근로자 제외</option>
              </select>
            </label>
          </div>
        </div>
        
        <div className={styles.settingsGroup}>
          <h4>근로 시간 설정</h4>
          <div className={styles.settingsRow}>
            <label>
              일 표준 근로시간:
              <input type="number" name="standardDailyHours" value={config.standardDailyHours} onChange={handleConfigChange} min="0" max="24" />
            </label>
          </div>
          <div className={styles.settingsRow}>
            <label>
              주 표준 근로시간:
              <input type="number" name="standardWeeklyHours" value={config.standardWeeklyHours} onChange={handleConfigChange} min="0" max="168" />
            </label>
          </div>
          <div className={styles.settingsRow}>
            <label>
              주 표준 근로일수:
              <input type="number" name="standardWeeklyDays" value={config.standardWeeklyDays} onChange={handleConfigChange} min="0" max="7" />
            </label>
          </div>
        </div>
        
        <div className={styles.settingsGroup}>
          <h4>급여 설정</h4>
          <div className={styles.settingsRow}>
            <label>
              시급:
              <input type="number" name="hourlyPay" value={config.hourlyPay} onChange={handleConfigChange} min="0" />
            </label>
          </div>
          <div className={styles.settingsRow}>
            <label>
              포괄임금 시급:
              <input type="number" name="hourlyPayTotal" value={config.hourlyPayTotal} onChange={handleConfigChange} min="0" />
            </label>
          </div>
          <div className={styles.settingsRow}>
            <label>
              연차 일수:
              <input type="number" name="numAnnualLeaveDays" value={config.numAnnualLeaveDays} onChange={handleConfigChange} min="0" />
            </label>
          </div>
          <div className={styles.settingsRow}>
            <label>
              야간/휴일/연장 비과세:
              <select name="nonTaxableOpt" value={config.nonTaxableOpt} onChange={handleConfigChange}>
                <option value={0}>과세</option>
                <option value={1}>비과세</option>
              </select>
            </label>
          </div>
        </div>
        
        <div className={styles.settingsGroup}>
          <h4>포괄임금 포함 항목</h4>
          <div className={`${styles.settingsRow} ${styles.checkboxRow}`}>
            <label>
              <input type="checkbox" name="includeBasicPay" checked={config.includeBasicPay} onChange={handleConfigChange} />
              기본급
            </label>
          </div>
          <div className={`${styles.settingsRow} ${styles.checkboxRow}`}>
            <label>
              <input type="checkbox" name="includeWeeklyHolidayPay" checked={config.includeWeeklyHolidayPay} onChange={handleConfigChange} />
              주휴수당
            </label>
          </div>
          <div className={`${styles.settingsRow} ${styles.checkboxRow}`}>
            <label>
              <input type="checkbox" name="includeAnnualLeavePay" checked={config.includeAnnualLeavePay} onChange={handleConfigChange} />
              연차수당
            </label>
          </div>
          <div className={`${styles.settingsRow} ${styles.checkboxRow}`}>
            <label>
              <input type="checkbox" name="includeNightAllowance" checked={config.includeNightAllowance} onChange={handleConfigChange} />
              야간수당
            </label>
          </div>
          <div className={`${styles.settingsRow} ${styles.checkboxRow}`}>
            <label>
              <input type="checkbox" name="includeHolidayAllowance" checked={config.includeHolidayAllowance} onChange={handleConfigChange} />
              휴일수당
            </label>
          </div>
          <div className={`${styles.settingsRow} ${styles.checkboxRow}`}>
            <label>
              <input type="checkbox" name="includeOvertimeAllowance" checked={config.includeOvertimeAllowance} onChange={handleConfigChange} />
              연장수당
            </label>
          </div>
        </div>
        
        <div className={styles.settingsActions}>
          <button className={styles.saveSettingsButton} onClick={saveConfig}>설정 저장</button>
        </div>
      </div>
    </div>
  );
};

export default SalarySettings; 