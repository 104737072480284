import React from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import styles from './ScheduleCalendar.module.css';

// 달력
const ScheduleCalendar = ({ 
  selectedDate, 
  onDateChange, 
  schedules 
}) => {
  // 날짜에 예약이 있는지 확인하는 함수
  const hasScheduleOnDate = (date) => {
    return schedules.some(schedule => {
      const scheduleDate = new Date(schedule.date.toDate());
      return scheduleDate.getFullYear() === date.getFullYear() &&
             scheduleDate.getMonth() === date.getMonth() &&
             scheduleDate.getDate() === date.getDate();
    });
  };

  // 타일에 클래스 이름을 추가하는 함수
  const tileClassName = ({ date, view }) => {
    if (view === 'month' && hasScheduleOnDate(date)) {
      return styles.hasSchedule;
    }
    return null;
  };

  return (
    <div className={styles.calendarPanel}>
      <h3>날짜 선택</h3>
      <Calendar 
        onChange={onDateChange} 
        value={selectedDate} 
        className={styles.customCalendar}
        tileClassName={tileClassName}
      />
    </div>
  );
};

export default ScheduleCalendar; 