import React from 'react';
import styles from './CustomerSearchDropdown.module.css';

// 드롭 다운 메뉴 고객
const CustomerSearchDropdown = ({
  visible,
  searchTerm,
  onSearchChange,
  customers,
  onSelectCustomer
}) => {
  if (!visible) return null;

  const filteredCustomers = customers.filter(customer => 
    customer.name.includes(searchTerm) || 
    customer.contact.includes(searchTerm)
  );

  return (
    <div className={styles.customerSearchDropdown}>
      <div className={styles.customerSearchHeader}>
        <input
          type="text"
          placeholder="고객명 또는 연락처 검색..."
          value={searchTerm}
          onChange={(e) => onSearchChange(e.target.value)}
        />
      </div>
      <div className={styles.customerSearchResults}>
        {filteredCustomers.length > 0 ? (
          filteredCustomers.map(customer => (
            <div 
              key={customer.id} 
              className={styles.customerItem}
              onClick={() => onSelectCustomer(customer)}
            >
              <div className={styles.customerName}>{customer.name}</div>
              <div className={styles.customerContact}>{customer.contact}</div>
            </div>
          ))
        ) : (
          <div className={styles.noCustomers}>검색 결과가 없습니다.</div>
        )}
      </div>
    </div>
  );
};

export default CustomerSearchDropdown; 