import React, { useState, useEffect } from 'react';
import BaseContract from './BaseContract';
import './Contracts.css';

const SupplyContract = ({ party, existingData, onSave }) => {
  const [formData, setFormData] = useState({
    supplyItems: '',
    quantity: '',
    unitPrice: '',
    totalPrice: '',
    deliveryDate: '',
    deliveryLocation: '',
    qualityStandards: '',
    inspectionMethod: '',
    paymentTerms: '',
    warrantyTerms: '',
    penaltyTerms: '',
    otherConditions: '',
  });

  useEffect(() => {
    if (existingData) {
      setFormData(existingData);
    }
  }, [existingData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSave = () => {
    onSave(formData);
  };

  return (
    <BaseContract 
      title="납품계약서" 
      onSave={handleSave}
      isValid={formData.supplyItems && formData.deliveryDate && formData.totalPrice}
    >
      <div className="contract-header">
        <p>공급자: {party.name} (이하 "공급자"라 함)</p>
        <p>수요자: [회사명] (이하 "수요자"라 함)</p>
      </div>

      <div className="contract-section">
        <h3>제1조 (납품 물품)</h3>
        <div className="form-group">
          <textarea
            name="supplyItems"
            value={formData.supplyItems}
            onChange={handleChange}
            placeholder="납품 물품의 상세 내역"
            required
          />
        </div>
      </div>

      <div className="contract-section">
        <h3>제2조 (수량 및 가격)</h3>
        <div className="form-group">
          <label>수량</label>
          <input
            type="text"
            name="quantity"
            value={formData.quantity}
            onChange={handleChange}
            placeholder="납품 수량"
            required
          />
        </div>
        <div className="form-group">
          <label>단가</label>
          <input
            type="number"
            name="unitPrice"
            value={formData.unitPrice}
            onChange={handleChange}
            placeholder="단가"
            required
          />
        </div>
        <div className="form-group">
          <label>총액</label>
          <input
            type="number"
            name="totalPrice"
            value={formData.totalPrice}
            onChange={handleChange}
            placeholder="총액"
            required
          />
        </div>
      </div>

      <div className="contract-section">
        <h3>제3조 (납품 일정 및 장소)</h3>
        <div className="form-group">
          <label>납품일</label>
          <input
            type="date"
            name="deliveryDate"
            value={formData.deliveryDate}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label>납품장소</label>
          <input
            type="text"
            name="deliveryLocation"
            value={formData.deliveryLocation}
            onChange={handleChange}
            placeholder="납품 장소"
            required
          />
        </div>
      </div>

      <div className="contract-section">
        <h3>제4조 (품질 기준)</h3>
        <div className="form-group">
          <textarea
            name="qualityStandards"
            value={formData.qualityStandards}
            onChange={handleChange}
            placeholder="품질 기준 명세"
          />
        </div>
      </div>

      <div className="contract-section">
        <h3>제5조 (검수 방법)</h3>
        <div className="form-group">
          <textarea
            name="inspectionMethod"
            value={formData.inspectionMethod}
            onChange={handleChange}
            placeholder="검수 방법 및 기준"
          />
        </div>
      </div>

      <div className="contract-section">
        <h3>제6조 (대금 지급 조건)</h3>
        <div className="form-group">
          <textarea
            name="paymentTerms"
            value={formData.paymentTerms}
            onChange={handleChange}
            placeholder="지급 조건 및 방법"
          />
        </div>
      </div>

      <div className="contract-section">
        <h3>제7조 (품질 보증)</h3>
        <div className="form-group">
          <textarea
            name="warrantyTerms"
            value={formData.warrantyTerms}
            onChange={handleChange}
            placeholder="품질 보증 조건"
          />
        </div>
      </div>

      <div className="contract-section">
        <h3>제8조 (지체상금)</h3>
        <div className="form-group">
          <textarea
            name="penaltyTerms"
            value={formData.penaltyTerms}
            onChange={handleChange}
            placeholder="지체상금 조건"
          />
        </div>
      </div>

      <div className="contract-section">
        <h3>제9조 (기타)</h3>
        <div className="form-group">
          <textarea
            name="otherConditions"
            value={formData.otherConditions}
            onChange={handleChange}
            placeholder="기타 계약조건"
          />
        </div>
      </div>
    </BaseContract>
  );
};

export default SupplyContract; 