import axios from 'axios';
import { requestInterceptor, responseInterceptor, errorInterceptor } from './interceptor';

// 1.기본 설정
// 리얼 서버
// 테스트 서버
const baseURL = 'https://asia-northeast3-caresalaryrp.cloudfunctions.net/agentHandler';
// const baseURL = 'http://127.0.0.1:5001/caresalaryrp/asia-northeast3/agentHandler/';

const instance = axios.create({
    baseURL,
    timeout: 18000,
});

// // 2.인터셉터 적용, jwt token get 및 set
// instance.interceptors.request.use(requestInterceptor);
// instance.interceptors.response.use(responseInterceptor, errorInterceptor);

const handleApiError = (error) => {
    console.error('API call failed:', error);

    if (error.response) {
        // 서버가 응답을 보낸 경우 (4xx, 5xx 상태 코드 등)
        console.error('Response data:', error.response.data);
        console.error('Response status:', error.response.status);
        console.error('Response headers:', error.response.headers);
    } else if (error.request) {
        // 요청이 만들어졌으나 응답을 받지 못한 경우
        console.error('Request data:', error.request);
    } else {
        // 요청을 만들기 전의 문제 발생 (예: 설정 오류)
        console.error('Error message:', error.message);
    }

    throw error;
};

// 3. API 메소드 실행
const api = {
    async executeRequest(method, url, data, config = {}) {
        try {
            const response = await instance[method](url, data, config);
            return response.data;
        } catch (error) {
            handleApiError(error);
        }
    },
    get: async (url, config = {}) => api.executeRequest('get', url, null, config),
    post: async (url, data, config = {}) => api.executeRequest('post', url, data, config),
    put: async (url, data, config = {}) => api.executeRequest('put', url, data, config),
    delete: async (url, config = {}) => api.executeRequest('delete', url, null, config),
};

export default api;