import React from 'react';
import styles from './EmployeeSearchDropdown.module.css';

// 드롭 다운 메뉴 직원
const EmployeeSearchDropdown = ({
  visible,
  searchTerm,
  onSearchChange,
  employees,
  onSelectEmployee
}) => {
  if (!visible) return null;

  const filteredEmployees = employees.filter(employee => 
    employee.name.includes(searchTerm)
  );

  return (
    <div className={styles.employeeSearchDropdown}>
      <div className={styles.employeeSearchHeader}>
        <input
          type="text"
          placeholder="근로자명 검색..."
          value={searchTerm}
          onChange={(e) => onSearchChange(e.target.value)}
        />
      </div>
      <div className={styles.employeeSearchResults}>
        {filteredEmployees.length > 0 ? (
          filteredEmployees.map(employee => (
            <div
              key={employee.id}
              className={styles.employeeItem}
              onClick={() => onSelectEmployee(employee)}
            >
              <div className={styles.employeeName}>{employee.name}</div>
              <div className={styles.employeePosition}>{employee.position || '일반 근로자'}</div>
            </div>
          ))
        ) : (
          <div className={styles.noEmployees}>검색 결과가 없습니다.</div>
        )}
      </div>
    </div>
  );
};

export default EmployeeSearchDropdown; 