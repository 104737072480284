import React from 'react';
import styles from './TabMenu.module.css';

const TabMenu = ({ tabs, activeTab, onTabChange, children }) => {
  return (
    <div>
      <div className={styles.tabMenu}>
        {tabs.map((tab, index) => (
          <div
            key={index}
            className={activeTab === tab ? styles.activeTab : styles.tab}
            onClick={() => onTabChange(tab)}
          >
            {tab}
          </div>
        ))}
      </div>
      <div className={styles.contentContainer}>
        {children}
      </div>
    </div>
  );
};

export default TabMenu; 