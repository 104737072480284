import React, { useEffect } from 'react';
import EvaluationModalContent from '../services/managements/evaluation/EvaluationModalContent';
import DocumentsModalContent from '../services/managements/documents/DocumentsModalContent';
import ScheduleModalContent from '../services/managements/schedule/ScheduleModalContent';
import SalaryModalContent from '../services/managements/salary/SalaryModalContent';
import ManagementModalContent from '../services/managements/management/ManagementModalContent';
import BizPhone from '../services/markets/bizPhone/BizPhone';
import BizAnalysis from '../services/markets/bizAnalysis/BizAnalysis';
import AttendanceQRGen from '../services/attendance/AttendanceQRGen';
import AttendanceStatus from '../services/attendance/AttendanceStatus';
import BizInsurance from '../services/markets/bizInsurance/BizInsurance';
import Diary from '../services/diary/diary/Diary';
import News from '../services/news/News';
import BizPromotion from '../services/markets/bizPromotion/BizPromotion';
import styles from './Modal.module.css';
import { useAgentContext } from '../../context/AgentContextProvider';

const Modal = ({ id, onClose }) => {
  const { updateModalContext } = useAgentContext();
  
  const getModalName = (id) => {
    const modalNames = {
      'evaluation': '영업 관리',
      'schedule': '일정 관리',
      'management': '업장 관리',
      'salary': '급여 관리',
      'documents': '서식 관리',
      'bizPhone': '사업자 알뜰폰',
      'bizPromotion': '비즈프로모션',
      'bizAnalysis': '사업 분석',
      'bizInsurance': '손해보험',
      'qrGenerate': '출결 QR 생성',
      'qrStatus': '출결 현황',
      'diary': '다이어리',
      'news': '오늘의 소식'
    };
    
    return modalNames[id] || id;
  };
  
  useEffect(() => {
    const modalName = getModalName(id);
    updateModalContext(id, modalName);
    
    return () => {
      updateModalContext(null, null);
    };
  }, [id]);

  const renderModalContent = (id) => {
    switch(id) {
      case 'evaluation':
        return <EvaluationModalContent />;
      case 'schedule':
        return <ScheduleModalContent />;
      case 'salary':
        return <SalaryModalContent />;
      case 'documents':
        return <DocumentsModalContent />;
      case 'management':
        return <ManagementModalContent />;
      case 'bizPhone':
        return <BizPhone />;
      case 'bizPromotion':
        return <BizPromotion />;
      case 'bizAnalysis':
        return <BizAnalysis />;
      case 'qrGenerate':
        return <AttendanceQRGen />;
      case 'qrStatus':
        return <AttendanceStatus />;
      case 'bizInsurance':
        return <BizInsurance />;
      case 'diary':
        return <Diary />;
      case 'news':
        return <News />;
      default:
        return null;
    }
  };

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.agent}>
        <span role="img" aria-label="agent">🤖</span> 안녕하세요! 케어샐러리입니다! 무엇을 도와드릴까요?
      </div>
      <div className={styles.modal}>
        <button className={styles.closeButton} onClick={onClose}>X</button>
        <div>{renderModalContent(id)}</div>
      </div>
    </div>
  );
};

export default Modal;
