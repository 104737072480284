import React, { useState, useEffect } from 'react';
import styles from './PayrollResult.module.css';
import { 
  recalculateDeductions, 
  recalculatePayroll,
  calculateTaxableAmount,
  calculateNonTaxableAmount,
  getCurrentYearMonth,
  initializeEmployeePayroll,
  recalculateDeductionField,
  formatWeekName,
  formatNumber
} from '../../../../../utils/payrollCalculator';
import { getAuth } from 'firebase/auth';
import { doc, setDoc, collection } from 'firebase/firestore';
import { db } from '../../../../../api/firebase/firebaseConfig';
import EmployeeBasicInfo from './EmployeeBasicInfo';
import EmployeeDetailInfo from './EmployeeDetailInfo';

// 공통 함수 추가
const updateSavingStatus = (setSavingStatus, employeeId, status) => {
  setSavingStatus(prev => ({
    ...prev,
    [employeeId]: status
  }));
  
  // 일정 시간 후 상태 초기화 (error, saved, completed, uncompleted 상태일 때)
  if (['error', 'saved', 'completed', 'uncompleted'].includes(status)) {
    setTimeout(() => {
      setSavingStatus(prev => ({
        ...prev,
        [employeeId]: null
      }));
    }, 3000);
  }
};

// 파이어스토어 저장 공통 함수
const saveToFirestore = async (auth, employee, yearMonth, additionalData = {}) => {
  if (!auth.currentUser) {
    throw new Error('로그인이 필요합니다.');
  }
  
  // 저장할 데이터 준비
  const payrollData = {
    ...employee,
    updatedAt: new Date(),
    yearMonth,
    ...additionalData
  };
  
  // Firestore에 저장
  const payrollRef = doc(
    collection(db, 'payrolls', auth.currentUser.uid, yearMonth),
    employee.identifierWorker
  );
  
  await setDoc(payrollRef, payrollData, { merge: true });
  return employee.name;
};

// 급여 계산 내용(카드형 결과) 페이지
const PayrollResult = ({ payrollResult, isLoading, yearMonth }) => {
  const [expandedItems, setExpandedItems] = useState({});
  const [editablePayroll, setEditablePayroll] = useState([]);
  const [completedEmployees, setCompletedEmployees] = useState({});
  const [savingStatus, setSavingStatus] = useState({});
  const auth = getAuth();

  // 컴포넌트 마운트 및 업데이트 시 연도-월 값 가져오기
  useEffect(() => {
    console.log('현재 적용할 연도-월:', yearMonth);
    
    // 연도-월 변경 감지를 위한 이벤트 리스너 추가
    const yearMonthSelect = document.querySelector('.year-month-select-small');
    
    if (yearMonthSelect) {
      const handleYearMonthChange = () => {
        const newYearMonth = getCurrentYearMonth();
        console.log('연도-월 변경 감지:', newYearMonth);
        
        // 로컬 스토리지에 저장 (다음 번 사용을 위해)
        localStorage.setItem('selectedYearMonth', newYearMonth);
      };
      
      yearMonthSelect.addEventListener('change', handleYearMonthChange);
      
      // 클린업 함수
      return () => {
        yearMonthSelect.removeEventListener('change', handleYearMonthChange);
      };
    }
  }, [yearMonth]);

  // 연도-월이 변경될 때마다 급여 계산 다시 수행
  useEffect(() => {
    if (payrollResult && yearMonth) {
      console.log(`급여 계산 시작 - 적용 연도-월: ${yearMonth}`);
      
      // 초기 로드 시 필요한 계산 수행
      const updatedPayroll = payrollResult.map(employee => {
        // payrollCalculator의 initializeEmployeePayroll 함수 사용
        const initializedEmployee = initializeEmployeePayroll(employee, yearMonth);
        
        // 완료 상태 확인
        const isCompleted = employee.isCompleted || false;
        if (isCompleted) {
          setCompletedEmployees(prev => ({
            ...prev,
            [employee.identifierWorker]: true
          }));
        }
        
        return {
          ...initializedEmployee,
          isCompleted // 완료 상태 추가
        };
      });
      
      // 이름순으로 정렬
      updatedPayroll.sort((a, b) => {
        return a.name.localeCompare(b.name, 'ko');
      });
      
      setEditablePayroll(updatedPayroll);
    }
  }, [payrollResult, yearMonth]);

  if (!payrollResult) return null;

  const toggleDetails = (index) => {
    setExpandedItems(prev => ({
      ...prev,
      [index]: !prev[index]
    }));
  };

  // 필드 유형에 따른 재계산 로직 분리
  const recalculateByFieldType = (currentEmployee, field, numericValueInt) => {
    // 법정임금 관련 필드
    const legalPayFields = ['basicPay', 'weeklyHolidayPay', 'annualLeavePay', 'nightAllowance', 
                           'holidayAllowance', 'overtimeAllowance', 'inclusivePay'];
    
    // 과세소득 변경 여부 확인 필드 목록 (기존 + 확장된 과세 사용자 정의 수당)
    const taxableFields = [
      'basicPay', 'weeklyHolidayPay', 'annualLeavePay', 'nightAllowance', 
      'holidayAllowance', 'overtimeAllowance', 'rewardPay', 'bonusPay', 'etcAllowance',
      'customPay1', 'customPay2', 'customPay3', 'customPay4', 'customPay5'
    ];
    
    // 비과세 필드 목록 (확장된 비과세 사용자 정의 수당)
    const nonTaxableFields = [
      'mealAllowance', 'carAllowance',
      'customNonTaxPay1', 'customNonTaxPay2', 'customNonTaxPay3', 'customNonTaxPay4', 'customNonTaxPay5'
    ];
    
    // 공제 항목 필드
    const deductionFields = ['pensionInsurance', 'healthInsurance', 'longTermInsurance', 
                            'employmentInsurance', 'incomeTax', 'localTax'];
    
    // 필드 유형에 따라 다른 계산 로직 적용
    if (legalPayFields.includes(field)) {
      console.log(`재계산 시 적용 연도-월: ${yearMonth}`);
      
      // payrollCalculator의 recalculatePayroll 함수 사용
      return recalculatePayroll(
        currentEmployee, 
        { [field]: numericValueInt }, 
        yearMonth, 
        currentEmployee.dependents || [1, 0]
      );
    } 
    else if ([...taxableFields, ...nonTaxableFields].includes(field)) {
      // 현재 필드 값 업데이트
      currentEmployee[field] = numericValueInt;
      
      console.log(`공제액 재계산 시 적용 연도-월: ${yearMonth}`);
      
      // payrollCalculator의 recalculateDeductions 함수 사용
      const recalculatedDeductions = recalculateDeductions(
        currentEmployee, 
        0, // taxableAllowance 파라미터는 여기서는 사용하지 않음
        yearMonth, 
        currentEmployee.dependents || [1, 0]
      );
      
      // 재계산된 공제 정보로 업데이트
      return {
        ...currentEmployee,
        taxableAllowanceTotal: recalculatedDeductions.taxableAllowanceTotal,
        nonTaxableAllowanceTotal: recalculatedDeductions.nonTaxableAllowanceTotal,
        pensionInsurance: recalculatedDeductions.pensionInsurance,
        healthInsurance: recalculatedDeductions.healthInsurance,
        longTermInsurance: recalculatedDeductions.longTermInsurance,
        employmentInsurance: recalculatedDeductions.employmentInsurance,
        industrialInsurance: recalculatedDeductions.industrialInsurance,
        incomeTax: recalculatedDeductions.incomeTax,
        localTax: recalculatedDeductions.localTax,
        totalDeductions: recalculatedDeductions.totalDeductions,
        netPay: recalculatedDeductions.netPay
      };
    }
    else if (deductionFields.includes(field)) {
      // payrollCalculator의 recalculateDeductionField 함수 사용
      const recalculatedDeduction = recalculateDeductionField(
        currentEmployee,
        field,
        numericValueInt
      );
      
      // 업데이트
      return {
        ...currentEmployee,
        ...recalculatedDeduction
      };
    }
    else {
      // 그 외 필드는 단순 업데이트
      return currentEmployee;
    }
  };

  const handleAllowanceChange = (index, field, value) => {
    // 완료된 직원은 수정 불가
    if (completedEmployees[editablePayroll[index].identifierWorker]) {
      return;
    }
    
    // 숫자만 입력 가능하도록
    const numericValue = value.replace(/[^0-9]/g, '');
    const numericValueInt = Number(numericValue);
    
    // 업데이트된 직원 데이터 생성
    const updatedEmployees = [...editablePayroll];
    const currentEmployee = { ...updatedEmployees[index] };
    
    // 필드 값 업데이트
    currentEmployee[field] = numericValueInt;
    
    // 필드 유형에 따른 재계산 로직 적용
    updatedEmployees[index] = recalculateByFieldType(currentEmployee, field, numericValueInt);
    
    // 과세 및 비과세 수당 재계산
    updatedEmployees[index].taxableAmount = calculateTaxableAmount(updatedEmployees[index]);
    updatedEmployees[index].nonTaxableAmount = calculateNonTaxableAmount(updatedEmployees[index]);
    
    setEditablePayroll(updatedEmployees);
  };

  // 임시저장 함수
  const handleSave = async (index) => {
    try {
      const employee = editablePayroll[index];
      const employeeId = employee.identifierWorker;
      
      // 저장 상태 업데이트
      updateSavingStatus(setSavingStatus, employeeId, 'saving');
      
      // Firestore에 저장
      const employeeName = await saveToFirestore(auth, employee, yearMonth);
      
      // 저장 성공 상태 업데이트
      updateSavingStatus(setSavingStatus, employeeId, 'saved');
      
      console.log(`${employeeName}의 급여 데이터가 저장되었습니다.`);
    } catch (error) {
      console.error('급여 데이터 저장 중 오류 발생:', error);
      
      // 저장 실패 상태 업데이트
      updateSavingStatus(setSavingStatus, editablePayroll[index].identifierWorker, 'error');
      
      alert('급여 데이터 저장에 실패했습니다.');
    }
  };

  // 완료 함수
  const handleComplete = async (index) => {
    try {
      const employee = editablePayroll[index];
      const employeeId = employee.identifierWorker;
      
      // 저장 상태 업데이트
      updateSavingStatus(setSavingStatus, employeeId, 'saving');
      
      // Firestore에 저장 (완료 상태 추가)
      const employeeName = await saveToFirestore(auth, employee, yearMonth, { isCompleted: true });
      
      // 완료 상태 업데이트
      setCompletedEmployees(prev => ({
        ...prev,
        [employeeId]: true
      }));
      
      // 저장 성공 상태 업데이트
      updateSavingStatus(setSavingStatus, employeeId, 'completed');
      
      // 편집 가능한 급여 데이터 업데이트
      const updatedPayroll = [...editablePayroll];
      updatedPayroll[index] = {
        ...updatedPayroll[index],
        isCompleted: true
      };
      setEditablePayroll(updatedPayroll);
      
      console.log(`${employeeName}의 급여 계산이 완료되었습니다.`);
    } catch (error) {
      console.error('급여 데이터 완료 처리 중 오류 발생:', error);
      
      // 저장 실패 상태 업데이트
      updateSavingStatus(setSavingStatus, editablePayroll[index].identifierWorker, 'error');
      
      alert('급여 데이터 완료 처리에 실패했습니다.');
    }
  };

  // 완료 해제 함수 추가
  const handleUncomplete = async (index) => {
    try {
      const employee = editablePayroll[index];
      const employeeId = employee.identifierWorker;
      
      // 저장 상태 업데이트
      updateSavingStatus(setSavingStatus, employeeId, 'saving');
      
      // Firestore에 저장 (완료 상태 제거)
      const employeeName = await saveToFirestore(auth, employee, yearMonth, { isCompleted: false });
      
      // 완료 상태 업데이트
      setCompletedEmployees(prev => {
        const updated = { ...prev };
        delete updated[employeeId];
        return updated;
      });
      
      // 저장 성공 상태 업데이트
      updateSavingStatus(setSavingStatus, employeeId, 'uncompleted');
      
      // 편집 가능한 급여 데이터 업데이트
      const updatedPayroll = [...editablePayroll];
      updatedPayroll[index] = {
        ...updatedPayroll[index],
        isCompleted: false
      };
      setEditablePayroll(updatedPayroll);
      
      console.log(`${employeeName}의 급여 계산 완료 상태가 해제되었습니다.`);
    } catch (error) {
      console.error('급여 데이터 완료 해제 처리 중 오류 발생:', error);
      
      // 저장 실패 상태 업데이트
      updateSavingStatus(setSavingStatus, editablePayroll[index].identifierWorker, 'error');
      
      alert('급여 데이터 완료 해제 처리에 실패했습니다.');
    }
  };

  // input에 표시할 값 포맷 (원 표시 추가)
  const formatInputValue = (num) => {
    return (num || 0).toLocaleString();
  };

  // 저장 상태에 따른 버튼 텍스트 반환
  const getSaveButtonText = (employeeId) => {
    switch (savingStatus[employeeId]) {
      case 'saving':
        return '저장 중...';
      case 'saved':
        return '저장 완료';
      case 'error':
        return '저장 실패';
      default:
        return '임시저장';
    }
  };

  // 완료 상태에 따른 버튼 텍스트 반환
  const getCompleteButtonText = (employeeId) => {
    switch (savingStatus[employeeId]) {
      case 'saving':
        return '처리 중...';
      case 'completed':
        return '완료됨';
      case 'uncompleted':
        return '해제됨';
      case 'error':
        return '처리 실패';
      default:
        return completedEmployees[employeeId] ? '완료 해제' : '완료';
    }
  };

  // 수당명 변경 핸들러 함수 추가
  const handleCustomPayNameChange = (index, field, value) => {
    // 완료된 직원은 수정 불가
    if (completedEmployees[editablePayroll[index].identifierWorker]) {
      return;
    }
    
    // 업데이트된 직원 데이터 생성
    const updatedEmployees = [...editablePayroll];
    updatedEmployees[index] = {
      ...updatedEmployees[index],
      [field]: value
    };
    
    setEditablePayroll(updatedEmployees);
  };

  return (
    <div className={styles.payrollResultContainer}>
      {/* 디버깅용: 현재 적용 중인 연도-월 표시 */}
      <div className={styles.debugInfo}>
        적용 연도-월: {yearMonth}
      </div>
      
      {editablePayroll.map((employee, index) => (
        <div key={index} 
          className={`${styles.employeePayroll} ${
            completedEmployees[employee.identifierWorker] ? styles.completedEmployee : ''
          }`}
        >
          <EmployeeBasicInfo 
            employee={employee}
            formatNumber={formatNumber}
            toggleDetails={() => toggleDetails(index)}
            isCompleted={completedEmployees[employee.identifierWorker]}
          />

          {expandedItems[index] && (
            <EmployeeDetailInfo 
              employee={employee}
              index={index}
              formatNumber={formatNumber}
              formatInputValue={formatInputValue}
              handleAllowanceChange={handleAllowanceChange}
              handleCustomPayNameChange={handleCustomPayNameChange}
              isCompleted={completedEmployees[employee.identifierWorker]}
              handleSave={() => handleSave(index)}
              handleComplete={() => completedEmployees[employee.identifierWorker] ? handleUncomplete(index) : handleComplete(index)}
              savingStatus={savingStatus[employee.identifierWorker]}
              getSaveButtonText={() => getSaveButtonText(employee.identifierWorker)}
              getCompleteButtonText={() => getCompleteButtonText(employee.identifierWorker)}
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default PayrollResult;
