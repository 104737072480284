import React, { useState } from 'react';
import styles from './BusinessReport.module.css';

// 고객 데이터를 기반으로 한 경영 보고서 컴포넌트
const BusinessReport = ({ customerData, suggestions }) => {
  const [reportType, setReportType] = useState('고객분석');
  
  // VIP 고객 (지출액 기준 상위 20% 또는 20만원 이상 지출)
  const vipCustomers = [...customerData]
    .sort((a, b) => b.spending - a.spending)
    .filter((customer, index, arr) => {
      // 실제 카테고리가 VIP인 고객 또는 지출액 기준으로 VIP 판단
      return customer.category === 'VIP' || index < arr.length * 0.2 || customer.spending >= 200000;
    });
  
  // 일반 고객 (VIP가 아닌 모든 고객)
  const regularCustomers = customerData.filter(customer => !vipCustomers.some(vip => vip.id === customer.id));
  
  // 최근 30일 이내 방문 고객
  const recentCustomers = customerData.filter(customer => {
    if (!customer.lastVisit) return false;
    const lastVisitDate = new Date(customer.lastVisit);
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
    return lastVisitDate >= thirtyDaysAgo;
  });
  
  // 연령대별 고객 분포 계산
  const calculateAgeGroups = () => {
    const ageGroups = {
      '10대 이하': 0,
      '20대': 0,
      '30대': 0,
      '40대': 0, 
      '50대': 0,
      '60대 이상': 0,
      '정보 없음': 0
    };
    
    const currentYear = new Date().getFullYear();
    
    customerData.forEach(customer => {
      if (!customer.birthdate) {
        ageGroups['정보 없음']++;
        return;
      }
      
      const birthYear = new Date(customer.birthdate).getFullYear();
      const age = currentYear - birthYear;
      
      if (age <= 19) ageGroups['10대 이하']++;
      else if (age <= 29) ageGroups['20대']++;
      else if (age <= 39) ageGroups['30대']++;
      else if (age <= 49) ageGroups['40대']++;
      else if (age <= 59) ageGroups['50대']++;
      else ageGroups['60대 이상']++;
    });
    
    return ageGroups;
  };
  
  const ageGroupDistribution = calculateAgeGroups();
  
  // 카테고리별 고객 수 (실제 카테고리 기준)
  const categoryDistribution = customerData.reduce((acc, customer) => {
    acc[customer.category] = (acc[customer.category] || 0) + 1;
    return acc;
  }, {});
  
  // 총 매출 및 평균 지출액
  const totalSales = customerData.reduce((sum, customer) => sum + (customer.spending || 0), 0);
  const avgSpending = customerData.length > 0 ? totalSales / customerData.length : 0;
  
  // VIP와 일반 고객의 매출 기여도
  const vipSales = vipCustomers.reduce((sum, customer) => sum + (customer.spending || 0), 0);
  const regularSales = totalSales - vipSales;
  
  // VIP와 일반 고객의 평균 지출액
  const vipAvgSpending = vipCustomers.length > 0 ? vipSales / vipCustomers.length : 0;
  const regularAvgSpending = regularCustomers.length > 0 ? regularSales / regularCustomers.length : 0;
  
  // 방문 횟수별 분포 (1회, 2-5회, 6-10회, 11회 이상)
  const visitDistribution = {
    '1회': customerData.filter(c => c.visitCount === 1).length,
    '2-5회': customerData.filter(c => c.visitCount >= 2 && c.visitCount <= 5).length,
    '6-10회': customerData.filter(c => c.visitCount >= 6 && c.visitCount <= 10).length,
    '11회 이상': customerData.filter(c => c.visitCount > 10).length
  };
  
  // 가족 구성 분석 (배우자 있음, 자녀 있음, 가족 정보 없음)
  const familyStructure = {
    '배우자 있음': customerData.filter(c => c.spouse && c.spouse.name).length,
    '자녀 있음': customerData.filter(c => c.children && c.children.length > 0).length,
    '가족 정보 없음': customerData.filter(c => 
      (!c.spouse || !c.spouse.name) && (!c.children || c.children.length === 0)
    ).length
  };
  
  // 재방문률 (2회 이상 방문한 고객 비율)
  const returnRate = customerData.length > 0 
    ? (customerData.filter(c => c.visitCount >= 2).length / customerData.length) * 100 
    : 0;
  
  // 에이전트 제안 상태 분포
  const suggestionStatusDistribution = suggestions.reduce((acc, suggestion) => {
    acc[suggestion.status] = (acc[suggestion.status] || 0) + 1;
    return acc;
  }, {});
  
  // 제안 채택률
  const adoptionRate = suggestions.length > 0
    ? (suggestions.filter(s => s.status === '채택').length / suggestions.length) * 100
    : 0;
  
  // 카테고리별 색상 설정
  const categoryColors = {
    '일반': '#6c757d',
    'VIP': '#ffc107',
    '단체': '#0dcaf0',
    '비즈니스': '#0d6efd',
    '신규': '#20c997'
  };
  
  // 상태별 색상 설정
  const statusColors = {
    '채택': '#198754',
    '미채택': '#dc3545',
    '검토 중': '#fd7e14'
  };
  
  // 보고서 타입에 따른 컨텐츠 렌더링
  const renderReportContent = () => {
    switch(reportType) {
      case '고객분석':
        return (
          <div className={styles.reportContent}>
            <div className={styles.reportSection}>
              <h3>고객 구성 분석</h3>
              <div className={styles.reportMetrics}>
                <div className={styles.metricCard}>
                  <h4>총 고객 수</h4>
                  <div className={styles.metricValue}>{customerData.length}명</div>
                </div>
                <div className={styles.metricCard}>
                  <h4>VIP 고객 수</h4>
                  <div className={styles.metricValue}>{vipCustomers.length}명</div>
                  <div className={styles.subText}>전체의 {((vipCustomers.length / customerData.length) * 100 || 0).toFixed(1)}%</div>
                </div>
                <div className={styles.metricCard}>
                  <h4>일반 고객 수</h4>
                  <div className={styles.metricValue}>{regularCustomers.length}명</div>
                  <div className={styles.subText}>전체의 {((regularCustomers.length / customerData.length) * 100 || 0).toFixed(1)}%</div>
                </div>
                <div className={styles.metricCard}>
                  <h4>재방문률</h4>
                  <div className={styles.metricValue}>{returnRate.toFixed(1)}%</div>
                </div>
              </div>
            </div>
            
            <div className={styles.reportRow}>
              <div className={`${styles.reportSection} ${styles.halfWidth}`}>
                <h3>고객 카테고리 분포</h3>
                <div className={styles.chartContainer}>
                  {Object.entries(categoryDistribution).length > 0 ? (
                    <div className={styles.barChart}>
                      {Object.entries(categoryDistribution).map(([category, count]) => (
                        <div key={category} className={styles.chartItem}>
                          <div className={styles.barLabel}>{category}</div>
                          <div className={styles.barContainer}>
                            <div 
                              className={styles.bar} 
                              style={{
                                width: `${(count / customerData.length) * 100}%`,
                                backgroundColor: categoryColors[category] || '#6c757d'
                              }}
                            ></div>
                            <span className={styles.barValue}>{count}명</span>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className={styles.noDataMessage}>데이터가 없습니다.</div>
                  )}
                </div>
              </div>
              
              <div className={`${styles.reportSection} ${styles.halfWidth}`}>
                <h3>방문 횟수 분포</h3>
                <div className={styles.chartContainer}>
                  {customerData.length > 0 ? (
                    <div className={styles.barChart}>
                      {Object.entries(visitDistribution).map(([range, count]) => (
                        <div key={range} className={styles.chartItem}>
                          <div className={styles.barLabel}>{range}</div>
                          <div className={styles.barContainer}>
                            <div 
                              className={styles.bar} 
                              style={{
                                width: `${(count / customerData.length) * 100}%`,
                                backgroundColor: 'var(--success-color)'
                              }}
                            ></div>
                            <span className={styles.barValue}>{count}명</span>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className={styles.noDataMessage}>데이터가 없습니다.</div>
                  )}
                </div>
              </div>
            </div>
            
            <div className={styles.reportRow}>
              <div className={styles.reportSection}>
                <h3>매출 기여도 상위 고객</h3>
                {vipCustomers.length > 0 ? (
                  <div className={styles.topCustomersTable}>
                    <table>
                      <thead>
                        <tr>
                          <th>순위</th>
                          <th>고객명</th>
                          <th>카테고리</th>
                          <th>총 지출액</th>
                          <th>방문 횟수</th>
                          <th>최근 방문일</th>
                        </tr>
                      </thead>
                      <tbody>
                        {vipCustomers.slice(0, 5).map((customer, index) => (
                          <tr key={customer.id}>
                            <td>{index + 1}</td>
                            <td>{customer.name}</td>
                            <td>
                              <span 
                                className={styles.categoryBadge}
                                style={{ backgroundColor: categoryColors[customer.category] }}
                              >
                                {customer.category}
                              </span>
                            </td>
                            <td>{customer.spending.toLocaleString()}원</td>
                            <td>{customer.visitCount}회</td>
                            <td>{customer.lastVisit || '정보 없음'}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <div className={styles.noDataMessage}>데이터가 없습니다.</div>
                )}
              </div>
            </div>
          </div>
        );
        
      case '수익분석':
        return (
          <div className={styles.reportContent}>
            <div className={styles.reportSection}>
              <h3>매출 개요</h3>
              <div className={styles.reportMetrics}>
                <div className={`${styles.metricCard} ${styles.highlight}`}>
                  <h4>총 매출액</h4>
                  <div className={styles.metricValue}>{totalSales.toLocaleString()}원</div>
                </div>
                <div className={styles.metricCard}>
                  <h4>고객 평균 지출액</h4>
                  <div className={styles.metricValue}>{avgSpending.toFixed(0).toLocaleString()}원</div>
                </div>
                <div className={styles.metricCard}>
                  <h4>VIP 고객 매출 기여도</h4>
                  <div className={styles.metricValue}>
                    {((vipSales / totalSales) * 100 || 0).toFixed(1)}%
                  </div>
                </div>
                <div className={styles.metricCard}>
                  <h4>최근 30일 매출</h4>
                  <div className={styles.metricValue}>
                    {recentCustomers.reduce((sum, c) => sum + c.spending, 0).toLocaleString()}원
                  </div>
                </div>
              </div>
            </div>
            
            <div className={styles.reportRow}>
              <div className={`${styles.reportSection} ${styles.halfWidth}`}>
                <h3>VIP vs 일반 고객 매출</h3>
                <div className={styles.chartContainer}>
                  {customerData.length > 0 ? (
                    <div className={styles.barChart}>
                      <div className={styles.chartItem}>
                        <div className={styles.barLabel}>VIP 고객</div>
                        <div className={styles.barContainer}>
                          <div 
                            className={styles.bar} 
                            style={{
                              width: `${(vipSales / totalSales) * 100}%`,
                              backgroundColor: '#ffc107'
                            }}
                          ></div>
                          <span className={styles.barValue}>{vipSales.toLocaleString()}원</span>
                        </div>
                      </div>
                      <div className={styles.chartItem}>
                        <div className={styles.barLabel}>일반 고객</div>
                        <div className={styles.barContainer}>
                          <div 
                            className={styles.bar} 
                            style={{
                              width: `${(regularSales / totalSales) * 100}%`,
                              backgroundColor: '#6c757d'
                            }}
                          ></div>
                          <span className={styles.barValue}>{regularSales.toLocaleString()}원</span>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className={styles.noDataMessage}>데이터가 없습니다.</div>
                  )}
                </div>
              </div>
              
              <div className={`${styles.reportSection} ${styles.halfWidth}`}>
                <h3>고객 유형별 평균 지출액</h3>
                <div className={styles.chartContainer}>
                  {customerData.length > 0 ? (
                    <div className={styles.barChart}>
                      <div className={styles.chartItem}>
                        <div className={styles.barLabel}>VIP 고객 평균</div>
                        <div className={styles.barContainer}>
                          <div 
                            className={styles.bar} 
                            style={{
                              width: `${(vipAvgSpending / (vipAvgSpending > regularAvgSpending ? vipAvgSpending : regularAvgSpending * 1.2)) * 100}%`,
                              backgroundColor: '#ffc107'
                            }}
                          ></div>
                          <span className={styles.barValue}>{vipAvgSpending.toFixed(0).toLocaleString()}원</span>
                        </div>
                      </div>
                      <div className={styles.chartItem}>
                        <div className={styles.barLabel}>일반 고객 평균</div>
                        <div className={styles.barContainer}>
                          <div 
                            className={styles.bar} 
                            style={{
                              width: `${(regularAvgSpending / (vipAvgSpending > regularAvgSpending ? vipAvgSpending : regularAvgSpending * 1.2)) * 100}%`,
                              backgroundColor: '#6c757d'
                            }}
                          ></div>
                          <span className={styles.barValue}>{regularAvgSpending.toFixed(0).toLocaleString()}원</span>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className={styles.noDataMessage}>데이터가 없습니다.</div>
                  )}
                </div>
              </div>
            </div>
            
            <div className={styles.reportSection}>
              <h3>고객 관리 효율성</h3>
              <div className={styles.efficiencyMetrics}>
                <div className={styles.efficiencyCard}>
                  <h4>방문 당 평균 지출액</h4>
                  <div className={styles.metricValue}>
                    {(totalSales / customerData.reduce((sum, c) => sum + c.visitCount, 0) || 0).toFixed(0).toLocaleString()}원
                  </div>
                  <p className={styles.metricDescription}>고객이 한 번 방문할 때마다 발생하는 평균 매출액입니다.</p>
                </div>
                <div className={styles.efficiencyCard}>
                  <h4>고객 획득 비용 추정</h4>
                  <div className={styles.metricValue}>20,000원</div>
                  <p className={styles.metricDescription}>새로운 고객을 유치하는데 필요한 예상 마케팅 비용입니다. (가정)</p>
                </div>
                <div className={styles.efficiencyCard}>
                  <h4>고객 생애 가치(LTV)</h4>
                  <div className={styles.metricValue}>
                    {(avgSpending * (1 + returnRate / 100 * 3) || 0).toFixed(0).toLocaleString()}원
                  </div>
                  <p className={styles.metricDescription}>고객이 장기적으로 창출할 것으로 예상되는 총 매출액입니다.</p>
                </div>
              </div>
            </div>
          </div>
        );
        
      case '제안효과':
        return (
          <div className={styles.reportContent}>
            <div className={styles.reportSection}>
              <h3>에이전트 제안 현황</h3>
              <div className={styles.reportMetrics}>
                <div className={styles.metricCard}>
                  <h4>총 제안 수</h4>
                  <div className={styles.metricValue}>{suggestions.length}건</div>
                </div>
                <div className={`${styles.metricCard} ${styles.highlight}`}>
                  <h4>제안 채택률</h4>
                  <div className={styles.metricValue}>{adoptionRate.toFixed(1)}%</div>
                  <div className={styles.progressBar}>
                    <div 
                      className={styles.progress} 
                      style={{width: `${adoptionRate}%`}}
                    ></div>
                  </div>
                </div>
                <div className={styles.metricCard}>
                  <h4>채택된 제안</h4>
                  <div className={styles.metricValue}>
                    {suggestions.filter(s => s.status === '채택').length}건
                  </div>
                </div>
                <div className={styles.metricCard}>
                  <h4>검토 중인 제안</h4>
                  <div className={styles.metricValue}>
                    {suggestions.filter(s => s.status === '검토 중').length}건
                  </div>
                </div>
              </div>
            </div>
            
            <div className={styles.reportRow}>
              <div className={`${styles.reportSection} ${styles.halfWidth}`}>
                <h3>제안 상태 분포</h3>
                <div className={styles.chartContainer}>
                  {suggestions.length > 0 ? (
                    <div className={styles.barChart}>
                      {Object.entries(suggestionStatusDistribution).map(([status, count]) => (
                        <div key={status} className={styles.chartItem}>
                          <div className={styles.barLabel}>{status}</div>
                          <div className={styles.barContainer}>
                            <div 
                              className={styles.bar} 
                              style={{
                                width: `${(count / suggestions.length) * 100}%`,
                                backgroundColor: statusColors[status] || '#6c757d'
                              }}
                            ></div>
                            <span className={styles.barValue}>{count}건</span>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className={styles.noDataMessage}>데이터가 없습니다.</div>
                  )}
                </div>
              </div>
              
              <div className={`${styles.reportSection} ${styles.halfWidth}`}>
                <h3>카테고리별 제안</h3>
                <div className={styles.chartContainer}>
                  {suggestions.length > 0 ? (
                    <div className={styles.barChart}>
                      {Object.entries(suggestions.reduce((acc, suggestion) => {
                        acc[suggestion.category] = (acc[suggestion.category] || 0) + 1;
                        return acc;
                      }, {})).map(([category, count]) => (
                        <div key={category} className={styles.chartItem}>
                          <div className={styles.barLabel}>{category}</div>
                          <div className={styles.barContainer}>
                            <div 
                              className={styles.bar} 
                              style={{
                                width: `${(count / suggestions.length) * 100}%`,
                                backgroundColor: '#0d6efd'
                              }}
                            ></div>
                            <span className={styles.barValue}>{count}건</span>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className={styles.noDataMessage}>데이터가 없습니다.</div>
                  )}
                </div>
              </div>
            </div>
            
            <div className={styles.reportSection}>
              <h3>성공적인 제안 사례</h3>
              {suggestions.filter(s => s.status === '채택' && s.result && s.result !== '-').length > 0 ? (
                <div className={styles.successfulSuggestions}>
                  {suggestions
                    .filter(s => s.status === '채택' && s.result && s.result !== '-')
                    .map(suggestion => (
                      <div key={suggestion.id} className={styles.successCase}>
                        <div className={styles.caseHeader}>
                          <h4>{suggestion.suggestion}</h4>
                          <span className={styles.caseDate}>{suggestion.date}</span>
                        </div>
                        <div className={styles.caseCategory}>
                          <span 
                            className={styles.categoryBadge}
                            style={{ backgroundColor: categoryColors[suggestion.category] }}
                          >
                            {suggestion.category}
                          </span>
                        </div>
                        <div className={styles.caseResult}>
                          <span className={styles.resultLabel}>결과:</span>
                          <span className={styles.resultValue}>{suggestion.result}</span>
                        </div>
                      </div>
                    ))
                  }
                </div>
              ) : (
                <div className={styles.noDataMessage}>성공 사례가 없습니다.</div>
              )}
            </div>
          </div>
        );
      
      default:
        return null;
    }
  };
  
  return (
    <div className={styles.businessReport}>
      <div className={styles.reportHeader}>
        <div className={styles.reportTabs}>
          <button 
            className={reportType === '고객분석' ? styles.activeTab : ''}
            onClick={() => setReportType('고객분석')}
          >
            고객 분석
          </button>
          <button 
            className={reportType === '수익분석' ? styles.activeTab : ''}
            onClick={() => setReportType('수익분석')}
          >
            수익 분석
          </button>
          <button 
            className={reportType === '제안효과' ? styles.activeTab : ''}
            onClick={() => setReportType('제안효과')}
          >
            제안 효과
          </button>
        </div>
      </div>
      
      <div className={styles.reportContainer}>
        {renderReportContent()}
      </div>
      
      <div className={styles.reportFooter}>
        <div className={styles.reportSummary}>
          <h3>종합 인사이트</h3>
          <div className={styles.insights}>
            {customerData.length > 0 ? (
              <>
                <p>
                  <strong>고객 인사이트:</strong> 총 {customerData.length}명의 고객 중, {vipCustomers.length}명은 VIP 고객으로 분류되며, 
                  이들은 전체 매출의 {((vipSales / totalSales) * 100 || 0).toFixed(1)}%를 차지합니다. 
                  재방문율은 {returnRate.toFixed(1)}%입니다.
                </p>
                <p>
                  <strong>고객 통계:</strong> 평균적으로 VIP 고객은 {vipAvgSpending.toFixed(0).toLocaleString()}원을 지출하며,
                  일반 고객 지출액 {regularAvgSpending.toFixed(0).toLocaleString()}원의 {(vipAvgSpending / regularAvgSpending).toFixed(1)}배에 달합니다.
                  {
                    (() => {
                      const maxAgeGroup = Object.entries(ageGroupDistribution)
                        .filter(([group]) => group !== '정보 없음')
                        .sort((a, b) => b[1] - a[1])[0];
                      return maxAgeGroup ? ` 가장 많은 고객 연령대는 ${maxAgeGroup[0]}(${maxAgeGroup[1]}명)입니다.` : '';
                    })()
                  }
                </p>
                <p>
                  <strong>매출 인사이트:</strong> 고객 평균 지출액은 {avgSpending.toFixed(0).toLocaleString()}원이며, 
                  방문당 평균 지출액은 {(totalSales / customerData.reduce((sum, c) => sum + c.visitCount, 0) || 0).toFixed(0).toLocaleString()}원입니다.
                </p>
              </>
            ) : (
              <p>아직 충분한 고객 데이터가 없습니다. 고객 정보를 추가하여 더 정확한 인사이트를 얻으세요.</p>
            )}
            
            {suggestions.length > 0 ? (
              <p>
                <strong>제안 인사이트:</strong> {suggestions.length}건의 에이전트 제안 중 {adoptionRate.toFixed(1)}%가 채택되었으며, 
                특히 {Object.entries(suggestions.reduce((acc, s) => {
                  if (s.status === '채택') {
                    acc[s.category] = (acc[s.category] || 0) + 1;
                  }
                  return acc;
                }, {})).sort((a, b) => b[1] - a[1])[0]?.[0] || '없음'} 카테고리에서 가장 많은 제안이 채택되었습니다.
              </p>
            ) : (
              <p>아직 에이전트 제안 데이터가 부족합니다. 더 많은 제안을 검토하여 인사이트를 얻으세요.</p>
            )}
          </div>
        </div>
        
        <button className={styles.btnPrimary}>
          보고서 PDF로 내보내기
        </button>
      </div>
    </div>
  );
};

export default BusinessReport; 