import React, { useState, useEffect } from 'react';
import BaseContract from './BaseContract';
import './Contracts.css';

const OutsourcingContract = ({ party, existingData, onSave }) => {
  const [formData, setFormData] = useState({
    projectName: '',
    projectScope: '',
    startDate: '',
    endDate: '',
    contractAmount: '',
    paymentSchedule: '',
    deliverables: '',
    technicalRequirements: '',
    confidentiality: '',
    intellectualProperty: '',
    terminationTerms: '',
    warrantyPeriod: '',
    otherConditions: '',
  });

  useEffect(() => {
    if (existingData) {
      setFormData(existingData);
    }
  }, [existingData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSave = () => {
    onSave(formData);
  };

  return (
    <BaseContract 
      title="외주계약서" 
      onSave={handleSave}
      isValid={formData.projectName && formData.startDate && formData.contractAmount}
    >
      <div className="contract-header">
        <p>위탁자: [회사명] (이하 "위탁자"라 함)</p>
        <p>수탁자: {party.name} (이하 "수탁자"라 함)</p>
      </div>

      <div className="contract-section">
        <h3>제1조 (프로젝트 정보)</h3>
        <div className="form-group">
          <label>프로젝트명</label>
          <input
            type="text"
            name="projectName"
            value={formData.projectName}
            onChange={handleChange}
            placeholder="프로젝트명"
            required
          />
        </div>
        <div className="form-group">
          <label>프로젝트 범위</label>
          <textarea
            name="projectScope"
            value={formData.projectScope}
            onChange={handleChange}
            placeholder="프로젝트 범위 및 내용"
            required
          />
        </div>
      </div>

      <div className="contract-section">
        <h3>제2조 (계약기간)</h3>
        <div className="form-group">
          <label>시작일</label>
          <input
            type="date"
            name="startDate"
            value={formData.startDate}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label>종료일</label>
          <input
            type="date"
            name="endDate"
            value={formData.endDate}
            onChange={handleChange}
            required
          />
        </div>
      </div>

      <div className="contract-section">
        <h3>제3조 (계약금액 및 지급조건)</h3>
        <div className="form-group">
          <label>계약금액</label>
          <input
            type="number"
            name="contractAmount"
            value={formData.contractAmount}
            onChange={handleChange}
            placeholder="계약금액"
            required
          />
        </div>
        <div className="form-group">
          <label>지급 일정</label>
          <textarea
            name="paymentSchedule"
            value={formData.paymentSchedule}
            onChange={handleChange}
            placeholder="지급 일정 및 조건"
            required
          />
        </div>
      </div>

      <div className="contract-section">
        <h3>제4조 (산출물)</h3>
        <div className="form-group">
          <textarea
            name="deliverables"
            value={formData.deliverables}
            onChange={handleChange}
            placeholder="산출물 내역 및 제출 조건"
          />
        </div>
      </div>

      <div className="contract-section">
        <h3>제5조 (기술요구사항)</h3>
        <div className="form-group">
          <textarea
            name="technicalRequirements"
            value={formData.technicalRequirements}
            onChange={handleChange}
            placeholder="기술요구사항 및 품질기준"
          />
        </div>
      </div>

      <div className="contract-section">
        <h3>제6조 (기밀유지)</h3>
        <div className="form-group">
          <textarea
            name="confidentiality"
            value={formData.confidentiality}
            onChange={handleChange}
            placeholder="기밀유지 조항"
          />
        </div>
      </div>

      <div className="contract-section">
        <h3>제7조 (지식재산권)</h3>
        <div className="form-group">
          <textarea
            name="intellectualProperty"
            value={formData.intellectualProperty}
            onChange={handleChange}
            placeholder="지식재산권 귀속 조항"
          />
        </div>
      </div>

      <div className="contract-section">
        <h3>제8조 (계약해지)</h3>
        <div className="form-group">
          <textarea
            name="terminationTerms"
            value={formData.terminationTerms}
            onChange={handleChange}
            placeholder="계약해지 조건"
          />
        </div>
      </div>

      <div className="contract-section">
        <h3>제9조 (하자보수)</h3>
        <div className="form-group">
          <label>하자보수 기간</label>
          <input
            type="text"
            name="warrantyPeriod"
            value={formData.warrantyPeriod}
            onChange={handleChange}
            placeholder="하자보수 기간"
          />
        </div>
      </div>

      <div className="contract-section">
        <h3>제10조 (기타)</h3>
        <div className="form-group">
          <textarea
            name="otherConditions"
            value={formData.otherConditions}
            onChange={handleChange}
            placeholder="기타 계약조건"
          />
        </div>
      </div>
    </BaseContract>
  );
};

export default OutsourcingContract; 