import React, { useState, useEffect } from 'react';
import { collection, getDocs, addDoc, query, where, doc, getDoc, updateDoc, setDoc, deleteDoc } from 'firebase/firestore';
import { firestore } from '../../../../api/firebase/firebaseConfig';
import { auth } from '../../../../api/firebase/firebaseConfig';
import 'react-calendar/dist/Calendar.css';
import styles from './ScheduleModalContent.module.css';
import { formatTimeInput } from '../../../../utils/utils';

// 컴포넌트 임포트
import ScheduleCalendar from './components/ScheduleCalendar';
import ScheduleList from './components/ScheduleList';
import ScheduleForm from './components/ScheduleForm';
import TabMenu from '../../../modals/TabMenu';

const ScheduleModalContent = () => {
  const [activeTab, setActiveTab] = useState('예약 관리');
  const [schedules, setSchedules] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [newSchedule, setNewSchedule] = useState({ 
    time: '', 
    endTime: '', 
    name: '', 
    contact: '', 
    note: '', 
    amount: 0,
    employeeId: '',
    employeeName: ''
  });
  const [customerData, setCustomerData] = useState([]);
  const [showCustomerList, setShowCustomerList] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [editingScheduleId, setEditingScheduleId] = useState(null);
  const [originalSchedule, setOriginalSchedule] = useState(null);
  const [employees, setEmployees] = useState([]);
  const [showEmployeeList, setShowEmployeeList] = useState(false);
  const [employeeSearchTerm, setEmployeeSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  // 탭 메뉴 정의
  const tabs = ['예약 관리', '시간제 직원 스케줄', '상근 직원 스케줄'];

  const fetchSchedules = async () => {
    const user = auth.currentUser;
    if (user) {
      try {
        console.log('Fetching schedules for user:', user.uid);
        const q = query(collection(firestore, 'schedules'), where('userId', '==', user.uid));
        const snapshot = await getDocs(q);
        const fetchedSchedules = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setSchedules(fetchedSchedules);
        console.log('Fetched schedules:', fetchedSchedules);
      } catch (error) {
        if (error.code === 'permission-denied') {
          console.error('권한이 없습니다.');
        } else {
          console.error('스케줄을 가져오는 중 오류가 발생했습니다:', error);
        }
        setSchedules([]); // 에러 발생 시 빈 배열로 설정
      }
    }
  };

  const fetchCustomerData = async () => {
    const user = auth.currentUser;
    if (user) {
      try {
        const customerDocRef = doc(firestore, 'customers', user.uid);
        const customerDoc = await getDoc(customerDocRef);
        
        if (customerDoc.exists()) {
          setCustomerData(customerDoc.data().customerList || []);
        } else {
          setCustomerData([]);
        }
      } catch (error) {
        console.error('고객 데이터를 가져오는 중 오류가 발생했습니다:', error);
        setCustomerData([]);
      }
    }
  };

  const fetchEmployees = async () => {
    const user = auth.currentUser;
    if (user) {
      try {
        const employeeDocRef = doc(firestore, 'employees', user.uid);
        const employeeDoc = await getDoc(employeeDocRef);
        
        if (employeeDoc.exists()) {
          setEmployees(employeeDoc.data().employeeList || []);
        } else {
          setEmployees([]);
        }
      } catch (error) {
        console.error('근로자 데이터를 가져오는 중 오류가 발생했습니다:', error);
        setEmployees([]);
      }
    }
  };

  useEffect(() => {
    fetchSchedules();
    fetchCustomerData();
    fetchEmployees();
  }, []);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    
    // 시간 입력 포맷팅 - time 또는 endTime 필드인 경우
    if (name === 'time' || name === 'endTime') {
      const formattedValue = formatTimeInput(value);
      setNewSchedule({ ...newSchedule, [name]: formattedValue });
    } else {
      setNewSchedule({ ...newSchedule, [name]: value });
    }
  };

  const handleSelectCustomer = (customer) => {
    setNewSchedule({
      ...newSchedule,
      name: customer.name,
      contact: customer.contact
    });
    setShowCustomerList(false);
  };
  
  const filteredCustomers = customerData.filter(customer => 
    customer.name.includes(searchTerm) || 
    customer.contact.includes(searchTerm)
  );

  const handleSelectEmployee = (employee) => {
    setNewSchedule({
      ...newSchedule,
      employeeId: employee.id,
      employeeName: employee.name
    });
    setShowEmployeeList(false);
  };
  
  const handleClearEmployee = () => {
    setNewSchedule({
      ...newSchedule,
      employeeId: '',
      employeeName: ''
    });
  };

  const filteredEmployees = employees.filter(employee => 
    employee.name.includes(employeeSearchTerm)
  );

  // 날짜에 예약이 있는지 확인하는 함수
  const hasScheduleOnDate = (date) => {
    return schedules.some(schedule => {
      const scheduleDate = new Date(schedule.date.toDate());
      return scheduleDate.getFullYear() === date.getFullYear() &&
             scheduleDate.getMonth() === date.getMonth() &&
             scheduleDate.getDate() === date.getDate();
    });
  };

  // 타일에 클래스 이름을 추가하는 함수
  const tileClassName = ({ date, view }) => {
    if (view === 'month' && hasScheduleOnDate(date)) {
      return 'has-schedule';
    }
    return null;
  };

  // 일정 수정 모드로 전환
  const handleEditSchedule = (schedule) => {
    setIsEditing(true);
    setEditingScheduleId(schedule.id);
    setOriginalSchedule(schedule);
    
    setNewSchedule({
      time: schedule.time || '',
      endTime: schedule.endTime || '',
      name: schedule.name || '',
      contact: schedule.contact || '',
      note: schedule.note || '',
      amount: schedule.amount || 0,
      employeeId: schedule.employeeId || '',
      employeeName: schedule.employeeName || ''
    });
  };
  
  // 수정 취소
  const handleCancelEdit = () => {
    setIsEditing(false);
    setEditingScheduleId(null);
    setOriginalSchedule(null);
    setNewSchedule({ 
      time: '', 
      endTime: '', 
      name: '', 
      contact: '', 
      note: '', 
      amount: 0,
      employeeId: '',
      employeeName: ''
    });
  };
  
  // 수정된 일정 저장
  const handleUpdateSchedule = async () => {
    const user = auth.currentUser;
    if (!user) {
      console.error('User is not authenticated');
      return;
    }
    
    if (!newSchedule.time) {
      alert('시작 시간은 필수 입력사항입니다.');
      return;
    }
    
    if (!newSchedule.name || !newSchedule.contact) {
      alert('예약자명과 연락처는 필수 입력사항입니다.');
      return;
    }
    
    try {
      // 스케줄 업데이트
      const scheduleDocRef = doc(firestore, 'schedules', editingScheduleId);
      await updateDoc(scheduleDocRef, {
        ...newSchedule,
        // date는 유지 (selectedDate가 아닌 원래 날짜를 사용)
      });
      
      // 근로자 출근 데이터 업데이트 (근로자가 바뀌었거나 시간이 바뀐 경우에만)
      if (newSchedule.employeeId && 
          (originalSchedule.employeeId !== newSchedule.employeeId || 
           originalSchedule.time !== newSchedule.time || 
           originalSchedule.endTime !== newSchedule.endTime)) {
        
        // 날짜 가져오기 (Firebase Timestamp를 Date 객체로 변환)
        const scheduleDate = originalSchedule.date.toDate ? 
                              originalSchedule.date.toDate() : originalSchedule.date;
        
        await updateEmployeeAttendance(
          newSchedule.employeeId, 
          scheduleDate, 
          newSchedule.time, 
          newSchedule.endTime
        );
      }
      
      // 고객 데이터 업데이트 (지출액 변경분 반영)
      if (originalSchedule && originalSchedule.name) {
        const customerDocRef = doc(firestore, 'customers', user.uid);
        const customerDoc = await getDoc(customerDocRef);
        
        if (customerDoc.exists()) {
          const customerList = customerDoc.data().customerList || [];
          
          // 원래 고객의 지출액 조정 (기존 고객과 새 고객이 다른 경우)
          if (originalSchedule.name !== newSchedule.name) {
            // 원래 고객의 지출액에서 원래 금액 빼기
            let updatedCustomerList = customerList.map(customer => {
              if (customer.name === originalSchedule.name) {
                const newSpending = Math.max(0, (customer.spending || 0) - (originalSchedule.amount || 0));
                const newVisitCount = Math.max(0, (customer.visitCount || 0) - 1);
                
                return {
                  ...customer,
                  spending: newSpending,
                  visitCount: newVisitCount
                };
              }
              return customer;
            });
            
            // 새 고객 찾기 또는 생성
            const existingCustomerIndex = updatedCustomerList.findIndex(
              customer => customer.name === newSchedule.name && customer.contact === newSchedule.contact
            );
            
            if (existingCustomerIndex >= 0) {
              // 기존 고객이면 정보 업데이트
              updatedCustomerList = updatedCustomerList.map((customer, index) => {
                if (index === existingCustomerIndex) {
                  return {
                    ...customer,
                    visitCount: (customer.visitCount || 0) + 1,
                    spending: (customer.spending || 0) + (parseInt(newSchedule.amount) || 0),
                    lastVisit: selectedDate.toISOString().split('T')[0]
                  };
                }
                return customer;
              });
            } else {
              // 신규 고객이면 추가
              const newCustomer = {
                id: Date.now(),
                name: newSchedule.name,
                contact: newSchedule.contact,
                birthdate: '',
                category: '신규',
                lastVisit: selectedDate.toISOString().split('T')[0],
                memo: newSchedule.note || '',
                spending: parseInt(newSchedule.amount) || 0,
                visitCount: 1,
                preferences: '',
                spouse: { name: '', contact: '', birthdate: '' },
                children: []
              };
              
              updatedCustomerList.push(newCustomer);
            }
            
            await updateDoc(customerDocRef, { customerList: updatedCustomerList });
          } else {
            // 같은 고객의 지출액만 변경된 경우
            const amountDiff = (parseInt(newSchedule.amount) || 0) - (originalSchedule.amount || 0);
            
            if (amountDiff !== 0) {
              const updatedCustomerList = customerList.map(customer => {
                if (customer.name === newSchedule.name) {
                  return {
                    ...customer,
                    spending: Math.max(0, (customer.spending || 0) + amountDiff)
                  };
                }
                return customer;
              });
              
              await updateDoc(customerDocRef, { customerList: updatedCustomerList });
            }
          }
        }
      }
      
      console.log('Schedule updated successfully');
      setIsEditing(false);
      setEditingScheduleId(null);
      setOriginalSchedule(null);
      setNewSchedule({ 
        time: '', 
        endTime: '', 
        name: '', 
        contact: '', 
        note: '', 
        amount: 0,
        employeeId: '',
        employeeName: ''
      });
      
      // 데이터 새로고침
      await fetchSchedules();
      await fetchCustomerData();
      
    } catch (error) {
      console.error('일정 수정 중 오류가 발생했습니다:', error);
    }
  };
  
  // 일정 삭제
  const handleDeleteSchedule = async (scheduleId, schedule) => {
    if (!window.confirm('정말로 이 일정을 삭제하시겠습니까?')) {
      return;
    }
    
    const user = auth.currentUser;
    if (!user) {
      console.error('User is not authenticated');
      return;
    }
    
    try {
      // 스케줄 삭제
      const scheduleDocRef = doc(firestore, 'schedules', scheduleId);
      await deleteDoc(scheduleDocRef);
      
      // 고객 데이터 업데이트 (지출액과 방문횟수 감소)
      if (schedule && schedule.name) {
        const customerDocRef = doc(firestore, 'customers', user.uid);
        const customerDoc = await getDoc(customerDocRef);
        
        if (customerDoc.exists()) {
          const customerList = customerDoc.data().customerList || [];
          
          const updatedCustomerList = customerList.map(customer => {
            if (customer.name === schedule.name) {
              const newSpending = Math.max(0, (customer.spending || 0) - (schedule.amount || 0));
              const newVisitCount = Math.max(0, (customer.visitCount || 0) - 1);
              
              return {
                ...customer,
                spending: newSpending,
                visitCount: newVisitCount
              };
            }
            return customer;
          });
          
          await updateDoc(customerDocRef, { customerList: updatedCustomerList });
        }
      }
      
      console.log('Schedule deleted successfully');
      
      // 수정 중이었다면 수정 모드 취소
      if (isEditing && editingScheduleId === scheduleId) {
        handleCancelEdit();
      }
      
      // 데이터 새로고침
      await fetchSchedules();
      await fetchCustomerData();
      
    } catch (error) {
      console.error('일정 삭제 중 오류가 발생했습니다:', error);
    }
  };

  // 일정 추가 함수에 근로자 출근 데이터 업데이트 코드 추가
  const handleAddSchedule = async () => {
    const user = auth.currentUser;
    if (user) {
      try {
        if (!newSchedule.time) {
          alert('시작 시간은 필수 입력사항입니다.');
          return;
        }
        
        if (!newSchedule.name || !newSchedule.contact) {
          alert('예약자명과 연락처는 필수 입력사항입니다.');
          return;
        }

        console.log('Adding schedule for user:', user.uid);
        const scheduleRef = await addDoc(collection(firestore, 'schedules'), {
          ...newSchedule,
          date: selectedDate,
          userId: user.uid
        });
        
        // 근로자 출근 데이터 업데이트 (근로자가 지정된 경우에만)
        if (newSchedule.employeeId) {
          await updateEmployeeAttendance(
            newSchedule.employeeId, 
            selectedDate, 
            newSchedule.time, 
            newSchedule.endTime
          );
        }
        
        // 고객 데이터 업데이트 또는 신규 등록
        const customerDocRef = doc(firestore, 'customers', user.uid);
        const customerDoc = await getDoc(customerDocRef);
        
        if (customerDoc.exists()) {
          const customerList = customerDoc.data().customerList || [];
          // 기존 고객인지 확인
          const existingCustomerIndex = customerList.findIndex(
            customer => customer.name === newSchedule.name && customer.contact === newSchedule.contact
          );
          
          if (existingCustomerIndex >= 0) {
            // 기존 고객이면 정보 업데이트
            const updatedCustomerList = customerList.map((customer, index) => {
              if (index === existingCustomerIndex) {
                return {
                  ...customer,
                  visitCount: (customer.visitCount || 0) + 1,
                  spending: (customer.spending || 0) + (parseInt(newSchedule.amount) || 0),
                  lastVisit: selectedDate.toISOString().split('T')[0]
                };
              }
              return customer;
            });
            
            await updateDoc(customerDocRef, { customerList: updatedCustomerList });
          } else {
            // 신규 고객이면 추가
            const newCustomer = {
              id: Date.now(),
              name: newSchedule.name,
              contact: newSchedule.contact,
              birthdate: '',
              category: '신규',
              lastVisit: selectedDate.toISOString().split('T')[0],
              memo: newSchedule.note || '',
              spending: parseInt(newSchedule.amount) || 0,
              visitCount: 1,
              preferences: '',
              spouse: {
                name: '',
                contact: '',
                birthdate: ''
              },
              children: []
            };
            
            await updateDoc(customerDocRef, { 
              customerList: [...customerList, newCustomer] 
            });
          }
        } else {
          // 고객 문서가 없으면 생성
          const newCustomer = {
            id: Date.now(),
            name: newSchedule.name,
            contact: newSchedule.contact,
            birthdate: '',
            category: '신규',
            lastVisit: selectedDate.toISOString().split('T')[0],
            memo: newSchedule.note || '',
            spending: parseInt(newSchedule.amount) || 0,
            visitCount: 1,
            preferences: '',
            spouse: {
              name: '',
              contact: '',
              birthdate: ''
            },
            children: []
          };
          
          await setDoc(customerDocRef, { 
            customerList: [newCustomer] 
          });
        }
        
        console.log('Schedule added successfully');
        setNewSchedule({ 
          time: '', 
          endTime: '', 
          name: '', 
          contact: '', 
          note: '', 
          amount: 0,
          employeeId: '',
          employeeName: ''
        });
        setShowCustomerList(false);
        
        // 스케줄과 고객 데이터 다시 가져오기
        await fetchSchedules();
        await fetchCustomerData();
        
      } catch (error) {
        console.error('스케줄을 추가하는 중 오류가 발생했습니다:', error);
      }
    } else {
      console.error('User is not authenticated');
    }
  };

  // 일정 추가 함수에 근로자 출근 데이터 업데이트 코드 추가
  const updateEmployeeAttendance = async (employeeId, date, startTime, endTime) => {
    if (!employeeId || !startTime) return;
    
    try {
      const user = auth.currentUser;
      if (!user) return;
      
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const monthKey = `${year}${month.toString().padStart(2, '0')}`;
      const dateKey = day.toString().padStart(2, '0');
      
      // 담당 고객명을 근무지로 설정
      let workplace = newSchedule.name || '기본 근무지';
      let workplaceId = Date.now().toString(); // 새 근무지 ID 생성
      let workplaceExists = false;
      
      // 종사자 문서에서 근무지 정보 확인 및 추가
      const employeeDocRef = doc(firestore, 'employees', user.uid);
      const employeeDoc = await getDoc(employeeDocRef);
      
      if (employeeDoc.exists()) {
        const employeeData = employeeDoc.data();
        const employeeList = employeeData.employeeList || [];
        const employeeIndex = employeeList.findIndex(emp => emp.id === employeeId);
        
        if (employeeIndex !== -1) {
          const employee = employeeList[employeeIndex];
          const workplaces = employee.workplaces || [];
          
          // 동일한 고객명의 근무지가 이미 있는지 확인
          const existingWorkplaceIndex = workplaces.findIndex(wp => wp.name === workplace);
          
          if (existingWorkplaceIndex !== -1) {
            // 이미 존재하는 근무지면 해당 근무지 ID 사용
            workplaceId = workplaces[existingWorkplaceIndex].id;
            workplaceExists = true;
          } else {
            // 새 근무지 정보 생성
            const newWorkplace = {
              id: workplaceId,
              name: workplace,
              address: '',
              isDefault: false,
              memo: '예약 일정에서 자동 생성된 근무지'
            };
            
            // 근무지 목록 복사 후 새 근무지 추가
            const updatedWorkplaces = [...workplaces, newWorkplace];
            
            // 종사자 정보 업데이트
            const updatedEmployeeList = [...employeeList];
            updatedEmployeeList[employeeIndex] = {
              ...employee,
              workplaces: updatedWorkplaces
            };
            
            // 종사자 문서 업데이트
            await updateDoc(employeeDocRef, {
              employeeList: updatedEmployeeList
            });
            
            console.log(`종사자 ${employee.name}에게 새 근무지 "${workplace}"가 추가되었습니다.`);
          }
        }
      }
      
      // 출결 데이터 업데이트
      const attendanceDocRef = doc(firestore, 'attendance', user.uid);
      const attendanceDoc = await getDoc(attendanceDocRef);
      
      if (attendanceDoc.exists()) {
        // 해당 날짜의 기존 출결 데이터 가져오기
        const monthData = attendanceDoc.data()[monthKey] || {};
        const employeeData = monthData[employeeId] || {};
        const dateData = employeeData[dateKey] || [];
        
        // 배열이 아닌 경우 배열로 변환 (기존 데이터 호환성)
        const recordsArray = Array.isArray(dateData) ? dateData : 
          (dateData && typeof dateData === 'object' ? [dateData] : []);
        
        // 예약 정보 출근 기록으로 추가/업데이트
        const newRecord = {
          recordId: Date.now().toString(),
          status: '출근',
          checkIn: startTime,
          checkOut: endTime || '',
          workplace: workplace,
          workplaceId: workplaceId,
          note: `${newSchedule.name || '고객'} 예약`,
          timestamp: Date.now()
        };
        
        // 기존 기록에 새 기록 추가
        const updatedRecords = [...recordsArray, newRecord];
        
        // Firestore 업데이트
        await updateDoc(attendanceDocRef, {
          [`${monthKey}.${employeeId}.${dateKey}`]: updatedRecords
        });
        
        console.log('근로자 출근 데이터가 업데이트되었습니다.');
      } else {
        // 문서가 없으면 새로 생성
        const newRecord = {
          recordId: Date.now().toString(),
          status: '출근',
          checkIn: startTime,
          checkOut: endTime || '',
          workplace: workplace,
          workplaceId: workplaceId,
          note: `${newSchedule.name || '고객'} 예약`,
          timestamp: Date.now()
        };
        
        await setDoc(attendanceDocRef, {
          [monthKey]: {
            [employeeId]: {
              [dateKey]: [newRecord]
            }
          }
        });
        
        console.log('근로자 출근 데이터가 생성되었습니다.');
      }
    } catch (error) {
      console.error('근로자 출근 데이터 업데이트 실패:', error);
    }
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  // 예약 관리 탭 렌더링
  const renderReservationTab = () => {
    return (
      <div className={styles.scheduleLayout}>
        <ScheduleCalendar
          selectedDate={selectedDate}
          onDateChange={handleDateChange}
          schedules={schedules}
        />
        <div className={styles.scheduleDetailsPanel}>
          <ScheduleList
            schedules={schedules}
            selectedDate={selectedDate}
            onEditSchedule={handleEditSchedule}
            onDeleteSchedule={handleDeleteSchedule}
          />
          <ScheduleForm
            schedule={newSchedule}
            isEditing={isEditing}
            onInputChange={handleInputChange}
            onAddSchedule={handleAddSchedule}
            onUpdateSchedule={handleUpdateSchedule}
            onCancelEdit={handleCancelEdit}
            showCustomerList={showCustomerList}
            setShowCustomerList={setShowCustomerList}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            customerData={customerData}
            onSelectCustomer={handleSelectCustomer}
            showEmployeeList={showEmployeeList}
            setShowEmployeeList={setShowEmployeeList}
            employeeSearchTerm={employeeSearchTerm}
            setEmployeeSearchTerm={setEmployeeSearchTerm}
            employees={employees}
            onSelectEmployee={handleSelectEmployee}
            onClearEmployee={handleClearEmployee}
          />
        </div>
      </div>
    );
  };

  // 시간제 직원 스케줄 탭 렌더링
  const renderPartTimeTab = () => {
    return (
      <div>
        <h3>시간제 직원 스케줄</h3>
        <p>시간제 직원 스케줄 관리 내용입니다. 추후 업데이트 예정입니다.</p>
      </div>
    );
  };

  // 상근 직원 스케줄 탭 렌더링
  const renderFullTimeTab = () => {
    return (
      <div>
        <h3>상근 직원 스케줄</h3>
        <p>상근 직원 스케줄 관리 내용입니다. 추후 업데이트 예정입니다.</p>
      </div>
    );
  };

  const renderContent = () => {
    // 로딩 중일 때 로딩 컴포넌트 표시
    if (isLoading) {
      return <div className={styles.loadingContainer}>데이터 불러오는 중...</div>;
    }
    
    // 탭 내용 렌더링
    let content;
    switch (activeTab) {
      case '예약 관리':
        content = renderReservationTab();
        break;
      case '시간제 직원 스케줄':
        content = renderPartTimeTab();
        break;
      case '상근 직원 스케줄':
        content = renderFullTimeTab();
        break;
      default:
        content = null;
    }
    
    // 탭 내용을 공통 컨테이너로 감쌈
    return (
      <div className={styles.scheduleContent}>
        {content}
      </div>
    );
  };

  return (
    <div className={styles.scheduleModalContent}>
      <TabMenu 
        tabs={tabs} 
        activeTab={activeTab} 
        onTabChange={handleTabChange}
      >
        {renderContent()}
      </TabMenu>
    </div>
  );
};

export default ScheduleModalContent;
