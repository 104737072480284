import api from '../api';

export const getBizAnalysis = async (payload) => {
  try {
    const response = await api.post('/bizAnalysis', payload);
    return response;
  } catch (error) {
    console.error('사업 분석 실패:', error);
    throw error;
  }
};