import api from '../api';

export const postCalculatePayroll = async (payload = {}) => {
  try {
    const response = await api.post('/calculatePayroll', { params: payload });
    return response;
  } catch (error) {
    console.error('급여 정보 가져오기 실패:', error);
    throw error;
  }
}; 