import React, { useState, useEffect } from 'react';
import BaseContract from './BaseContract';
import './Contracts.css';

const EmploymentContract = ({ party, existingData, onSave }) => {
  const [formData, setFormData] = useState({
    startDate: '',
    endDate: '',
    workPlace: '',
    workHours: '',
    workDays: '',
    salary: '',
    paymentDate: '',
    benefits: '',
    otherConditions: '',
  });

  useEffect(() => {
    if (existingData) {
      setFormData(existingData);
    }
  }, [existingData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSave = () => {
    onSave(formData);
  };

  return (
    <BaseContract 
      title="근로계약서" 
      onSave={handleSave}
      isValid={formData.startDate && formData.workPlace && formData.salary}
    >
      <div className="contract-header">
        <p>사업주: [회사명] (이하 "사업주"라 함)</p>
        <p>근로자: {party.name} (이하 "근로자"라 함)</p>
      </div>

      <div className="contract-section">
        <h3>제1조 (계약기간)</h3>
        <div className="form-group">
          <label>계약 시작일</label>
          <input
            type="date"
            name="startDate"
            value={formData.startDate}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label>계약 종료일</label>
          <input
            type="date"
            name="endDate"
            value={formData.endDate}
            onChange={handleChange}
          />
        </div>
      </div>

      <div className="contract-section">
        <h3>제2조 (근무장소)</h3>
        <div className="form-group">
          <input
            type="text"
            name="workPlace"
            value={formData.workPlace}
            onChange={handleChange}
            placeholder="근무지 주소"
            required
          />
        </div>
      </div>

      <div className="contract-section">
        <h3>제3조 (근무시간 및 휴게시간)</h3>
        <div className="form-group">
          <input
            type="text"
            name="workHours"
            value={formData.workHours}
            onChange={handleChange}
            placeholder="근무시간 (예: 09:00-18:00)"
            required
          />
        </div>
        <div className="form-group">
          <input
            type="text"
            name="workDays"
            value={formData.workDays}
            onChange={handleChange}
            placeholder="근무일 (예: 월요일-금요일)"
            required
          />
        </div>
      </div>

      <div className="contract-section">
        <h3>제4조 (임금)</h3>
        <div className="form-group">
          <label>월 급여</label>
          <input
            type="number"
            name="salary"
            value={formData.salary}
            onChange={handleChange}
            placeholder="월 급여액"
            required
          />
        </div>
        <div className="form-group">
          <label>급여 지급일</label>
          <input
            type="text"
            name="paymentDate"
            value={formData.paymentDate}
            onChange={handleChange}
            placeholder="매월 ○일"
            required
          />
        </div>
      </div>

      <div className="contract-section">
        <h3>제5조 (복리후생)</h3>
        <div className="form-group">
          <textarea
            name="benefits"
            value={formData.benefits}
            onChange={handleChange}
            placeholder="복리후생 내용"
          />
        </div>
      </div>

      <div className="contract-section">
        <h3>제6조 (기타)</h3>
        <div className="form-group">
          <textarea
            name="otherConditions"
            value={formData.otherConditions}
            onChange={handleChange}
            placeholder="기타 계약조건"
          />
        </div>
      </div>
    </BaseContract>
  );
};

export default EmploymentContract; 