import api from '../api';

// 관리자용 출결 처리 API
export const postAttendance = async (payload) => {
  try {
    const response = await api.post('/attendance', { params: payload });
    return response;
  } catch (error) {
    console.error('출퇴근 정보 전송 실패:', error);
    throw error;
  }
};

// 직원용 출결 처리 API (백엔드와 일치하는 파라미터 순서)
export const postEmployeeAttendance = async (adminId, employeeId, attendanceType = '출근', workplace = '기본 근무지') => {
  try {
    const response = await api.post('/employee-attendance', { 
      params: {
        adminId, 
        employeeId,
        attendanceType,
        workplace
      }
    });
    return response;
  } catch (error) {
    console.error('직원 출퇴근 처리 실패:', error);
    throw error;
  }
};