import api from '../api';

/**
 * AI 에이전트에 메시지를 전송하고 응답을 받는 함수
 * 
 * 흐름:
 * 1. 사용자로부터 payload(메시지, 컨텍스트, 대화이력 등) 수신
 * 2. 컨텍스트가 있는 경우 최적화 수행
 * 3. API 요청 전송
 * 4. 응답 반환 또는 에러 처리
 */
export const postAiAgent = async (payload) => {
  try {
    // 컨텍스트가 너무 큰 경우 요약
    if (payload.context) {
      // 필요한 컨텍스트만 추출/요약
      payload.context = optimizeContext(payload.context);
    }
    
    const response = await api.post('/aiAgent', { params: payload });
    return response;
  } catch (error) {
    console.error('에이전트 정보 전송 실패:', error);
    throw error;
  }
};

/**
 * 컨텍스트 최적화 함수
 * 
 * 흐름:
 * 1. 컨텍스트에서 필요한 정보 추출
 * 2. 각 정보를 압축된 형태로 변환
 * 3. 최적화된 컨텍스트 반환
 */
const optimizeContext = (context) => {
  const { activeModal, activeTab, visibleData, formState } = context;
  
  // 필수 정보만 포함하여 압축
  return {
    modal: activeModal,
    tab: activeTab,
    data: summarizeVisibleData(visibleData),
    form: summarizeFormState(formState),
    timestamp: context.timestamp
  };
};

/**
 * 화면에 표시된 데이터 요약 함수
 * 
 * 흐름:
 * 1. 빈 요약 객체 생성
 * 2. 각 데이터 타입별로 처리:
 *    - 이미 요약된 데이터는 그대로 사용
 *    - 배열은 항목 수만 표시
 *    - 객체는 키 목록만 표시
 *    - 기본값은 그대로 전달
 * 3. 요약된 데이터 반환
 */
const summarizeVisibleData = (data) => {
  // 각 데이터 타입별로 간결한 요약만 반환
  const summary = {};
  
  Object.keys(data).forEach(key => {
    // 이미 요약된 형태면 그대로 사용
    if (data[key].summary) {
      summary[key] = data[key].summary;
    } 
    // 배열이면 개수만 표시
    else if (Array.isArray(data[key])) {
      summary[key] = `${data[key].length}개 항목`;
    } 
    // 객체면 키 목록만 표시
    else if (typeof data[key] === 'object') {
      summary[key] = `${Object.keys(data[key]).join(', ')}`;
    }
    // 기본값은 그대로 전달
    else {
      summary[key] = data[key];
    }
  });
  
  return summary;
};

/**
 * 폼 상태 요약 함수
 * 
 * 흐름:
 * 1. 빈 요약 객체 생성
 * 2. 각 폼별로:
 *    - 입력된 필드 수 계산
 *    - 폼 완성 여부 확인
 * 3. 요약된 폼 상태 반환
 */
const summarizeFormState = (formState) => {
  const summary = {};
  
  Object.keys(formState).forEach(formId => {
    // 폼 완성도, 입력된 필드 수 등만 포함
    const form = formState[formId];
    summary[formId] = {
      filledFields: Object.keys(form).length,
      isComplete: form.isComplete || false
    };
  });
  
  return summary;
};