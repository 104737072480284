import React, { useState } from 'react';
import Login from './Login';
import Signup from './Signup';
import style from './AuthTabs.module.css';
import { ReactComponent as Logo } from '../../assets/images/CareSalary_logo.svg';

const AuthTabs = () => {
  const [activeTab, setActiveTab] = useState('login');

  return (
    <div className={style.authTabsContainer}>
      <div className={style.retroTitle}>
        <div className={style.pixelText}>소상공인 업무관리 프로그램</div>
        <div className={style.pixelTextLarge}>
          <Logo width="40px" height="40px" style={{marginRight: '10px', verticalAlign: 'middle'}} />
          <span style={{verticalAlign: 'middle'}}>케어샐러리</span>
        </div>
      </div>
      <div className={style.tabs}>
        <button
          className={`${style.tabButton} ${activeTab === 'login' ? style.active : ''}`}
          onClick={() => setActiveTab('login')}
        >
          로그인
        </button>
        <button
          className={`${style.tabButton} ${activeTab === 'signup' ? style.active : ''}`}
          onClick={() => setActiveTab('signup')}
        >
          회원가입
        </button>
      </div>
      <div className={style.tabContent}>
        {activeTab === 'login' ? <Login /> : <Signup />}
      </div>
    </div>
  );
};

export default AuthTabs;