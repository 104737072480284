import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../../api/firebase/firebaseConfig';
import { postEmployeeAttendance } from '../../../api/post/postattendance';
import styles from './AttendanceCheck.module.css';

const AttendanceCheck = () => {
  const { token, employeeId, workplaceName } = useParams();
  const [status, setStatus] = useState('loading');
  const [message, setMessage] = useState('');
  const [errorDetails, setErrorDetails] = useState(null);
  const [employeeName, setEmployeeName] = useState('');
  const [selectedWorkplace, setSelectedWorkplace] = useState(null);
  const [attendanceType, setAttendanceType] = useState('출근');
  const navigate = useNavigate();

  // 토큰에서 관리자 ID 추출
  const getAdminIdFromToken = (token) => {
    try {
      const decodedToken = atob(token); // Base64 디코딩
      const [adminId, timestamp] = decodedToken.split(':');
      
      // 토큰 유효성 검사 (예: 1시간 이내에 생성된 토큰만 유효)
      const currentTime = new Date().getTime();
      const tokenTime = parseInt(timestamp, 10);
      const ONE_HOUR = 60 * 60 * 1000;
      
      if (currentTime - tokenTime > ONE_HOUR) {
        return null; // 만료된 토큰
      }
      
      return adminId;
    } catch (error) {
      console.error('토큰 디코딩 오류:', error);
      return null;
    }
  };

  useEffect(() => {
    const validateToken = () => {
      const adminId = getAdminIdFromToken(token);
      
      if (!adminId) {
        setStatus('error');
        setMessage('유효하지 않거나 만료된 QR 코드입니다.');
        setErrorDetails('새로운 QR 코드를 스캔해주세요.');
        return false;
      }
      
      return adminId;
    };
    
    const adminId = validateToken();
    if (!adminId) return;
    
    // URL에서 직접 정보 사용
    if (employeeId && workplaceName) {
      const decodedWorkplaceName = decodeURIComponent(workplaceName);
      setEmployeeName('직원'); // 기본값 설정
      setSelectedWorkplace({
        name: decodedWorkplaceName
      });
      setStatus('confirm');
    } else if (employeeId) {
      // 근무지 이름이 없는 경우
      setEmployeeName('직원'); // 기본값 설정
      setStatus('error');
      setMessage('근무지 정보가 없습니다.');
      setErrorDetails('QR 코드를 다시 스캔해주세요.');
    } else {
      setStatus('error');
      setMessage('직원 정보가 없습니다.');
      setErrorDetails('QR 코드를 다시 스캔해주세요.');
    }
  }, [token, employeeId, workplaceName]);

  const handleAttendanceCheck = async () => {
    if (!selectedWorkplace) {
      setStatus('error');
      setMessage('근무지를 선택해주세요.');
      return;
    }
    
    setStatus('processing');
    setErrorDetails(null);
    
    // 토큰에서 관리자 ID 추출
    const adminId = getAdminIdFromToken(token);
    
    if (!adminId) {
      setStatus('error');
      setMessage('유효하지 않거나 만료된 QR 코드입니다.');
      setErrorDetails('새로운 QR 코드를 스캔해주세요.');
      return;
    }
    
    try {
      // URL에서 가져온 정보로 출결 처리
      // 출근 타입 파라미터 추가 (기본값: '출근')
      const response = await postEmployeeAttendance(adminId, employeeId, attendanceType, selectedWorkplace.name);
      
      if (response && response.message) {
        setStatus('success');
        setMessage(response.message || '출결 처리가 완료되었습니다.');
      } else {
        setStatus('error');
        setMessage('출결 처리 중 오류가 발생했습니다.');
        setErrorDetails('응답 데이터가 올바르지 않습니다.');
      }
    } catch (error) {
      console.error('출석 처리 오류:', error);
      setStatus('error');
      setMessage('출석 처리 중 오류가 발생했습니다.');
      
      if (error.response) {
        setErrorDetails({
          status: error.response.status,
          data: error.response.data
        });
      } else if (error.request) {
        setErrorDetails({
          message: '서버에서 응답이 없습니다.'
        });
      } else {
        setErrorDetails({
          message: error.message
        });
      }
    }
  };

  const renderContent = () => {
    switch (status) {
      case 'loading':
        return <div className={styles.loading}>정보를 불러오는 중...</div>;
        
      case 'error':
        return (
          <div className={styles.errorContainer}>
            <div className={styles.errorIcon}>❌</div>
            <h3>오류 발생</h3>
            <p>{message}</p>
            
            {errorDetails && (
              <div className={styles.errorDetails}>
                <h4>오류 상세 정보:</h4>
                <pre>{typeof errorDetails === 'object' ? JSON.stringify(errorDetails, null, 2) : errorDetails}</pre>
              </div>
            )}
            
            <div className={styles.errorActions}>
              <button className={styles.retryButton} onClick={() => window.location.reload()}>
                다시 시도
              </button>
              <button className={styles.backButton} onClick={() => navigate('/')}>
                홈으로 돌아가기
              </button>
            </div>
          </div>
        );
      
      case 'confirm':
        return (
          <div className={styles.confirmContainer}>
            <h3>출결 확인</h3>
            <p><strong>{employeeName}</strong> 님의 <strong>{selectedWorkplace?.name}</strong> 근무지 출결을 처리하시겠습니까?</p>
            
            <div className={styles.attendanceTypeSelector}>
              <label>출결 유형:</label>
              <select 
                value={attendanceType} 
                onChange={(e) => setAttendanceType(e.target.value)}
              >
                <option value="출근">출근</option>
                <option value="퇴근">퇴근</option>
                <option value="연차">연차</option>
                <option value="반차">반차</option>
              </select>
            </div>
            
            <div className={styles.confirmButtons}>
              <button
                className={styles.confirmButton}
                onClick={handleAttendanceCheck}
              >
                확인
              </button>
              <button
                className={styles.cancelButton}
                onClick={() => navigate('/')}
              >
                취소
              </button>
            </div>
          </div>
        );
      
      case 'processing':
        return <div className={styles.loading}>출결 처리 중...</div>;
      
      case 'success':
        return (
          <div className={styles.successContainer}>
            <div className={styles.successIcon}>✅</div>
            <h3>출결 처리 완료</h3>
            <p>{message}</p>
            <p className={styles.workplaceInfo}>근무지: {selectedWorkplace?.name}</p>
            <button className={styles.backButton} onClick={() => navigate('/')}>
              홈으로 돌아가기
            </button>
          </div>
        );
      
      default:
        return <div className={styles.loading}>처리 중...</div>;
    }
  };

  return (
    <div className={styles.attendanceCheckContainer}>
      <h2>출결 체크</h2>
      <div className={styles.attendanceCheckContent}>
        {renderContent()}
      </div>
    </div>
  );
};

export default AttendanceCheck; 