// context/AgentContextProvider.js
import React, { createContext, useContext, useState, useEffect } from 'react';

// 컨텍스트 생성
const AgentContext = createContext();

export const AgentContextProvider = ({ children }) => {
  // 컨텍스트 데이터 상태
  const [contextData, setContextData] = useState({
    activeModal: null,
    activeTab: null,
    visibleData: {},
    formState: {},
    timestamp: null
  });
  
  // 모달 활성화 감지 및 컨텍스트 데이터 업데이트
  const updateModalContext = (modalId, modalName) => {
    setContextData(prev => ({
      ...prev,
      activeModal: modalId ? { id: modalId, name: modalName } : null,
      timestamp: new Date().toISOString()
    }));
  };
  
  // 탭 컨텍스트 업데이트
  const updateTabContext = (tabId, tabName) => {
    setContextData(prev => ({
      ...prev,
      activeTab: { id: tabId, name: tabName },
      timestamp: new Date().toISOString()
    }));
  };
  
  // 데이터 컨텍스트 업데이트
  const updateDataContext = (dataType, data) => {
    setContextData(prev => ({
      ...prev,
      visibleData: {
        ...prev.visibleData,
        [dataType]: data
      },
      timestamp: new Date().toISOString()
    }));
  };
  
  // 폼 상태 업데이트
  const updateFormContext = (formId, formData) => {
    setContextData(prev => ({
      ...prev,
      formState: {
        ...prev.formState,
        [formId]: formData
      },
      timestamp: new Date().toISOString()
    }));
  };
  
  // 전체 컨텍스트 데이터 가져오기
  const getContextData = () => {
    return {
      ...contextData,
      contextVersion: '1.0'
    };
  };
  
  // 컨텍스트 리셋
  const resetContext = () => {
    setContextData({
      activeModal: null,
      activeTab: null,
      visibleData: {},
      formState: {},
      timestamp: new Date().toISOString()
    });
  };
  
  return (
    <AgentContext.Provider value={{
      contextData,
      updateModalContext,
      updateTabContext,
      updateDataContext,
      updateFormContext,
      getContextData,
      resetContext
    }}>
      {children}
    </AgentContext.Provider>
  );
};

// 훅 사용을 위한 래퍼
export const useAgentContext = () => useContext(AgentContext);