import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Terms.module.css';

const Terms = () => {
  // 이용약관 내용을 단일 문자열 변수로 관리
  const termsContent = `
  페어엠소프트 이용약관

  제1조 (목적)
  본 이용약관은 페어엠소프트(이하 '회사')가 제공하는 업무관리 ERP 서비스 및 AI 에이전트 기반 서비스(이하 '서비스')를 이용함에 있어 회사와 이용자(이하 '이용자') 간의 권리와 의무 및 책임사항을 명확히 규정함을 목적으로 합니다.

  제2조(서비스 이용료)
  ① 서비스 이용료는 월 11,000원(부가세 포함)이며, 매월 말일까지 이용량을 집계하여 익월 10일까지 이용자에게 청구합니다.
  ② 서비스 이용료의 납부기한은 청구월의 익월 10일까지이며, 납부기한이 공휴일인 경우 익영업일까지로 연장됩니다.
  ③ 최초 서비스 신청 시 1회에 한하여 가입비 11,000원이 부과되며, 이는 반환되지 않습니다.

  제3조(이용계약 체결)
  ① 이용자는 본 약관에 동의한 후 가입신청을 하고, 회사가 이를 승낙하여 서비스 이용 계약이 체결됩니다.
  ② 이용자는 본 약관에 동의하지 않을 경우 서비스를 이용할 수 없습니다.

  제4조(데이터의 보존 및 관리)
  ① 회사는 이용자의 서비스 이용으로 발생한 데이터를 정기적으로 백업하여 보관하며, 이용자가 요청할 경우 이를 제공합니다.
  ② 이용자가 서비스를 해지하는 경우, 회사는 관련 데이터를 회사의 개인정보 처리방침에 따라 처리 및 파기합니다.

  제5조(서비스 이용 범위)
  ① 서비스는 가입한 아이디당 당 1개의 업체에서만 사용 가능합니다.
  ② 다른 업체에서 사용을 원하는 경우 별도의 가입 절차를 진행하여야 합니다.

  제6조(서비스 이용료)
  ① 서비스 이용료는 월 11,000원(부가세 포함)으로 하며, 매월 말 기준으로 서비스 이용량을 집계하여 청구할 수 있습니다.
  ② 서비스 이용료의 미납 시 회사는 서비스 제공을 중지할 수 있습니다.

  제7조(서비스 변경 및 해지)
  ① 이용자는 언제든지 서비스 해지를 요청할 수 있으며, 회사는 이용자의 요청에 따라 신속히 처리합니다.
  ② 회사는 이용자가 본 약관을 위반하거나 이용료를 미납한 경우 사전 통지 후 서비스 이용을 제한하거나 계약을 해지할 수 있습니다.
  ③ 6개월 이상 서비스 이용 기록이 없는 경우 회사는 사전 통지 후 이용자를 휴면 계정으로 처리하거나 계약을 해지할 수 있습니다.

  제7조(서비스의 저작권 및 지적재산권)
  서비스 및 관련 문서의 저작권 및 소유권, 지적재산권은 회사에 있으며, 회사의 명시적 동의 없이는 복제, 수정, 리버스 엔지니어링, 양도 등을 금지합니다.

  제8조(책임의 제한)
  ① 회사는 이용자의 고의 또는 과실로 인한 서비스 장애나 데이터 손실 등에 대해서는 책임을 지지 않습니다.
  ② 이용자의 과실이란 다음의 경우를 포함합니다.
    1. 개인정보나 비밀번호 관리 소홀로 인한 데이터 유출
    2. 서비스 이용 안내 및 약관을 위반한 경우
    3. 고의적이거나 반복적인 부정 이용 행위

  제9조(기타 정보 관리 및 제3자 제공)
  회사는 이용자의 개인정보와 관련한 사항은 회사의 개인정보 처리방침을 따르며, 법령에 의한 경우나 이용자의 사전 동의가 있는 경우 외에는 이용자의 정보를 제3자에게 제공하지 않습니다.

  제9조(서비스 분석 및 AI 활용)
  회사는 서비스의 품질 향상 및 이용자 맞춤형 서비스 제공을 위하여 서비스 이용 행태 데이터를 수집하고 분석하여 AI 에이전트를 강화하는 데 활용합니다.

  제10조(약관의 변경)
  회사는 본 약관의 내용을 변경할 경우 시행일 최소 7일 전 이용자에게 공지하며, 이용자의 권리에 중요한 영향을 미치는 사항은 최소 30일 전에 공지합니다.

  제10조(분쟁 해결)
  본 약관에 관한 분쟁이 발생할 경우 상호 협의하여 해결하되, 해결되지 않을 경우 관계법령에 따라 처리합니다.

  부칙
  본 이용약관은 2022년 12월 3일부터 시행합니다.
  `;

  return (
    <div className={styles.termsContainer}>
      <div className={styles.termsContent}>
        <pre className={styles.termsText}>{termsContent}</pre>
        
        <div className={styles.footerLinks}>
          <Link to="/" className={styles.backLink}>홈으로 돌아가기</Link>
        </div>
      </div>
    </div>
  );
};

export default Terms; 