import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from '../modals/Modal';
import { auth } from '../../api/firebase/firebaseConfig';
import { signOut } from 'firebase/auth';
import styles from './RightMenu.module.css';
import { ReactComponent as Logo } from '../../assets/images/CareSalary_logo.svg';

const RightMenu = ({ menuType }) => {
  const [activeModal, setActiveModal] = useState(null);
  const navigate = useNavigate();
  
  const menuItems = {
    bountyQuest: [
      { id: 'bountyQuest', label: '바운티 퀘스트' },
    ],
    operation: [
      { id: 'evaluation', label: '영업 관리' },
      { id: 'schedule', label: '일정 관리' },
      { id: 'management', label: '업장 관리' },
      { id: 'salary', label: '급여 관리' },
      { id: 'documents', label: '서식 관리' },
      // { id: 'account', label: '회계 관리' },
    ],
    tools: [
      { id: 'tools', label: '업무도구' },
    ],
    news: [
      { id: 'news', label: '오늘의 소식' },
    ],
    market: [
      // { id: 'bizPhone', label: '사업자 알뜰폰' },
      { id: 'bizPromotion', label: '비즈프로모션' },
      { id: 'bizAnalysis', label: '사업 분석' },
      { id: 'bizInsurance', label: '손해(화재)보험' },
    ],
    attendance: [
      { id: 'qrGenerate', label: '출결 QR 생성' },
      { id: 'qrStatus', label: '출결 현황' },
    ],
    diary: [
      { id: 'diary', label: '다이어리' },
    ],
  };

  // 기본값 설정 (menuType이 없거나 유효하지 않은 경우 'operation' 사용)
  const currentMenuType = menuItems[menuType] ? menuType : 'operation';

  const openModal = (id) => setActiveModal(id);
  const closeModal = () => setActiveModal(null);

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        // 로그아웃 성공 시 처리
        navigate('/auth'); // 인증 페이지로 리다이렉트
      })
      .catch((error) => {
        // 로그아웃 실패 시 에러 처리
        console.error('로그아웃 실패:', error);
      });
  };

  return (
    <div className={styles.rightsideMenuContainer}>
      <div className={styles.logoContainer}>
        <Logo width="30px" height="30px" className={styles.logo} />
        <h3 className={styles.logoText}>케어샐러리</h3>
      </div>
      <div className={styles.rightsideMenu}>
        {menuItems[currentMenuType] && menuItems[currentMenuType].map(item => (
          <button key={item.id} className={styles.rightsideMenuItem} onClick={() => openModal(item.id)}>
            {item.label}
          </button>
        ))}
      </div>
      <button className={styles.rightsideLogoutButton} onClick={handleLogout}>로그아웃</button>
      {activeModal && <Modal id={activeModal} onClose={closeModal} />}
    </div>
  );
};

export default RightMenu;