import React, { useState, useEffect } from 'react';
import { postCalculatePayroll } from '../../../../api/post/postCalculatePayroll';
import styles from './SalaryModalContent.module.css';
import PayrollResult from './components/PayrollResult';
import SalarySettings from './components/SalarySettings';
import { doc, getDoc, setDoc, collection, getDocs } from 'firebase/firestore';
import { db } from '../../../../api/firebase/firebaseConfig';
import { getAuth } from 'firebase/auth';
import TabMenu from '../../../modals/TabMenu';

// 급여 계산 페이지
const SalaryModalContent = () => {
  const [payrollResult, setPayrollResult] = useState(null);
  const [isLoading, setIsLoading] = useState(true); // 초기 로딩 상태를 true로 설정
  const [yearMonth, setYearMonth] = useState(() => {
    const now = new Date();
    return `${now.getFullYear()}${String(now.getMonth() + 1).padStart(2, '0')}`;
  });
  const [activeTab, setActiveTab] = useState('급여 계산'); // '급여 계산' 또는 '급여 설정'
  const [config, setConfig] = useState({});
  const auth = getAuth();

  const tabs = ['급여 계산', '급여 설정'];

  // 컴포넌트 마운트 시 설정 불러오기 및 기존 급여 데이터 확인
  useEffect(() => {
    const initializeData = async () => {
      try {
        setIsLoading(true);
        
        // 설정 불러오기
        await fetchConfig();
        
        // 기존 급여 데이터 확인
        if (auth.currentUser) {
          await loadExistingPayrollData();
        }
      } catch (error) {
        console.error('초기화 중 오류 발생:', error);
      } finally {
        setIsLoading(false);
      }
    };
    
    initializeData();
  }, []);

  // 기존 급여 데이터 로드 함수
  const loadExistingPayrollData = async () => {
    try {
      if (!auth.currentUser) {
        console.error('로그인이 필요합니다.');
        return false;
      }

      // Firestore에서 해당 월의 급여 데이터 조회
      const payrollsRef = collection(db, 'payrolls', auth.currentUser.uid, yearMonth);
      const payrollsSnapshot = await getDocs(payrollsRef);
      
      if (!payrollsSnapshot.empty) {
        const payrollData = payrollsSnapshot.docs.map(doc => doc.data());
        console.log(`기존 급여 데이터 ${payrollData.length}건 로드 완료:`, payrollData);
        
        // 데이터 포맷팅 및 결과 설정
        const formattedResult = formatPayrollData(payrollData);
        setPayrollResult(formattedResult);
        return true; // 데이터가 있음을 반환
      } else {
        console.log('기존 급여 데이터가 없습니다.');
        return false; // 데이터가 없음을 반환
      }
    } catch (error) {
      console.error('기존 급여 데이터 로드 실패:', error);
      return false;
    }
  };

  // 급여 데이터 포맷팅 함수 리팩토링
  const formatPayrollData = (payrollData) => {
    return payrollData.map(employeeData => {
      // 기본 빈 객체 또는 기본값 정의
      const defaultEmployeeSettings = {
        hourlyWage: 0,
        isComprehensiveWage: false,
        comprehensiveWage: 0,
        weeklyHolidayAllowance: 0,
        annualLeaveAllowance: 0,
        healthInsuranceApplied: false,
        nationalPensionApplied: false,
        employmentInsuranceApplied: false,
        industrialAccidentInsuranceApplied: false,
        incomeTaxDeduction: false
      };

      // 데이터 확장 및 null 값 처리
      return {
        ...employeeData,
        showDetails: false,
        name: employeeData.name || '이름 없음',
        identifierWorker: employeeData.identifierWorker || '',
        totalWorkTime: employeeData.totalWorkTime || 0,
        totalWorkDays: employeeData.totalWorkDays || 0,
        totalLeaveValue: employeeData.totalLeaveValue || 0,
        weeklyDetails: employeeData.weeklyDetails || {},
        inclusivePay: employeeData.inclusivePay || 0,
        basicPay: employeeData.basicPay || 0,
        weeklyHolidayPay: employeeData.weeklyHolidayPay || 0,
        annualLeavePay: employeeData.annualLeavePay || 0,
        nightAllowance: employeeData.nightAllowance || 0,
        holidayAllowance: employeeData.holidayAllowance || 0,
        overtimeAllowance: employeeData.overtimeAllowance || 0,
        adjustmentPay: employeeData.adjustmentPay || 0,
        legalPayTotal: employeeData.legalPayTotal || 0,
        finalPay: employeeData.finalPay || 0,
        taxableAllowanceTotal: employeeData.taxableAllowanceTotal || 0,
        nonTaxableAllowanceTotal: employeeData.nonTaxableAllowanceTotal || 0,
        // 커스텀 페이 항목
        ...Array.from({ length: 8 }).reduce((acc, _, i) => {
          acc[`customPay${i + 1}`] = employeeData[`customPay${i + 1}`] || 0;
          return acc;
        }, {}),
        // 수당 항목
        mealAllowance: employeeData.mealAllowance || 0,
        carAllowance: employeeData.carAllowance || 0,
        rewardPay: employeeData.rewardPay || 0,
        bonusPay: employeeData.bonusPay || 0,
        etcAllowance: employeeData.etcAllowance || 0,
        nonTaxableOpt: employeeData.nonTaxableOpt || 0,
        // 공제 항목
        pensionInsurance: employeeData.pensionInsurance || 0,
        healthInsurance: employeeData.healthInsurance || 0,
        longTermInsurance: employeeData.longTermInsurance || 0,
        employmentInsurance: employeeData.employmentInsurance || 0,
        industrialInsurance: employeeData.industrialInsurance || 0,
        incomeTax: employeeData.incomeTax || 0,
        localTax: employeeData.localTax || 0,
        totalDeductions: employeeData.totalDeductions || 0,
        netPay: employeeData.netPay || 0,
        employeeSettings: employeeData.employeeSettings || defaultEmployeeSettings
      };
    });
  };

  // 연월 변경 시 기존 데이터 확인
  useEffect(() => {
    if (auth.currentUser && yearMonth) {
      setIsLoading(true);
      loadExistingPayrollData()
        .then(hasData => {
          // 데이터가 없는 경우 payrollResult를 null로 설정
          if (!hasData) {
            setPayrollResult(null);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [yearMonth]);

  // Firestore에서 설정 불러오기
  const fetchConfig = async () => {
    try {
      if (!auth.currentUser) {
        console.error('로그인이 필요합니다.');
        return;
      }
      
      const configDocRef = doc(db, 'config', auth.currentUser.uid);
      const configDoc = await getDoc(configDocRef);
      
      if (configDoc.exists()) {
        const savedConfig = configDoc.data().payrollConfig;
        if (savedConfig) {
          // 저장된 설정이 있으면 불러오기
          setConfig({
            ...savedConfig,
            yearMonth: yearMonth, // 현재 선택된 년월 유지
            // 누락될 수 있는 필드 기본값 설정
            baseWorker: savedConfig.baseWorker || 0,
            baseWorkerOpt: savedConfig.baseWorkerOpt || 0,
            extendedOpt: savedConfig.extendedOpt || 4,
            hourlyPay: savedConfig.hourlyPay || 10000,
            hourlyPayTotal: savedConfig.hourlyPayTotal || 15000,
            includeAnnualLeavePay: savedConfig.includeAnnualLeavePay !== undefined ? savedConfig.includeAnnualLeavePay : true,
            includeBasicPay: savedConfig.includeBasicPay !== undefined ? savedConfig.includeBasicPay : true,
            includeHolidayAllowance: savedConfig.includeHolidayAllowance !== undefined ? savedConfig.includeHolidayAllowance : true,
            includeNightAllowance: savedConfig.includeNightAllowance !== undefined ? savedConfig.includeNightAllowance : true,
            includeOvertimeAllowance: savedConfig.includeOvertimeAllowance !== undefined ? savedConfig.includeOvertimeAllowance : true,
            includeWeeklyHolidayPay: savedConfig.includeWeeklyHolidayPay !== undefined ? savedConfig.includeWeeklyHolidayPay : true,
            nightEnd: savedConfig.nightEnd || "06:00",
            nightStart: savedConfig.nightStart || "22:00",
            nonTaxableOpt: savedConfig.nonTaxableOpt || 0,
            numAnnualLeaveDays: savedConfig.numAnnualLeaveDays || 0,
            standardDailyHours: savedConfig.standardDailyHours || 8,
            standardWeeklyDays: savedConfig.standardWeeklyDays || 5,
            standardWeeklyHours: savedConfig.standardWeeklyHours || 40,
            weekEnds: savedConfig.weekEnds || 7
          });
        } else {
          // 설정이 없으면 기본값으로 초기화
          initializeDefaultConfig();
        }
      } else {
        // config 문서가 없으면 기본값으로 초기화
        initializeDefaultConfig();
      }
    } catch (error) {
      console.error('설정 불러오기 실패:', error);
      // 오류 발생 시 기본값으로 초기화
      initializeDefaultConfig();
    }
  };

  // 기본 설정값 생성 함수
  const createDefaultConfig = (yearMonth) => ({
    yearMonth,
    baseWorker: 0,
    baseWorkerOpt: 0,
    extendedOpt: 4,
    hourlyPay: 10000,
    hourlyPayTotal: 15000,
    includeAnnualLeavePay: true,
    includeBasicPay: true,
    includeHolidayAllowance: true,
    includeNightAllowance: true,
    includeOvertimeAllowance: true,
    includeWeeklyHolidayPay: true,
    nightEnd: "06:00",
    nightStart: "22:00",
    nonTaxableOpt: 0,
    numAnnualLeaveDays: 0,
    standardDailyHours: 8,
    standardWeeklyDays: 5,
    standardWeeklyHours: 40,
    weekEnds: 7
  });

  // 기본 설정값 초기화 함수
  const initializeDefaultConfig = () => {
    setConfig(createDefaultConfig(yearMonth));
  };

  // Firestore에 설정 저장
  const saveConfig = async () => {
    try {
      if (!auth.currentUser) {
        console.error('로그인이 필요합니다.');
        return;
      }
      
      // 완전한 설정 객체 생성
      const completeConfig = {
        ...createDefaultConfig(),
        ...config,
        yearMonth: config.yearMonth || yearMonth
      };
      
      const configDocRef = doc(db, 'config', auth.currentUser.uid);
      
      // 현재 문서 확인
      const configDoc = await getDoc(configDocRef);
      
      // 문서 업데이트 또는 생성
      await setDoc(configDocRef, {
        ...configDoc.exists() ? configDoc.data() : {},
        payrollConfig: completeConfig
      });
      
      // 설정 상태 업데이트
      setConfig(completeConfig);
      alert('설정이 저장되었습니다.');
    } catch (error) {
      console.error('설정 저장 실패:', error);
      alert('설정 저장에 실패했습니다.');
    }
  };

  // 년월 변경 핸들러
  const handleYearMonthChange = (yearMonthValue) => {
    setYearMonth(yearMonthValue);
    setConfig(prev => ({
      ...prev,
      yearMonth: yearMonthValue
    }));
  };

  // 설정 변경 핸들러
  const handleConfigChange = (e) => {
    const { name, value, type, checked } = e.target;
    
    // 입력 타입에 따라 적절한 값 설정
    const newValue = type === 'checkbox' ? checked : 
                    type === 'number' ? Number(value) : value;
    
    setConfig(prev => ({
      ...prev,
      [name]: newValue
    }));
  };

  // 급여 계산 API 호출 함수 리팩토링
  const handleCalculate = async () => {
    try {
      setIsLoading(true);
      
      // 백엔드 API 호출
      const response = await postCalculatePayroll({ 
        config: {
          ...config,
          yearMonth: yearMonth
        },
        uid: auth.currentUser?.uid
      });
      
      // 응답 데이터 처리
      const formattedResult = Array.isArray(response) ? response.map(item => {
        const employeeData = Array.isArray(item) ? item[0] : item;
        
        if (!employeeData) return { showDetails: false };
        
        return formatPayrollData([employeeData])[0]; // formatPayrollData 함수 재사용
      }) : [];
      
      console.log('계산 결과', formattedResult);
      setPayrollResult(formattedResult);
    } catch (error) {
      console.error('Error fetching payrolls:', error);
      alert('급여 계산 중 오류가 발생했습니다.');
    } finally {
      setIsLoading(false);
    }
  };

  // 초기화 및 재계산 함수
  const handleReset = () => {
    // 사용자에게 확인 요청
    const confirmed = window.confirm('정말 초기화하고 다시 계산하시겠습니까?');
    
    if (confirmed) {
      // 결과 초기화
      setPayrollResult(null);
      
      // 완료 상태 초기화 코드 추가
      const resetPayrollData = async () => {
        try {
          if (auth.currentUser) {
            // Firestore에서 해당 월의 문서 참조 가져오기
            const payrollsRef = collection(db, 'payrolls', auth.currentUser.uid, yearMonth);
            const payrollsSnapshot = await getDocs(payrollsRef);
            
            // 각 문서에 대해 isCompleted 상태를 false로 업데이트
            const promises = payrollsSnapshot.docs.map(doc => {
              return setDoc(doc.ref, { isCompleted: false }, { merge: true });
            });
            
            await Promise.all(promises);
            console.log('모든 직원 급여 완료 상태가 초기화되었습니다.');
          }
        } catch (error) {
          console.error('완료 상태 초기화 중 오류 발생:', error);
        }
      };
      
      // 완료 상태 초기화 실행
      resetPayrollData();
      
      // 재계산 (약간의 지연 후 실행)
      setTimeout(() => {
        handleCalculate();
      }, 100);
    }
  };

  // 연도 목록 생성
  const getYears = () => {
    const currentYear = new Date().getFullYear();
    return [currentYear + 1, currentYear, currentYear - 1, currentYear - 2];
  };

  // 현재 선택된 연도 가져오기
  const getSelectedYear = () => {
    return yearMonth ? parseInt(yearMonth.substring(0, 4)) : new Date().getFullYear();
  };

  // 현재 선택된 월 가져오기
  const getSelectedMonth = () => {
    return yearMonth ? parseInt(yearMonth.substring(4, 6)) : new Date().getMonth() + 1;
  };

  // 연월 선택 UI 렌더링 리팩토링
  const renderYearMonthSelector = () => {
    const selectedYear = getSelectedYear();
    const selectedMonth = getSelectedMonth();
    
    const handleYearClick = (year) => {
      const newYearMonth = `${year}${String(selectedMonth).padStart(2, '0')}`;
      setYearMonth(newYearMonth);
      setConfig(prev => ({
        ...prev,
        yearMonth: newYearMonth
      }));
    };
    
    const handleMonthClick = (month) => {
      const newYearMonth = `${selectedYear}${String(month).padStart(2, '0')}`;
      setYearMonth(newYearMonth);
      setConfig(prev => ({
        ...prev,
        yearMonth: newYearMonth
      }));
    };
    
    return (
      <div className={styles.yearMonthSelector}>
        {/* 연도 선택 탭 */}
        <div className={styles.yearTimeline}>
          {getYears().map(year => (
            <div 
              key={year} 
              className={`${styles.yearTab} ${selectedYear === year ? styles.selected : ''}`}
              onClick={() => handleYearClick(year)}
            >
              {year}
            </div>
          ))}
        </div>
        
        {/* 월 선택 그리드 */}
        <div className={styles.monthGrid}>
          {Array.from({ length: 12 }).map((_, i) => ({ 
            num: i + 1, 
            name: `${i + 1}월` 
          })).map(month => (
            <div 
              key={month.num} 
              className={`${styles.monthTile} ${selectedMonth === month.num ? styles.selected : ''}`}
              onClick={() => handleMonthClick(month.num)}
            >
              <span className={styles.monthNumber}>{month.num}</span>
              <span className={styles.monthLabel}>월</span>
            </div>
          ))}
        </div>
      </div>
    );
  };

  // 설정 화면 렌더링
  const renderSettingsTab = () => {
    return <SalarySettings config={config} handleConfigChange={handleConfigChange} saveConfig={saveConfig} />;
  };

  // 탭 변경 핸들러
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const renderCalculateTab = () => {
    if (!payrollResult) {
      // 계산 전 화면 - 커스텀 년월 선택기 사용
      return (
        <div className={styles.calculationScreen}>
          <h2>급여 계산</h2>
          {renderYearMonthSelector()}
          <p>계산하기 버튼을 클릭하여 급여를 계산하세요.</p>
          <button 
            className={styles.calculateButton} 
            onClick={handleCalculate}
            disabled={isLoading}
          >
            {isLoading ? '계산 중...' : '계산하기'}
          </button>
        </div>
      );
    } else {
      // 계산 후 화면 - PayrollResult 컴포넌트와 초기화 버튼 표시
      return (
        <div className={styles.resultContainer}>
          <div className={styles.resultHeader}>
            <div className={styles.resultActions}>
              <button
                className={styles.resetButton}
                onClick={handleReset}
                disabled={isLoading}
              >
                {isLoading ? '처리 중...' : '초기화'}
              </button>
            </div>
          </div>
          
          {/* 계산 결과 내 연월 선택기 추가 */}
          <div className={styles.resultYearMonthSelector}>
            {renderYearMonthSelector()}
          </div>
          
          <PayrollResult 
            payrollResult={payrollResult} 
            isLoading={isLoading}
            yearMonth={yearMonth}
          />
        </div>
      );
    }
  };

  const renderContent = () => {
    // 로딩 중일 때 로딩 컴포넌트 표시
    if (isLoading) {
      return <div className={styles.loadingContainer}>데이터 불러오는 중...</div>;
    }
    
    // 탭 내용 렌더링
    let content;
    switch (activeTab) {
      case '급여 설정':
        content = renderSettingsTab();
        break;
      case '급여 계산':
      default:
        content = renderCalculateTab();
    }
    
    // 탭 내용을 공통 컨테이너로 감쌈
    return (
      <div className={styles.salaryContent}>
        {content}
      </div>
    );
  };

  return (
    <div className={styles.salaryModalContent}>
      <TabMenu
        tabs={tabs}
        activeTab={activeTab}
        onTabChange={handleTabChange}
      >
        {renderContent()}
      </TabMenu>
    </div>
  );
};

export default SalaryModalContent;
