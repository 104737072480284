import React, { useState, useEffect } from 'react';
import { getBizPromotion } from '../../../../api/get/getBizPromotion';
import styles from './BizPromotion.module.css';

// 소상공인 지원 프로모션
const BizPromotion = () => {
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isInitialState, setIsInitialState] = useState(true);
  const [filters, setFilters] = useState({
    provider: '',
    maxPrice: ''
  });

  // 정부 지원 사이트 데이터
  const govSupportSites = [
    {
      id: 1,
      name: '소상공인 지원 포털',
      url: 'https://www.sbiz.or.kr',
      description: '소상공인 지원 정책 및 제도 안내'
    },
    {
      id: 2,
      name: '중소기업 지원 사업',
      url: 'https://www.smba.go.kr',
      description: '중소기업 지원 사업 및 정책 정보'
    },
    {
      id: 3,
      name: '창업넷',
      url: 'https://www.k-startup.go.kr',
      description: '창업 지원 및 정보 제공'
    },
    {
      id: 4,
      name: '고용지원센터',
      url: 'https://www.work.go.kr',
      description: '고용 지원 및 취업 정보'
    }
  ];

  // 데이터 가져오기
  const bizPhoneData = async () => {
    try {
      setLoading(true);
      setIsInitialState(false);
      const response = await getBizPromotion(filters);
      setPlans(response.data.data);
      setError(null);
    } catch (err) {
      setError('데이터를 불러오는 중 오류가 발생했습니다.');
      console.error('데이터 로딩 오류:', err);
    } finally {
      setLoading(false);
    }
  };

  // 컴포넌트 마운트 시 데이터 로드
  useEffect(() => {
    bizPhoneData();
  }, []);

  // 필터 변경 핸들러
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters(prev => ({
      ...prev,
      [name]: value
    }));
  };

  // 필터 적용 핸들러 (찾기 버튼)
  const applyFilters = () => {
    bizPhoneData();
  };

  return (
    <div className={styles.bizPromotionContent}>
      <h1 className={styles.bizPromotionTitle}>소상공인 지원 프로모션</h1>
      
      {/* 정부 지원 사이트 섹션 */}
      <section className={styles.govSupportSection}>
        <h2 className={styles.sectionTitle}>소상공인 지원 사이트</h2>
        <div className={styles.govSupportLinks}>
          {govSupportSites.map(site => (
            <a 
              key={site.id} 
              href={site.url} 
              target="_blank" 
              rel="noopener noreferrer" 
              className={styles.govSupportLink}
            >
              <div className={styles.govSupportCard}>
                <h3>{site.name}</h3>
                <p>{site.description}</p>
                <span className={styles.visitButton}>바로가기</span>
              </div>
            </a>
          ))}
        </div>
      </section>
      
      {/* 유망 상품 섹션 */}
      <section className={styles.featuredProductsSection}>
        <h2 className={styles.sectionTitle}>유망 지원 상품</h2>
        
        {/* 필터 섹션 */}
        <div className={styles.filterSection}>
          <div className={styles.filterItem}>
            <label>통신사</label>
            <select 
              name="provider" 
              value={filters.provider} 
              onChange={handleFilterChange}
            >
              <option value="">전체</option>
              <option value="SK알뜰폰">SK알뜰폰</option>
              <option value="KT알뜰폰">KT알뜰폰</option>
              <option value="LG알뜰폰">LG알뜰폰</option>
            </select>
          </div>
          
          <div className={styles.filterItem}>
            <label>최대 가격</label>
            <input 
              type="number" 
              name="maxPrice" 
              value={filters.maxPrice} 
              onChange={handleFilterChange}
              placeholder="최대 가격"
            />
          </div>
          
          <button className={styles.filterButton} onClick={applyFilters}>
            분석하기
          </button>
        </div>
        
        {/* 로딩 및 에러 처리 */}
        {loading && <div className={styles.loading}>로딩 중...</div>}
        {error && <div className={styles.errorMessage}>{error}</div>}
        
        {/* 초기 상태 메시지 */}
        {isInitialState && !loading && (
          <div className={styles.initialMessage}>
            원하시는 조건을 선택하고 찾기 버튼을 눌러주세요.
          </div>
        )}
        
        {/* 상품 목록 */}
        {!loading && !error && !isInitialState && (
          <div className={styles.productsContainer}>
            {plans.length === 0 ? (
              <div className={styles.noProducts}>조건에 맞는 상품이 없습니다.</div>
            ) : (
              plans.map(plan => (
                <div key={plan.id} className={styles.productCard}>
                  <div className={styles.productHeader}>
                    <h3>{plan.planName}</h3>
                    <span className={styles.provider}>{plan.provider}</span>
                  </div>
                  <div className={styles.productDetails}>
                    <div className={styles.detailItem}>
                      <span className={styles.label}>데이터</span>
                      <span className={styles.value}>{plan.data}</span>
                    </div>
                    <div className={styles.detailItem}>
                      <span className={styles.label}>통화</span>
                      <span className={styles.value}>{plan.call}</span>
                    </div>
                    <div className={styles.detailItem}>
                      <span className={styles.label}>문자</span>
                      <span className={styles.value}>{plan.sms}</span>
                    </div>
                  </div>
                  <div className={styles.productBenefits}>
                    <h4>혜택</h4>
                    <ul>
                      {plan.benefits.map((benefit, index) => (
                        <li key={index}>{benefit}</li>
                      ))}
                    </ul>
                  </div>
                  <div className={styles.productPrice}>
                    <span className={styles.priceValue}>{plan.price.toLocaleString()}</span>
                    <span className={styles.priceUnit}>원/월</span>
                  </div>
                  <button className={styles.applyButton}>신청하기</button>
                </div>
              ))
            )}
          </div>
        )}
      </section>
    </div>
  );
};

export default BizPromotion;
