import React, { useState } from 'react';
import styles from './AgentSuggestions.module.css';
import { postAiStrategy } from '../../../../../api/post/postAistrategy';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../../../../../api/firebase/firebaseConfig';
import { getAuth } from 'firebase/auth';
import { collection, query, where, getDocs } from 'firebase/firestore';
import Loading from '../../../../modals/Loading';
// import { getAuth } from 'firebase/auth';
// const auth = getAuth();

const AgentSuggestions = ({ suggestions, updateSuggestions }) => {
  const [selectedSuggestion, setSelectedSuggestion] = useState(null);
  const [statusFilter, setStatusFilter] = useState('전체');
  const [categoryFilter, setCategoryFilter] = useState('전체');
  const [isLoading, setIsLoading] = useState(false);
  const [periodFilter, setPeriodFilter] = useState('month');
  const auth = getAuth();
  
  // 필터된 제안 목록
  const filteredSuggestions = suggestions.filter(suggestion => {
    const statusMatch = statusFilter === '전체' || suggestion.status === statusFilter;
    const categoryMatch = categoryFilter === '전체' || suggestion.category === categoryFilter;
    return statusMatch && categoryMatch;
  });
  
  // 상태 업데이트 처리
  const handleStatusChange = (id, newStatus) => {
    const updatedSuggestions = suggestions.map(suggestion => 
      suggestion.id === id ? { ...suggestion, status: newStatus } : suggestion
    );
    updateSuggestions(updatedSuggestions);
  };
  
  // 결과 업데이트 처리
  const handleResultChange = (id, result) => {
    const updatedSuggestions = suggestions.map(suggestion => 
      suggestion.id === id ? { ...suggestion, result } : suggestion
    );
    updateSuggestions(updatedSuggestions);
  };
  
  // 카테고리 목록 추출
  const categories = ['전체', ...new Set(suggestions.map(s => s.category))];
  
  // 전략 생성 함수
  const generateStrategies = async () => {
    // 사용자 확인 요청
    const userConfirmed = window.confirm("새 전략을 생성하시겠습니까?");
    if (!userConfirmed) {
      return; // 사용자가 취소했을 경우 함수 종료
    }
    
    setIsLoading(true);
    try {
      if (!auth.currentUser) {
        console.error('로그인이 필요합니다.');
        alert('로그인이 필요합니다.');
        setIsLoading(false);
        return;
      }
      
      // 1. 사용자의 다이어리 데이터 가져오기
      const diaryData = await fetchDiaryData(periodFilter);
      
      // 2. 스케줄 및 고객 데이터 가져오기
      const customerData = await fetchCustomerData();
      const scheduleData = await fetchScheduleData();
      
      // 3. AI 전략 요청하기 - userId 파라미터 추가
      const payload = {
        diaryData,
        period: periodFilter,
        customerData,
        scheduleData,
        userId: auth.currentUser.uid // 사용자 ID 추가
      };
      
      // AI 응답 수신
      const response = await postAiStrategy(payload);
      
      // AI 응답 형식 검사 및 변환
      let strategies = [];
      
      if (response.success && response.data && response.data.strategies) {
        // 응답 형식이 { success: true, data: { strategies: [...] } } 인 경우
        strategies = response.data.strategies;
      } else if (response.strategies) {
        // 응답 형식이 { strategies: [...] } 인 경우
        strategies = response.strategies;
      } else {
        alert('AI 응답 형식이 올바르지 않습니다.');
        setIsLoading(false);
        return;
      }
      
      if (strategies && strategies.length > 0) {
        // 4. 새 전략 생성 - AI 응답 형식에 맞게 수정
        const newSuggestions = strategies.map(strategy => ({
          id: `str-${Date.now()}-${Math.floor(Math.random() * 1000)}`,
          date: new Date().toISOString().split('T')[0],
          category: strategy.category || '기타',
          suggestion: `${strategy.title}: ${strategy.description}`,
          targetGroup: strategy.targetCustomerGroup,
          expectedEffect: strategy.expectedEffect,
          recommendedActions: strategy.recommendedActions || [],
          priority: strategy.priority,
          status: '검토 중',
          result: ''
        }));
        
        try {
          // 5. Firestore에 직접 저장
          const suggestionsDocRef = doc(db, 'agentSuggestions', auth.currentUser.uid);
          
          // 저장할 데이터 구조
          const dataToSave = { 
            suggestionList: newSuggestions,
            lastUpdated: new Date().toISOString()
          };
          
          // 데이터 저장
          await setDoc(suggestionsDocRef, dataToSave);
          
          // UI 업데이트
          updateSuggestions(newSuggestions);
          setSelectedSuggestion(null);
          
          alert('새로운 전략이 성공적으로 생성되었습니다.');
        } catch (saveError) {
          alert('전략 저장 중 오류가 발생했습니다: ' + saveError.message);
        }
      } else {
        alert('전략 생성에 실패했습니다. 데이터가 부족합니다.');
      }
    } catch (error) {
      alert('전략 생성 중 오류가 발생했습니다: ' + (error.message || '알 수 없는 오류'));
    } finally {
      setIsLoading(false);
    }
  };
  
  // 다이어리 데이터 가져오기
  const fetchDiaryData = async (period) => {
    if (!auth.currentUser) return {};
    
    try {
      const endDate = new Date();
      let startDate = new Date();
      
      // 기간 설정
      if (period === 'month') {
        startDate.setMonth(startDate.getMonth() - 1);
      } else if (period === 'quarter') {
        startDate.setMonth(startDate.getMonth() - 3);
      } else if (period === 'halfYear') {
        startDate.setMonth(startDate.getMonth() - 6);
      }
      
      const diaryDocRef = doc(db, 'diary', auth.currentUser.uid);
      const diaryDoc = await getDoc(diaryDocRef);
      
      if (!diaryDoc.exists()) return {};
      
      const diaryData = diaryDoc.data();
      const filteredData = {};
      
      // 월별 데이터 필터링
      Object.keys(diaryData).forEach(monthKey => {
        const year = parseInt(monthKey.substring(0, 4));
        const month = parseInt(monthKey.substring(4, 6)) - 1;
        
        const monthDate = new Date(year, month, 1);
        if (monthDate >= startDate && monthDate <= endDate) {
          filteredData[monthKey] = diaryData[monthKey];
        }
      });
      
      return filteredData;
    } catch (error) {
      console.error('다이어리 데이터 가져오기 실패:', error);
      return {};
    }
  };
  
  // 고객 데이터 가져오기
  const fetchCustomerData = async () => {
    if (!auth.currentUser) return [];
    
    try {
      const customerDocRef = doc(db, 'customers', auth.currentUser.uid);
      const customerDoc = await getDoc(customerDocRef);
      
      if (!customerDoc.exists()) return [];
      
      return customerDoc.data().customerList || [];
    } catch (error) {
      console.error('고객 데이터 가져오기 실패:', error);
      return [];
    }
  };
  
  // 스케줄 데이터 가져오기
  const fetchScheduleData = async () => {
    if (!auth.currentUser) return [];
    
    try {
      // 컬렉션 쿼리로 변경
      const schedulesCollection = collection(db, 'schedules');
      const q = query(schedulesCollection, where('userId', '==', auth.currentUser.uid));
      const querySnapshot = await getDocs(q);
      
      const schedules = [];
      querySnapshot.forEach((doc) => {
        schedules.push({ id: doc.id, ...doc.data() });
      });
      
      return schedules;
    } catch (error) {
      console.error('스케줄 데이터 가져오기 실패:', error);
      return [];
    }
  };
  
  return (
    <div className={styles.agentSuggestionsContainer}>
      {isLoading && (
        <div className={styles.overlayLoading}>
          <Loading message="전략 생성 중..." />
        </div>
      )}
      
      <div className={styles.suggestionsHeader}>
        <div className={styles.filterControls}>
          <div className={styles.filterGroup}>
            <label>상태 필터:</label>
            <select 
              value={statusFilter}
              onChange={(e) => setStatusFilter(e.target.value)}
            >
              <option value="전체">전체</option>
              <option value="채택">채택</option>
              <option value="미채택">미채택</option>
              <option value="검토 중">검토 중</option>
            </select>
          </div>
          
          <div className={styles.filterGroup}>
            <label>카테고리 필터:</label>
            <select 
              value={categoryFilter}
              onChange={(e) => setCategoryFilter(e.target.value)}
            >
              {categories.map(category => (
                <option key={category} value={category}>{category}</option>
              ))}
            </select>
          </div>
          
          <div className={styles.filterGroup}>
            <label>분석 기간:</label>
            <select 
              value={periodFilter}
              onChange={(e) => setPeriodFilter(e.target.value)}
            >
              <option value="month">1개월</option>
              <option value="quarter">3개월</option>
              <option value="halfYear">6개월</option>
            </select>
          </div>
          
          <button 
            className={styles.btnGenerate}
            onClick={generateStrategies}
            disabled={isLoading}
          >
            {isLoading ? '생성 중...' : '전략 생성'}
          </button>
        </div>
      </div>
      
      <div className={styles.suggestionsContent}>
        <div className={styles.suggestionsList}>
          <table className={styles.suggestionsTable}>
            <thead>
              <tr>
                <th>날짜</th>
                <th>카테고리</th>
                <th>제안 내용</th>
                <th>상태</th>
                <th>결과</th>
                <th>관리</th>
              </tr>
            </thead>
            <tbody>
              {filteredSuggestions.length > 0 ? (
                filteredSuggestions.map(suggestion => (
                  <tr 
                    key={suggestion.id} 
                    className={selectedSuggestion === suggestion.id ? styles.selectedRow : ''}
                  >
                    <td>{suggestion.date}</td>
                    <td>
                      <span className={styles.categoryBadge}>
                        {suggestion.category}
                      </span>
                    </td>
                    <td className={styles.suggestionContent}>{suggestion.suggestion}</td>
                    <td>
                      <span className={`${styles.statusBadge} ${
                        suggestion.status === '채택' ? styles.adopted :
                        suggestion.status === '미채택' ? styles.rejected :
                        styles.pending
                      }`}>
                        {suggestion.status}
                      </span>
                    </td>
                    <td>{suggestion.result}</td>
                    <td>
                      <button 
                        className={styles.btnView}
                        onClick={() => setSelectedSuggestion(
                          selectedSuggestion === suggestion.id ? null : suggestion.id
                        )}
                      >
                        {selectedSuggestion === suggestion.id ? '닫기' : '상세'}
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6" className={styles.noData}>제안 내역이 없습니다.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        
        {selectedSuggestion !== null && (
          <div className={styles.suggestionDetailPanel}>
            {(() => {
              const suggestion = suggestions.find(s => s.id === selectedSuggestion);
              return (
                <>
                  <h3>제안 상세 관리</h3>
                  <div className={styles.suggestionDetail}>
                    <div className={styles.detailGroup}>
                      <span className={styles.detailLabel}>날짜:</span>
                      <span className={styles.detailValue}>{suggestion.date}</span>
                    </div>
                    <div className={styles.detailGroup}>
                      <span className={styles.detailLabel}>카테고리:</span>
                      <span className={`${styles.detailValue} ${styles.categoryBadge}`}>
                        {suggestion.category}
                      </span>
                    </div>
                    <div className={styles.detailGroup}>
                      <span className={styles.detailLabel}>우선순위:</span>
                      <span className={styles.detailValue}>{suggestion.priority || '-'}</span>
                    </div>
                    <div className={`${styles.detailGroup} ${styles.fullWidth}`}>
                      <span className={styles.detailLabel}>제안 내용:</span>
                      <p className={styles.detailValue}>{suggestion.suggestion}</p>
                    </div>
                    {suggestion.targetGroup && (
                      <div className={`${styles.detailGroup} ${styles.fullWidth}`}>
                        <span className={styles.detailLabel}>대상 고객:</span>
                        <p className={styles.detailValue}>{suggestion.targetGroup}</p>
                      </div>
                    )}
                    {suggestion.expectedEffect && (
                      <div className={`${styles.detailGroup} ${styles.fullWidth}`}>
                        <span className={styles.detailLabel}>기대 효과:</span>
                        <p className={styles.detailValue}>{suggestion.expectedEffect}</p>
                      </div>
                    )}
                    {suggestion.recommendedActions?.length > 0 && (
                      <div className={`${styles.detailGroup} ${styles.fullWidth}`}>
                        <span className={styles.detailLabel}>권장 조치:</span>
                        <ul className={styles.detailValue}>
                          {suggestion.recommendedActions.map((action, index) => (
                            <li key={index}>{action}</li>
                          ))}
                        </ul>
                      </div>
                    )}
                    <div className={`${styles.detailGroup} ${styles.statusControl}`}>
                      <span className={styles.detailLabel}>상태:</span>
                      <div className={styles.radioControls}>
                        <label>
                          <input 
                            type="radio" 
                            name="status" 
                            value="채택" 
                            checked={suggestion.status === "채택"}
                            onChange={() => handleStatusChange(suggestion.id, "채택")}
                          />
                          채택
                        </label>
                        <label>
                          <input 
                            type="radio" 
                            name="status" 
                            value="미채택" 
                            checked={suggestion.status === "미채택"}
                            onChange={() => handleStatusChange(suggestion.id, "미채택")}
                          />
                          미채택
                        </label>
                        <label>
                          <input 
                            type="radio" 
                            name="status" 
                            value="검토 중" 
                            checked={suggestion.status === "검토 중"}
                            onChange={() => handleStatusChange(suggestion.id, "검토 중")}
                          />
                          검토 중
                        </label>
                      </div>
                    </div>
                    <div className={`${styles.detailGroup} ${styles.fullWidth}`}>
                      <span className={styles.detailLabel}>결과:</span>
                      <input 
                        type="text"
                        value={suggestion.result}
                        onChange={(e) => handleResultChange(suggestion.id, e.target.value)}
                        placeholder="제안 적용 결과를 입력하세요..."
                        disabled={suggestion.status !== "채택"}
                      />
                    </div>
                  </div>
                </>
              );
            })()}
          </div>
        )}
      </div>
    </div>
  );
};

export default AgentSuggestions; 