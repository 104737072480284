import React, { useState } from 'react';
import style from './MainMenu.module.css';
import { ReactComponent as Logo } from '../../assets/images/CareSalary_logo.svg';

// MainMenu 컴포넌트 정의
const MainMenu = ({ setMenuType, setShowAgent }) => {
  const handleMenuClick = (type) => {
    setMenuType(type); // 메뉴 타입 변경
  };

  // 1. 메뉴 아이템 배열 정의 - 상단 버튼은 제거하고 menuItems에 통합
  const menuItems = [
    '로고', '', '',
    '바운티 퀘스트', '오늘의 소식', '사업장터',
    '출결관리', '운영관리', '업무도구',
    '다이어리', '커뮤니티', '에이전트'
  ];

  // 2. 메뉴 아이템들을 순회하며 MenuItem 컴포넌트를 생성하고 렌더링
  return (
    <div className={style.mainMenu}>
      {menuItems.map((item, index) =>
        item === '로고' ? ( // 로고 생성성
          <div key={index} className={`${style.menuItem} ${style.logo}`}>
            <Logo width="100%" height="100%" />
          </div>
        ) : item === '' ? ( // 투명한 메뉴 항목 생성
          <div key={index} className={`${style.menuItem} ${style.transparent}`}></div>
        ) : (               // 메뉴 아이템 생성
          <MenuItem
            key={index}
            item={item}
            setMenuType={setMenuType}
            setShowAgent={setShowAgent}
          />
        )
      )}
    </div>
  );
};

// MenuItem 컴포넌트 정의
const MenuItem = ({ item, setMenuType, setShowAgent }) => {
  // 3. 마우스 위치 상태 (원형 물결의 시작 위치를 % 단위로 저장)
  const [mousePosition, setMousePosition] = useState({ x: '50%', y: '50%' });

  // 4. 아이템에 따른 스타일 클래스를 한 번만 설정 (고정된 파스텔 색상 매핑)
  const [menuItemStyle] = useState(() => getMenuItemStyle(item));

  // 5. 마우스가 요소에 들어왔을 때 호출 (hover 시 원형 물결 시작 위치 계산)
  const handleMouseEnter = (e) => {
    const rect = e.currentTarget.getBoundingClientRect();
    const x = ((e.clientX - rect.left) / rect.width) * 100;
    const y = ((e.clientY - rect.top) / rect.height) * 100;
    setMousePosition({ x: `${x}%`, y: `${y}%` });
  };

  // 6. 마우스가 요소에서 나갔을 때 호출 (원형 물결의 시작 위치를 중앙으로 리셋)
  const handleMouseLeave = () => {
    setMousePosition({ x: '50%', y: '50%' });
  };

  // 7. 호버 효과를 적용할 아이템 (추후 필요시 리스트 추가)
  const noHoverEffectItems = [];

  const hasHoverEffect = !noHoverEffectItems.includes(item);

  // 8. MenuItem 컴포넌트 렌더링
  return (
    <div
      className={`${style.menuItem} ${style[menuItemStyle]}`}
      onMouseEnter={hasHoverEffect ? handleMouseEnter : null}
      onMouseLeave={hasHoverEffect ? handleMouseLeave : null}
      onClick={() => {
        if (item === '커뮤니티') {
          window.open('https://exquibalancer.com/', '_blank');
        } else if (item === '바운티 퀘스트') {
          setMenuType('bountyQuest');
        } else if (item === '오늘의 소식') {
          setMenuType('news');
        } else if (item === '사업장터') {
          setMenuType('market');          // 사업장터 클릭 시 market 메뉴로 변경
        } else if (item === '운영관리') {
          setMenuType('operation');       // 'default'에서 'operation'으로 변경
        } else if (item === '업무도구') {
          setMenuType('tools');           // 업무도구 클릭 시 tools 메뉴로 변경
        } else if (item === '에이전트') {
          setShowAgent((prev) => !prev);  // 에이전트 메뉴
        } else if (item === '출결관리') {
          setMenuType('attendance');      // 출결관리 클릭 시 attendance 메뉴로 변경
        } else if (item === '다이어리') {
          setMenuType('diary');           // 다이어리 클릭 시 diary 메뉴로 변경
        }
      }}
    >
      {item}
      <div
        className={`${style.wave} ${hasHoverEffect ? style.hoverEffect : ''}`}
        style={{
          top: mousePosition.y,
          left: mousePosition.x,
        }}
      ></div>
    </div>
  );
};

// 아이템 이름에 따라 고정된 파스텔 색상 클래스를 반환
const getMenuItemStyle = (item) => {
  const mapping = {
    '바운티 퀘스트': 'menuItemBountyQuest',
    '오늘의 소식': 'menuItemNews',
    '출결관리': 'menuItemAttendance',
    '운영관리': 'menuItemManagement',
    '업무도구': 'menuItemTools',
    '사업장터': 'menuItemMyNews',
    '다이어리': 'menuItemDiary',
    '커뮤니티': 'menuItemCommunity',
    '에이전트': 'menuItemAgent',
  };
  return mapping[item] || '';
};

export default MainMenu;