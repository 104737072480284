import React, { useState } from 'react';
import styles from './News.module.css';

const News = () => {
  const [activeCategory, setActiveCategory] = useState('all');

  const newsCategories = {
    politics: { name: '종합/정치', icon: '⚖️' },
    economy: { name: '경제', icon: '💰' },
    tech: { name: 'IT/기술', icon: '💻' },
    international: { name: '국제/외교', icon: '🌏' },
    english: { name: '영자신문', icon: '🔤' },
    sports: { name: '스포츠', icon: '⚽' },
    culture: { name: '문화/예술', icon: '🎨' },
    finance: { name: '금융/증권', icon: '📈' },
    environment: { name: '환경', icon: '🌿' },
    local: { name: '지역', icon: '🏘️' },
    globalFinance: { name: '금융 외신', icon: '🏦' },
    globalEconomy: { name: '국제 경제', icon: '🌐' },
  };

  const newsLinks = {
    politics: [
      { name: '조선일보', url: 'https://www.chosun.com', icon: '🇰🇷' },
      { name: '한겨레', url: 'https://www.hani.co.kr', icon: '🇰🇷' },
      { name: '문화일보', url: 'https://www.munhwa.com', icon: '🇰🇷' },
      { name: '경향신문', url: 'https://www.khan.co.kr', icon: '🇰🇷' },
      { name: '내일신문', url: 'https://www.naeil.com', icon: '🇰🇷' },
      { name: 'The New York Times', url: 'https://www.nytimes.com', icon: '🇺🇸' },
      { name: 'The Guardian', url: 'https://www.theguardian.com', icon: '🇬🇧' },
    ],
    economy: [
      { name: '조선경제', url: 'https://www.chosun.com/economy', icon: '🇰🇷' },
      { name: '매일경제', url: 'https://www.mk.co.kr', icon: '🇰🇷' },
      { name: '머니투데이', url: 'https://www.mt.co.kr', icon: '🇰🇷' },
      { name: '서울경제', url: 'https://www.sedaily.com', icon: '🇰🇷' },
      { name: 'Wall Street Journal', url: 'https://www.wsj.com', icon: '🇺🇸' },
      { name: 'Financial Times', url: 'https://www.ft.com', icon: '🇬🇧' },
    ],
    tech: [
      { name: '전자신문', url: 'https://www.etnews.com', icon: '🇰🇷' },
      { name: 'IT비즈뉴스', url: 'https://www.itbiznews.com', icon: '🇰🇷' },
      { name: 'The Verge', url: 'https://www.theverge.com', icon: '🇺🇸' },
      { name: 'Wired', url: 'https://www.wired.com', icon: '🇺🇸' },
      { name: 'TechCrunch', url: 'https://techcrunch.com', icon: '🇺🇸' },
    ],
    international: [
      { name: '한겨레', url: 'https://www.hani.co.kr', icon: '🇰🇷' },
      { name: 'Financial Times', url: 'https://www.ft.com', icon: '🇬🇧' },
      { name: 'The Economist', url: 'https://www.economist.com', icon: '📊' },
      { name: 'BBC News', url: 'https://www.bbc.com/news', icon: '🇬🇧' },
    ],
    english: [
      { name: '코리아타임스', url: 'https://www.koreatimes.co.kr', icon: '🇰🇷' },
      { name: 'The Korea Herald', url: 'https://www.koreaherald.com', icon: '🇰🇷' },
      { name: 'International Herald Tribune', url: 'https://www.nytimes.com/international', icon: '🇺🇸' },
    ],
    sports: [
      { name: '네이버 스포츠', url: 'https://sports.naver.com', icon: '🇰🇷' },
      { name: '스포츠경향', url: 'https://www.sportskyunghyang.com', icon: '🇰🇷' },
      { name: '스포츠동아', url: 'https://sports.donga.com', icon: '🇰🇷' },
      { name: 'ESPN', url: 'https://www.espn.com', icon: '🇺🇸' },
    ],
    culture: [
      { name: '문화일보', url: 'https://www.munhwa.com', icon: '🇰🇷' },
      { name: '뉴스컬쳐', url: 'https://www.newsculture.com', icon: '🇰🇷' },
      { name: 'The Arts Desk', url: 'https://www.theartsdesk.com', icon: '🇬🇧' },
      { name: 'NYT Arts & Culture', url: 'https://www.nytimes.com/arts', icon: '🇺🇸' },
    ],
    finance: [
      { name: '데일리경제', url: 'https://www.mk.co.kr', icon: '🇰🇷' },
      { name: '파이낸셜뉴스', url: 'https://www.fnnews.com', icon: '🇰🇷' },
      { name: '헤럴드경제', url: 'https://www.heraldcorp.com', icon: '🇰🇷' },
      { name: 'Bloomberg', url: 'https://www.bloomberg.com', icon: '🇺🇸' },
    ],
    environment: [
      { name: '환경일보', url: 'https://www.envilbo.com', icon: '🇰🇷' },
      { name: 'Guardian Environment', url: 'https://www.theguardian.com/environment', icon: '🇬🇧' },
      { name: 'National Geographic', url: 'https://www.nationalgeographic.org', icon: '🇺🇸' },
    ],
    local: [
      { name: '고양신문', url: 'https://www.goyangnews.net', icon: '🇰🇷' },
      { name: '부산경제신문', url: 'https://www.befn.kr', icon: '🇰🇷' },
      { name: 'The Local', url: 'https://www.thelocal.com', icon: '🇪🇺' },
    ],
    globalFinance: [
      { name: 'Bloomberg', url: 'https://www.bloomberg.com', icon: '🇺🇸' },
      { name: 'Reuters', url: 'https://www.reuters.com', icon: '🇬🇧' },
      { name: 'Nikkei Asia', url: 'https://asia.nikkei.com', icon: '🇯🇵' },
    ],
    globalEconomy: [
      { name: 'Financial Times', url: 'https://www.ft.com', icon: '🇬🇧' },
      { name: 'Wall Street Journal', url: 'https://www.wsj.com', icon: '🇺🇸' },
      { name: 'The Economist', url: 'https://www.economist.com', icon: '📊' },
    ],
  };

  const handleCategoryChange = (category) => {
    setActiveCategory(category);
  };

  const getDisplayNews = () => {
    if (activeCategory === 'all') {
      return Object.values(newsLinks).flat();
    }
    return newsLinks[activeCategory] || [];
  };

  return (
    <div className={styles.newsContainer}>
      <h2>📰 뉴스 포털</h2>
      <div className={styles.categoryButtons}>
        <button 
          className={`${styles.categoryButton} ${activeCategory === 'all' ? styles.active : ''}`}
          onClick={() => handleCategoryChange('all')}
        >
          전체 보기
        </button>
        {Object.entries(newsCategories).map(([key, value]) => (
          <button
            key={key}
            className={`${styles.categoryButton} ${activeCategory === key ? styles.active : ''}`}
            onClick={() => handleCategoryChange(key)}
          >
            {value.icon} {value.name}
          </button>
        ))}
      </div>
      <div className={styles.newsGrid}>
        {getDisplayNews().map((news, index) => (
          <a 
            key={index} 
            href={news.url} 
            target="_blank" 
            rel="noopener noreferrer" 
            className={styles.newsItem}
          >
            <span className={styles.newsIcon}>{news.icon}</span>
            <span className={styles.newsName}>{news.name}</span>
          </a>
        ))}
      </div>
    </div>
  );
};

export default News;