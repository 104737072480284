import React, { useState, useEffect } from 'react';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db, storage } from '../../../../api/firebase/firebaseConfig';
import styles from './ManagementModalContent.module.css';
import { getAuth } from 'firebase/auth';
import { v4 as uuidv4 } from 'uuid'; // 직원 고유아이디 생성을 위한 라이브러리
import EmployeeList from './components/EmployeeList';
import EmployeeForm from './components/EmployeeForm';
import TabMenu from '../../../modals/TabMenu';

const ManagementModalContent = () => {
  const [employees, setEmployees] = useState([]);
  const [customerList, setCustomerList] = useState([]); // 고객 목록 상태 추가
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [isAddingEmployee, setIsAddingEmployee] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [formData, setFormData] = useState({
    name: '',
    birthDate: '',
    idNumber: '',
    address: '',
    workplaces: [], // 근무지 목록 배열로 변경
    workHistory: '',
    additionalInfo: '',
    photoURL: '',
    salaryType: 'monthly',
    monthlySalary: '',
    healthInsurance: false,
    nationalPension: false,
    employmentInsurance: false,
    industrialAccidentInsurance: false,
    incomeTaxDeduction: false,
  });
  const [photoFile, setPhotoFile] = useState(null);
  const auth = getAuth();

  // 현재 선택된 근무지와 관련 상태 추가
  const [currentWorkplace, setCurrentWorkplace] = useState('');
  const [selectedWorkplaceIndex, setSelectedWorkplaceIndex] = useState(-1);
  const [showCustomerList, setShowCustomerList] = useState(false); // 고객 목록 표시 여부 상태 추가

  // 탭 관련 상태 추가
  const [activeTab, setActiveTab] = useState('인사 관리');
  const tabs = ['인사 관리', '근무지 설정'];

  // 탭 변경 핸들러
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  // 직원 목록 불러오기
  useEffect(() => {
    fetchEmployees();
    fetchCustomerData(); // 고객 데이터 로드
  }, []);

  const fetchEmployees = async () => {
    setIsLoading(true);
    try {
      if (!auth.currentUser) {
        console.error('로그인이 필요합니다.');
        setIsLoading(false);
        return;
      }
      
      // 사용자 UID를 문서 ID로 사용
      const employeeDocRef = doc(db, 'employees', auth.currentUser.uid);
      const employeeDoc = await getDoc(employeeDocRef);
      
      if (employeeDoc.exists()) {
        // 문서가 존재하면 직원 목록 가져오기
        const data = employeeDoc.data();
        setEmployees(data.employeeList || []);
      } else {
        // 문서가 없으면 빈 배열 설정
        setEmployees([]);
        // 필요시 빈 문서 생성
        await setDoc(employeeDocRef, { employeeList: [], updatedAt: new Date() });
      }
    } catch (error) {
      console.error('직원 목록 불러오기 실패:', error);
      setEmployees([]);
    } finally {
      setIsLoading(false);
    }
  };

  // 고객 데이터 로드 함수 추가
  const fetchCustomerData = async () => {
    try {
      if (!auth.currentUser) return;
      
      const customerDocRef = doc(db, 'customers', auth.currentUser.uid);
      const customerDoc = await getDoc(customerDocRef);
      
      if (customerDoc.exists()) {
        setCustomerList(customerDoc.data().customerList || []);
      }
    } catch (error) {
      console.error('고객 데이터 로드 실패:', error);
    }
  };

  // 직원 선택 시 상세 정보 불러오기
  const handleSelectEmployee = (employee) => {
    setIsAddingEmployee(false);
    setSelectedEmployee(employee);
    
    // 근무지가 없는 경우 기본 근무지 추가
    let workplaces = [];
    
    if (employee.workplaces && employee.workplaces.length > 0) {
      // 기존 근무지 목록 사용
      workplaces = employee.workplaces;
      
      // 기본 근무지가 없는 경우 첫 번째 근무지를 기본 근무지로 설정
      if (!workplaces.some(wp => wp.isDefault)) {
        workplaces = workplaces.map((wp, index) => {
          if (index === 0) {
            return { ...wp, isDefault: true };
          }
          return wp;
        });
      }
    } else {
      // 근무지가 없는 경우 기본 근무지 생성
      workplaces = [
        {
          name: '기본 근무지',
          hourlyWage: '',
          isComprehensiveWage: false,
          comprehensiveWage: '',
          weeklyHolidayAllowance: '',
          annualLeaveAllowance: '',
          isDefault: true
        }
      ];
    }
    
    setFormData({
      name: employee.name || '',
      birthDate: employee.birthDate || '',
      idNumber: employee.idNumber || '',
      address: employee.address || '',
      workplaces: workplaces,
      workHistory: employee.workHistory || '',
      additionalInfo: employee.additionalInfo || '',
      photoURL: employee.photoURL || '',
      salaryType: employee.salaryType || 'monthly',
      monthlySalary: employee.monthlySalary || '',
      healthInsurance: employee.healthInsurance || false,
      nationalPension: employee.nationalPension || false,
      employmentInsurance: employee.employmentInsurance || false,
      industrialAccidentInsurance: employee.industrialAccidentInsurance || false,
      incomeTaxDeduction: employee.incomeTaxDeduction || false,
    });
    setCurrentWorkplace('');
    setSelectedWorkplaceIndex(0); // 첫 번째 근무지 선택
  };

  // 새 직원 추가 모드로 전환
  const handleAddNewEmployee = () => {
    setSelectedEmployee(null);
    setIsAddingEmployee(true);
    setFormData({
      name: '',
      birthDate: '',
      idNumber: '',
      address: '',
      workplaces: [
        // 기본 근무지 추가 및 isDefault 속성 설정
        {
          name: '기본 근무지',
          hourlyWage: '',
          isComprehensiveWage: false,
          comprehensiveWage: '',
          weeklyHolidayAllowance: '',
          annualLeaveAllowance: '',
          isDefault: true // 기본 근무지 표시
        }
      ],
      workHistory: '',
      additionalInfo: '',
      photoURL: '',
      salaryType: 'monthly',
      monthlySalary: '',
      healthInsurance: false,
      nationalPension: false,
      employmentInsurance: false,
      industrialAccidentInsurance: false,
      incomeTaxDeduction: false,
    });
    setPhotoFile(null);
    setCurrentWorkplace('');
    setSelectedWorkplaceIndex(0); // 기본 근무지 선택
  };

  // 직원 삭제
  const handleDeleteEmployee = async () => {
    if (!selectedEmployee || !auth.currentUser) return;
    
    if (window.confirm('정말로 이 직원을 삭제하시겠습니까?')) {
      try {
        // 선택된 직원을 제외한 새 배열 생성
        const updatedEmployeeList = employees.filter(emp => emp.id !== selectedEmployee.id);
        
        // 문서 업데이트
        const employeeDocRef = doc(db, 'employees', auth.currentUser.uid);
        await updateDoc(employeeDocRef, {
          employeeList: updatedEmployeeList,
          updatedAt: new Date()
        });
        
        setEmployees(updatedEmployeeList);
        setSelectedEmployee(null);
        alert('직원이 삭제되었습니다.');
      } catch (error) {
        console.error('직원 삭제 실패:', error);
        alert('직원 삭제에 실패했습니다.');
      }
    }
  };

  // 입력 필드 변경 처리
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  // 체크박스 변경 처리
  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData({
      ...formData,
      [name]: checked
    });
  };

  // 급여 유형 변경 처리
  const handleSalaryTypeChange = (e) => {
    const { value } = e.target;
    setFormData({
      ...formData,
      salaryType: value,
    });
  };

  // 사진 파일 선택 처리
  const handlePhotoChange = (e) => {
    if (e.target.files[0]) {
      setPhotoFile(e.target.files[0]);
    }
  };

  // 근무지 추가 함수
  const handleAddWorkplace = () => {
    if (!currentWorkplace.trim()) {
      alert('근무지 이름을 입력해주세요.');
      return;
    }
    
    // 이미 존재하는 근무지인지 확인
    if (formData.workplaces.some(wp => wp.name === currentWorkplace.trim())) {
      alert('이미 등록된 근무지입니다.');
      return;
    }
    
    const newWorkplace = {
      name: currentWorkplace.trim(),
      hourlyWage: '',
      isComprehensiveWage: false,
      comprehensiveWage: '',
      weeklyHolidayAllowance: '',
      annualLeaveAllowance: '',
      isDefault: false // 추가된 근무지는 기본 근무지가 아님
    };
    
    setFormData({
      ...formData,
      workplaces: [...formData.workplaces, newWorkplace]
    });
    
    setCurrentWorkplace('');
    // 새로 추가된 근무지 선택
    setSelectedWorkplaceIndex(formData.workplaces.length);
  };

  // 근무지 선택 함수
  const handleSelectWorkplace = (index) => {
    setSelectedWorkplaceIndex(index);
  };

  // 근무지 삭제 함수
  const handleDeleteWorkplace = (index) => {
    // 기본 근무지인 경우 삭제 방지
    if (formData.workplaces[index].isDefault) {
      alert('기본 근무지는 삭제할 수 없습니다.');
      return;
    }
    
    // 마지막 남은 근무지인 경우 삭제 방지
    if (formData.workplaces.length <= 1) {
      alert('최소 하나의 근무지는 유지해야 합니다.');
      return;
    }
    
    if (window.confirm('이 근무지를 삭제하시겠습니까?')) {
      const updatedWorkplaces = [...formData.workplaces];
      updatedWorkplaces.splice(index, 1);
      
      setFormData({
        ...formData,
        workplaces: updatedWorkplaces
      });
      
      // 삭제 후 선택된 인덱스 조정
      if (selectedWorkplaceIndex >= updatedWorkplaces.length) {
        setSelectedWorkplaceIndex(updatedWorkplaces.length - 1);
      } else if (selectedWorkplaceIndex === index) {
        setSelectedWorkplaceIndex(0);
      }
    }
  };

  // 선택된 근무지의 정보 업데이트 함수
  const handleWorkplaceInputChange = (e) => {
    const { name, value } = e.target;
    
    if (selectedWorkplaceIndex === -1) return;
    
    const updatedWorkplaces = [...formData.workplaces];
    updatedWorkplaces[selectedWorkplaceIndex] = {
      ...updatedWorkplaces[selectedWorkplaceIndex],
      [name]: value
    };
    
    setFormData({
      ...formData,
      workplaces: updatedWorkplaces
    });
  };

  // 선택된 근무지의 체크박스 변경 처리
  const handleWorkplaceCheckboxChange = (e) => {
    const { name, checked } = e.target;
    
    if (selectedWorkplaceIndex === -1) return;
    
    const updatedWorkplaces = [...formData.workplaces];
    updatedWorkplaces[selectedWorkplaceIndex] = {
      ...updatedWorkplaces[selectedWorkplaceIndex],
      [name]: checked
    };
    
    setFormData({
      ...formData,
      workplaces: updatedWorkplaces
    });
  };

  // 직원 정보 저장 (추가 또는 수정)
  const handleSaveEmployee = async (e) => {
    e.preventDefault();
    
    if (!auth.currentUser) {
      alert('로그인이 필요합니다.');
      return;
    }
    
    try {
      let photoURL = formData.photoURL;
      
      const employeeData = {
        ...formData,
        photoURL
      };
      
      const employeeDocRef = doc(db, 'employees', auth.currentUser.uid);
      
      if (isAddingEmployee) {
        // 새 직원 추가
        const newEmployee = {
          ...employeeData,
          id: uuidv4(), // 고유 ID 생성
          createdAt: new Date()
        };
        
        // 기존 직원 목록에 새 직원 추가
        const updatedEmployeeList = [...employees, newEmployee];
        
        await updateDoc(employeeDocRef, {
          employeeList: updatedEmployeeList,
          updatedAt: new Date()
        });
        
        setEmployees(updatedEmployeeList);
        alert('새 직원이 등록되었습니다.');
      } else if (selectedEmployee) {
        // 기존 직원 정보 수정
        const updatedEmployeeList = employees.map(emp => {
          if (emp.id === selectedEmployee.id) {
            return {
              ...emp,
              ...employeeData,
              updatedAt: new Date()
            };
          }
          return emp;
        });
        
        await updateDoc(employeeDocRef, {
          employeeList: updatedEmployeeList,
          updatedAt: new Date()
        });
        
        setEmployees(updatedEmployeeList);
        alert('직원 정보가 수정되었습니다.');
      }
      
      setIsAddingEmployee(false);
      setSelectedEmployee(null);
    } catch (error) {
      console.error('직원 정보 저장 실패:', error);
      alert('직원 정보 저장에 실패했습니다.');
    }
  };

  // 컴포넌트 마운트 시 기본 근무지 선택 상태 설정
  useEffect(() => {
    if (formData.workplaces && formData.workplaces.length > 0 && selectedWorkplaceIndex === -1) {
      setSelectedWorkplaceIndex(0);
    }
  }, [formData.workplaces]);

  // 인사 관리 탭 렌더링
  const renderManagementTab = () => {
    return (
      <div className={styles.managementContent}>
        <EmployeeList 
          employees={employees}
          selectedEmployee={selectedEmployee}
          isLoading={isLoading}
          onSelectEmployee={handleSelectEmployee}
          onAddNewEmployee={handleAddNewEmployee}
        />
        
        <div className={styles.employeeDetailSection}>
          {isAddingEmployee || selectedEmployee ? (
            <EmployeeForm 
              isAddingEmployee={isAddingEmployee}
              selectedEmployee={selectedEmployee}
              formData={formData}
              photoFile={photoFile}
              currentWorkplace={currentWorkplace}
              selectedWorkplaceIndex={selectedWorkplaceIndex}
              showCustomerList={showCustomerList}
              customerList={customerList}
              handleInputChange={handleInputChange}
              handleCheckboxChange={handleCheckboxChange}
              handleSalaryTypeChange={handleSalaryTypeChange}
              handlePhotoChange={handlePhotoChange}
              handleWorkplaceInputChange={handleWorkplaceInputChange}
              handleWorkplaceCheckboxChange={handleWorkplaceCheckboxChange}
              handleSaveEmployee={handleSaveEmployee}
              handleDeleteEmployee={handleDeleteEmployee}
              setCurrentWorkplace={setCurrentWorkplace}
              handleAddWorkplace={handleAddWorkplace}
              handleSelectWorkplace={handleSelectWorkplace}
              handleDeleteWorkplace={handleDeleteWorkplace}
              setShowCustomerList={setShowCustomerList}
              setIsAddingEmployee={setIsAddingEmployee}
              setSelectedEmployee={setSelectedEmployee}
            />
          ) : (
            <div className={styles.noSelection}>
              <p>직원을 선택하거나 새 직원을 등록해주세요.</p>
            </div>
          )}
        </div>
      </div>
    );
  };

  // 근무지 설정 탭 렌더링
  const renderWorkplaceTab = () => {
    return (
      <div className={styles.workplaceContent}>
        <h3>근무지 설정</h3>
        <p>회사 근무지를 관리할 수 있습니다. 추후 업데이트 예정입니다.</p>
      </div>
    );
  };

  // 선택된 탭에 따른 콘텐츠 렌더링
  const renderContent = () => {
    // 로딩 중일 때 로딩 컴포넌트 표시
    if (isLoading) {
      return (
        <div className={styles.loadingContainer}>
          <div className={styles.spinner}></div>
          <div>로딩 중...</div>
        </div>
      );
    }
    
    // 탭 내용 렌더링
    let content;
    switch (activeTab) {
      case '근무지 설정':
        content = renderWorkplaceTab();
        break;
      case '인사 관리':
      default:
        content = renderManagementTab();
    }
    
    // 각 탭 컨텐츠에 이미 managementContent 또는 workplaceContent 클래스가 적용되어 있으므로
    // 추가 컨테이너 불필요
    return content;
  };

  return (
    <div className={styles.managementModalContent}>
      <TabMenu
        tabs={tabs}
        activeTab={activeTab}
        onTabChange={handleTabChange}
      >
        {renderContent()}
      </TabMenu>
    </div>
  );
};

export default ManagementModalContent;   