import api from '../api';

export const getBizPhone = async (payload) => {
  try {
    const response = await api.post('/bizPhone', payload);
    return response;
  } catch (error) {
    console.error('알뜰폰 데이터 조회 실패:', error);
    throw error;
  }
};