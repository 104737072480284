// Interior3DCube.jsx
import React from 'react';
import styles from './Interior3DCube.module.css';

const Interior3DCube = ({ member, backgrounds }) => {
  const { furniture, businessName, ownerName } = member;

  const wallStyle = backgrounds?.wall ? { backgroundImage: `url(${backgrounds.wall})` } : {};
  const windowStyle = backgrounds?.window ? { backgroundImage: `url(${backgrounds.window})` } : {};
  
  // 배너 이미지 가져오기 - 속성명 수정
  const bannerImage = backgrounds?.banner || ''; // banner01이 아닌 banner로 수정

  return (
    <div className={styles.roomWrapper}>
      {(businessName || ownerName) && (
        <div className={styles.cubeSigns}>
          <div className={styles.bannerContainer}>
            <img src={bannerImage} alt="Banner" className={styles.bannerImage} />
            {businessName && <div className={styles.businessSign}>{businessName}</div>}
            {/* {ownerName && <div className={styles.ownerSign}>{ownerName}</div>} */}
          </div>
        </div>
      )}
      <div className={styles.mainWall} style={wallStyle}>
        <div className={styles.window} style={windowStyle}></div>
        {furniture && furniture.map((item, index) => (
          <img
            key={index}
            className={`${styles.furnitureItem} ${styles[item.type]}`}
            src={item.image}
            alt={item.type}
          />
        ))}
      </div>
    </div>
  );
};

export default Interior3DCube;
