import React from 'react';
import styles from './EmployeeForm.module.css';

const EmployeeForm = ({ 
  isAddingEmployee, 
  selectedEmployee, 
  formData, 
  photoFile, 
  currentWorkplace, 
  selectedWorkplaceIndex, 
  showCustomerList, 
  customerList,
  handleInputChange, 
  handleCheckboxChange, 
  handleSalaryTypeChange, 
  handlePhotoChange, 
  handleWorkplaceInputChange, 
  handleWorkplaceCheckboxChange, 
  handleSaveEmployee, 
  handleDeleteEmployee, 
  setCurrentWorkplace, 
  handleAddWorkplace, 
  handleSelectWorkplace, 
  handleDeleteWorkplace, 
  setShowCustomerList, 
  setIsAddingEmployee, 
  setSelectedEmployee 
}) => {
  return (
    <form onSubmit={handleSaveEmployee} className={styles.employeeForm}>
      <h3>{isAddingEmployee ? '새 직원 등록' : '직원 정보 수정'}</h3>
      
      <div className={styles.formColumns}>
        <div className={styles.formColumn}>
          {/* 왼쪽 컬럼 */}
          <div className={styles.formRow}>
            <div className={styles.formGroup}>
              <label>이름</label>
              <input 
                type="text" 
                name="name" 
                value={formData.name} 
                onChange={handleInputChange}
                required
              />
            </div>
            
            <div className={styles.formGroup}>
              <label>생년월일</label>
              <input 
                type="date" 
                name="birthDate" 
                value={formData.birthDate} 
                onChange={handleInputChange}
              />
            </div>
          </div>
          
          <div className={styles.formGroup}>
            <label>주민등록번호</label>
            <input 
              type="text" 
              name="idNumber" 
              value={formData.idNumber} 
              onChange={handleInputChange}
              placeholder="000000-0000000"
            />
          </div>
          
          <div className={styles.formGroup}>
            <label>주소</label>
            <input 
              type="text" 
              name="address" 
              value={formData.address} 
              onChange={handleInputChange}
            />
          </div>
          
          <div className={styles.formRow}>
            <div className={styles.formGroup}>
              <label>급여 유형</label>
              <div className={styles.inlineRadioGroup}>
                <label>
                  <input
                    type="radio"
                    name="salaryType"
                    value="monthly"
                    checked={formData.salaryType === 'monthly'}
                    onChange={handleSalaryTypeChange}
                  />
                  월급제
                </label>
                <label>
                  <input
                    type="radio"
                    name="salaryType"
                    value="hourly"
                    checked={formData.salaryType === 'hourly'}
                    onChange={handleSalaryTypeChange}
                  />
                  시급제
                </label>
              </div>
            </div>
            
            {formData.salaryType === 'monthly' && (
              <div className={styles.formGroup}>
                <label>월 급여액</label>
                <input
                  type="number"
                  name="monthlySalary"
                  value={formData.monthlySalary || ''}
                  onChange={handleInputChange}
                  placeholder="예: 3000000"
                />
              </div>
            )}
          </div>
          
          <div className={styles.formGroup}>
            <label>사회보험 및 세금</label>
            <div className={styles.compactInsuranceSection}>
              <label className={styles.inlineCheckbox}>
                <input
                  type="checkbox"
                  name="healthInsurance"
                  checked={formData.healthInsurance || false}
                  onChange={handleCheckboxChange}
                />
                건강보험
              </label>
              <label className={styles.inlineCheckbox}>
                <input
                  type="checkbox"
                  name="nationalPension"
                  checked={formData.nationalPension || false}
                  onChange={handleCheckboxChange}
                />
                국민연금
              </label>
              <label className={styles.inlineCheckbox}>
                <input
                  type="checkbox"
                  name="employmentInsurance"
                  checked={formData.employmentInsurance || false}
                  onChange={handleCheckboxChange}
                />
                고용보험
              </label>
              <label className={styles.inlineCheckbox}>
                <input
                  type="checkbox"
                  name="industrialAccidentInsurance"
                  checked={formData.industrialAccidentInsurance || false}
                  onChange={handleCheckboxChange}
                />
                산재보험
              </label>
              <label className={styles.inlineCheckbox}>
                <input
                  type="checkbox"
                  name="incomeTaxDeduction"
                  checked={formData.incomeTaxDeduction || false}
                  onChange={handleCheckboxChange}
                />
                소득세 공제
              </label>
            </div>
          </div>
        </div>
        
        <div className={styles.formColumn}>
          {/* 오른쪽 컬럼 */}
          <div className={styles.formGroup + ' ' + styles.workplaceSection}>
            <label>근무지 관리</label>
            <div className={styles.workplaceAdd}>
              <input 
                type="text" 
                value={currentWorkplace} 
                onChange={(e) => setCurrentWorkplace(e.target.value)}
                placeholder="새 근무지 이름"
              />
              <button 
                type="button" 
                className={styles.addWorkplaceBtn}
                onClick={handleAddWorkplace}
              >
                추가
              </button>
              <button 
                type="button" 
                className={styles.showCustomerBtn}
                onClick={() => setShowCustomerList(!showCustomerList)}
              >
                {showCustomerList ? '닫기' : '고객목록'}
              </button>
            </div>
            
            {/* 고객 목록 섹션 */}
            {showCustomerList && (
              <div className={styles.customerListSection}>
                <div className={styles.customerList}>
                  {customerList.length === 0 ? (
                    <div className={styles.noCustomers}>등록된 고객이 없습니다.</div>
                  ) : (
                    customerList.map((customer, index) => (
                      <div 
                        key={index}
                        className={styles.customerItem}
                        onClick={() => {
                          setCurrentWorkplace(customer.name);
                          setShowCustomerList(false); // 선택 후 목록 닫기
                        }}
                      >
                        <span>{customer.name}</span>
                      </div>
                    ))
                  )}
                </div>
              </div>
            )}
            
            <div className={styles.workplaceList}>
              {formData.workplaces.length === 0 ? (
                <div className={styles.noWorkplaces}>등록된 근무지가 없습니다.</div>
              ) : (
                <div className={styles.workplaceItems}>
                  {formData.workplaces.map((workplace, index) => (
                    <div 
                      key={index} 
                      className={`${styles.workplaceItem} ${selectedWorkplaceIndex === index ? styles.selected : ''} ${workplace.isDefault ? styles.defaultWorkplace : ''}`}
                      onClick={() => handleSelectWorkplace(index)}
                    >
                      <span>
                        {workplace.name}
                        {workplace.isDefault && <small className={styles.defaultLabel}>{'(기본)'}</small>}
                      </span>
                      {!workplace.isDefault && formData.workplaces.length > 1 && (
                        <button 
                          type="button" 
                          className={styles.deleteWorkplaceBtn}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteWorkplace(index);
                          }}
                        >
                          삭제
                        </button>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
            
            {formData.salaryType === 'hourly' && selectedWorkplaceIndex !== -1 && (
              <div className={styles.workplaceDetails}>
                <div className={styles.superCompactForm}>
                  <div className={styles.formRow}>
                    <div className={styles.formGroup}>
                      <label>기준시급</label>
                      <div className={styles.inlineInputGroup}>
                        <input
                          type="number"
                          name="hourlyWage"
                          value={formData.workplaces[selectedWorkplaceIndex].hourlyWage || ''}
                          onChange={handleWorkplaceInputChange}
                          placeholder="예: 9860"
                        />
                      </div>
                    </div>
                    
                    <div className={styles.formGroup}>
                      <label>
                        <input
                          type="checkbox"
                          name="isComprehensiveWage"
                          checked={formData.workplaces[selectedWorkplaceIndex].isComprehensiveWage || false}
                          onChange={handleWorkplaceCheckboxChange}
                        />
                        포괄시급
                      </label>
                      {formData.workplaces[selectedWorkplaceIndex].isComprehensiveWage && (
                        <input
                          type="number"
                          name="comprehensiveWage"
                          value={formData.workplaces[selectedWorkplaceIndex].comprehensiveWage || ''}
                          onChange={handleWorkplaceInputChange}
                          placeholder="포괄시급액"
                        />
                      )}
                    </div>
                  </div>
                  
                  <div className={styles.formRow}>
                    <div className={styles.formGroup}>
                      <label>주휴수당</label>
                      <input
                        type="number"
                        name="weeklyHolidayAllowance"
                        value={formData.workplaces[selectedWorkplaceIndex].weeklyHolidayAllowance || ''}
                        onChange={handleWorkplaceInputChange}
                        placeholder="예: 1972"
                      />
                    </div>
                    
                    <div className={styles.formGroup}>
                      <label>연차수당</label>
                      <input
                        type="number"
                        name="annualLeaveAllowance"
                        value={formData.workplaces[selectedWorkplaceIndex].annualLeaveAllowance || ''}
                        onChange={handleWorkplaceInputChange}
                        placeholder="예: 1972"
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          
          <div className={styles.formRow}>
            <div className={styles.formGroup}>
              <label>근무 내역</label>
              <textarea 
                name="workHistory" 
                value={formData.workHistory} 
                onChange={handleInputChange}
                placeholder="근무 시작일, 직위 등"
                rows="2"
              />
            </div>
            
            <div className={styles.formGroup}>
              <label>추가 정보</label>
              <textarea 
                name="additionalInfo" 
                value={formData.additionalInfo} 
                onChange={handleInputChange}
                placeholder="자격증, 특이사항 등"
                rows="2"
              />
            </div>
          </div>
        </div>
      </div>
      
      <div className={styles.formActions}>
        <button type="submit" className={styles.saveBtn}>
          {isAddingEmployee ? '등록하기' : '수정하기'}
        </button>
        {selectedEmployee && (
          <button 
            type="button" 
            className={styles.deleteBtn} 
            onClick={handleDeleteEmployee}
          >
            삭제하기
          </button>
        )}
        <button 
          type="button" 
          className={styles.cancelBtn}
          onClick={() => {
            setIsAddingEmployee(false);
            setSelectedEmployee(null);
          }}
        >
          취소
        </button>
      </div>
    </form>
  );
};

export default EmployeeForm; 