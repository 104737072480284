import React from 'react';
import styles from './BizInsurance.module.css';

const BizInsurance = () => {
  return (
    <div className={styles.insuranceModal}>
      <div className={styles.insuranceContent}>
        <h2>손해보험(화재보험) 상담</h2>
        <p>전문 상담사가 귀하와 귀하의 비즈니스에 맞는 최적의 보험 상품을 제안해드립니다.</p>
        
        <button className={styles.kakaoConsultBtn} onClick={() => window.open('https://open.kakao.com/o/sLV8mjNf')}>
          <span role="img" aria-label="kakao">💬</span> 카카오톡 상담하기
        </button>
        
        <div className={styles.infoContainer}>
          <div className={styles.businessCard}>
            <h3>수석 설계사</h3>
            <div className={styles.cardContent}>
              <div className={styles.consultantInfo}>
                <p className={styles.name}>김용우</p>
                <p className={styles.title}>케어샐러리 수석 설계사</p>
                <p className={styles.contact}>010-7634-8128</p>
              </div>
            </div>
          </div>
          
          <div className={styles.adBanner}>
            <div className={styles.bannerContent}>
              <h3>손해보험 판매자격 보유</h3>
              <p>메리츠화재| 삼성화재 등<br></br>13개 손해보험사 취급</p>
              <p>고유번호 20170389030012</p>
              <p className={styles.promotion}>무료로 보장분석 받아보세요!</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BizInsurance; 