import React, { useState, useEffect } from 'react';
import { doc, getDoc, collection, setDoc, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../../../api/firebase/firebaseConfig';
import { getAuth } from 'firebase/auth';
import './DocumentsModalContent.css';
import TabMenu from '../../../modals/TabMenu';

// TODO 계약서 고도화 해야함

// 계약서 컴포넌트들 임포트
import EmploymentContract from './contracts/EmploymentContract';
import ServiceContract from './contracts/ServiceContract';
import SupplyContract from './contracts/SupplyContract';
import OutsourcingContract from './contracts/OutsourcingContract';

const CONTRACT_TYPES = {
  EMP: { code: 'EMP', name: '근로계약서', component: EmploymentContract },
  SVC: { code: 'SVC', name: '서비스 제공계약서', component: ServiceContract },
  SUP: { code: 'SUP', name: '납품계약서', component: SupplyContract },
  OUT: { code: 'OUT', name: '외주계약서', component: OutsourcingContract },
};

export const CUSTOMER_CATEGORIES = {
  VIP: { 
    code: 'VIP', 
    name: 'VIP 고객',
    color: '#ffd700'
  },
  REGULAR: { 
    code: 'REGULAR', 
    name: '일반 고객',
    color: '#2196F3'
  },
  NEW: { 
    code: 'NEW', 
    name: '신규 고객',
    color: '#4CAF50'
  },
  CORPORATE: { 
    code: 'CORPORATE', 
    name: '기업 고객',
    color: '#9C27B0'
  },
  PARTNER: { 
    code: 'PARTNER', 
    name: '협력사',
    color: '#FF5722'
  },
  GROUP: { 
    code: 'GROUP', 
    name: '단체 고객',
    color: '#607D8B'
  },
  INACTIVE: { 
    code: 'INACTIVE', 
    name: '휴면 고객',
    color: '#9E9E9E'
  }
};

// 계약 관리 관련 타입 정의
const CONTRACT_CATEGORIES = {
  SERVICE: 'service',
  EMPLOYMENT: 'employment',
  SUPPLY: 'supply',
  OUTSOURCING: 'outsourcing'
};

// 계약 상태 정의
const CONTRACT_STATUS = {
  DRAFT: 'draft',
  ACTIVE: 'active',
  COMPLETED: 'completed',
  TERMINATED: 'terminated',
  EXPIRED: 'expired'
};

const DocumentsModalContent = () => {
  const [customers, setCustomers] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [selectedParty, setSelectedParty] = useState(null);
  const [contractType, setContractType] = useState('');
  const [contractHistory, setContractHistory] = useState([]);
  const [selectedContract, setSelectedContract] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [filteredParties, setFilteredParties] = useState([]);
  
  // 상태 관리
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [contractTypes, setContractTypes] = useState([]);
  const [agreements, setAgreements] = useState([]);
  const [filters, setFilters] = useState({
    type: '',
    partyType: '',
    status: '',
    dateRange: null,
    searchTerm: ''
  });
  
  // 탭 관련 상태 추가
  const [activeTab, setActiveTab] = useState('고객 계약');
  const tabs = ['고객 계약', '직원 계약'];
  
  const auth = getAuth();

  // 탭 변경 핸들러 함수
  const handleTabChange = (tab) => {
    setActiveTab(tab);
    // 탭에 따라 partyType 설정
    if (tab === '고객 계약') {
      setPartyType('customer');
    } else {
      setPartyType('employee');
    }
    setSelectedParty(null);
    setContractHistory([]);
    handleClearSearch();
  };

  // partyType 상태 - 탭에 따라 값이 변경됨
  const [partyType, setPartyType] = useState('customer');

  // 고객 및 직원 데이터 로드
  useEffect(() => {
    const loadData = async () => {
      if (!auth.currentUser) return;

      try {
        // 고객 데이터 로드
        const customerDoc = await getDoc(doc(db, 'customers', auth.currentUser.uid));
        if (customerDoc.exists()) {
          setCustomers(customerDoc.data().customerList || []);
        }

        // 직원 데이터 로드
        const employeeDoc = await getDoc(doc(db, 'employees', auth.currentUser.uid));
        if (employeeDoc.exists()) {
          setEmployees(employeeDoc.data().employeeList || []);
        }
      } catch (error) {
        console.error('데이터 로드 실패:', error);
      } finally {
        setIsLoading(false);
      }
    };

    loadData();
  }, [auth.currentUser]);

  // 계약 이력 로드
  const loadContractHistory = async (party) => {
    if (!party || !auth.currentUser) return;

    try {
      // 수정된 경로로 계약서 조회
      const contractsRef = collection(
        db, 
        'documents', 
        auth.currentUser.uid, 
        'parties', 
        party.id, 
        'contracts'
      );
      const contractDocs = await getDocs(contractsRef);
      
      const history = [];
      contractDocs.forEach(doc => {
        history.push({
          id: doc.id,
          ...doc.data()
        });
      });

      setContractHistory(history.sort((a, b) => 
        new Date(b.contractDate) - new Date(a.contractDate)
      ));
    } catch (error) {
      console.error('계약 이력 로드 실패:', error);
    }
  };

  // 계약서 저장
  const handleSaveContract = async (contractData) => {
    if (!selectedParty || !auth.currentUser) return;

    const contractDate = new Date();
    const yearMonth = contractDate.getFullYear().toString() +
      (contractDate.getMonth() + 1).toString().padStart(2, '0');

    try {
      // 경로 구조 수정: documents/{userId}/parties/{partyId}/contracts/{documentId}
      const contractRef = doc(
        collection(db, 'documents', auth.currentUser.uid, 'parties', selectedParty.id, 'contracts')
      );

      await setDoc(contractRef, {
        ...contractData,
        contractType,
        contractDate: contractDate.toISOString(),
        partyType,
        partyId: selectedParty.id,
        partyName: selectedParty.name,
        yearMonth
      });

      alert('계약서가 저장되었습니다.');
      loadContractHistory(selectedParty);
    } catch (error) {
      console.error('계약서 저장 실패:', error);
      alert('계약서 저장에 실패했습니다.');
    }
  };

  // 계약서 컴포넌트 렌더링
  const renderContractForm = () => {
    if (!contractType || !selectedParty) return null;

    const ContractComponent = CONTRACT_TYPES[contractType]?.component;
    if (!ContractComponent) return null;

    return (
      <ContractComponent
        party={selectedParty}
        onSave={handleSaveContract}
        initialData={selectedContract}
      />
    );
  };

  // useEffect: 필터링된 목록 업데이트
  useEffect(() => {
    let filtered = [];
    
    // 현재 탭에 따라 데이터 소스 선택
    const dataSource = partyType === 'customer' ? customers : employees;
    
    // 필터링
    if (searchTerm || (partyType === 'customer' && selectedCategories.length > 0)) {
      // 검색어로 필터링
      filtered = dataSource.filter(party => {
        const matchesSearch = !searchTerm || 
          party.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          (party.contact && party.contact.includes(searchTerm));
          
        // 카테고리 필터링 (고객일 경우만)
        const matchesCategory = partyType !== 'customer' || 
          selectedCategories.length === 0 || 
          (party.category && selectedCategories.includes(party.category));
          
        return matchesSearch && matchesCategory;
      });
    } else {
      filtered = dataSource;
    }
    
    setFilteredParties(filtered);
  }, [customers, employees, partyType, searchTerm, selectedCategories]);

  // 카테고리 토글 함수
  const handleCategoryToggle = (category) => {
    setSelectedCategories(prev => {
      if (prev.includes(category)) {
        return prev.filter(c => c !== category);
      } else {
        return [...prev, category];
      }
    });
  };

  // 검색 초기화 함수
  const handleClearSearch = () => {
    setSearchTerm('');
    setSelectedCategories([]);
  };

  // 계약 종류 로드
  const loadContractTypes = async (orgId) => {
    const typesRef = collection(db, 'contracts', orgId, 'types');
    const typesSnap = await getDocs(typesRef);
    const types = [];
    typesSnap.forEach(doc => {
      types.push({ id: doc.id, ...doc.data() });
    });
    setContractTypes(types);
  };

  // 계약 목록 로드 (필터링 적용)
  const loadAgreements = async (orgId) => {
    let query = collection(db, 'contracts', orgId, 'agreements');
    
    // 필터 적용
    if (filters.type) {
      query = query.where('typeId', '==', filters.type);
    }
    if (filters.status) {
      query = query.where('status', '==', filters.status);
    }
    if (filters.partyType) {
      query = query.where('partyType', '==', filters.partyType);
    }
    if (filters.dateRange) {
      query = query.where('startDate', '>=', filters.dateRange.start)
                  .where('startDate', '<=', filters.dateRange.end);
    }
    
    const agreementsSnap = await getDocs(query);
    const agreements = [];
    agreementsSnap.forEach(doc => {
      agreements.push({ id: doc.id, ...doc.data() });
    });
    setAgreements(agreements);
  };

  // 계약 상세 정보 로드 (스케줄 포함)
  const loadAgreementDetails = async (orgId, agreementId) => {
    // 계약 기본 정보
    const agreementRef = doc(db, 'contracts', orgId, 'agreements', agreementId);
    const agreementSnap = await getDoc(agreementRef);
    
    // 스케줄 정보
    const schedulesRef = collection(db, 'contracts', orgId, 'schedules');
    const schedulesQuery = query(schedulesRef, where('agreementId', '==', agreementId));
    const schedulesSnap = await getDocs(schedulesQuery);
    
    // 진행 기록
    const recordsRef = collection(db, 'contracts', orgId, 'progress', agreementId, 'records');
    const recordsSnap = await getDocs(recordsRef);
    
    return {
      agreement: { id: agreementSnap.id, ...agreementSnap.data() },
      schedules: schedulesSnap.docs.map(doc => ({ id: doc.id, ...doc.data() })),
      records: recordsSnap.docs.map(doc => ({ id: doc.id, ...doc.data() }))
    };
  };

  // 계약 상태 관리를 위한 새로운 함수
  const handleContractStatusChange = async (contractId, newStatus) => {
    if (!selectedParty || !auth.currentUser) return;

    try {
      const contractRef = doc(
        db, 
        'documents', 
        auth.currentUser.uid, 
        'parties', 
        selectedParty.id, 
        'contracts',
        contractId
      );

      await setDoc(contractRef, {
        status: newStatus,
        updatedAt: new Date().toISOString()
      }, { merge: true });

      // 계약 이력 새로고침
      loadContractHistory(selectedParty);
      alert('계약 상태가 업데이트되었습니다.');
    } catch (error) {
      console.error('계약 상태 업데이트 실패:', error);
      alert('계약 상태 업데이트에 실패했습니다.');
    }
  };

  // 이전 계약서 로드 함수 개선
  const loadPreviousContract = async (contractId) => {
    if (!selectedParty || !auth.currentUser) return;

    try {
      const contractRef = doc(
        db,
        'documents',
        auth.currentUser.uid,
        'parties',
        selectedParty.id,
        'contracts',
        contractId
      );

      const contractDoc = await getDoc(contractRef);
      if (contractDoc.exists()) {
        setSelectedContract(contractDoc.data());
        setContractType(contractDoc.data().contractType);
      }
    } catch (error) {
      console.error('이전 계약서 로드 실패:', error);
      alert('이전 계약서를 불러오는데 실패했습니다.');
    }
  };

  // 계약 이력 컴포넌트 수정
  const renderContractHistory = () => (
    <div className="contractHistory">
      <h4>계약 이력</h4>
      <div className="historyList">
        {contractHistory.length === 0 ? (
          <div className="noContracts">등록된 계약이 없습니다.</div>
        ) : (
          contractHistory.map(contract => (
            <div 
              key={contract.id} 
              className={`contractItem ${selectedContract?.id === contract.id ? 'selected' : ''}`}
              onClick={() => setSelectedContract(contract)}
            >
              <div className="contractDate">
                {new Date(contract.contractDate).toLocaleDateString()}
              </div>
              <div className="contractTitle">
                {CONTRACT_TYPES[contract.contractType]?.name || '계약서'}
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );

  // 탭 내용 렌더링
  const renderContent = () => {
    if (isLoading) {
      return (
        <div className="loadingContainer">
          <div className="spinner"></div>
          <div>로딩 중...</div>
        </div>
      );
    }
    
    return (
      <div className="documentsContent">
        <div className="partyList">
          <h3>{partyType === 'customer' ? '고객 목록' : '직원 목록'}</h3>
          
          {/* 검색 섹션 */}
          <div className="searchSection">
            <div className="searchBox">
              <input
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder={`${partyType === 'customer' ? '고객' : '직원'} 검색...`}
              />
              {searchTerm && (
                <button 
                  className="clearSearch"
                  onClick={handleClearSearch}
                >
                  ×
                </button>
              )}
            </div>
            
            {/* 고객 카테고리 필터 (고객 모드일 때만 표시) */}
            {partyType === 'customer' && (
              <div className="categoryFilters">
                {Object.entries(CUSTOMER_CATEGORIES).map(([key, label]) => (
                  <label key={key} className="categoryCheckbox">
                    <input
                      type="checkbox"
                      checked={selectedCategories.includes(key)}
                      onChange={() => handleCategoryToggle(key)}
                    />
                    <span>{label.name}</span>
                  </label>
                ))}
              </div>
            )}
          </div>

          {/* 필터링된 목록 */}
          <div className="listContainer">
            {!filteredParties || filteredParties.length === 0 ? (
              <div className="noResults">
                {searchTerm || selectedCategories.length > 0 
                  ? '검색 결과가 없습니다.'
                  : `등록된 ${partyType === 'customer' ? '고객' : '직원'}이 없습니다.`
                }
              </div>
            ) : (
              filteredParties.map(party => (
                party && (
                  <div
                    key={party.id}
                    className={`partyItem ${selectedParty?.id === party.id ? 'selected' : ''}`}
                    onClick={() => {
                      setSelectedParty(party);
                      loadContractHistory(party);
                    }}
                  >
                    <div className="partyItemContent">
                      <div className="partyName">{party.name}</div>
                      {partyType === 'customer' && party.category && (
                        <span className={`categoryTag ${party.category.toLowerCase()}`}>
                          {CUSTOMER_CATEGORIES[party.category]?.name || party.category}
                        </span>
                      )}
                      {party.contact && (
                        <div className="partyContact">{party.contact}</div>
                      )}
                    </div>
                  </div>
                )
              ))
            )}
          </div>
        </div>

        {selectedParty && (
          <div className="contractSection">
            <div className="contractTypeSelector">
              <select
                value={contractType}
                onChange={(e) => {
                  setContractType(e.target.value);
                  setSelectedContract(null);
                }}
              >
                <option value="">계약서 종류 선택</option>
                {Object.values(CONTRACT_TYPES).map(type => (
                  <option key={type.code} value={type.code}>
                    {type.name}
                  </option>
                ))}
              </select>
            </div>

            {renderContractHistory()}

            <div className="contractForm">
              {renderContractForm()}
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="documentsModalContent">
      <TabMenu
        tabs={tabs}
        activeTab={activeTab}
        onTabChange={handleTabChange}
      >
        {renderContent()}
      </TabMenu>
    </div>
  );
};

export default DocumentsModalContent;