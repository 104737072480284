import React, { useState } from 'react';
import MainMenu from '../../components/layout/MainMenu';
import MainAgent from '../../ai/components/MainAgent';
import Menu from '../../components/layout/RightMenu';
import Notice from '../../components/layout/Notice';
import Interior3DCube from './Interior3DCube';
import StatisticsSection from './StatisticsSection';
import sofa from '../../assets/interior/sofa.png';
import window01 from '../../assets/interior/window01.png';  // 창문 이미지 import
import background01 from '../../assets/images/background01.jpg'; // 배경 이미지 import
import banner01 from '../../assets/images/banner01.jpg'; // 배너 이미지 import

import AdBanner from '../../assets/images/ADbanner.png'

import styles from './Home.module.css';
import { AgentContextProvider } from '../../context/AgentContextProvider';

const Home = () => {
  const [menuType, setMenuType] = useState('operation');
  const [showAgent, setShowAgent] = useState(false);

  // 회원 객체
  const memberData = {
    companyType: "디지털오피스",  // "웰니스센터", "컨설팅룸", "러닝허브"도 가능
    growthStage: 2,              // 1, 2, 3 단계 중 선택
    businessName: "디지털오피스 본점", // 사업장명 간판
    ownerName: "홍길동",              // 사업주명 간판
    furniture: [
      { type: "sofa", image: sofa },  // 직접 import한 이미지 사용
    ],
    curtainType: "modern"        // 예: modern, classic 등 다양하게
  };

  const backgrounds = {
    window: window01,
    wall: background01,
    banner: banner01,
  };

  return (
    <AgentContextProvider>
      <div className={styles.container}>
        <div className={styles.leftSection}>
          <MainMenu setMenuType={setMenuType} setShowAgent={setShowAgent} />
          {showAgent && <MainAgent />}  {/* 에이전트 모달 표시 */}
          <br></br>
          <div className={styles.bannerSection}>
            <a href="https://prophetsophia-s-room.web.app" target="_blank" rel="noopener noreferrer">
              <img style={{borderRadius: '10px'}} src={AdBanner} alt="배너 이미지" className={styles.bannerImage} />
            </a>
          </div>
        </div>
        <div className={styles.interiorSection}>
          <div className={styles.interiorDisplay}>
            <Interior3DCube member={memberData} backgrounds={backgrounds} />
          </div>
          <div className={styles.statsSection}>
            <StatisticsSection />
          </div>
        </div>
        <div className={styles.rightMenu}>
          <Menu menuType={menuType} />
          <Notice />
        </div>
      </div>
    </AgentContextProvider>
  );
};

export default Home;