import React from 'react';
import style from './Footer.module.css';

const Footer = () => {
  return (
    <footer className={style.footer}>
      <div className={style.footerContent}>
        <div className={style.companyInfo}>
          <h3>회사명 : 페어엠소프트(paremsoft)</h3>
          <h3>서비스명 : 케어샐러리(caresalary)</h3>
          <p>대표: 김용우</p>
          <p>사업자등록번호: 761-36-00991</p>
          <p>통신판매업신고: 2022-대구수성구-1434</p>
          <p>주소: 대구광역시 수성구 상록로2길 12(범어동) 1층</p>
          <p>연락처: 010-7634-8128</p>
          <p>이메일: caresalary@naver.com</p>
        </div>
        <div className={style.footerLinks}>
          <h3>바로가기</h3>
          <ul>
            <li><a href="/terms">이용약관</a></li>
            <li><a href="/privacy">개인정보처리방침</a></li>
            <li><a href="https://pf.kakao.com/_KCkFxj/chat" target="_blank" rel="noopener noreferrer">카카오톡 고객센터</a></li>
          </ul>
        </div>
      </div>
      <div className={style.copyright}>
        <p>© 2022 페어엠소프트(paremsoft). All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer; 