import api from '../api';

export const getBizPromotion = async (params) => {
  try {
    const response = await api.post('/bizPromotion', params);
    return response;
  } catch (error) {
    console.error('비즈프로모션 데이터 조회 실패:', error);
    throw error;
  }
}; 