// AI마케팅 전략
// 백엔드 반환값 예시
/*
{
  success: true,
  data: {
    strategies: [
      {
        id: 'str-001',
        title: '충성 고객 감사 이벤트',
        description: '지난 3개월간 매출 데이터를 분석한 결과, 자주 방문하는 VIP 고객을 위한 특별 이벤트가 필요합니다.',
        targetGroups: ['VIP', '단골'],
        expectedEffect: '고객 충성도 향상 및 매출 10% 증가 예상',
        suggestedActions: [
          '지난 3개월간 3회 이상 방문 고객에게 할인 쿠폰 제공',
          '가족 동반 시 추가 혜택 제공',
          '단골 고객 전용 이벤트 진행'
        ],
        priority: 'high',
        category: '고객 관리',
        status: '검토 중',
        date: '2023-11-15'
      },
      {
        id: 'str-002',
        title: '비수기 매출 활성화 전략',
        description: '다이어리 데이터 분석 결과, 매월 중순에 매출이 하락하는 패턴이 발견되었습니다.',
        targetGroups: ['일반', '신규'],
        expectedEffect: '비수기 매출 15% 상승 및 신규 고객 유입 증가',
        suggestedActions: [
          '월 중순 평일 점심 할인 프로모션 진행',
          '소셜미디어 마케팅 강화',
          '기존 고객 추천 보상 프로그램 도입'
        ],
        priority: 'medium',
        category: '매출 향상',
        status: '검토 중',
        date: '2023-11-16'
      }
    ],
    insights: {
      salesTrend: '최근 3개월간 매출은 전년 동기 대비 5% 상승했으나, 주중 방문율이 감소하는 추세입니다.',
      customerBehavior: 'VIP 고객은 주로 주말에 방문하며, 가족 단위 고객이 증가하고 있습니다.',
      opportunities: '기념일/생일 마케팅과 단체 예약 프로모션의 효과가 높을 것으로 예상됩니다.',
      recommendations: '고객 데이터베이스를 활용한 개인화된 마케팅과 포인트 적립 시스템 강화가 필요합니다.'
    }
  }
}
*/
import api from '../api';

/**
 * 다이어리 메모와 고객 데이터를 기반으로 AI 마케팅 전략을 요청하는 함수
 * @param {Object} payload 전략 수립에 필요한 데이터
 * @param {Object} payload.diaryData 다이어리 메모 데이터
 * @param {string} payload.period 분석 기간 (month, quarter, halfYear)
 * @param {Array} payload.customerData 관련 고객 데이터
 * @param {Array} payload.scheduleData 관련 스케줄 데이터
 * @returns {Promise} AI가 제안한 마케팅 전략
 */
export const postAiStrategy = async (payload) => {
  try {
    const response = await api.post('/aiStrategy', { params: payload });
    return response.data;
  } catch (error) {
    console.error('AI 마케팅 전략 요청 실패:', error);
    throw error;
  }
};