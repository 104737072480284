import React, { useState, useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import { getFirestore, collection, doc, getDocs, getDoc } from 'firebase/firestore';
import styles from './StatisticsSection.module.css';

const StatisticsSection = () => {
  const [statistics, setStatistics] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchStatistics = async () => {
      try {
        setLoading(true);
        const auth = getAuth();
        const user = auth.currentUser;
        
        if (!user) {
          throw new Error('로그인이 필요합니다.');
        }

        // Firestore 초기화
        const db = getFirestore();
        
        // 최근 6개월 데이터를 위한 월 배열 생성
        let monthsArray = [];
        
        // 현재 날짜로부터 최근 6개월을 계산하는 가장 단순한 방법
        const today = new Date();
        const currentYear = today.getFullYear();
        const currentMonth = today.getMonth() + 1; // 1-12로 변환
                
        // 최근 6개월 계산 (현재 월 포함)
        for (let i = 0; i < 6; i++) {
          // 월 계산 (0이하가 되면 이전 연도로)
          let targetMonth = currentMonth - i;
          let targetYear = currentYear;
          
          if (targetMonth <= 0) {
            targetMonth += 12;
            targetYear -= 1;
          }
          
          const ym = `${targetYear}${String(targetMonth).padStart(2, '0')}`;
          monthsArray.push(ym);
        }

        // 각 월별 급여 데이터 가져오기
        const monthlyData = await Promise.all(
          monthsArray.map(async (ym) => {
            const payrollsRef = collection(db, 'payrolls', user.uid, ym);
            const payrollsSnapshot = await getDocs(payrollsRef);
            
            let totalLaborCost = 0;
            const employeeSalaries = [];
            
            payrollsSnapshot.forEach(doc => {
              const payrollData = doc.data();
              totalLaborCost += payrollData.finalPay || 0;
              
              employeeSalaries.push({
                name: payrollData.name,
                currentSalary: payrollData.finalPay || 0,
                previousSalary: payrollData.previousPay || 0
              });
            });

            // 다이어리에서 매출 및 지출 데이터 가져오기
            let totalRevenue = 0;
            let totalExpenses = 0;
            
            try {
              // 다이어리 데이터 가져오기 (문서에서 직접 데이터 가져오기)
              const diaryDocRef = doc(db, 'diary', user.uid);
              const diaryDocSnapshot = await getDoc(diaryDocRef);
              
              if (diaryDocSnapshot.exists()) {
                const diaryData = diaryDocSnapshot.data();
                
                // 해당 월(ym)의 데이터가 있는지 확인
                if (diaryData[ym]) {
                  const monthData = diaryData[ym];
                  
                  // 월 데이터의 각 날짜(일) 처리
                  Object.keys(monthData).forEach(day => {
                    const dayData = monthData[day];
                    
                    // memoList 처리
                    if (dayData.memoList) {
                      if (typeof dayData.memoList === 'object' && !Array.isArray(dayData.memoList)) {
                        // 객체 형태의 memoList
                        Object.values(dayData.memoList).forEach(memo => {
                          const salesAmount = memo.sales ? parseInt(memo.sales, 10) || 0 : 0;
                          const expenseAmount = memo.expense ? parseInt(memo.expense, 10) || 0 : 0;
                          
                          totalRevenue += salesAmount;
                          totalExpenses += expenseAmount;
                        });
                      } else if (Array.isArray(dayData.memoList)) {
                        // 배열 형태의 memoList
                        dayData.memoList.forEach(memo => {
                          const salesAmount = memo.sales ? parseInt(memo.sales, 10) || 0 : 0;
                          const expenseAmount = memo.expense ? parseInt(memo.expense, 10) || 0 : 0;
                          
                          totalRevenue += salesAmount;
                          totalExpenses += expenseAmount;
                        });
                      }
                    }
                  });
                } else {
                  console.log(`${ym} 월 데이터가 없습니다.`);
                }
              } else {
                console.log('다이어리 문서가 존재하지 않습니다.');
              }
            } catch (diaryErr) {
              console.error(`다이어리 데이터 가져오기 오류 (${ym}):`, diaryErr);
            }

            return {
              yearMonth: ym,
              totalLaborCost,
              employeeSalaries,
              totalRevenue,
              totalExpenses
            };
          })
        );

        // 월별 재무 데이터 구성
        const monthlyFinancials = monthlyData.map((data, index) => {
          const yearNum = parseInt(data.yearMonth.slice(0, 4));
          const monthNum = parseInt(data.yearMonth.slice(4));
          
          // 월 이름 직접 매핑
          const monthNames = ["1월", "2월", "3월", "4월", "5월", "6월", "7월", "8월", "9월", "10월", "11월", "12월"];
          const month = monthNames[monthNum - 1]; // 배열은 0부터 시작하므로 -1
                    
          const revenue = data.totalRevenue || 0;
          const laborCost = data.totalLaborCost || 0;
          const otherExpenses = data.totalExpenses || 0;
          
          return {
            month,
            year: yearNum,
            monthNum: monthNum,
            yearMonth: data.yearMonth,
            revenue,
            laborCost,
            otherExpenses,
            profit: revenue - laborCost - otherExpenses
          };
        });

        // 정확한 날짜 순서대로 정렬 (최근 날짜가 먼저 오도록)
        const sortedMonthlyFinancials = [...monthlyFinancials].sort((a, b) => {
          return b.yearMonth.localeCompare(a.yearMonth);
        });

        // 현재 월 데이터 (첫 번째 = 가장 최근 달)
        const currentMonthData = sortedMonthlyFinancials.length > 0 ? 
          { 
            ...monthlyData.find(d => d.yearMonth === sortedMonthlyFinancials[0].yearMonth) || monthlyData[0] 
          } : 
          monthlyData[0];
                
        const statisticsData = {
          businessSummary: {
            totalRevenue: sortedMonthlyFinancials[0]?.revenue || 0,
            monthlyGrowth: sortedMonthlyFinancials[1] && sortedMonthlyFinancials[1].revenue > 0 
              ? ((sortedMonthlyFinancials[0].revenue - sortedMonthlyFinancials[1].revenue) / sortedMonthlyFinancials[1].revenue * 100).toFixed(1)
              : 0,
            totalExpenses: (sortedMonthlyFinancials[0]?.laborCost || 0) + (sortedMonthlyFinancials[0]?.otherExpenses || 0),
            profitRate: sortedMonthlyFinancials[0]?.revenue > 0 
              ? ((sortedMonthlyFinancials[0].profit / sortedMonthlyFinancials[0].revenue) * 100).toFixed(1)
              : 0
          },
          monthlyFinancials: sortedMonthlyFinancials,
          employeeSalaries: currentMonthData.employeeSalaries
        };
        
        setStatistics(statisticsData);
      } catch (err) {
        console.error('통계 데이터 에러:', err);
        setError(err.message || '통계 데이터를 불러오는 중 오류가 발생했습니다.');
      } finally {
        setLoading(false);
      }
    };

    fetchStatistics();
    
    // 다이어리 데이터 업데이트 이벤트 리스너 추가 --- 차후 리덕스로 교체
    const handleDiaryUpdate = () => {
      console.log('다이어리 데이터 업데이트 감지, 통계 다시 로딩');
      fetchStatistics();
    };
    
    window.addEventListener('diaryDataUpdated', handleDiaryUpdate);
    
    // 컴포넌트 언마운트 시 이벤트 리스너 제거
    return () => {
      window.removeEventListener('diaryDataUpdated', handleDiaryUpdate);
    };
  }, []);

  if (loading) return <div className={styles.statsLoading}>통계 데이터를 불러오는 중...</div>;
  if (error) return <div className={styles.statsError}>{error}</div>;
  if (!statistics) return <div className={styles.statsEmpty}>통계 데이터가 없습니다.</div>;

  const { businessSummary, monthlyFinancials, employeeSalaries } = statistics;

  // 숫자 포맷팅 함수
  const formatNumber = (num) => {
    return new Intl.NumberFormat('ko-KR').format(num);
  };

  return (
    <div className={styles.statisticsContainer}>
      <div className={styles.statsHeader}>
        <h2>사업장 현황</h2>
        <span className={styles.statsDate}>최근 업데이트: {new Date().toLocaleDateString()}</span>
      </div>
      
      <div className={styles.statsGrid}>
        {/* 비즈니스 요약 */}
        <div className={`${styles.statsCard} ${styles.summaryCard}`}>
          <h3>비즈니스 요약</h3>
          <div className={styles.summaryGrid}>
            <div className={styles.summaryItem}>
              <span className={styles.summaryLabel}>이번달 매출</span>
              <span className={styles.summaryValue}>{formatNumber(businessSummary.totalRevenue)}원</span>
            </div>
            <div className={styles.summaryItem}>
              <span className={styles.summaryLabel}>매출 성장률</span>
              <span className={`${styles.summaryValue} ${styles.positive}`}>{businessSummary.monthlyGrowth}%</span>
            </div>
            <div className={styles.summaryItem}>
              <span className={styles.summaryLabel}>총 지출</span>
              <span className={styles.summaryValue}>{formatNumber(businessSummary.totalExpenses)}원</span>
            </div>
            <div className={styles.summaryItem}>
              <span className={styles.summaryLabel}>이익률</span>
              <span className={styles.summaryValue}>{businessSummary.profitRate}%</span>
            </div>
          </div>
        </div>
        
        {/* 월별 매출 현황 그래프 */}
        <div className={styles.statsCard}>
          <h3>월별 매출 현황</h3>
          <div className={styles.chartContainer}>
            <div className={styles.barChart}>
              {monthlyFinancials.map((item, index) => {
                const maxRevenue = Math.max(...monthlyFinancials.map(s => s.revenue || 0));
                const heightPercent = maxRevenue > 0 
                  ? Math.max(5, (item.revenue / maxRevenue) * 90) 
                  : 5;
                  
                return (
                  <div className={styles.barItem} key={index}>
                    <div 
                      className={styles.bar}
                      style={{ height: heightPercent + '%' }}
                    >
                      <span className={styles.barValue}>{(item.revenue / 1000000).toFixed(1)}M</span>
                    </div>
                    <span className={styles.barLabel}>{item.month}</span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        
        {/* 직원 급여 현황 */}
        <div className={styles.statsCard}>
          <h3>직원 급여 현황</h3>
          <div className={styles.salaryList}>
            <div className={styles.salaryTotal}>
              당월 급여 총액: <span className={styles.salaryTotalAmount}>
                {formatNumber(employeeSalaries.reduce((sum, emp) => sum + emp.currentSalary, 0))}원
              </span>
            </div>
            {employeeSalaries.map((employee, index) => (
              <div className={styles.salaryItem} key={index}>
                <div className={styles.salaryInfo}>
                  <span className={styles.employeeName}>{employee.name}</span>
                  <span className={styles.salaryAmount}>{formatNumber(employee.currentSalary)}원</span>
                </div>
                <div className={styles.salaryBarContainer}>
                  <div 
                    className={styles.salaryBar} 
                    style={{ 
                      width: '100%',
                      backgroundColor: '#4e73df'
                    }}
                  ></div>
                </div>
              </div>
            ))}
          </div>
        </div>
        
        {/* 월별 비용 분석 */}
        <div className={styles.statsCard}>
          <h3>월별 매출/비용 비율(인건비 제외)</h3>
          <div className={styles.expenseChart}>
            {monthlyFinancials.map((item, index) => (
              <div className={styles.expenseItem} key={index}>
                <div className={styles.expenseInfo}>
                  <span className={styles.expenseMonth}>{item.month}</span>
                  <div className={styles.expenseBars}>
                    <div 
                      className={`${styles.expenseBar} ${styles.laborCost}`}
                      style={{ width: `${(item.laborCost / (item.laborCost + item.otherExpenses)) * 100}%` }}
                    >
                      <span className={styles.expenseValue}>{(item.laborCost / 1000000).toFixed(1)}M</span>
                    </div>
                    <div 
                      className={`${styles.expenseBar} ${styles.otherCost}`}
                      style={{ width: `${(item.otherExpenses / (item.laborCost + item.otherExpenses)) * 100}%` }}
                    >
                      <span className={styles.expenseValue}>{(item.otherExpenses / 1000000).toFixed(1)}M</span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatisticsSection; 