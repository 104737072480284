module.exports = [
    {
     "이상": 770,
     "미만": 775,
     "1": 0,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": "#REF!"
    },
    {
     "이상": 775,
     "미만": 780,
     "1": 0,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 780,
     "미만": 785,
     "1": 0,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 785,
     "미만": 790,
     "1": 0,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 790,
     "미만": 795,
     "1": 0,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 795,
     "미만": 800,
     "1": 0,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 800,
     "미만": 805,
     "1": 0,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 805,
     "미만": 810,
     "1": 0,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 810,
     "미만": 815,
     "1": 0,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 815,
     "미만": 820,
     "1": 0,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 820,
     "미만": 825,
     "1": 0,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 825,
     "미만": 830,
     "1": 0,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 830,
     "미만": 835,
     "1": 0,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 835,
     "미만": 840,
     "1": 0,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 840,
     "미만": 845,
     "1": 0,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 845,
     "미만": 850,
     "1": 0,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 850,
     "미만": 855,
     "1": 0,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 855,
     "미만": 860,
     "1": 0,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 860,
     "미만": 865,
     "1": 0,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 865,
     "미만": 870,
     "1": 0,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 870,
     "미만": 875,
     "1": 0,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 875,
     "미만": 880,
     "1": 0,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 880,
     "미만": 885,
     "1": 0,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 885,
     "미만": 890,
     "1": 0,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 890,
     "미만": 895,
     "1": 0,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 895,
     "미만": 900,
     "1": 0,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 900,
     "미만": 905,
     "1": 0,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 905,
     "미만": 910,
     "1": 0,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 910,
     "미만": 915,
     "1": 0,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 915,
     "미만": 920,
     "1": 0,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 920,
     "미만": 925,
     "1": 0,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 925,
     "미만": 930,
     "1": 0,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 930,
     "미만": 935,
     "1": 0,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 935,
     "미만": 940,
     "1": 0,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 940,
     "미만": 945,
     "1": 0,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 945,
     "미만": 950,
     "1": 0,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 950,
     "미만": 955,
     "1": 0,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 955,
     "미만": 960,
     "1": 0,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 960,
     "미만": 965,
     "1": 0,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 965,
     "미만": 970,
     "1": 0,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 970,
     "미만": 975,
     "1": 0,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 975,
     "미만": 980,
     "1": 0,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 980,
     "미만": 985,
     "1": 0,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 985,
     "미만": 990,
     "1": 0,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 990,
     "미만": 995,
     "1": 0,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 995,
     "미만": 1000,
     "1": 0,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1000,
     "미만": 1005,
     "1": 0,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1005,
     "미만": 1010,
     "1": 0,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1010,
     "미만": 1015,
     "1": 0,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1015,
     "미만": 1020,
     "1": 0,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1020,
     "미만": 1025,
     "1": 0,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1025,
     "미만": 1030,
     "1": 0,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1030,
     "미만": 1035,
     "1": 0,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1035,
     "미만": 1040,
     "1": 0,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1040,
     "미만": 1045,
     "1": 0,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1045,
     "미만": 1050,
     "1": 0,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1050,
     "미만": 1055,
     "1": 0,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1055,
     "미만": 1060,
     "1": 0,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1060,
     "미만": 1065,
     "1": 1040,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1065,
     "미만": 1070,
     "1": 1110,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1070,
     "미만": 1075,
     "1": 1180,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1075,
     "미만": 1080,
     "1": 1250,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1080,
     "미만": 1085,
     "1": 1320,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1085,
     "미만": 1090,
     "1": 1390,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1090,
     "미만": 1095,
     "1": 1460,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1095,
     "미만": 1100,
     "1": 1530,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1100,
     "미만": 1105,
     "1": 1600,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1105,
     "미만": 1110,
     "1": 1670,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1110,
     "미만": 1115,
     "1": 1740,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1115,
     "미만": 1120,
     "1": 1810,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1120,
     "미만": 1125,
     "1": 1880,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1125,
     "미만": 1130,
     "1": 1950,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1130,
     "미만": 1135,
     "1": 2020,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1135,
     "미만": 1140,
     "1": 2090,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1140,
     "미만": 1145,
     "1": 2160,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1145,
     "미만": 1150,
     "1": 2230,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1150,
     "미만": 1155,
     "1": 2300,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1155,
     "미만": 1160,
     "1": 2370,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1160,
     "미만": 1165,
     "1": 2440,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1165,
     "미만": 1170,
     "1": 2500,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1170,
     "미만": 1175,
     "1": 2570,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1175,
     "미만": 1180,
     "1": 2640,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1180,
     "미만": 1185,
     "1": 2710,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1185,
     "미만": 1190,
     "1": 2780,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1190,
     "미만": 1195,
     "1": 2850,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1195,
     "미만": 1200,
     "1": 2920,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1200,
     "미만": 1205,
     "1": 2990,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1205,
     "미만": 1210,
     "1": 3060,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1210,
     "미만": 1215,
     "1": 3130,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1215,
     "미만": 1220,
     "1": 3200,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1220,
     "미만": 1225,
     "1": 3270,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1225,
     "미만": 1230,
     "1": 3340,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1230,
     "미만": 1235,
     "1": 3410,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1235,
     "미만": 1240,
     "1": 3480,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1240,
     "미만": 1245,
     "1": 3550,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1245,
     "미만": 1250,
     "1": 3620,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1250,
     "미만": 1255,
     "1": 3700,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1255,
     "미만": 1260,
     "1": 3810,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1260,
     "미만": 1265,
     "1": 3910,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1265,
     "미만": 1270,
     "1": 4010,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1270,
     "미만": 1275,
     "1": 4120,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1275,
     "미만": 1280,
     "1": 4220,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1280,
     "미만": 1285,
     "1": 4320,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1285,
     "미만": 1290,
     "1": 4430,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1290,
     "미만": 1295,
     "1": 4530,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1295,
     "미만": 1300,
     "1": 4630,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1300,
     "미만": 1305,
     "1": 4740,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1305,
     "미만": 1310,
     "1": 4840,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1310,
     "미만": 1315,
     "1": 4940,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1315,
     "미만": 1320,
     "1": 5050,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1320,
     "미만": 1325,
     "1": 5150,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1325,
     "미만": 1330,
     "1": 5250,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1330,
     "미만": 1335,
     "1": 5360,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1335,
     "미만": 1340,
     "1": 5460,
     "2": 0,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1340,
     "미만": 1345,
     "1": 5560,
     "2": 1060,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1345,
     "미만": 1350,
     "1": 5670,
     "2": 1170,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1350,
     "미만": 1355,
     "1": 5770,
     "2": 1270,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1355,
     "미만": 1360,
     "1": 5870,
     "2": 1370,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1360,
     "미만": 1365,
     "1": 5980,
     "2": 1480,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1365,
     "미만": 1370,
     "1": 6080,
     "2": 1580,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1370,
     "미만": 1375,
     "1": 6180,
     "2": 1680,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1375,
     "미만": 1380,
     "1": 6290,
     "2": 1790,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1380,
     "미만": 1385,
     "1": 6390,
     "2": 1890,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1385,
     "미만": 1390,
     "1": 6490,
     "2": 1990,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1390,
     "미만": 1395,
     "1": 6600,
     "2": 2100,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1395,
     "미만": 1400,
     "1": 6700,
     "2": 2200,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1400,
     "미만": 1405,
     "1": 6800,
     "2": 2300,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1405,
     "미만": 1410,
     "1": 6910,
     "2": 2410,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1410,
     "미만": 1415,
     "1": 7010,
     "2": 2510,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1415,
     "미만": 1420,
     "1": 7110,
     "2": 2610,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1420,
     "미만": 1425,
     "1": 7210,
     "2": 2710,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1425,
     "미만": 1430,
     "1": 7320,
     "2": 2820,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1430,
     "미만": 1435,
     "1": 7420,
     "2": 2920,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1435,
     "미만": 1440,
     "1": 7520,
     "2": 3020,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1440,
     "미만": 1445,
     "1": 7630,
     "2": 3130,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1445,
     "미만": 1450,
     "1": 7730,
     "2": 3230,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1450,
     "미만": 1455,
     "1": 7830,
     "2": 3330,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1455,
     "미만": 1460,
     "1": 7940,
     "2": 3440,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1460,
     "미만": 1465,
     "1": 8040,
     "2": 3540,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1465,
     "미만": 1470,
     "1": 8140,
     "2": 3640,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1470,
     "미만": 1475,
     "1": 8250,
     "2": 3750,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1475,
     "미만": 1480,
     "1": 8350,
     "2": 3850,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1480,
     "미만": 1485,
     "1": 8450,
     "2": 3950,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1485,
     "미만": 1490,
     "1": 8560,
     "2": 4060,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1490,
     "미만": 1495,
     "1": 8660,
     "2": 4160,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1495,
     "미만": 1500,
     "1": 8760,
     "2": 4260,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1500,
     "미만": 1510,
     "1": 8920,
     "2": 4420,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1510,
     "미만": 1520,
     "1": 9120,
     "2": 4620,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1520,
     "미만": 1530,
     "1": 9330,
     "2": 4830,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1530,
     "미만": 1540,
     "1": 9540,
     "2": 5040,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1540,
     "미만": 1550,
     "1": 9740,
     "2": 5240,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1550,
     "미만": 1560,
     "1": 9950,
     "2": 5450,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1560,
     "미만": 1570,
     "1": 10160,
     "2": 5660,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1570,
     "미만": 1580,
     "1": 10360,
     "2": 5860,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1580,
     "미만": 1590,
     "1": 10570,
     "2": 6070,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1590,
     "미만": 1600,
     "1": 10780,
     "2": 6280,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1600,
     "미만": 1610,
     "1": 10980,
     "2": 6480,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1610,
     "미만": 1620,
     "1": 11190,
     "2": 6690,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1620,
     "미만": 1630,
     "1": 11400,
     "2": 6900,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1630,
     "미만": 1640,
     "1": 11600,
     "2": 7100,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1640,
     "미만": 1650,
     "1": 11810,
     "2": 7310,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1650,
     "미만": 1660,
     "1": 12020,
     "2": 7520,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1660,
     "미만": 1670,
     "1": 12220,
     "2": 7720,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1670,
     "미만": 1680,
     "1": 12430,
     "2": 7930,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1680,
     "미만": 1690,
     "1": 12640,
     "2": 8140,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1690,
     "미만": 1700,
     "1": 12840,
     "2": 8340,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1700,
     "미만": 1710,
     "1": 13050,
     "2": 8550,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1710,
     "미만": 1720,
     "1": 13260,
     "2": 8760,
     "3": 0,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1720,
     "미만": 1730,
     "1": 13460,
     "2": 8960,
     "3": 1040,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1730,
     "미만": 1740,
     "1": 13670,
     "2": 9170,
     "3": 1240,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1740,
     "미만": 1750,
     "1": 13880,
     "2": 9380,
     "3": 1440,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1750,
     "미만": 1760,
     "1": 14080,
     "2": 9580,
     "3": 1640,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1760,
     "미만": 1770,
     "1": 14290,
     "2": 9790,
     "3": 1830,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1770,
     "미만": 1780,
     "1": 14500,
     "2": 10000,
     "3": 2030,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1780,
     "미만": 1790,
     "1": 14700,
     "2": 10200,
     "3": 2230,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1790,
     "미만": 1800,
     "1": 14910,
     "2": 10410,
     "3": 2430,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1800,
     "미만": 1810,
     "1": 15110,
     "2": 10610,
     "3": 2630,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1810,
     "미만": 1820,
     "1": 15320,
     "2": 10820,
     "3": 2830,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1820,
     "미만": 1830,
     "1": 15530,
     "2": 11030,
     "3": 3020,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1830,
     "미만": 1840,
     "1": 15730,
     "2": 11230,
     "3": 3220,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1840,
     "미만": 1850,
     "1": 15940,
     "2": 11440,
     "3": 3420,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1850,
     "미만": 1860,
     "1": 16150,
     "2": 11650,
     "3": 3620,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1860,
     "미만": 1870,
     "1": 16350,
     "2": 11850,
     "3": 3820,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1870,
     "미만": 1880,
     "1": 16560,
     "2": 12060,
     "3": 4020,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1880,
     "미만": 1890,
     "1": 16770,
     "2": 12270,
     "3": 4220,
     "4": 0,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1890,
     "미만": 1900,
     "1": 16970,
     "2": 12470,
     "3": 4410,
     "4": 1040,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1900,
     "미만": 1910,
     "1": 17180,
     "2": 12680,
     "3": 4610,
     "4": 1240,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1910,
     "미만": 1920,
     "1": 17390,
     "2": 12890,
     "3": 4810,
     "4": 1440,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1920,
     "미만": 1930,
     "1": 17590,
     "2": 13090,
     "3": 5010,
     "4": 1630,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1930,
     "미만": 1940,
     "1": 17800,
     "2": 13300,
     "3": 5210,
     "4": 1830,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1940,
     "미만": 1950,
     "1": 18010,
     "2": 13510,
     "3": 5410,
     "4": 2030,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1950,
     "미만": 1960,
     "1": 18210,
     "2": 13710,
     "3": 5600,
     "4": 2230,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1960,
     "미만": 1970,
     "1": 18420,
     "2": 13920,
     "3": 5800,
     "4": 2430,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1970,
     "미만": 1980,
     "1": 18630,
     "2": 14130,
     "3": 6000,
     "4": 2630,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1980,
     "미만": 1990,
     "1": 18880,
     "2": 14330,
     "3": 6200,
     "4": 2820,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 1990,
     "미만": 2000,
     "1": 19200,
     "2": 14540,
     "3": 6400,
     "4": 3020,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 2000,
     "미만": 2010,
     "1": 19520,
     "2": 14750,
     "3": 6600,
     "4": 3220,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 2010,
     "미만": 2020,
     "1": 19850,
     "2": 14950,
     "3": 6800,
     "4": 3420,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 2020,
     "미만": 2030,
     "1": 20170,
     "2": 15160,
     "3": 6990,
     "4": 3620,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 2030,
     "미만": 2040,
     "1": 20490,
     "2": 15370,
     "3": 7190,
     "4": 3820,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 2040,
     "미만": 2050,
     "1": 20810,
     "2": 15570,
     "3": 7390,
     "4": 4020,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 2050,
     "미만": 2060,
     "1": 21130,
     "2": 15780,
     "3": 7590,
     "4": 4210,
     "5": 0,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 2060,
     "미만": 2070,
     "1": 21450,
     "2": 15990,
     "3": 7790,
     "4": 4410,
     "5": 1040,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 2070,
     "미만": 2080,
     "1": 21770,
     "2": 16190,
     "3": 7990,
     "4": 4610,
     "5": 1240,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 2080,
     "미만": 2090,
     "1": 22090,
     "2": 16400,
     "3": 8180,
     "4": 4810,
     "5": 1430,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 2090,
     "미만": 2100,
     "1": 22420,
     "2": 16600,
     "3": 8380,
     "4": 5010,
     "5": 1630,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 2100,
     "미만": 2110,
     "1": 22740,
     "2": 16810,
     "3": 8580,
     "4": 5210,
     "5": 1830,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 2110,
     "미만": 2120,
     "1": 23060,
     "2": 17020,
     "3": 8780,
     "4": 5400,
     "5": 2030,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 2120,
     "미만": 2130,
     "1": 23380,
     "2": 17220,
     "3": 8980,
     "4": 5600,
     "5": 2230,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 2130,
     "미만": 2140,
     "1": 23700,
     "2": 17430,
     "3": 9180,
     "4": 5800,
     "5": 2430,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 2140,
     "미만": 2150,
     "1": 24020,
     "2": 17640,
     "3": 9380,
     "4": 6000,
     "5": 2630,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 2150,
     "미만": 2160,
     "1": 24340,
     "2": 17840,
     "3": 9570,
     "4": 6200,
     "5": 2820,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 2160,
     "미만": 2170,
     "1": 24660,
     "2": 18050,
     "3": 9770,
     "4": 6400,
     "5": 3020,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 2170,
     "미만": 2180,
     "1": 24990,
     "2": 18260,
     "3": 9970,
     "4": 6600,
     "5": 3220,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 2180,
     "미만": 2190,
     "1": 25310,
     "2": 18460,
     "3": 10170,
     "4": 6790,
     "5": 3420,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 2190,
     "미만": 2200,
     "1": 25630,
     "2": 18670,
     "3": 10370,
     "4": 6990,
     "5": 3620,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 2200,
     "미만": 2210,
     "1": 25950,
     "2": 18950,
     "3": 10570,
     "4": 7190,
     "5": 3820,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 2210,
     "미만": 2220,
     "1": 26270,
     "2": 19270,
     "3": 10760,
     "4": 7390,
     "5": 4010,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 2220,
     "미만": 2230,
     "1": 26590,
     "2": 19590,
     "3": 10960,
     "4": 7590,
     "5": 4210,
     "6": 0,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 2230,
     "미만": 2240,
     "1": 26910,
     "2": 19910,
     "3": 11160,
     "4": 7790,
     "5": 4410,
     "6": 1040,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 2240,
     "미만": 2250,
     "1": 27240,
     "2": 20240,
     "3": 11360,
     "4": 7980,
     "5": 4610,
     "6": 1230,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 2250,
     "미만": 2260,
     "1": 27560,
     "2": 20560,
     "3": 11560,
     "4": 8180,
     "5": 4810,
     "6": 1430,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 2260,
     "미만": 2270,
     "1": 27880,
     "2": 20880,
     "3": 11760,
     "4": 8380,
     "5": 5010,
     "6": 1630,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 2270,
     "미만": 2280,
     "1": 28200,
     "2": 21200,
     "3": 11960,
     "4": 8580,
     "5": 5210,
     "6": 1830,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 2280,
     "미만": 2290,
     "1": 28520,
     "2": 21520,
     "3": 12150,
     "4": 8780,
     "5": 5400,
     "6": 2030,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 2290,
     "미만": 2300,
     "1": 28840,
     "2": 21840,
     "3": 12350,
     "4": 8980,
     "5": 5600,
     "6": 2230,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 2300,
     "미만": 2310,
     "1": 29160,
     "2": 22160,
     "3": 12550,
     "4": 9180,
     "5": 5800,
     "6": 2430,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 2310,
     "미만": 2320,
     "1": 29480,
     "2": 22480,
     "3": 12750,
     "4": 9370,
     "5": 6000,
     "6": 2620,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 2320,
     "미만": 2330,
     "1": 29810,
     "2": 22810,
     "3": 12950,
     "4": 9570,
     "5": 6200,
     "6": 2820,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 2330,
     "미만": 2340,
     "1": 30130,
     "2": 23130,
     "3": 13150,
     "4": 9770,
     "5": 6400,
     "6": 3020,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 2340,
     "미만": 2350,
     "1": 30450,
     "2": 23450,
     "3": 13340,
     "4": 9970,
     "5": 6590,
     "6": 3220,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 2350,
     "미만": 2360,
     "1": 30770,
     "2": 23770,
     "3": 13540,
     "4": 10170,
     "5": 6790,
     "6": 3420,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 2360,
     "미만": 2370,
     "1": 31090,
     "2": 24090,
     "3": 13740,
     "4": 10370,
     "5": 6990,
     "6": 3620,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 2370,
     "미만": 2380,
     "1": 31410,
     "2": 24410,
     "3": 13940,
     "4": 10560,
     "5": 7190,
     "6": 3810,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 2380,
     "미만": 2390,
     "1": 31730,
     "2": 24730,
     "3": 14140,
     "4": 10760,
     "5": 7390,
     "6": 4010,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 2390,
     "미만": 2400,
     "1": 32050,
     "2": 25050,
     "3": 14340,
     "4": 10960,
     "5": 7590,
     "6": 4210,
     "7": 0,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 2400,
     "미만": 2410,
     "1": 32380,
     "2": 25380,
     "3": 14530,
     "4": 11160,
     "5": 7780,
     "6": 4410,
     "7": 1030,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 2410,
     "미만": 2420,
     "1": 32700,
     "2": 25700,
     "3": 14730,
     "4": 11360,
     "5": 7980,
     "6": 4610,
     "7": 1230,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 2420,
     "미만": 2430,
     "1": 33020,
     "2": 26020,
     "3": 14930,
     "4": 11560,
     "5": 8180,
     "6": 4810,
     "7": 1430,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 2430,
     "미만": 2440,
     "1": 33340,
     "2": 26340,
     "3": 15130,
     "4": 11760,
     "5": 8380,
     "6": 5010,
     "7": 1630,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 2440,
     "미만": 2450,
     "1": 33660,
     "2": 26660,
     "3": 15330,
     "4": 11950,
     "5": 8580,
     "6": 5200,
     "7": 1830,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 2450,
     "미만": 2460,
     "1": 33980,
     "2": 26980,
     "3": 15530,
     "4": 12150,
     "5": 8780,
     "6": 5400,
     "7": 2030,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 2460,
     "미만": 2470,
     "1": 34300,
     "2": 27300,
     "3": 15730,
     "4": 12350,
     "5": 8980,
     "6": 5600,
     "7": 2230,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 2470,
     "미만": 2480,
     "1": 34630,
     "2": 27630,
     "3": 15920,
     "4": 12550,
     "5": 9170,
     "6": 5800,
     "7": 2420,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 2480,
     "미만": 2490,
     "1": 34950,
     "2": 27950,
     "3": 16120,
     "4": 12750,
     "5": 9370,
     "6": 6000,
     "7": 2620,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 2490,
     "미만": 2500,
     "1": 35270,
     "2": 28270,
     "3": 16320,
     "4": 12950,
     "5": 9570,
     "6": 6200,
     "7": 2820,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 2500,
     "미만": 2510,
     "1": 35600,
     "2": 28600,
     "3": 16530,
     "4": 13150,
     "5": 9780,
     "6": 6400,
     "7": 3030,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 2510,
     "미만": 2520,
     "1": 35940,
     "2": 28940,
     "3": 16740,
     "4": 13360,
     "5": 9990,
     "6": 6610,
     "7": 3240,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 2520,
     "미만": 2530,
     "1": 36280,
     "2": 29280,
     "3": 16950,
     "4": 13580,
     "5": 10200,
     "6": 6830,
     "7": 3450,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 2530,
     "미만": 2540,
     "1": 36630,
     "2": 29630,
     "3": 17160,
     "4": 13790,
     "5": 10410,
     "6": 7040,
     "7": 3660,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 2540,
     "미만": 2550,
     "1": 36970,
     "2": 29970,
     "3": 17370,
     "4": 14000,
     "5": 10620,
     "6": 7250,
     "7": 3870,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 2550,
     "미만": 2560,
     "1": 37310,
     "2": 30310,
     "3": 17590,
     "4": 14210,
     "5": 10840,
     "6": 7460,
     "7": 4090,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 2560,
     "미만": 2570,
     "1": 37650,
     "2": 30650,
     "3": 17800,
     "4": 14420,
     "5": 11050,
     "6": 7670,
     "7": 4300,
     "8": 0,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 2570,
     "미만": 2580,
     "1": 38000,
     "2": 31000,
     "3": 18010,
     "4": 14630,
     "5": 11260,
     "6": 7880,
     "7": 4510,
     "8": 1130,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 2580,
     "미만": 2590,
     "1": 38340,
     "2": 31340,
     "3": 18220,
     "4": 14850,
     "5": 11470,
     "6": 8100,
     "7": 4720,
     "8": 1350,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 2590,
     "미만": 2600,
     "1": 38830,
     "2": 31680,
     "3": 18430,
     "4": 15060,
     "5": 11680,
     "6": 8310,
     "7": 4930,
     "8": 1560,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 2600,
     "미만": 2610,
     "1": 39690,
     "2": 32020,
     "3": 18650,
     "4": 15270,
     "5": 11900,
     "6": 8520,
     "7": 5150,
     "8": 1770,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 2610,
     "미만": 2620,
     "1": 40540,
     "2": 32360,
     "3": 18920,
     "4": 15480,
     "5": 12110,
     "6": 8730,
     "7": 5360,
     "8": 1980,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 2620,
     "미만": 2630,
     "1": 41400,
     "2": 32710,
     "3": 19250,
     "4": 15690,
     "5": 12320,
     "6": 8940,
     "7": 5570,
     "8": 2190,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 2630,
     "미만": 2640,
     "1": 42260,
     "2": 33050,
     "3": 19580,
     "4": 15910,
     "5": 12530,
     "6": 9160,
     "7": 5780,
     "8": 2410,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 2640,
     "미만": 2650,
     "1": 43110,
     "2": 33390,
     "3": 19910,
     "4": 16120,
     "5": 12740,
     "6": 9370,
     "7": 5990,
     "8": 2620,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 2650,
     "미만": 2660,
     "1": 43970,
     "2": 33730,
     "3": 20240,
     "4": 16330,
     "5": 12960,
     "6": 9580,
     "7": 6210,
     "8": 2830,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 2660,
     "미만": 2670,
     "1": 44820,
     "2": 34080,
     "3": 20570,
     "4": 16540,
     "5": 13170,
     "6": 9790,
     "7": 6420,
     "8": 3040,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 2670,
     "미만": 2680,
     "1": 45680,
     "2": 34420,
     "3": 20900,
     "4": 16750,
     "5": 13380,
     "6": 10000,
     "7": 6630,
     "8": 3250,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 2680,
     "미만": 2690,
     "1": 46540,
     "2": 34760,
     "3": 21230,
     "4": 16970,
     "5": 13590,
     "6": 10220,
     "7": 6840,
     "8": 3470,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 2690,
     "미만": 2700,
     "1": 47390,
     "2": 35100,
     "3": 21560,
     "4": 17180,
     "5": 13800,
     "6": 10430,
     "7": 7050,
     "8": 3680,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 2700,
     "미만": 2710,
     "1": 48250,
     "2": 35450,
     "3": 21890,
     "4": 17390,
     "5": 14020,
     "6": 10640,
     "7": 7270,
     "8": 3890,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 2710,
     "미만": 2720,
     "1": 49100,
     "2": 35790,
     "3": 22220,
     "4": 17600,
     "5": 14230,
     "6": 10850,
     "7": 7480,
     "8": 4100,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 2720,
     "미만": 2730,
     "1": 49960,
     "2": 36130,
     "3": 22550,
     "4": 17810,
     "5": 14440,
     "6": 11060,
     "7": 7690,
     "8": 4310,
     "9": 0,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 2730,
     "미만": 2740,
     "1": 50810,
     "2": 36470,
     "3": 22880,
     "4": 18030,
     "5": 14650,
     "6": 11280,
     "7": 7900,
     "8": 4530,
     "9": 1150,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 2740,
     "미만": 2750,
     "1": 51670,
     "2": 36810,
     "3": 23210,
     "4": 18240,
     "5": 14860,
     "6": 11490,
     "7": 8110,
     "8": 4740,
     "9": 1360,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 2750,
     "미만": 2760,
     "1": 52530,
     "2": 37160,
     "3": 23540,
     "4": 18450,
     "5": 15070,
     "6": 11700,
     "7": 8320,
     "8": 4950,
     "9": 1570,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 2760,
     "미만": 2770,
     "1": 53380,
     "2": 37500,
     "3": 23870,
     "4": 18660,
     "5": 15290,
     "6": 11910,
     "7": 8540,
     "8": 5160,
     "9": 1790,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 2770,
     "미만": 2780,
     "1": 54240,
     "2": 37840,
     "3": 24200,
     "4": 18950,
     "5": 15500,
     "6": 12120,
     "7": 8750,
     "8": 5370,
     "9": 2000,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 2780,
     "미만": 2790,
     "1": 55090,
     "2": 38180,
     "3": 24520,
     "4": 19270,
     "5": 15710,
     "6": 12340,
     "7": 8960,
     "8": 5590,
     "9": 2210,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 2790,
     "미만": 2800,
     "1": 55950,
     "2": 38530,
     "3": 24850,
     "4": 19600,
     "5": 15920,
     "6": 12550,
     "7": 9170,
     "8": 5800,
     "9": 2420,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 2800,
     "미만": 2810,
     "1": 56800,
     "2": 39300,
     "3": 25180,
     "4": 19930,
     "5": 16130,
     "6": 12760,
     "7": 9380,
     "8": 6010,
     "9": 2630,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 2810,
     "미만": 2820,
     "1": 57660,
     "2": 40160,
     "3": 25510,
     "4": 20260,
     "5": 16350,
     "6": 12970,
     "7": 9600,
     "8": 6220,
     "9": 2850,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 2820,
     "미만": 2830,
     "1": 58520,
     "2": 41020,
     "3": 25840,
     "4": 20590,
     "5": 16560,
     "6": 13180,
     "7": 9810,
     "8": 6430,
     "9": 3060,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 2830,
     "미만": 2840,
     "1": 59370,
     "2": 41870,
     "3": 26170,
     "4": 20920,
     "5": 16770,
     "6": 13400,
     "7": 10020,
     "8": 6650,
     "9": 3270,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 2840,
     "미만": 2850,
     "1": 60230,
     "2": 42730,
     "3": 26500,
     "4": 21250,
     "5": 16980,
     "6": 13610,
     "7": 10230,
     "8": 6860,
     "9": 3480,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 2850,
     "미만": 2860,
     "1": 61080,
     "2": 43580,
     "3": 26830,
     "4": 21580,
     "5": 17190,
     "6": 13820,
     "7": 10440,
     "8": 7070,
     "9": 3690,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 2860,
     "미만": 2870,
     "1": 61940,
     "2": 44440,
     "3": 27160,
     "4": 21910,
     "5": 17410,
     "6": 14030,
     "7": 10660,
     "8": 7280,
     "9": 3910,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 2870,
     "미만": 2880,
     "1": 62790,
     "2": 45290,
     "3": 27490,
     "4": 22240,
     "5": 17620,
     "6": 14240,
     "7": 10870,
     "8": 7490,
     "9": 4120,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 2880,
     "미만": 2890,
     "1": 63650,
     "2": 46150,
     "3": 27820,
     "4": 22570,
     "5": 17830,
     "6": 14460,
     "7": 11080,
     "8": 7710,
     "9": 4330,
     "10": 0,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 2890,
     "미만": 2900,
     "1": 64510,
     "2": 47010,
     "3": 28150,
     "4": 22900,
     "5": 18040,
     "6": 14670,
     "7": 11290,
     "8": 7920,
     "9": 4540,
     "10": 1170,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 2900,
     "미만": 2910,
     "1": 65360,
     "2": 47860,
     "3": 28480,
     "4": 23230,
     "5": 18250,
     "6": 14880,
     "7": 11500,
     "8": 8130,
     "9": 4750,
     "10": 1380,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 2910,
     "미만": 2920,
     "1": 66220,
     "2": 48720,
     "3": 28810,
     "4": 23560,
     "5": 18470,
     "6": 15090,
     "7": 11720,
     "8": 8340,
     "9": 4970,
     "10": 1590,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 2920,
     "미만": 2930,
     "1": 67070,
     "2": 49570,
     "3": 29140,
     "4": 23890,
     "5": 18680,
     "6": 15300,
     "7": 11930,
     "8": 8550,
     "9": 5180,
     "10": 1800,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 2930,
     "미만": 2940,
     "1": 67930,
     "2": 50430,
     "3": 29470,
     "4": 24220,
     "5": 18970,
     "6": 15510,
     "7": 12140,
     "8": 8760,
     "9": 5390,
     "10": 2010,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 2940,
     "미만": 2950,
     "1": 68780,
     "2": 51280,
     "3": 29800,
     "4": 24550,
     "5": 19300,
     "6": 15730,
     "7": 12350,
     "8": 8980,
     "9": 5600,
     "10": 2230,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 2950,
     "미만": 2960,
     "1": 69640,
     "2": 52140,
     "3": 30130,
     "4": 24880,
     "5": 19630,
     "6": 15940,
     "7": 12560,
     "8": 9190,
     "9": 5810,
     "10": 2440,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 2960,
     "미만": 2970,
     "1": 70500,
     "2": 53000,
     "3": 30460,
     "4": 25210,
     "5": 19960,
     "6": 16150,
     "7": 12780,
     "8": 9400,
     "9": 6030,
     "10": 2650,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 2970,
     "미만": 2980,
     "1": 71350,
     "2": 53850,
     "3": 30790,
     "4": 25540,
     "5": 20290,
     "6": 16360,
     "7": 12990,
     "8": 9610,
     "9": 6240,
     "10": 2860,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 2980,
     "미만": 2990,
     "1": 72210,
     "2": 54710,
     "3": 31120,
     "4": 25870,
     "5": 20620,
     "6": 16570,
     "7": 13200,
     "8": 9820,
     "9": 6450,
     "10": 3070,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 2990,
     "미만": 3000,
     "1": 73060,
     "2": 55560,
     "3": 31450,
     "4": 26200,
     "5": 20950,
     "6": 16790,
     "7": 13410,
     "8": 10040,
     "9": 6660,
     "10": 3290,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 3000,
     "미만": 3020,
     "1": 74350,
     "2": 56850,
     "3": 31940,
     "4": 26690,
     "5": 21440,
     "6": 17100,
     "7": 13730,
     "8": 10350,
     "9": 6980,
     "10": 3600,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 3020,
     "미만": 3040,
     "1": 76060,
     "2": 58560,
     "3": 32600,
     "4": 27350,
     "5": 22100,
     "6": 17530,
     "7": 14150,
     "8": 10780,
     "9": 7400,
     "10": 4030,
     "11": 0,
     "Column14": 0
    },
    {
     "이상": 3040,
     "미만": 3060,
     "1": 77770,
     "2": 60270,
     "3": 33260,
     "4": 28010,
     "5": 22760,
     "6": 17950,
     "7": 14580,
     "8": 11200,
     "9": 7830,
     "10": 4450,
     "11": 1080,
     "Column14": 0
    },
    {
     "이상": 3060,
     "미만": 3080,
     "1": 79480,
     "2": 61980,
     "3": 33920,
     "4": 28670,
     "5": 23420,
     "6": 18380,
     "7": 15000,
     "8": 11630,
     "9": 8250,
     "10": 4880,
     "11": 1500,
     "Column14": 0
    },
    {
     "이상": 3080,
     "미만": 3100,
     "1": 81190,
     "2": 63690,
     "3": 34580,
     "4": 29330,
     "5": 24080,
     "6": 18830,
     "7": 15430,
     "8": 12050,
     "9": 8680,
     "10": 5300,
     "11": 1930,
     "Column14": 0
    },
    {
     "이상": 3100,
     "미만": 3120,
     "1": 82900,
     "2": 65400,
     "3": 35240,
     "4": 29990,
     "5": 24740,
     "6": 19490,
     "7": 15850,
     "8": 12470,
     "9": 9100,
     "10": 5720,
     "11": 2350,
     "Column14": 0
    },
    {
     "이상": 3120,
     "미만": 3140,
     "1": 84620,
     "2": 67120,
     "3": 35900,
     "4": 30650,
     "5": 25400,
     "6": 20150,
     "7": 16270,
     "8": 12900,
     "9": 9520,
     "10": 6150,
     "11": 2770,
     "Column14": 0
    },
    {
     "이상": 3140,
     "미만": 3160,
     "1": 86330,
     "2": 68830,
     "3": 36560,
     "4": 31310,
     "5": 26060,
     "6": 20810,
     "7": 16700,
     "8": 13320,
     "9": 9950,
     "10": 6570,
     "11": 3200,
     "Column14": 0
    },
    {
     "이상": 3160,
     "미만": 3180,
     "1": 88040,
     "2": 70540,
     "3": 37220,
     "4": 31970,
     "5": 26720,
     "6": 21470,
     "7": 17120,
     "8": 13750,
     "9": 10370,
     "10": 7000,
     "11": 3620,
     "Column14": 1370
    },
    {
     "이상": 3180,
     "미만": 3200,
     "1": 89750,
     "2": 72250,
     "3": 37880,
     "4": 32630,
     "5": 27380,
     "6": 22130,
     "7": 17540,
     "8": 14170,
     "9": 10790,
     "10": 7420,
     "11": 4040,
     "Column14": 1790
    },
    {
     "이상": 3200,
     "미만": 3220,
     "1": 91460,
     "2": 73960,
     "3": 38540,
     "4": 33290,
     "5": 28040,
     "6": 22790,
     "7": 17970,
     "8": 14590,
     "9": 11220,
     "10": 7840,
     "11": 4470,
     "Column14": 2220
    },
    {
     "이상": 3220,
     "미만": 3240,
     "1": 93170,
     "2": 75670,
     "3": 40120,
     "4": 33950,
     "5": 28700,
     "6": 23450,
     "7": 18390,
     "8": 15020,
     "9": 11640,
     "10": 8270,
     "11": 4890,
     "Column14": 2640
    },
    {
     "이상": 3240,
     "미만": 3260,
     "1": 95430,
     "2": 77380,
     "3": 41770,
     "4": 34610,
     "5": 29360,
     "6": 24110,
     "7": 18860,
     "8": 15440,
     "9": 12070,
     "10": 8690,
     "11": 5320,
     "Column14": 3070
    },
    {
     "이상": 3260,
     "미만": 3280,
     "1": 97880,
     "2": 79100,
     "3": 43420,
     "4": 35270,
     "5": 30020,
     "6": 24770,
     "7": 19520,
     "8": 15870,
     "9": 12490,
     "10": 9120,
     "11": 5740,
     "Column14": 3490
    },
    {
     "이상": 3280,
     "미만": 3300,
     "1": 100320,
     "2": 80810,
     "3": 45070,
     "4": 35920,
     "5": 30670,
     "6": 25420,
     "7": 20170,
     "8": 16290,
     "9": 12910,
     "10": 9540,
     "11": 6160,
     "Column14": 3910
    },
    {
     "이상": 3300,
     "미만": 3320,
     "1": 102770,
     "2": 82520,
     "3": 46720,
     "4": 36580,
     "5": 31330,
     "6": 26080,
     "7": 20830,
     "8": 16710,
     "9": 13340,
     "10": 9960,
     "11": 6590,
     "Column14": 4340
    },
    {
     "이상": 3320,
     "미만": 3340,
     "1": 105210,
     "2": 84230,
     "3": 48370,
     "4": 37240,
     "5": 31990,
     "6": 26740,
     "7": 21490,
     "8": 17140,
     "9": 13760,
     "10": 10390,
     "11": 7010,
     "Column14": 4760
    },
    {
     "이상": 3340,
     "미만": 3360,
     "1": 107660,
     "2": 85940,
     "3": 49940,
     "4": 37870,
     "5": 32620,
     "6": 27370,
     "7": 22120,
     "8": 17540,
     "9": 14170,
     "10": 10790,
     "11": 7420,
     "Column14": 5170
    },
    {
     "이상": 3360,
     "미만": 3380,
     "1": 110100,
     "2": 87650,
     "3": 51510,
     "4": 38500,
     "5": 33250,
     "6": 28000,
     "7": 22750,
     "8": 17950,
     "9": 14570,
     "10": 11200,
     "11": 7820,
     "Column14": 5570
    },
    {
     "이상": 3380,
     "미만": 3400,
     "1": 112550,
     "2": 89370,
     "3": 53070,
     "4": 39950,
     "5": 33880,
     "6": 28630,
     "7": 23380,
     "8": 18350,
     "9": 14970,
     "10": 11600,
     "11": 8220,
     "Column14": 5970
    },
    {
     "이상": 3400,
     "미만": 3420,
     "1": 114990,
     "2": 91080,
     "3": 54640,
     "4": 41510,
     "5": 34500,
     "6": 29250,
     "7": 24000,
     "8": 18750,
     "9": 15370,
     "10": 12000,
     "11": 8620,
     "Column14": 6370
    },
    {
     "이상": 3420,
     "미만": 3440,
     "1": 117440,
     "2": 92790,
     "3": 56200,
     "4": 43080,
     "5": 35130,
     "6": 29880,
     "7": 24630,
     "8": 19380,
     "9": 15780,
     "10": 12400,
     "11": 9030,
     "Column14": 6780
    },
    {
     "이상": 3440,
     "미만": 3460,
     "1": 119880,
     "2": 94880,
     "3": 57770,
     "4": 44640,
     "5": 35750,
     "6": 30500,
     "7": 25250,
     "8": 20000,
     "9": 16180,
     "10": 12800,
     "11": 9430,
     "Column14": 7180
    },
    {
     "이상": 3460,
     "미만": 3480,
     "1": 122330,
     "2": 97330,
     "3": 59330,
     "4": 46210,
     "5": 36380,
     "6": 31130,
     "7": 25880,
     "8": 20630,
     "9": 16580,
     "10": 13210,
     "11": 9830,
     "Column14": 7580
    },
    {
     "이상": 3480,
     "미만": 3500,
     "1": 124770,
     "2": 99770,
     "3": 60900,
     "4": 47770,
     "5": 37010,
     "6": 31760,
     "7": 26510,
     "8": 21260,
     "9": 16980,
     "10": 13610,
     "11": 10230,
     "Column14": 7980
    },
    {
     "이상": 3500,
     "미만": 3520,
     "1": 127220,
     "2": 102220,
     "3": 62460,
     "4": 49340,
     "5": 37630,
     "6": 32380,
     "7": 27130,
     "8": 21880,
     "9": 17390,
     "10": 14010,
     "11": 10640,
     "Column14": 8390
    },
    {
     "이상": 3520,
     "미만": 3540,
     "1": 129660,
     "2": 104660,
     "3": 64030,
     "4": 50900,
     "5": 38260,
     "6": 33010,
     "7": 27760,
     "8": 22510,
     "9": 17790,
     "10": 14410,
     "11": 11040,
     "Column14": 8790
    },
    {
     "이상": 3540,
     "미만": 3560,
     "1": 132110,
     "2": 107110,
     "3": 65590,
     "4": 52460,
     "5": 39340,
     "6": 33630,
     "7": 28380,
     "8": 23130,
     "9": 18190,
     "10": 14820,
     "11": 11440,
     "Column14": 9190
    },
    {
     "이상": 3560,
     "미만": 3580,
     "1": 134550,
     "2": 109550,
     "3": 67150,
     "4": 54030,
     "5": 40900,
     "6": 34260,
     "7": 29010,
     "8": 23760,
     "9": 18590,
     "10": 15220,
     "11": 11840,
     "Column14": 9590
    },
    {
     "이상": 3580,
     "미만": 3600,
     "1": 137000,
     "2": 112000,
     "3": 68720,
     "4": 55590,
     "5": 42470,
     "6": 34880,
     "7": 29630,
     "8": 24380,
     "9": 19130,
     "10": 15620,
     "11": 12250,
     "Column14": 10000
    },
    {
     "이상": 3600,
     "미만": 3620,
     "1": 139440,
     "2": 114440,
     "3": 70280,
     "4": 57160,
     "5": 44030,
     "6": 35510,
     "7": 30260,
     "8": 25010,
     "9": 19760,
     "10": 16020,
     "11": 12650,
     "Column14": 10400
    },
    {
     "이상": 3620,
     "미만": 3640,
     "1": 141890,
     "2": 116890,
     "3": 71850,
     "4": 58720,
     "5": 45600,
     "6": 36140,
     "7": 30890,
     "8": 25640,
     "9": 20390,
     "10": 16420,
     "11": 13050,
     "Column14": 10800
    },
    {
     "이상": 3640,
     "미만": 3660,
     "1": 144330,
     "2": 119330,
     "3": 73410,
     "4": 60290,
     "5": 47160,
     "6": 36760,
     "7": 31510,
     "8": 26260,
     "9": 21010,
     "10": 16830,
     "11": 13450,
     "Column14": 11200
    },
    {
     "이상": 3660,
     "미만": 3680,
     "1": 146780,
     "2": 121780,
     "3": 74980,
     "4": 61850,
     "5": 48730,
     "6": 37390,
     "7": 32140,
     "8": 26890,
     "9": 21640,
     "10": 17230,
     "11": 13850,
     "Column14": 11600
    },
    {
     "이상": 3680,
     "미만": 3700,
     "1": 149220,
     "2": 124220,
     "3": 76540,
     "4": 63420,
     "5": 50290,
     "6": 38010,
     "7": 32760,
     "8": 27510,
     "9": 22260,
     "10": 17630,
     "11": 14260,
     "Column14": 12010
    },
    {
     "이상": 3700,
     "미만": 3720,
     "1": 151670,
     "2": 126670,
     "3": 78110,
     "4": 64980,
     "5": 51860,
     "6": 38730,
     "7": 33390,
     "8": 28140,
     "9": 22890,
     "10": 18030,
     "11": 14660,
     "Column14": 12410
    },
    {
     "이상": 3720,
     "미만": 3740,
     "1": 154110,
     "2": 129110,
     "3": 79670,
     "4": 66550,
     "5": 53420,
     "6": 40300,
     "7": 34020,
     "8": 28770,
     "9": 23520,
     "10": 18440,
     "11": 15060,
     "Column14": 12810
    },
    {
     "이상": 3740,
     "미만": 3760,
     "1": 156560,
     "2": 131560,
     "3": 81230,
     "4": 68110,
     "5": 54980,
     "6": 41860,
     "7": 34640,
     "8": 29390,
     "9": 24140,
     "10": 18890,
     "11": 15460,
     "Column14": 13210
    },
    {
     "이상": 3760,
     "미만": 3780,
     "1": 163920,
     "2": 136090,
     "3": 84260,
     "4": 71130,
     "5": 58010,
     "6": 44880,
     "7": 35850,
     "8": 30600,
     "9": 25350,
     "10": 20100,
     "11": 16240,
     "Column14": 13990
    },
    {
     "이상": 3780,
     "미만": 3800,
     "1": 166590,
     "2": 138740,
     "3": 85970,
     "4": 72850,
     "5": 59720,
     "6": 46600,
     "7": 36540,
     "8": 31290,
     "9": 26040,
     "10": 20790,
     "11": 16680,
     "Column14": 14430
    },
    {
     "이상": 3800,
     "미만": 3820,
     "1": 169260,
     "2": 141400,
     "3": 87680,
     "4": 74560,
     "5": 61430,
     "6": 48310,
     "7": 37220,
     "8": 31970,
     "9": 26720,
     "10": 21470,
     "11": 17120,
     "Column14": 14870
    },
    {
     "이상": 3820,
     "미만": 3840,
     "1": 171930,
     "2": 144050,
     "3": 89390,
     "4": 76270,
     "5": 63140,
     "6": 50020,
     "7": 37900,
     "8": 32650,
     "9": 27400,
     "10": 22150,
     "11": 17560,
     "Column14": 15310
    },
    {
     "이상": 3840,
     "미만": 3860,
     "1": 174600,
     "2": 146710,
     "3": 91100,
     "4": 77980,
     "5": 64850,
     "6": 51730,
     "7": 38600,
     "8": 33340,
     "9": 28090,
     "10": 22840,
     "11": 18000,
     "Column14": 15750
    },
    {
     "이상": 3860,
     "미만": 3880,
     "1": 177270,
     "2": 149360,
     "3": 92820,
     "4": 79690,
     "5": 66570,
     "6": 53440,
     "7": 40320,
     "8": 34020,
     "9": 28770,
     "10": 23520,
     "11": 18440,
     "Column14": 16190
    },
    {
     "이상": 3880,
     "미만": 3900,
     "1": 179940,
     "2": 152020,
     "3": 94920,
     "4": 81400,
     "5": 68280,
     "6": 55150,
     "7": 42030,
     "8": 34710,
     "9": 29460,
     "10": 24210,
     "11": 18960,
     "Column14": 16630
    },
    {
     "이상": 3900,
     "미만": 3920,
     "1": 182610,
     "2": 154670,
     "3": 97370,
     "4": 83110,
     "5": 69990,
     "6": 56860,
     "7": 43740,
     "8": 35390,
     "9": 30140,
     "10": 24890,
     "11": 19640,
     "Column14": 17070
    },
    {
     "이상": 3920,
     "미만": 3940,
     "1": 185280,
     "2": 157330,
     "3": 99810,
     "4": 84830,
     "5": 71700,
     "6": 58580,
     "7": 45450,
     "8": 36080,
     "9": 30830,
     "10": 25580,
     "11": 20330,
     "Column14": 17510
    },
    {
     "이상": 3940,
     "미만": 3960,
     "1": 187950,
     "2": 159980,
     "3": 102260,
     "4": 86540,
     "5": 73410,
     "6": 60290,
     "7": 47160,
     "8": 36760,
     "9": 31510,
     "10": 26260,
     "11": 21010,
     "Column14": 17950
    },
    {
     "이상": 3960,
     "미만": 3980,
     "1": 190620,
     "2": 162640,
     "3": 104700,
     "4": 88250,
     "5": 75120,
     "6": 62000,
     "7": 48870,
     "8": 37450,
     "9": 32200,
     "10": 26950,
     "11": 21700,
     "Column14": 18390
    },
    {
     "이상": 3980,
     "미만": 4000,
     "1": 193290,
     "2": 165290,
     "3": 107150,
     "4": 89960,
     "5": 76840,
     "6": 63710,
     "7": 50590,
     "8": 38130,
     "9": 32880,
     "10": 27630,
     "11": 22380,
     "Column14": 18880
    },
    {
     "이상": 4000,
     "미만": 4020,
     "1": 195960,
     "2": 167950,
     "3": 109590,
     "4": 91670,
     "5": 78550,
     "6": 65420,
     "7": 52300,
     "8": 39170,
     "9": 33570,
     "10": 28320,
     "11": 23070,
     "Column14": 19570
    },
    {
     "이상": 4020,
     "미만": 4040,
     "1": 198630,
     "2": 170600,
     "3": 112040,
     "4": 93380,
     "5": 80260,
     "6": 67130,
     "7": 54010,
     "8": 40880,
     "9": 34250,
     "10": 29000,
     "11": 23750,
     "Column14": 20250
    },
    {
     "이상": 4040,
     "미만": 4060,
     "1": 201300,
     "2": 173260,
     "3": 114480,
     "4": 95730,
     "5": 81970,
     "6": 68840,
     "7": 55720,
     "8": 42590,
     "9": 34930,
     "10": 29680,
     "11": 24430,
     "Column14": 20930
    },
    {
     "이상": 4060,
     "미만": 4080,
     "1": 203970,
     "2": 175910,
     "3": 116930,
     "4": 98180,
     "5": 83680,
     "6": 70560,
     "7": 57430,
     "8": 44310,
     "9": 35620,
     "10": 30370,
     "11": 25120,
     "Column14": 21620
    },
    {
     "이상": 4080,
     "미만": 4100,
     "1": 206640,
     "2": 178570,
     "3": 119370,
     "4": 100620,
     "5": 85390,
     "6": 72270,
     "7": 59140,
     "8": 46020,
     "9": 36300,
     "10": 31050,
     "11": 25800,
     "Column14": 22300
    },
    {
     "이상": 4100,
     "미만": 4120,
     "1": 209310,
     "2": 181220,
     "3": 121820,
     "4": 103070,
     "5": 87100,
     "6": 73980,
     "7": 60850,
     "8": 47730,
     "9": 36990,
     "10": 31740,
     "11": 26490,
     "Column14": 22990
    },
    {
     "이상": 4120,
     "미만": 4140,
     "1": 211980,
     "2": 183880,
     "3": 124260,
     "4": 105510,
     "5": 88820,
     "6": 75690,
     "7": 62570,
     "8": 49440,
     "9": 37670,
     "10": 32420,
     "11": 27170,
     "Column14": 23670
    },
    {
     "이상": 4140,
     "미만": 4160,
     "1": 214650,
     "2": 186530,
     "3": 126710,
     "4": 107960,
     "5": 90530,
     "6": 77400,
     "7": 64280,
     "8": 51150,
     "9": 38360,
     "10": 33110,
     "11": 27860,
     "Column14": 24360
    },
    {
     "이상": 4160,
     "미만": 4180,
     "1": 217320,
     "2": 189190,
     "3": 129150,
     "4": 110400,
     "5": 92240,
     "6": 79110,
     "7": 65990,
     "8": 52860,
     "9": 39740,
     "10": 33790,
     "11": 28540,
     "Column14": 25040
    },
    {
     "이상": 4180,
     "미만": 4200,
     "1": 219990,
     "2": 191840,
     "3": 131600,
     "4": 112850,
     "5": 94100,
     "6": 80830,
     "7": 67700,
     "8": 54580,
     "9": 41450,
     "10": 34480,
     "11": 29230,
     "Column14": 25730
    },
    {
     "이상": 4200,
     "미만": 4220,
     "1": 222660,
     "2": 194500,
     "3": 134040,
     "4": 115290,
     "5": 96540,
     "6": 82540,
     "7": 69410,
     "8": 56290,
     "9": 43160,
     "10": 35160,
     "11": 29910,
     "Column14": 26410
    },
    {
     "이상": 4220,
     "미만": 4240,
     "1": 225330,
     "2": 197150,
     "3": 136490,
     "4": 117740,
     "5": 98990,
     "6": 84250,
     "7": 71120,
     "8": 58000,
     "9": 44870,
     "10": 35850,
     "11": 30600,
     "Column14": 27100
    },
    {
     "이상": 4240,
     "미만": 4260,
     "1": 228000,
     "2": 199810,
     "3": 138930,
     "4": 120180,
     "5": 101430,
     "6": 85960,
     "7": 72830,
     "8": 59710,
     "9": 46580,
     "10": 36530,
     "11": 31280,
     "Column14": 27780
    },
    {
     "이상": 4260,
     "미만": 4280,
     "1": 230670,
     "2": 202460,
     "3": 141380,
     "4": 122630,
     "5": 103880,
     "6": 87670,
     "7": 74550,
     "8": 61420,
     "9": 48300,
     "10": 37220,
     "11": 31970,
     "Column14": 28470
    },
    {
     "이상": 4280,
     "미만": 4300,
     "1": 233340,
     "2": 205120,
     "3": 143820,
     "4": 125070,
     "5": 106320,
     "6": 89380,
     "7": 76260,
     "8": 63130,
     "9": 50010,
     "10": 37900,
     "11": 32650,
     "Column14": 29150
    },
    {
     "이상": 4300,
     "미만": 4320,
     "1": 236010,
     "2": 207770,
     "3": 146270,
     "4": 127520,
     "5": 108770,
     "6": 91090,
     "7": 77970,
     "8": 64840,
     "9": 51720,
     "10": 38590,
     "11": 33330,
     "Column14": 29830
    },
    {
     "이상": 4320,
     "미만": 4340,
     "1": 238680,
     "2": 210430,
     "3": 148710,
     "4": 129960,
     "5": 111210,
     "6": 92810,
     "7": 79680,
     "8": 66560,
     "9": 53430,
     "10": 40310,
     "11": 34020,
     "Column14": 30520
    },
    {
     "이상": 4340,
     "미만": 4360,
     "1": 241350,
     "2": 213080,
     "3": 151160,
     "4": 132410,
     "5": 113660,
     "6": 94910,
     "7": 81390,
     "8": 68270,
     "9": 55140,
     "10": 42020,
     "11": 34700,
     "Column14": 31200
    },
    {
     "이상": 4360,
     "미만": 4380,
     "1": 244020,
     "2": 215740,
     "3": 153600,
     "4": 134850,
     "5": 116100,
     "6": 97350,
     "7": 83100,
     "8": 69980,
     "9": 56850,
     "10": 43730,
     "11": 35390,
     "Column14": 31890
    },
    {
     "이상": 4380,
     "미만": 4400,
     "1": 246690,
     "2": 218390,
     "3": 156050,
     "4": 137300,
     "5": 118550,
     "6": 99800,
     "7": 84820,
     "8": 71690,
     "9": 58570,
     "10": 45440,
     "11": 36070,
     "Column14": 32570
    },
    {
     "이상": 4400,
     "미만": 4420,
     "1": 249360,
     "2": 221050,
     "3": 158490,
     "4": 139740,
     "5": 120990,
     "6": 102240,
     "7": 86530,
     "8": 73400,
     "9": 60280,
     "10": 47150,
     "11": 36760,
     "Column14": 33260
    },
    {
     "이상": 4420,
     "미만": 4440,
     "1": 252030,
     "2": 223700,
     "3": 160940,
     "4": 142190,
     "5": 123440,
     "6": 104690,
     "7": 88240,
     "8": 75110,
     "9": 61990,
     "10": 48860,
     "11": 37440,
     "Column14": 33940
    },
    {
     "이상": 4440,
     "미만": 4460,
     "1": 254700,
     "2": 226360,
     "3": 163380,
     "4": 144630,
     "5": 125880,
     "6": 107130,
     "7": 89950,
     "8": 76820,
     "9": 63700,
     "10": 50570,
     "11": 38130,
     "Column14": 34630
    },
    {
     "이상": 4460,
     "미만": 4480,
     "1": 257370,
     "2": 229010,
     "3": 165830,
     "4": 147080,
     "5": 128330,
     "6": 109580,
     "7": 91660,
     "8": 78540,
     "9": 65410,
     "10": 52290,
     "11": 39160,
     "Column14": 35310
    },
    {
     "이상": 4480,
     "미만": 4500,
     "1": 260040,
     "2": 231670,
     "3": 168270,
     "4": 149520,
     "5": 130770,
     "6": 112020,
     "7": 93370,
     "8": 80250,
     "9": 67120,
     "10": 54000,
     "11": 40870,
     "Column14": 36000
    },
    {
     "이상": 4500,
     "미만": 4520,
     "1": 262840,
     "2": 234460,
     "3": 170850,
     "4": 152100,
     "5": 133350,
     "6": 114600,
     "7": 95850,
     "8": 82050,
     "9": 68930,
     "10": 55800,
     "11": 42680,
     "Column14": 36720
    },
    {
     "이상": 4520,
     "미만": 4540,
     "1": 265650,
     "2": 237250,
     "3": 173430,
     "4": 154680,
     "5": 135930,
     "6": 117180,
     "7": 98430,
     "8": 83860,
     "9": 70730,
     "10": 57610,
     "11": 44480,
     "Column14": 37440
    },
    {
     "이상": 4540,
     "미만": 4560,
     "1": 268450,
     "2": 240040,
     "3": 176010,
     "4": 157260,
     "5": 138510,
     "6": 119760,
     "7": 101010,
     "8": 85670,
     "9": 72540,
     "10": 59420,
     "11": 46290,
     "Column14": 38160
    },
    {
     "이상": 4560,
     "미만": 4580,
     "1": 271260,
     "2": 242830,
     "3": 178590,
     "4": 159840,
     "5": 141090,
     "6": 122340,
     "7": 103590,
     "8": 87470,
     "9": 74350,
     "10": 61220,
     "11": 48100,
     "Column14": 39350
    },
    {
     "이상": 4580,
     "미만": 4600,
     "1": 276560,
     "2": 248120,
     "3": 183670,
     "4": 164920,
     "5": 146170,
     "6": 127420,
     "7": 108670,
     "8": 89920,
     "9": 76150,
     "10": 63030,
     "11": 49900,
     "Column14": 41150
    },
    {
     "이상": 4600,
     "미만": 4620,
     "1": 279370,
     "2": 250910,
     "3": 186250,
     "4": 167500,
     "5": 148750,
     "6": 130000,
     "7": 111250,
     "8": 92500,
     "9": 77960,
     "10": 64830,
     "11": 51710,
     "Column14": 42960
    },
    {
     "이상": 4620,
     "미만": 4640,
     "1": 282170,
     "2": 253700,
     "3": 188830,
     "4": 170080,
     "5": 151330,
     "6": 132580,
     "7": 113830,
     "8": 95080,
     "9": 79760,
     "10": 66640,
     "11": 53510,
     "Column14": 44760
    },
    {
     "이상": 4640,
     "미만": 4660,
     "1": 284980,
     "2": 256490,
     "3": 191410,
     "4": 172660,
     "5": 153910,
     "6": 135160,
     "7": 116410,
     "8": 97660,
     "9": 81570,
     "10": 68450,
     "11": 55320,
     "Column14": 46570
    },
    {
     "이상": 4660,
     "미만": 4680,
     "1": 287780,
     "2": 259280,
     "3": 193990,
     "4": 175240,
     "5": 156490,
     "6": 137740,
     "7": 118990,
     "8": 100240,
     "9": 83380,
     "10": 70250,
     "11": 57130,
     "Column14": 48380
    },
    {
     "이상": 4680,
     "미만": 4700,
     "1": 290590,
     "2": 262070,
     "3": 196570,
     "4": 177820,
     "5": 159070,
     "6": 140320,
     "7": 121570,
     "8": 102820,
     "9": 85180,
     "10": 72060,
     "11": 58930,
     "Column14": 50180
    },
    {
     "이상": 4700,
     "미만": 4720,
     "1": 293390,
     "2": 264860,
     "3": 199150,
     "4": 180400,
     "5": 161650,
     "6": 142900,
     "7": 124150,
     "8": 105400,
     "9": 86990,
     "10": 73860,
     "11": 60740,
     "Column14": 51990
    },
    {
     "이상": 4720,
     "미만": 4740,
     "1": 296200,
     "2": 267650,
     "3": 201730,
     "4": 182980,
     "5": 164230,
     "6": 145480,
     "7": 126730,
     "8": 107980,
     "9": 89230,
     "10": 75670,
     "11": 62540,
     "Column14": 53790
    },
    {
     "이상": 4740,
     "미만": 4760,
     "1": 299000,
     "2": 270440,
     "3": 204310,
     "4": 185560,
     "5": 166810,
     "6": 148060,
     "7": 129310,
     "8": 110560,
     "9": 91810,
     "10": 77480,
     "11": 64350,
     "Column14": 55600
    },
    {
     "이상": 4760,
     "미만": 4780,
     "1": 301810,
     "2": 273230,
     "3": 206890,
     "4": 188140,
     "5": 169390,
     "6": 150640,
     "7": 131890,
     "8": 113140,
     "9": 94390,
     "10": 79280,
     "11": 66160,
     "Column14": 57410
    },
    {
     "이상": 4780,
     "미만": 4800,
     "1": 304610,
     "2": 276020,
     "3": 209470,
     "4": 190720,
     "5": 171970,
     "6": 153220,
     "7": 134470,
     "8": 115720,
     "9": 96970,
     "10": 81090,
     "11": 67960,
     "Column14": 59210
    },
    {
     "이상": 4800,
     "미만": 4820,
     "1": 307420,
     "2": 278810,
     "3": 212050,
     "4": 193300,
     "5": 174550,
     "6": 155800,
     "7": 137050,
     "8": 118300,
     "9": 99550,
     "10": 82890,
     "11": 69770,
     "Column14": 61020
    },
    {
     "이상": 4820,
     "미만": 4840,
     "1": 310220,
     "2": 281600,
     "3": 214630,
     "4": 195880,
     "5": 177130,
     "6": 158380,
     "7": 139630,
     "8": 120880,
     "9": 102130,
     "10": 84700,
     "11": 71570,
     "Column14": 62820
    },
    {
     "이상": 4840,
     "미만": 4860,
     "1": 313030,
     "2": 284390,
     "3": 217210,
     "4": 198460,
     "5": 179710,
     "6": 160960,
     "7": 142210,
     "8": 123460,
     "9": 104710,
     "10": 86510,
     "11": 73380,
     "Column14": 64630
    },
    {
     "이상": 4860,
     "미만": 4880,
     "1": 315830,
     "2": 287180,
     "3": 219790,
     "4": 201040,
     "5": 182290,
     "6": 163540,
     "7": 144790,
     "8": 126040,
     "9": 107290,
     "10": 88540,
     "11": 75190,
     "Column14": 66440
    },
    {
     "이상": 4880,
     "미만": 4900,
     "1": 318640,
     "2": 289970,
     "3": 222370,
     "4": 203620,
     "5": 184870,
     "6": 166120,
     "7": 147370,
     "8": 128620,
     "9": 109870,
     "10": 91120,
     "11": 76990,
     "Column14": 68240
    },
    {
     "이상": 4900,
     "미만": 4920,
     "1": 321440,
     "2": 292760,
     "3": 224950,
     "4": 206200,
     "5": 187450,
     "6": 168700,
     "7": 149950,
     "8": 131200,
     "9": 112450,
     "10": 93700,
     "11": 78800,
     "Column14": 70050
    },
    {
     "이상": 4920,
     "미만": 4940,
     "1": 324250,
     "2": 295550,
     "3": 227530,
     "4": 208780,
     "5": 190030,
     "6": 171280,
     "7": 152530,
     "8": 133780,
     "9": 115030,
     "10": 96280,
     "11": 80600,
     "Column14": 71850
    },
    {
     "이상": 4940,
     "미만": 4960,
     "1": 327050,
     "2": 298340,
     "3": 230110,
     "4": 211360,
     "5": 192610,
     "6": 173860,
     "7": 155110,
     "8": 136360,
     "9": 117610,
     "10": 98860,
     "11": 82410,
     "Column14": 73660
    },
    {
     "이상": 4960,
     "미만": 4980,
     "1": 329860,
     "2": 301130,
     "3": 232690,
     "4": 213940,
     "5": 195190,
     "6": 176440,
     "7": 157690,
     "8": 138940,
     "9": 120190,
     "10": 101440,
     "11": 84220,
     "Column14": 75470
    },
    {
     "이상": 4980,
     "미만": 5000,
     "1": 332660,
     "2": 303920,
     "3": 235270,
     "4": 216520,
     "5": 197770,
     "6": 179020,
     "7": 160270,
     "8": 141520,
     "9": 122770,
     "10": 104020,
     "11": 86020,
     "Column14": 77270
    },
    {
     "이상": 5000,
     "미만": 5020,
     "1": 335470,
     "2": 306710,
     "3": 237850,
     "4": 219100,
     "5": 200350,
     "6": 181600,
     "7": 162850,
     "8": 144100,
     "9": 125350,
     "10": 106600,
     "11": 87850,
     "Column14": 79080
    },
    {
     "이상": 5020,
     "미만": 5040,
     "1": 338270,
     "2": 309500,
     "3": 240430,
     "4": 221680,
     "5": 202930,
     "6": 184180,
     "7": 165430,
     "8": 146680,
     "9": 127930,
     "10": 109180,
     "11": 90430,
     "Column14": 80880
    },
    {
     "이상": 5040,
     "미만": 5060,
     "1": 341080,
     "2": 312290,
     "3": 243010,
     "4": 224260,
     "5": 205510,
     "6": 186760,
     "7": 168010,
     "8": 149260,
     "9": 130510,
     "10": 111760,
     "11": 93010,
     "Column14": 82690
    },
    {
     "이상": 5060,
     "미만": 5080,
     "1": 343880,
     "2": 315080,
     "3": 245590,
     "4": 226840,
     "5": 208090,
     "6": 189340,
     "7": 170590,
     "8": 151840,
     "9": 133090,
     "10": 114340,
     "11": 95590,
     "Column14": 84500
    },
    {
     "이상": 5080,
     "미만": 5100,
     "1": 346690,
     "2": 317870,
     "3": 248170,
     "4": 229420,
     "5": 210670,
     "6": 191920,
     "7": 173170,
     "8": 154420,
     "9": 135670,
     "10": 116920,
     "11": 98170,
     "Column14": 86300
    },
    {
     "이상": 5100,
     "미만": 5120,
     "1": 349490,
     "2": 320660,
     "3": 250750,
     "4": 232000,
     "5": 213250,
     "6": 194500,
     "7": 175750,
     "8": 157000,
     "9": 138250,
     "10": 119500,
     "11": 100750,
     "Column14": 88250
    },
    {
     "이상": 5120,
     "미만": 5140,
     "1": 352300,
     "2": 323450,
     "3": 253330,
     "4": 234580,
     "5": 215830,
     "6": 197080,
     "7": 178330,
     "8": 159580,
     "9": 140830,
     "10": 122080,
     "11": 103330,
     "Column14": 90830
    },
    {
     "이상": 5140,
     "미만": 5160,
     "1": 355100,
     "2": 326240,
     "3": 255910,
     "4": 237160,
     "5": 218410,
     "6": 199660,
     "7": 180910,
     "8": 162160,
     "9": 143410,
     "10": 124660,
     "11": 105910,
     "Column14": 93410
    },
    {
     "이상": 5160,
     "미만": 5180,
     "1": 357910,
     "2": 329030,
     "3": 258490,
     "4": 239740,
     "5": 220990,
     "6": 202240,
     "7": 183490,
     "8": 164740,
     "9": 145990,
     "10": 127240,
     "11": 108490,
     "Column14": 95990
    },
    {
     "이상": 5180,
     "미만": 5200,
     "1": 360710,
     "2": 331820,
     "3": 261070,
     "4": 242320,
     "5": 223570,
     "6": 204820,
     "7": 186070,
     "8": 167320,
     "9": 148570,
     "10": 129820,
     "11": 111070,
     "Column14": 98570
    },
    {
     "이상": 5200,
     "미만": 5220,
     "1": 363520,
     "2": 334610,
     "3": 263650,
     "4": 244900,
     "5": 226150,
     "6": 207400,
     "7": 188650,
     "8": 169900,
     "9": 151150,
     "10": 132400,
     "11": 113650,
     "Column14": 101150
    },
    {
     "이상": 5220,
     "미만": 5240,
     "1": 366320,
     "2": 337400,
     "3": 266230,
     "4": 247480,
     "5": 228730,
     "6": 209980,
     "7": 191230,
     "8": 172480,
     "9": 153730,
     "10": 134980,
     "11": 116230,
     "Column14": 103730
    },
    {
     "이상": 5240,
     "미만": 5260,
     "1": 369130,
     "2": 340190,
     "3": 268810,
     "4": 250060,
     "5": 231310,
     "6": 212560,
     "7": 193810,
     "8": 175060,
     "9": 156310,
     "10": 137560,
     "11": 118810,
     "Column14": 106310
    },
    {
     "이상": 5260,
     "미만": 5280,
     "1": 371930,
     "2": 342980,
     "3": 271390,
     "4": 252640,
     "5": 233890,
     "6": 215140,
     "7": 196390,
     "8": 177640,
     "9": 158890,
     "10": 140140,
     "11": 121390,
     "Column14": 108890
    },
    {
     "이상": 5280,
     "미만": 5300,
     "1": 374740,
     "2": 345770,
     "3": 273970,
     "4": 255220,
     "5": 236470,
     "6": 217720,
     "7": 198970,
     "8": 180220,
     "9": 161470,
     "10": 142720,
     "11": 123970,
     "Column14": 111470
    },
    {
     "이상": 5300,
     "미만": 5320,
     "1": 377540,
     "2": 348560,
     "3": 276550,
     "4": 257800,
     "5": 239050,
     "6": 220300,
     "7": 201550,
     "8": 182800,
     "9": 164050,
     "10": 145300,
     "11": 126550,
     "Column14": 114050
    },
    {
     "이상": 5320,
     "미만": 5340,
     "1": 380350,
     "2": 351350,
     "3": 279130,
     "4": 260380,
     "5": 241630,
     "6": 222880,
     "7": 204130,
     "8": 185380,
     "9": 166630,
     "10": 147880,
     "11": 129130,
     "Column14": 116630
    },
    {
     "이상": 5340,
     "미만": 5360,
     "1": 383150,
     "2": 354140,
     "3": 281710,
     "4": 262960,
     "5": 244210,
     "6": 225460,
     "7": 206710,
     "8": 187960,
     "9": 169210,
     "10": 150460,
     "11": 131710,
     "Column14": 119210
    },
    {
     "이상": 5360,
     "미만": 5380,
     "1": 385960,
     "2": 356930,
     "3": 284290,
     "4": 265540,
     "5": 246790,
     "6": 228040,
     "7": 209290,
     "8": 190540,
     "9": 171790,
     "10": 153040,
     "11": 134290,
     "Column14": 121790
    },
    {
     "이상": 5380,
     "미만": 5400,
     "1": 388760,
     "2": 359720,
     "3": 286870,
     "4": 268120,
     "5": 249370,
     "6": 230620,
     "7": 211870,
     "8": 193120,
     "9": 174370,
     "10": 155620,
     "11": 136870,
     "Column14": 124370
    },
    {
     "이상": 5400,
     "미만": 5420,
     "1": 391570,
     "2": 362510,
     "3": 289450,
     "4": 270700,
     "5": 251950,
     "6": 233200,
     "7": 214450,
     "8": 195700,
     "9": 176950,
     "10": 158200,
     "11": 139450,
     "Column14": 126950
    },
    {
     "이상": 5420,
     "미만": 5440,
     "1": 394370,
     "2": 365300,
     "3": 292030,
     "4": 273280,
     "5": 254530,
     "6": 235780,
     "7": 217030,
     "8": 198280,
     "9": 179530,
     "10": 160780,
     "11": 142030,
     "Column14": 129530
    },
    {
     "이상": 5440,
     "미만": 5460,
     "1": 397180,
     "2": 368090,
     "3": 294610,
     "4": 275860,
     "5": 257110,
     "6": 238360,
     "7": 219610,
     "8": 200860,
     "9": 182110,
     "10": 163360,
     "11": 144610,
     "Column14": 132110
    },
    {
     "이상": 5460,
     "미만": 5480,
     "1": 399980,
     "2": 370880,
     "3": 297190,
     "4": 278440,
     "5": 259690,
     "6": 240940,
     "7": 222190,
     "8": 203440,
     "9": 184690,
     "10": 165940,
     "11": 147190,
     "Column14": 134690
    },
    {
     "이상": 5480,
     "미만": 5500,
     "1": 402790,
     "2": 373670,
     "3": 299770,
     "4": 281020,
     "5": 262270,
     "6": 243520,
     "7": 224770,
     "8": 206020,
     "9": 187270,
     "10": 168520,
     "11": 149770,
     "Column14": 137270
    },
    {
     "이상": 5500,
     "미만": 5520,
     "1": 405590,
     "2": 376460,
     "3": 302350,
     "4": 283600,
     "5": 264850,
     "6": 246100,
     "7": 227350,
     "8": 208600,
     "9": 189850,
     "10": 171100,
     "11": 152350,
     "Column14": 139850
    },
    {
     "이상": 5520,
     "미만": 5540,
     "1": 408400,
     "2": 379250,
     "3": 304930,
     "4": 286180,
     "5": 267430,
     "6": 248680,
     "7": 229930,
     "8": 211180,
     "9": 192430,
     "10": 173680,
     "11": 154930,
     "Column14": 142430
    },
    {
     "이상": 5540,
     "미만": 5560,
     "1": 411200,
     "2": 382040,
     "3": 307510,
     "4": 288760,
     "5": 270010,
     "6": 251260,
     "7": 232510,
     "8": 213760,
     "9": 195010,
     "10": 176260,
     "11": 157510,
     "Column14": 145010
    },
    {
     "이상": 5560,
     "미만": 5580,
     "1": 414010,
     "2": 384830,
     "3": 310090,
     "4": 291340,
     "5": 272590,
     "6": 253840,
     "7": 235090,
     "8": 216340,
     "9": 197590,
     "10": 178840,
     "11": 160090,
     "Column14": 147590
    },
    {
     "이상": 5580,
     "미만": 5600,
     "1": 416810,
     "2": 387620,
     "3": 312670,
     "4": 293920,
     "5": 275170,
     "6": 256420,
     "7": 237670,
     "8": 218920,
     "9": 200170,
     "10": 181420,
     "11": 162670,
     "Column14": 150170
    },
    {
     "이상": 5600,
     "미만": 5620,
     "1": 419620,
     "2": 390410,
     "3": 315250,
     "4": 296500,
     "5": 277750,
     "6": 259000,
     "7": 240250,
     "8": 221500,
     "9": 202750,
     "10": 184000,
     "11": 165250,
     "Column14": 152750
    },
    {
     "이상": 5620,
     "미만": 5640,
     "1": 422420,
     "2": 393200,
     "3": 317830,
     "4": 299080,
     "5": 280330,
     "6": 261580,
     "7": 242830,
     "8": 224080,
     "9": 205330,
     "10": 186580,
     "11": 167830,
     "Column14": 155330
    },
    {
     "이상": 5640,
     "미만": 5660,
     "1": 425230,
     "2": 395990,
     "3": 320410,
     "4": 301660,
     "5": 282910,
     "6": 264160,
     "7": 245410,
     "8": 226660,
     "9": 207910,
     "10": 189160,
     "11": 170410,
     "Column14": 157910
    },
    {
     "이상": 5660,
     "미만": 5680,
     "1": 428030,
     "2": 398780,
     "3": 322990,
     "4": 304240,
     "5": 285490,
     "6": 266740,
     "7": 247990,
     "8": 229240,
     "9": 210490,
     "10": 191740,
     "11": 172990,
     "Column14": 160490
    },
    {
     "이상": 5680,
     "미만": 5700,
     "1": 430840,
     "2": 401570,
     "3": 325570,
     "4": 306820,
     "5": 288070,
     "6": 269320,
     "7": 250570,
     "8": 231820,
     "9": 213070,
     "10": 194320,
     "11": 175570,
     "Column14": 163070
    },
    {
     "이상": 5700,
     "미만": 5720,
     "1": 433640,
     "2": 404360,
     "3": 328150,
     "4": 309400,
     "5": 290650,
     "6": 271900,
     "7": 253150,
     "8": 234400,
     "9": 215650,
     "10": 196900,
     "11": 178150,
     "Column14": 165650
    },
    {
     "이상": 5720,
     "미만": 5740,
     "1": 436450,
     "2": 407150,
     "3": 330730,
     "4": 311980,
     "5": 293230,
     "6": 274480,
     "7": 255730,
     "8": 236980,
     "9": 218230,
     "10": 199480,
     "11": 180730,
     "Column14": 168230
    },
    {
     "이상": 5740,
     "미만": 5760,
     "1": 439250,
     "2": 409940,
     "3": 333310,
     "4": 314560,
     "5": 295810,
     "6": 277060,
     "7": 258310,
     "8": 239560,
     "9": 220810,
     "10": 202060,
     "11": 183310,
     "Column14": 170810
    },
    {
     "이상": 5760,
     "미만": 5780,
     "1": 442060,
     "2": 412730,
     "3": 335890,
     "4": 317140,
     "5": 298390,
     "6": 279640,
     "7": 260890,
     "8": 242140,
     "9": 223390,
     "10": 204640,
     "11": 185890,
     "Column14": 173390
    },
    {
     "이상": 5780,
     "미만": 5800,
     "1": 444860,
     "2": 415520,
     "3": 338470,
     "4": 319720,
     "5": 300970,
     "6": 282220,
     "7": 263470,
     "8": 244720,
     "9": 225970,
     "10": 207220,
     "11": 188470,
     "Column14": 175970
    },
    {
     "이상": 5800,
     "미만": 5820,
     "1": 447670,
     "2": 418310,
     "3": 341050,
     "4": 322300,
     "5": 303550,
     "6": 284800,
     "7": 266050,
     "8": 247300,
     "9": 228550,
     "10": 209800,
     "11": 191050,
     "Column14": 178550
    },
    {
     "이상": 5820,
     "미만": 5840,
     "1": 450470,
     "2": 421100,
     "3": 343630,
     "4": 324880,
     "5": 306130,
     "6": 287380,
     "7": 268630,
     "8": 249880,
     "9": 231130,
     "10": 212380,
     "11": 193630,
     "Column14": 181130
    },
    {
     "이상": 5840,
     "미만": 5860,
     "1": 470380,
     "2": 441000,
     "3": 372100,
     "4": 353350,
     "5": 334600,
     "6": 315850,
     "7": 297100,
     "8": 278350,
     "9": 259600,
     "10": 240850,
     "11": 222100,
     "Column14": 209600
    },
    {
     "이상": 5860,
     "미만": 5880,
     "1": 475720,
     "2": 446320,
     "3": 377240,
     "4": 358490,
     "5": 339740,
     "6": 320990,
     "7": 302240,
     "8": 283490,
     "9": 264740,
     "10": 245990,
     "11": 227240,
     "Column14": 214740
    },
    {
     "이상": 5880,
     "미만": 5900,
     "1": 478690,
     "2": 449140,
     "3": 379880,
     "4": 361130,
     "5": 342380,
     "6": 323630,
     "7": 304880,
     "8": 286130,
     "9": 267380,
     "10": 248630,
     "11": 229880,
     "Column14": 217380
    },
    {
     "이상": 5900,
     "미만": 5920,
     "1": 483220,
     "2": 451960,
     "3": 382520,
     "4": 363770,
     "5": 345020,
     "6": 326270,
     "7": 307520,
     "8": 288770,
     "9": 270020,
     "10": 251270,
     "11": 232520,
     "Column14": 220020
    },
    {
     "이상": 5920,
     "미만": 5940,
     "1": 487760,
     "2": 454780,
     "3": 385160,
     "4": 366410,
     "5": 347660,
     "6": 328910,
     "7": 310160,
     "8": 291410,
     "9": 272660,
     "10": 253910,
     "11": 235160,
     "Column14": 222660
    },
    {
     "이상": 5940,
     "미만": 5960,
     "1": 492300,
     "2": 457600,
     "3": 387800,
     "4": 369050,
     "5": 350300,
     "6": 331550,
     "7": 312800,
     "8": 294050,
     "9": 275300,
     "10": 256550,
     "11": 237800,
     "Column14": 225300
    },
    {
     "이상": 5960,
     "미만": 5980,
     "1": 496830,
     "2": 460420,
     "3": 390440,
     "4": 371690,
     "5": 352940,
     "6": 334190,
     "7": 315440,
     "8": 296690,
     "9": 277940,
     "10": 259190,
     "11": 240440,
     "Column14": 227940
    },
    {
     "이상": 5980,
     "미만": 6000,
     "1": 501370,
     "2": 463240,
     "3": 393080,
     "4": 374330,
     "5": 355580,
     "6": 336830,
     "7": 318080,
     "8": 299330,
     "9": 280580,
     "10": 261830,
     "11": 243080,
     "Column14": 230580
    },
    {
     "이상": 6000,
     "미만": 6020,
     "1": 505900,
     "2": 466060,
     "3": 395720,
     "4": 376970,
     "5": 358220,
     "6": 339470,
     "7": 320720,
     "8": 301970,
     "9": 283220,
     "10": 264470,
     "11": 245720,
     "Column14": 233220
    },
    {
     "이상": 6020,
     "미만": 6040,
     "1": 510440,
     "2": 468880,
     "3": 398360,
     "4": 379610,
     "5": 360860,
     "6": 342110,
     "7": 323360,
     "8": 304610,
     "9": 285860,
     "10": 267110,
     "11": 248360,
     "Column14": 235860
    },
    {
     "이상": 6040,
     "미만": 6060,
     "1": 514980,
     "2": 471700,
     "3": 401000,
     "4": 382250,
     "5": 363500,
     "6": 344750,
     "7": 326000,
     "8": 307250,
     "9": 288500,
     "10": 269750,
     "11": 251000,
     "Column14": 238500
    },
    {
     "이상": 6060,
     "미만": 6080,
     "1": 519510,
     "2": 474520,
     "3": 403640,
     "4": 384890,
     "5": 366140,
     "6": 347390,
     "7": 328640,
     "8": 309890,
     "9": 291140,
     "10": 272390,
     "11": 253640,
     "Column14": 241140
    },
    {
     "이상": 6080,
     "미만": 6100,
     "1": 524050,
     "2": 477340,
     "3": 406280,
     "4": 387530,
     "5": 368780,
     "6": 350030,
     "7": 331280,
     "8": 312530,
     "9": 293780,
     "10": 275030,
     "11": 256280,
     "Column14": 243780
    },
    {
     "이상": 6100,
     "미만": 6120,
     "1": 528580,
     "2": 481250,
     "3": 408920,
     "4": 390170,
     "5": 371420,
     "6": 352670,
     "7": 333920,
     "8": 315170,
     "9": 296420,
     "10": 277670,
     "11": 258920,
     "Column14": 246420
    },
    {
     "이상": 6120,
     "미만": 6140,
     "1": 533120,
     "2": 485760,
     "3": 411560,
     "4": 392810,
     "5": 374060,
     "6": 355310,
     "7": 336560,
     "8": 317810,
     "9": 299060,
     "10": 280310,
     "11": 261560,
     "Column14": 249060
    },
    {
     "이상": 6140,
     "미만": 6160,
     "1": 537660,
     "2": 490280,
     "3": 414200,
     "4": 395450,
     "5": 376700,
     "6": 357950,
     "7": 339200,
     "8": 320450,
     "9": 301700,
     "10": 282950,
     "11": 264200,
     "Column14": 251700
    },
    {
     "이상": 6160,
     "미만": 6180,
     "1": 542190,
     "2": 494790,
     "3": 416840,
     "4": 398090,
     "5": 379340,
     "6": 360590,
     "7": 341840,
     "8": 323090,
     "9": 304340,
     "10": 285590,
     "11": 266840,
     "Column14": 254340
    },
    {
     "이상": 6180,
     "미만": 6200,
     "1": 546730,
     "2": 499300,
     "3": 419480,
     "4": 400730,
     "5": 381980,
     "6": 363230,
     "7": 344480,
     "8": 325730,
     "9": 306980,
     "10": 288230,
     "11": 269480,
     "Column14": 256980
    },
    {
     "이상": 6200,
     "미만": 6220,
     "1": 551260,
     "2": 503810,
     "3": 422120,
     "4": 403370,
     "5": 384620,
     "6": 365870,
     "7": 347120,
     "8": 328370,
     "9": 309620,
     "10": 290870,
     "11": 272120,
     "Column14": 259620
    },
    {
     "이상": 6220,
     "미만": 6240,
     "1": 555800,
     "2": 508320,
     "3": 424760,
     "4": 406010,
     "5": 387260,
     "6": 368510,
     "7": 349760,
     "8": 331010,
     "9": 312260,
     "10": 293510,
     "11": 274760,
     "Column14": 262260
    },
    {
     "이상": 6240,
     "미만": 6260,
     "1": 560340,
     "2": 512840,
     "3": 427400,
     "4": 408650,
     "5": 389900,
     "6": 371150,
     "7": 352400,
     "8": 333650,
     "9": 314900,
     "10": 296150,
     "11": 277400,
     "Column14": 264900
    },
    {
     "이상": 6260,
     "미만": 6280,
     "1": 564870,
     "2": 517350,
     "3": 430040,
     "4": 411290,
     "5": 392540,
     "6": 373790,
     "7": 355040,
     "8": 336290,
     "9": 317540,
     "10": 298790,
     "11": 280040,
     "Column14": 267540
    },
    {
     "이상": 6280,
     "미만": 6300,
     "1": 569410,
     "2": 521860,
     "3": 432680,
     "4": 413930,
     "5": 395180,
     "6": 376430,
     "7": 357680,
     "8": 338930,
     "9": 320180,
     "10": 301430,
     "11": 282680,
     "Column14": 270180
    },
    {
     "이상": 6300,
     "미만": 6320,
     "1": 573940,
     "2": 526370,
     "3": 435320,
     "4": 416570,
     "5": 397820,
     "6": 379070,
     "7": 360320,
     "8": 341570,
     "9": 322820,
     "10": 304070,
     "11": 285320,
     "Column14": 272820
    },
    {
     "이상": 6320,
     "미만": 6340,
     "1": 578480,
     "2": 530880,
     "3": 437960,
     "4": 419210,
     "5": 400460,
     "6": 381710,
     "7": 362960,
     "8": 344210,
     "9": 325460,
     "10": 306710,
     "11": 287960,
     "Column14": 275460
    },
    {
     "이상": 6340,
     "미만": 6360,
     "1": 583020,
     "2": 535400,
     "3": 440600,
     "4": 421850,
     "5": 403100,
     "6": 384350,
     "7": 365600,
     "8": 346850,
     "9": 328100,
     "10": 309350,
     "11": 290600,
     "Column14": 278100
    },
    {
     "이상": 6360,
     "미만": 6380,
     "1": 587550,
     "2": 539910,
     "3": 443240,
     "4": 424490,
     "5": 405740,
     "6": 386990,
     "7": 368240,
     "8": 349490,
     "9": 330740,
     "10": 311990,
     "11": 293240,
     "Column14": 280740
    },
    {
     "이상": 6380,
     "미만": 6400,
     "1": 592090,
     "2": 544420,
     "3": 445880,
     "4": 427130,
     "5": 408380,
     "6": 389630,
     "7": 370880,
     "8": 352130,
     "9": 333380,
     "10": 314630,
     "11": 295880,
     "Column14": 283380
    },
    {
     "이상": 6400,
     "미만": 6420,
     "1": 596620,
     "2": 548930,
     "3": 448520,
     "4": 429770,
     "5": 411020,
     "6": 392270,
     "7": 373520,
     "8": 354770,
     "9": 336020,
     "10": 317270,
     "11": 298520,
     "Column14": 286020
    },
    {
     "이상": 6420,
     "미만": 6440,
     "1": 601160,
     "2": 553440,
     "3": 451160,
     "4": 432410,
     "5": 413660,
     "6": 394910,
     "7": 376160,
     "8": 357410,
     "9": 338660,
     "10": 319910,
     "11": 301160,
     "Column14": 288660
    },
    {
     "이상": 6440,
     "미만": 6460,
     "1": 605700,
     "2": 557960,
     "3": 453800,
     "4": 435050,
     "5": 416300,
     "6": 397550,
     "7": 378800,
     "8": 360050,
     "9": 341300,
     "10": 322550,
     "11": 303800,
     "Column14": 291300
    },
    {
     "이상": 6460,
     "미만": 6480,
     "1": 610230,
     "2": 562470,
     "3": 456440,
     "4": 437690,
     "5": 418940,
     "6": 400190,
     "7": 381440,
     "8": 362690,
     "9": 343940,
     "10": 325190,
     "11": 306440,
     "Column14": 293940
    },
    {
     "이상": 6480,
     "미만": 6500,
     "1": 614770,
     "2": 566980,
     "3": 459080,
     "4": 440330,
     "5": 421580,
     "6": 402830,
     "7": 384080,
     "8": 365330,
     "9": 346580,
     "10": 327830,
     "11": 309080,
     "Column14": 296580
    },
    {
     "이상": 6500,
     "미만": 6520,
     "1": 619300,
     "2": 571490,
     "3": 461720,
     "4": 442970,
     "5": 424220,
     "6": 405470,
     "7": 386720,
     "8": 367970,
     "9": 349220,
     "10": 330470,
     "11": 311720,
     "Column14": 299220
    },
    {
     "이상": 6520,
     "미만": 6540,
     "1": 623840,
     "2": 576000,
     "3": 464360,
     "4": 445610,
     "5": 426860,
     "6": 408110,
     "7": 389360,
     "8": 370610,
     "9": 351860,
     "10": 333110,
     "11": 314360,
     "Column14": 301860
    },
    {
     "이상": 6540,
     "미만": 6560,
     "1": 628380,
     "2": 580520,
     "3": 467000,
     "4": 448250,
     "5": 429500,
     "6": 410750,
     "7": 392000,
     "8": 373250,
     "9": 354500,
     "10": 335750,
     "11": 317000,
     "Column14": 304500
    },
    {
     "이상": 6560,
     "미만": 6580,
     "1": 632910,
     "2": 585030,
     "3": 469640,
     "4": 450890,
     "5": 432140,
     "6": 413390,
     "7": 394640,
     "8": 375890,
     "9": 357140,
     "10": 338390,
     "11": 319640,
     "Column14": 307140
    },
    {
     "이상": 6580,
     "미만": 6600,
     "1": 637450,
     "2": 589540,
     "3": 472280,
     "4": 453530,
     "5": 434780,
     "6": 416030,
     "7": 397280,
     "8": 378530,
     "9": 359780,
     "10": 341030,
     "11": 322280,
     "Column14": 309780
    },
    {
     "이상": 6600,
     "미만": 6620,
     "1": 641980,
     "2": 594050,
     "3": 474920,
     "4": 456170,
     "5": 437420,
     "6": 418670,
     "7": 399920,
     "8": 381170,
     "9": 362420,
     "10": 343670,
     "11": 324920,
     "Column14": 312420
    },
    {
     "이상": 6620,
     "미만": 6640,
     "1": 646520,
     "2": 598560,
     "3": 477560,
     "4": 458810,
     "5": 440060,
     "6": 421310,
     "7": 402560,
     "8": 383810,
     "9": 365060,
     "10": 346310,
     "11": 327560,
     "Column14": 315060
    },
    {
     "이상": 6640,
     "미만": 6660,
     "1": 651060,
     "2": 603080,
     "3": 481320,
     "4": 461450,
     "5": 442700,
     "6": 423950,
     "7": 405200,
     "8": 386450,
     "9": 367700,
     "10": 348950,
     "11": 330200,
     "Column14": 317700
    },
    {
     "이상": 6660,
     "미만": 6680,
     "1": 655590,
     "2": 607590,
     "3": 485540,
     "4": 464090,
     "5": 445340,
     "6": 426590,
     "7": 407840,
     "8": 389090,
     "9": 370340,
     "10": 351590,
     "11": 332840,
     "Column14": 320340
    },
    {
     "이상": 6680,
     "미만": 6700,
     "1": 660130,
     "2": 612100,
     "3": 489760,
     "4": 466730,
     "5": 447980,
     "6": 429230,
     "7": 410480,
     "8": 391730,
     "9": 372980,
     "10": 354230,
     "11": 335480,
     "Column14": 322980
    },
    {
     "이상": 6700,
     "미만": 6720,
     "1": 664660,
     "2": 616610,
     "3": 493990,
     "4": 469370,
     "5": 450620,
     "6": 431870,
     "7": 413120,
     "8": 394370,
     "9": 375620,
     "10": 356870,
     "11": 338120,
     "Column14": 325620
    },
    {
     "이상": 6720,
     "미만": 6740,
     "1": 669200,
     "2": 621120,
     "3": 498210,
     "4": 472010,
     "5": 453260,
     "6": 434510,
     "7": 415760,
     "8": 397010,
     "9": 378260,
     "10": 359510,
     "11": 340760,
     "Column14": 328260
    },
    {
     "이상": 6740,
     "미만": 6760,
     "1": 673740,
     "2": 625640,
     "3": 502440,
     "4": 474650,
     "5": 455900,
     "6": 437150,
     "7": 418400,
     "8": 399650,
     "9": 380900,
     "10": 362150,
     "11": 343400,
     "Column14": 330900
    },
    {
     "이상": 6760,
     "미만": 6780,
     "1": 678270,
     "2": 630150,
     "3": 506660,
     "4": 477290,
     "5": 458540,
     "6": 439790,
     "7": 421040,
     "8": 402290,
     "9": 383540,
     "10": 364790,
     "11": 346040,
     "Column14": 333540
    },
    {
     "이상": 6780,
     "미만": 6800,
     "1": 682810,
     "2": 634660,
     "3": 510880,
     "4": 480880,
     "5": 461180,
     "6": 442430,
     "7": 423680,
     "8": 404930,
     "9": 386180,
     "10": 367430,
     "11": 348680,
     "Column14": 336180
    },
    {
     "이상": 6800,
     "미만": 6820,
     "1": 687340,
     "2": 639170,
     "3": 515110,
     "4": 485110,
     "5": 463820,
     "6": 445070,
     "7": 426320,
     "8": 407570,
     "9": 388820,
     "10": 370070,
     "11": 351320,
     "Column14": 338820
    },
    {
     "이상": 6820,
     "미만": 6840,
     "1": 691880,
     "2": 643680,
     "3": 519330,
     "4": 489330,
     "5": 466460,
     "6": 447710,
     "7": 428960,
     "8": 410210,
     "9": 391460,
     "10": 372710,
     "11": 353960,
     "Column14": 341460
    },
    {
     "이상": 6840,
     "미만": 6860,
     "1": 696420,
     "2": 648200,
     "3": 523560,
     "4": 493560,
     "5": 469100,
     "6": 450350,
     "7": 431600,
     "8": 412850,
     "9": 394100,
     "10": 375350,
     "11": 356600,
     "Column14": 344100
    },
    {
     "이상": 6860,
     "미만": 6880,
     "1": 700950,
     "2": 652710,
     "3": 527780,
     "4": 497780,
     "5": 471740,
     "6": 452990,
     "7": 434240,
     "8": 415490,
     "9": 396740,
     "10": 377990,
     "11": 359240,
     "Column14": 346740
    },
    {
     "이상": 6880,
     "미만": 6900,
     "1": 705490,
     "2": 657220,
     "3": 532000,
     "4": 502000,
     "5": 474380,
     "6": 455630,
     "7": 436880,
     "8": 418130,
     "9": 399380,
     "10": 380630,
     "11": 361880,
     "Column14": 349380
    },
    {
     "이상": 6900,
     "미만": 6920,
     "1": 710020,
     "2": 661730,
     "3": 536230,
     "4": 506230,
     "5": 477020,
     "6": 458270,
     "7": 439520,
     "8": 420770,
     "9": 402020,
     "10": 383270,
     "11": 364520,
     "Column14": 352020
    },
    {
     "이상": 6920,
     "미만": 6940,
     "1": 714560,
     "2": 666240,
     "3": 540450,
     "4": 510450,
     "5": 480450,
     "6": 460910,
     "7": 442160,
     "8": 423410,
     "9": 404660,
     "10": 385910,
     "11": 367160,
     "Column14": 354660
    },
    {
     "이상": 6940,
     "미만": 6960,
     "1": 719100,
     "2": 670760,
     "3": 544680,
     "4": 514680,
     "5": 484680,
     "6": 463550,
     "7": 444800,
     "8": 426050,
     "9": 407300,
     "10": 388550,
     "11": 369800,
     "Column14": 357300
    },
    {
     "이상": 6960,
     "미만": 6980,
     "1": 723630,
     "2": 675270,
     "3": 548900,
     "4": 518900,
     "5": 488900,
     "6": 466190,
     "7": 447440,
     "8": 428690,
     "9": 409940,
     "10": 391190,
     "11": 372440,
     "Column14": 359940
    },
    {
     "이상": 6980,
     "미만": 7000,
     "1": 728170,
     "2": 679780,
     "3": 553120,
     "4": 523120,
     "5": 493120,
     "6": 468830,
     "7": 450080,
     "8": 431330,
     "9": 412580,
     "10": 393830,
     "11": 375080,
     "Column14": 362580
    },
    {
     "이상": 7000,
     "미만": 7020,
     "1": 732700,
     "2": 684290,
     "3": 557350,
     "4": 527350,
     "5": 497350,
     "6": 471470,
     "7": 452720,
     "8": 433970,
     "9": 415220,
     "10": 396470,
     "11": 377720,
     "Column14": 365220
    },
    {
     "이상": 7020,
     "미만": 7040,
     "1": 737240,
     "2": 688800,
     "3": 561570,
     "4": 531570,
     "5": 501570,
     "6": 474110,
     "7": 455360,
     "8": 436610,
     "9": 417860,
     "10": 399110,
     "11": 380360,
     "Column14": 367860
    },
    {
     "이상": 7040,
     "미만": 7060,
     "1": 741780,
     "2": 693320,
     "3": 565800,
     "4": 535800,
     "5": 505800,
     "6": 476750,
     "7": 458000,
     "8": 439250,
     "9": 420500,
     "10": 401750,
     "11": 383000,
     "Column14": 370500
    },
    {
     "이상": 7060,
     "미만": 7080,
     "1": 746310,
     "2": 697830,
     "3": 570020,
     "4": 540020,
     "5": 510020,
     "6": 480020,
     "7": 460640,
     "8": 441890,
     "9": 423140,
     "10": 404390,
     "11": 385640,
     "Column14": 373140
    },
    {
     "이상": 7080,
     "미만": 7100,
     "1": 750850,
     "2": 702340,
     "3": 574240,
     "4": 544240,
     "5": 514240,
     "6": 484240,
     "7": 463280,
     "8": 444530,
     "9": 425780,
     "10": 407030,
     "11": 388280,
     "Column14": 375780
    },
    {
     "이상": 7100,
     "미만": 7120,
     "1": 755380,
     "2": 706850,
     "3": 578470,
     "4": 548470,
     "5": 518470,
     "6": 488470,
     "7": 465920,
     "8": 447170,
     "9": 428420,
     "10": 409670,
     "11": 390920,
     "Column14": 378420
    },
    {
     "이상": 7120,
     "미만": 7140,
     "1": 759920,
     "2": 711360,
     "3": 582690,
     "4": 552690,
     "5": 522690,
     "6": 492690,
     "7": 468560,
     "8": 449810,
     "9": 431060,
     "10": 412310,
     "11": 393560,
     "Column14": 381060
    },
    {
     "이상": 7140,
     "미만": 7160,
     "1": 764460,
     "2": 715880,
     "3": 586920,
     "4": 556920,
     "5": 526920,
     "6": 496920,
     "7": 471200,
     "8": 452450,
     "9": 433700,
     "10": 414950,
     "11": 396200,
     "Column14": 383700
    },
    {
     "이상": 7160,
     "미만": 7180,
     "1": 768990,
     "2": 720390,
     "3": 591140,
     "4": 561140,
     "5": 531140,
     "6": 501140,
     "7": 473840,
     "8": 455090,
     "9": 436340,
     "10": 417590,
     "11": 398840,
     "Column14": 386340
    },
    {
     "이상": 7180,
     "미만": 7200,
     "1": 773530,
     "2": 724900,
     "3": 595360,
     "4": 565360,
     "5": 535360,
     "6": 505360,
     "7": 476480,
     "8": 457730,
     "9": 438980,
     "10": 420230,
     "11": 401480,
     "Column14": 388980
    },
    {
     "이상": 7200,
     "미만": 7220,
     "1": 778060,
     "2": 729410,
     "3": 599590,
     "4": 569590,
     "5": 539590,
     "6": 509590,
     "7": 479590,
     "8": 460370,
     "9": 441620,
     "10": 422870,
     "11": 404120,
     "Column14": 391620
    },
    {
     "이상": 7220,
     "미만": 7240,
     "1": 782600,
     "2": 733920,
     "3": 603810,
     "4": 573810,
     "5": 543810,
     "6": 513810,
     "7": 483810,
     "8": 463010,
     "9": 444260,
     "10": 425510,
     "11": 406760,
     "Column14": 394260
    },
    {
     "이상": 7240,
     "미만": 7260,
     "1": 787140,
     "2": 738440,
     "3": 608040,
     "4": 578040,
     "5": 548040,
     "6": 518040,
     "7": 488040,
     "8": 465650,
     "9": 446900,
     "10": 428150,
     "11": 409400,
     "Column14": 396900
    },
    {
     "이상": 7260,
     "미만": 7280,
     "1": 791670,
     "2": 742950,
     "3": 612260,
     "4": 582260,
     "5": 552260,
     "6": 522260,
     "7": 492260,
     "8": 468290,
     "9": 449540,
     "10": 430790,
     "11": 412040,
     "Column14": 399540
    },
    {
     "이상": 7280,
     "미만": 7300,
     "1": 796210,
     "2": 747460,
     "3": 616480,
     "4": 586480,
     "5": 556480,
     "6": 526480,
     "7": 496480,
     "8": 470930,
     "9": 452180,
     "10": 433430,
     "11": 414680,
     "Column14": 402180
    },
    {
     "이상": 7300,
     "미만": 7320,
     "1": 800740,
     "2": 751970,
     "3": 620710,
     "4": 590710,
     "5": 560710,
     "6": 530710,
     "7": 500710,
     "8": 473570,
     "9": 454820,
     "10": 436070,
     "11": 417320,
     "Column14": 404820
    },
    {
     "이상": 7320,
     "미만": 7340,
     "1": 805280,
     "2": 756480,
     "3": 624930,
     "4": 594930,
     "5": 564930,
     "6": 534930,
     "7": 504930,
     "8": 476210,
     "9": 457460,
     "10": 438710,
     "11": 419960,
     "Column14": 407460
    },
    {
     "이상": 7340,
     "미만": 7360,
     "1": 809820,
     "2": 761000,
     "3": 629160,
     "4": 599160,
     "5": 569160,
     "6": 539160,
     "7": 509160,
     "8": 479160,
     "9": 460100,
     "10": 441350,
     "11": 422600,
     "Column14": 410100
    },
    {
     "이상": 7360,
     "미만": 7380,
     "1": 814350,
     "2": 765510,
     "3": 633380,
     "4": 603380,
     "5": 573380,
     "6": 543380,
     "7": 513380,
     "8": 483380,
     "9": 462740,
     "10": 443990,
     "11": 425240,
     "Column14": 412740
    },
    {
     "이상": 7380,
     "미만": 7400,
     "1": 818890,
     "2": 770020,
     "3": 637600,
     "4": 607600,
     "5": 577600,
     "6": 547600,
     "7": 517600,
     "8": 487600,
     "9": 465380,
     "10": 446630,
     "11": 427880,
     "Column14": 415380
    },
    {
     "이상": 7400,
     "미만": 7420,
     "1": 823420,
     "2": 774530,
     "3": 641830,
     "4": 611830,
     "5": 581830,
     "6": 551830,
     "7": 521830,
     "8": 491830,
     "9": 468020,
     "10": 449270,
     "11": 430520,
     "Column14": 418020
    },
    {
     "이상": 7420,
     "미만": 7440,
     "1": 827960,
     "2": 779040,
     "3": 646050,
     "4": 616050,
     "5": 586050,
     "6": 556050,
     "7": 526050,
     "8": 496050,
     "9": 470660,
     "10": 451910,
     "11": 433160,
     "Column14": 420660
    },
    {
     "이상": 7440,
     "미만": 7460,
     "1": 832500,
     "2": 783560,
     "3": 650280,
     "4": 620280,
     "5": 590280,
     "6": 560280,
     "7": 530280,
     "8": 500280,
     "9": 473300,
     "10": 454550,
     "11": 435800,
     "Column14": 423300
    },
    {
     "이상": 7460,
     "미만": 7480,
     "1": 837030,
     "2": 788070,
     "3": 654500,
     "4": 624500,
     "5": 594500,
     "6": 564500,
     "7": 534500,
     "8": 504500,
     "9": 475940,
     "10": 457190,
     "11": 438440,
     "Column14": 425940
    },
    {
     "이상": 7480,
     "미만": 7500,
     "1": 841570,
     "2": 792580,
     "3": 658720,
     "4": 628720,
     "5": 598720,
     "6": 568720,
     "7": 538720,
     "8": 508720,
     "9": 478720,
     "10": 459830,
     "11": 441080,
     "Column14": 428580
    },
    {
     "이상": 7500,
     "미만": 7520,
     "1": 846100,
     "2": 797090,
     "3": 662950,
     "4": 632950,
     "5": 602950,
     "6": 572950,
     "7": 542950,
     "8": 512950,
     "9": 482950,
     "10": 462470,
     "11": 443720,
     "Column14": 431220
    },
    {
     "이상": 7520,
     "미만": 7540,
     "1": 850640,
     "2": 801600,
     "3": 667170,
     "4": 637170,
     "5": 607170,
     "6": 577170,
     "7": 547170,
     "8": 517170,
     "9": 487170,
     "10": 465110,
     "11": 446360,
     "Column14": 433860
    },
    {
     "이상": 7540,
     "미만": 7560,
     "1": 855180,
     "2": 806120,
     "3": 671400,
     "4": 641400,
     "5": 611400,
     "6": 581400,
     "7": 551400,
     "8": 521400,
     "9": 491400,
     "10": 467750,
     "11": 449000,
     "Column14": 436500
    },
    {
     "이상": 7560,
     "미만": 7580,
     "1": 859710,
     "2": 810630,
     "3": 675620,
     "4": 645620,
     "5": 615620,
     "6": 585620,
     "7": 555620,
     "8": 525620,
     "9": 495620,
     "10": 470390,
     "11": 451640,
     "Column14": 439140
    },
    {
     "이상": 7580,
     "미만": 7600,
     "1": 864250,
     "2": 815140,
     "3": 679840,
     "4": 649840,
     "5": 619840,
     "6": 589840,
     "7": 559840,
     "8": 529840,
     "9": 499840,
     "10": 473030,
     "11": 454280,
     "Column14": 441780
    },
    {
     "이상": 7600,
     "미만": 7620,
     "1": 868780,
     "2": 819650,
     "3": 684070,
     "4": 654070,
     "5": 624070,
     "6": 594070,
     "7": 564070,
     "8": 534070,
     "9": 504070,
     "10": 475670,
     "11": 456920,
     "Column14": 444420
    },
    {
     "이상": 7620,
     "미만": 7640,
     "1": 873320,
     "2": 824160,
     "3": 688290,
     "4": 658290,
     "5": 628290,
     "6": 598290,
     "7": 568290,
     "8": 538290,
     "9": 508290,
     "10": 478310,
     "11": 459560,
     "Column14": 447060
    },
    {
     "이상": 7640,
     "미만": 7660,
     "1": 877860,
     "2": 828680,
     "3": 692520,
     "4": 662520,
     "5": 632520,
     "6": 602520,
     "7": 572520,
     "8": 542520,
     "9": 512520,
     "10": 482520,
     "11": 462200,
     "Column14": 449700
    },
    {
     "이상": 7660,
     "미만": 7680,
     "1": 882390,
     "2": 833190,
     "3": 696740,
     "4": 666740,
     "5": 636740,
     "6": 606740,
     "7": 576740,
     "8": 546740,
     "9": 516740,
     "10": 486740,
     "11": 464840,
     "Column14": 452340
    },
    {
     "이상": 7680,
     "미만": 7700,
     "1": 886930,
     "2": 837700,
     "3": 700960,
     "4": 670960,
     "5": 640960,
     "6": 610960,
     "7": 580960,
     "8": 550960,
     "9": 520960,
     "10": 490960,
     "11": 467480,
     "Column14": 454980
    },
    {
     "이상": 7700,
     "미만": 7720,
     "1": 891460,
     "2": 842210,
     "3": 705190,
     "4": 675190,
     "5": 645190,
     "6": 615190,
     "7": 585190,
     "8": 555190,
     "9": 525190,
     "10": 495190,
     "11": 470120,
     "Column14": 457620
    },
    {
     "이상": 7720,
     "미만": 7740,
     "1": 896000,
     "2": 846720,
     "3": 709410,
     "4": 679410,
     "5": 649410,
     "6": 619410,
     "7": 589410,
     "8": 559410,
     "9": 529410,
     "10": 499410,
     "11": 472760,
     "Column14": 460260
    },
    {
     "이상": 7740,
     "미만": 7760,
     "1": 900540,
     "2": 851240,
     "3": 713640,
     "4": 683640,
     "5": 653640,
     "6": 623640,
     "7": 593640,
     "8": 563640,
     "9": 533640,
     "10": 503640,
     "11": 475400,
     "Column14": 462900
    },
    {
     "이상": 7760,
     "미만": 7780,
     "1": 905070,
     "2": 855750,
     "3": 717860,
     "4": 687860,
     "5": 657860,
     "6": 627860,
     "7": 597860,
     "8": 567860,
     "9": 537860,
     "10": 507860,
     "11": 478040,
     "Column14": 465540
    },
    {
     "이상": 7780,
     "미만": 7800,
     "1": 909610,
     "2": 860260,
     "3": 722080,
     "4": 692080,
     "5": 662080,
     "6": 632080,
     "7": 602080,
     "8": 572080,
     "9": 542080,
     "10": 512080,
     "11": 482080,
     "Column14": 468180
    },
    {
     "이상": 7800,
     "미만": 7820,
     "1": 914140,
     "2": 864770,
     "3": 726310,
     "4": 696310,
     "5": 666310,
     "6": 636310,
     "7": 606310,
     "8": 576310,
     "9": 546310,
     "10": 516310,
     "11": 486310,
     "Column14": 470820
    },
    {
     "이상": 7820,
     "미만": 7840,
     "1": 918680,
     "2": 869280,
     "3": 730530,
     "4": 700530,
     "5": 670530,
     "6": 640530,
     "7": 610530,
     "8": 580530,
     "9": 550530,
     "10": 520530,
     "11": 490530,
     "Column14": 473460
    },
    {
     "이상": 7840,
     "미만": 7860,
     "1": 923220,
     "2": 873800,
     "3": 734760,
     "4": 704760,
     "5": 674760,
     "6": 644760,
     "7": 614760,
     "8": 584760,
     "9": 554760,
     "10": 524760,
     "11": 494760,
     "Column14": 476100
    },
    {
     "이상": 7860,
     "미만": 7880,
     "1": 927750,
     "2": 878310,
     "3": 738980,
     "4": 708980,
     "5": 678980,
     "6": 648980,
     "7": 618980,
     "8": 588980,
     "9": 558980,
     "10": 528980,
     "11": 498980,
     "Column14": 478980
    },
    {
     "이상": 7880,
     "미만": 7900,
     "1": 932290,
     "2": 882820,
     "3": 743200,
     "4": 713200,
     "5": 683200,
     "6": 653200,
     "7": 623200,
     "8": 593200,
     "9": 563200,
     "10": 533200,
     "11": 503200,
     "Column14": 483200
    },
    {
     "이상": 7900,
     "미만": 7920,
     "1": 936820,
     "2": 887330,
     "3": 747430,
     "4": 717430,
     "5": 687430,
     "6": 657430,
     "7": 627430,
     "8": 597430,
     "9": 567430,
     "10": 537430,
     "11": 507430,
     "Column14": 487430
    },
    {
     "이상": 7920,
     "미만": 7940,
     "1": 941360,
     "2": 891840,
     "3": 751650,
     "4": 721650,
     "5": 691650,
     "6": 661650,
     "7": 631650,
     "8": 601650,
     "9": 571650,
     "10": 541650,
     "11": 511650,
     "Column14": 491650
    },
    {
     "이상": 7940,
     "미만": 7960,
     "1": 945900,
     "2": 896360,
     "3": 755880,
     "4": 725880,
     "5": 695880,
     "6": 665880,
     "7": 635880,
     "8": 605880,
     "9": 575880,
     "10": 545880,
     "11": 515880,
     "Column14": 495880
    },
    {
     "이상": 7960,
     "미만": 7980,
     "1": 950430,
     "2": 900870,
     "3": 760100,
     "4": 730100,
     "5": 700100,
     "6": 670100,
     "7": 640100,
     "8": 610100,
     "9": 580100,
     "10": 550100,
     "11": 520100,
     "Column14": 500100
    },
    {
     "이상": 7980,
     "미만": 8000,
     "1": 954970,
     "2": 905380,
     "3": 764320,
     "4": 734320,
     "5": 704320,
     "6": 674320,
     "7": 644320,
     "8": 614320,
     "9": 584320,
     "10": 554320,
     "11": 524320,
     "Column14": 504320
    },
    {
     "이상": 8000,
     "미만": 8020,
     "1": 959500,
     "2": 909890,
     "3": 768550,
     "4": 738550,
     "5": 708550,
     "6": 678550,
     "7": 648550,
     "8": 618550,
     "9": 588550,
     "10": 558550,
     "11": 528550,
     "Column14": 508550
    },
    {
     "이상": 8020,
     "미만": 8040,
     "1": 964040,
     "2": 914400,
     "3": 772770,
     "4": 742770,
     "5": 712770,
     "6": 682770,
     "7": 652770,
     "8": 622770,
     "9": 592770,
     "10": 562770,
     "11": 532770,
     "Column14": 512770
    },
    {
     "이상": 8040,
     "미만": 8060,
     "1": 968580,
     "2": 918920,
     "3": 777000,
     "4": 747000,
     "5": 717000,
     "6": 687000,
     "7": 657000,
     "8": 627000,
     "9": 597000,
     "10": 567000,
     "11": 537000,
     "Column14": 517000
    },
    {
     "이상": 8060,
     "미만": 8080,
     "1": 973110,
     "2": 923430,
     "3": 781220,
     "4": 751220,
     "5": 721220,
     "6": 691220,
     "7": 661220,
     "8": 631220,
     "9": 601220,
     "10": 571220,
     "11": 541220,
     "Column14": 521220
    },
    {
     "이상": 8080,
     "미만": 8100,
     "1": 977650,
     "2": 927940,
     "3": 785440,
     "4": 755440,
     "5": 725440,
     "6": 695440,
     "7": 665440,
     "8": 635440,
     "9": 605440,
     "10": 575440,
     "11": 545440,
     "Column14": 525440
    },
    {
     "이상": 8100,
     "미만": 8120,
     "1": 982180,
     "2": 932450,
     "3": 789670,
     "4": 759670,
     "5": 729670,
     "6": 699670,
     "7": 669670,
     "8": 639670,
     "9": 609670,
     "10": 579670,
     "11": 549670,
     "Column14": 529670
    },
    {
     "이상": 8120,
     "미만": 8140,
     "1": 986720,
     "2": 936960,
     "3": 793890,
     "4": 763890,
     "5": 733890,
     "6": 703890,
     "7": 673890,
     "8": 643890,
     "9": 613890,
     "10": 583890,
     "11": 553890,
     "Column14": 533890
    },
    {
     "이상": 8140,
     "미만": 8160,
     "1": 991260,
     "2": 941480,
     "3": 798120,
     "4": 768120,
     "5": 738120,
     "6": 708120,
     "7": 678120,
     "8": 648120,
     "9": 618120,
     "10": 588120,
     "11": 558120,
     "Column14": 538120
    },
    {
     "이상": 8160,
     "미만": 8180,
     "1": 995790,
     "2": 945990,
     "3": 802340,
     "4": 772340,
     "5": 742340,
     "6": 712340,
     "7": 682340,
     "8": 652340,
     "9": 622340,
     "10": 592340,
     "11": 562340,
     "Column14": 542340
    },
    {
     "이상": 8180,
     "미만": 8200,
     "1": 1000330,
     "2": 950500,
     "3": 806560,
     "4": 776560,
     "5": 746560,
     "6": 716560,
     "7": 686560,
     "8": 656560,
     "9": 626560,
     "10": 596560,
     "11": 566560,
     "Column14": 546560
    },
    {
     "이상": 8200,
     "미만": 8220,
     "1": 1004860,
     "2": 955010,
     "3": 810790,
     "4": 780790,
     "5": 750790,
     "6": 720790,
     "7": 690790,
     "8": 660790,
     "9": 630790,
     "10": 600790,
     "11": 570790,
     "Column14": 550790
    },
    {
     "이상": 8220,
     "미만": 8240,
     "1": 1009400,
     "2": 959520,
     "3": 815010,
     "4": 785010,
     "5": 755010,
     "6": 725010,
     "7": 695010,
     "8": 665010,
     "9": 635010,
     "10": 605010,
     "11": 575010,
     "Column14": 555010
    },
    {
     "이상": 8240,
     "미만": 8260,
     "1": 1013940,
     "2": 964040,
     "3": 819240,
     "4": 789240,
     "5": 759240,
     "6": 729240,
     "7": 699240,
     "8": 669240,
     "9": 639240,
     "10": 609240,
     "11": 579240,
     "Column14": 559240
    },
    {
     "이상": 8260,
     "미만": 8280,
     "1": 1018470,
     "2": 968550,
     "3": 823460,
     "4": 793460,
     "5": 763460,
     "6": 733460,
     "7": 703460,
     "8": 673460,
     "9": 643460,
     "10": 613460,
     "11": 583460,
     "Column14": 563460
    },
    {
     "이상": 8280,
     "미만": 8300,
     "1": 1023010,
     "2": 973060,
     "3": 827680,
     "4": 797680,
     "5": 767680,
     "6": 737680,
     "7": 707680,
     "8": 677680,
     "9": 647680,
     "10": 617680,
     "11": 587680,
     "Column14": 567680
    },
    {
     "이상": 8300,
     "미만": 8320,
     "1": 1027540,
     "2": 977570,
     "3": 831910,
     "4": 801910,
     "5": 771910,
     "6": 741910,
     "7": 711910,
     "8": 681910,
     "9": 651910,
     "10": 621910,
     "11": 591910,
     "Column14": 571910
    },
    {
     "이상": 8320,
     "미만": 8340,
     "1": 1032080,
     "2": 982080,
     "3": 836130,
     "4": 806130,
     "5": 776130,
     "6": 746130,
     "7": 716130,
     "8": 686130,
     "9": 656130,
     "10": 626130,
     "11": 596130,
     "Column14": 576130
    },
    {
     "이상": 8340,
     "미만": 8360,
     "1": 1036740,
     "2": 986720,
     "3": 840480,
     "4": 810480,
     "5": 780480,
     "6": 750480,
     "7": 720480,
     "8": 690480,
     "9": 660480,
     "10": 630480,
     "11": 600480,
     "Column14": 580480
    },
    {
     "이상": 8360,
     "미만": 8380,
     "1": 1041420,
     "2": 991370,
     "3": 844840,
     "4": 814840,
     "5": 784840,
     "6": 754840,
     "7": 724840,
     "8": 694840,
     "9": 664840,
     "10": 634840,
     "11": 604840,
     "Column14": 584840
    },
    {
     "이상": 8380,
     "미만": 8400,
     "1": 1046100,
     "2": 996030,
     "3": 849210,
     "4": 819210,
     "5": 789210,
     "6": 759210,
     "7": 729210,
     "8": 699210,
     "9": 669210,
     "10": 639210,
     "11": 609210,
     "Column14": 589210
    },
    {
     "이상": 8400,
     "미만": 8420,
     "1": 1050780,
     "2": 1000680,
     "3": 853580,
     "4": 823580,
     "5": 793580,
     "6": 763580,
     "7": 733580,
     "8": 703580,
     "9": 673580,
     "10": 643580,
     "11": 613580,
     "Column14": 593580
    },
    {
     "이상": 8420,
     "미만": 8440,
     "1": 1055460,
     "2": 1005340,
     "3": 857950,
     "4": 827950,
     "5": 797950,
     "6": 767950,
     "7": 737950,
     "8": 707950,
     "9": 677950,
     "10": 647950,
     "11": 617950,
     "Column14": 597950
    },
    {
     "이상": 8440,
     "미만": 8460,
     "1": 1060140,
     "2": 1010000,
     "3": 862320,
     "4": 832320,
     "5": 802320,
     "6": 772320,
     "7": 742320,
     "8": 712320,
     "9": 682320,
     "10": 652320,
     "11": 622320,
     "Column14": 602320
    },
    {
     "이상": 8460,
     "미만": 8480,
     "1": 1064820,
     "2": 1014650,
     "3": 866680,
     "4": 836680,
     "5": 806680,
     "6": 776680,
     "7": 746680,
     "8": 716680,
     "9": 686680,
     "10": 656680,
     "11": 626680,
     "Column14": 606680
    },
    {
     "이상": 8480,
     "미만": 8500,
     "1": 1069500,
     "2": 1019310,
     "3": 871050,
     "4": 841050,
     "5": 811050,
     "6": 781050,
     "7": 751050,
     "8": 721050,
     "9": 691050,
     "10": 661050,
     "11": 631050,
     "Column14": 611050
    },
    {
     "이상": 8500,
     "미만": 8520,
     "1": 1074180,
     "2": 1023960,
     "3": 875420,
     "4": 845420,
     "5": 815420,
     "6": 785420,
     "7": 755420,
     "8": 725420,
     "9": 695420,
     "10": 665420,
     "11": 635420,
     "Column14": 615420
    },
    {
     "이상": 8520,
     "미만": 8540,
     "1": 1078860,
     "2": 1028620,
     "3": 879790,
     "4": 849790,
     "5": 819790,
     "6": 789790,
     "7": 759790,
     "8": 729790,
     "9": 699790,
     "10": 669790,
     "11": 639790,
     "Column14": 619790
    },
    {
     "이상": 8540,
     "미만": 8560,
     "1": 1083540,
     "2": 1033280,
     "3": 884160,
     "4": 854160,
     "5": 824160,
     "6": 794160,
     "7": 764160,
     "8": 734160,
     "9": 704160,
     "10": 674160,
     "11": 644160,
     "Column14": 624160
    },
    {
     "이상": 8560,
     "미만": 8580,
     "1": 1088220,
     "2": 1037930,
     "3": 888520,
     "4": 858520,
     "5": 828520,
     "6": 798520,
     "7": 768520,
     "8": 738520,
     "9": 708520,
     "10": 678520,
     "11": 648520,
     "Column14": 628520
    },
    {
     "이상": 8580,
     "미만": 8600,
     "1": 1092900,
     "2": 1042590,
     "3": 892890,
     "4": 862890,
     "5": 832890,
     "6": 802890,
     "7": 772890,
     "8": 742890,
     "9": 712890,
     "10": 682890,
     "11": 652890,
     "Column14": 632890
    },
    {
     "이상": 8600,
     "미만": 8620,
     "1": 1097580,
     "2": 1047240,
     "3": 897260,
     "4": 867260,
     "5": 837260,
     "6": 807260,
     "7": 777260,
     "8": 747260,
     "9": 717260,
     "10": 687260,
     "11": 657260,
     "Column14": 637260
    },
    {
     "이상": 8620,
     "미만": 8640,
     "1": 1102260,
     "2": 1051900,
     "3": 901630,
     "4": 871630,
     "5": 841630,
     "6": 811630,
     "7": 781630,
     "8": 751630,
     "9": 721630,
     "10": 691630,
     "11": 661630,
     "Column14": 641630
    },
    {
     "이상": 8640,
     "미만": 8660,
     "1": 1106940,
     "2": 1056560,
     "3": 906000,
     "4": 876000,
     "5": 846000,
     "6": 816000,
     "7": 786000,
     "8": 756000,
     "9": 726000,
     "10": 696000,
     "11": 666000,
     "Column14": 646000
    },
    {
     "이상": 8660,
     "미만": 8680,
     "1": 1111620,
     "2": 1061210,
     "3": 910360,
     "4": 880360,
     "5": 850360,
     "6": 820360,
     "7": 790360,
     "8": 760360,
     "9": 730360,
     "10": 700360,
     "11": 670360,
     "Column14": 650360
    },
    {
     "이상": 8680,
     "미만": 8700,
     "1": 1116300,
     "2": 1065870,
     "3": 914730,
     "4": 884730,
     "5": 854730,
     "6": 824730,
     "7": 794730,
     "8": 764730,
     "9": 734730,
     "10": 704730,
     "11": 674730,
     "Column14": 654730
    },
    {
     "이상": 8700,
     "미만": 8720,
     "1": 1120980,
     "2": 1070520,
     "3": 919100,
     "4": 889100,
     "5": 859100,
     "6": 829100,
     "7": 799100,
     "8": 769100,
     "9": 739100,
     "10": 709100,
     "11": 679100,
     "Column14": 659100
    },
    {
     "이상": 8720,
     "미만": 8740,
     "1": 1125660,
     "2": 1075180,
     "3": 923470,
     "4": 893470,
     "5": 863470,
     "6": 833470,
     "7": 803470,
     "8": 773470,
     "9": 743470,
     "10": 713470,
     "11": 683470,
     "Column14": 663470
    },
    {
     "이상": 8740,
     "미만": 8760,
     "1": 1130340,
     "2": 1079840,
     "3": 927840,
     "4": 897840,
     "5": 867840,
     "6": 837840,
     "7": 807840,
     "8": 777840,
     "9": 747840,
     "10": 717840,
     "11": 687840,
     "Column14": 667840
    },
    {
     "이상": 8760,
     "미만": 8780,
     "1": 1135020,
     "2": 1084490,
     "3": 932200,
     "4": 902200,
     "5": 872200,
     "6": 842200,
     "7": 812200,
     "8": 782200,
     "9": 752200,
     "10": 722200,
     "11": 692200,
     "Column14": 672200
    },
    {
     "이상": 8780,
     "미만": 8800,
     "1": 1139700,
     "2": 1089150,
     "3": 936570,
     "4": 906570,
     "5": 876570,
     "6": 846570,
     "7": 816570,
     "8": 786570,
     "9": 756570,
     "10": 726570,
     "11": 696570,
     "Column14": 676570
    },
    {
     "이상": 8800,
     "미만": 8820,
     "1": 1144380,
     "2": 1093800,
     "3": 940940,
     "4": 910940,
     "5": 880940,
     "6": 850940,
     "7": 820940,
     "8": 790940,
     "9": 760940,
     "10": 730940,
     "11": 700940,
     "Column14": 680940
    },
    {
     "이상": 8820,
     "미만": 8840,
     "1": 1149060,
     "2": 1098460,
     "3": 945310,
     "4": 915310,
     "5": 885310,
     "6": 855310,
     "7": 825310,
     "8": 795310,
     "9": 765310,
     "10": 735310,
     "11": 705310,
     "Column14": 685310
    },
    {
     "이상": 8840,
     "미만": 8860,
     "1": 1153740,
     "2": 1103120,
     "3": 949680,
     "4": 919680,
     "5": 889680,
     "6": 859680,
     "7": 829680,
     "8": 799680,
     "9": 769680,
     "10": 739680,
     "11": 709680,
     "Column14": 689680
    },
    {
     "이상": 8860,
     "미만": 8880,
     "1": 1158420,
     "2": 1107770,
     "3": 954040,
     "4": 924040,
     "5": 894040,
     "6": 864040,
     "7": 834040,
     "8": 804040,
     "9": 774040,
     "10": 744040,
     "11": 714040,
     "Column14": 694040
    },
    {
     "이상": 8880,
     "미만": 8900,
     "1": 1163100,
     "2": 1112430,
     "3": 958410,
     "4": 928410,
     "5": 898410,
     "6": 868410,
     "7": 838410,
     "8": 808410,
     "9": 778410,
     "10": 748410,
     "11": 718410,
     "Column14": 698410
    },
    {
     "이상": 8900,
     "미만": 8920,
     "1": 1167780,
     "2": 1117080,
     "3": 962780,
     "4": 932780,
     "5": 902780,
     "6": 872780,
     "7": 842780,
     "8": 812780,
     "9": 782780,
     "10": 752780,
     "11": 722780,
     "Column14": 702780
    },
    {
     "이상": 8920,
     "미만": 8940,
     "1": 1172460,
     "2": 1121740,
     "3": 967150,
     "4": 937150,
     "5": 907150,
     "6": 877150,
     "7": 847150,
     "8": 817150,
     "9": 787150,
     "10": 757150,
     "11": 727150,
     "Column14": 707150
    },
    {
     "이상": 8940,
     "미만": 8960,
     "1": 1177140,
     "2": 1126400,
     "3": 971520,
     "4": 941520,
     "5": 911520,
     "6": 881520,
     "7": 851520,
     "8": 821520,
     "9": 791520,
     "10": 761520,
     "11": 731520,
     "Column14": 711520
    },
    {
     "이상": 8960,
     "미만": 8980,
     "1": 1181820,
     "2": 1131050,
     "3": 975880,
     "4": 945880,
     "5": 915880,
     "6": 885880,
     "7": 855880,
     "8": 825880,
     "9": 795880,
     "10": 765880,
     "11": 735880,
     "Column14": 715880
    },
    {
     "이상": 8980,
     "미만": 9000,
     "1": 1186500,
     "2": 1135710,
     "3": 980250,
     "4": 950250,
     "5": 920250,
     "6": 890250,
     "7": 860250,
     "8": 830250,
     "9": 800250,
     "10": 770250,
     "11": 740250,
     "Column14": 720250
    },
    {
     "이상": 9000,
     "미만": 9020,
     "1": 1191180,
     "2": 1140360,
     "3": 984620,
     "4": 954620,
     "5": 924620,
     "6": 894620,
     "7": 864620,
     "8": 834620,
     "9": 804620,
     "10": 774620,
     "11": 744620,
     "Column14": 724620
    },
    {
     "이상": 9020,
     "미만": 9040,
     "1": 1195860,
     "2": 1145020,
     "3": 988990,
     "4": 958990,
     "5": 928990,
     "6": 898990,
     "7": 868990,
     "8": 838990,
     "9": 808990,
     "10": 778990,
     "11": 748990,
     "Column14": 728990
    },
    {
     "이상": 9040,
     "미만": 9060,
     "1": 1200540,
     "2": 1149680,
     "3": 993360,
     "4": 963360,
     "5": 933360,
     "6": 903360,
     "7": 873360,
     "8": 843360,
     "9": 813360,
     "10": 783360,
     "11": 753360,
     "Column14": 733360
    },
    {
     "이상": 9060,
     "미만": 9080,
     "1": 1205220,
     "2": 1154330,
     "3": 997720,
     "4": 967720,
     "5": 937720,
     "6": 907720,
     "7": 877720,
     "8": 847720,
     "9": 817720,
     "10": 787720,
     "11": 757720,
     "Column14": 737720
    },
    {
     "이상": 9080,
     "미만": 9100,
     "1": 1209900,
     "2": 1158990,
     "3": 1002090,
     "4": 972090,
     "5": 942090,
     "6": 912090,
     "7": 882090,
     "8": 852090,
     "9": 822090,
     "10": 792090,
     "11": 762090,
     "Column14": 742090
    },
    {
     "이상": 9100,
     "미만": 9120,
     "1": 1214580,
     "2": 1163640,
     "3": 1006460,
     "4": 976460,
     "5": 946460,
     "6": 916460,
     "7": 886460,
     "8": 856460,
     "9": 826460,
     "10": 796460,
     "11": 766460,
     "Column14": 746460
    },
    {
     "이상": 9120,
     "미만": 9140,
     "1": 1219260,
     "2": 1168300,
     "3": 1010830,
     "4": 980830,
     "5": 950830,
     "6": 920830,
     "7": 890830,
     "8": 860830,
     "9": 830830,
     "10": 800830,
     "11": 770830,
     "Column14": 750830
    },
    {
     "이상": 9140,
     "미만": 9160,
     "1": 1223940,
     "2": 1172960,
     "3": 1015200,
     "4": 985200,
     "5": 955200,
     "6": 925200,
     "7": 895200,
     "8": 865200,
     "9": 835200,
     "10": 805200,
     "11": 775200,
     "Column14": 755200
    },
    {
     "이상": 9160,
     "미만": 9180,
     "1": 1228620,
     "2": 1177610,
     "3": 1019560,
     "4": 989560,
     "5": 959560,
     "6": 929560,
     "7": 899560,
     "8": 869560,
     "9": 839560,
     "10": 809560,
     "11": 779560,
     "Column14": 759560
    },
    {
     "이상": 9180,
     "미만": 9200,
     "1": 1233300,
     "2": 1182270,
     "3": 1023930,
     "4": 993930,
     "5": 963930,
     "6": 933930,
     "7": 903930,
     "8": 873930,
     "9": 843930,
     "10": 813930,
     "11": 783930,
     "Column14": 763930
    },
    {
     "이상": 9200,
     "미만": 9220,
     "1": 1237980,
     "2": 1186920,
     "3": 1028300,
     "4": 998300,
     "5": 968300,
     "6": 938300,
     "7": 908300,
     "8": 878300,
     "9": 848300,
     "10": 818300,
     "11": 788300,
     "Column14": 768300
    },
    {
     "이상": 9220,
     "미만": 9240,
     "1": 1244640,
     "2": 1191580,
     "3": 1032670,
     "4": 1002670,
     "5": 972670,
     "6": 942670,
     "7": 912670,
     "8": 882670,
     "9": 852670,
     "10": 822670,
     "11": 792670,
     "Column14": 772670
    },
    {
     "이상": 9240,
     "미만": 9260,
     "1": 1251470,
     "2": 1196240,
     "3": 1037040,
     "4": 1007040,
     "5": 977040,
     "6": 947040,
     "7": 917040,
     "8": 887040,
     "9": 857040,
     "10": 827040,
     "11": 797040,
     "Column14": 777040
    },
    {
     "이상": 9260,
     "미만": 9280,
     "1": 1258290,
     "2": 1200890,
     "3": 1041400,
     "4": 1011400,
     "5": 981400,
     "6": 951400,
     "7": 921400,
     "8": 891400,
     "9": 861400,
     "10": 831400,
     "11": 801400,
     "Column14": 781400
    },
    {
     "이상": 9280,
     "미만": 9300,
     "1": 1265120,
     "2": 1205550,
     "3": 1045770,
     "4": 1015770,
     "5": 985770,
     "6": 955770,
     "7": 925770,
     "8": 895770,
     "9": 865770,
     "10": 835770,
     "11": 805770,
     "Column14": 785770
    },
    {
     "이상": 9300,
     "미만": 9320,
     "1": 1271940,
     "2": 1210200,
     "3": 1050140,
     "4": 1020140,
     "5": 990140,
     "6": 960140,
     "7": 930140,
     "8": 900140,
     "9": 870140,
     "10": 840140,
     "11": 810140,
     "Column14": 790140
    },
    {
     "이상": 9320,
     "미만": 9340,
     "1": 1278770,
     "2": 1214860,
     "3": 1054510,
     "4": 1024510,
     "5": 994510,
     "6": 964510,
     "7": 934510,
     "8": 904510,
     "9": 874510,
     "10": 844510,
     "11": 814510,
     "Column14": 794510
    },
    {
     "이상": 9340,
     "미만": 9360,
     "1": 1285590,
     "2": 1219520,
     "3": 1058880,
     "4": 1028880,
     "5": 998880,
     "6": 968880,
     "7": 938880,
     "8": 908880,
     "9": 878880,
     "10": 848880,
     "11": 818880,
     "Column14": 798880
    },
    {
     "이상": 9360,
     "미만": 9380,
     "1": 1292420,
     "2": 1224170,
     "3": 1063240,
     "4": 1033240,
     "5": 1003240,
     "6": 973240,
     "7": 943240,
     "8": 913240,
     "9": 883240,
     "10": 853240,
     "11": 823240,
     "Column14": 803240
    },
    {
     "이상": 9380,
     "미만": 9400,
     "1": 1299240,
     "2": 1228830,
     "3": 1067610,
     "4": 1037610,
     "5": 1007610,
     "6": 977610,
     "7": 947610,
     "8": 917610,
     "9": 887610,
     "10": 857610,
     "11": 827610,
     "Column14": 807610
    },
    {
     "이상": 9400,
     "미만": 9420,
     "1": 1306070,
     "2": 1233480,
     "3": 1071980,
     "4": 1041980,
     "5": 1011980,
     "6": 981980,
     "7": 951980,
     "8": 921980,
     "9": 891980,
     "10": 861980,
     "11": 831980,
     "Column14": 811980
    },
    {
     "이상": 9420,
     "미만": 9440,
     "1": 1312890,
     "2": 1238140,
     "3": 1076350,
     "4": 1046350,
     "5": 1016350,
     "6": 986350,
     "7": 956350,
     "8": 926350,
     "9": 896350,
     "10": 866350,
     "11": 836350,
     "Column14": 816350
    },
    {
     "이상": 9440,
     "미만": 9460,
     "1": 1319720,
     "2": 1244840,
     "3": 1080720,
     "4": 1050720,
     "5": 1020720,
     "6": 990720,
     "7": 960720,
     "8": 930720,
     "9": 900720,
     "10": 870720,
     "11": 840720,
     "Column14": 820720
    },
    {
     "이상": 9460,
     "미만": 9480,
     "1": 1326540,
     "2": 1251630,
     "3": 1085080,
     "4": 1055080,
     "5": 1025080,
     "6": 995080,
     "7": 965080,
     "8": 935080,
     "9": 905080,
     "10": 875080,
     "11": 845080,
     "Column14": 825080
    },
    {
     "이상": 9480,
     "미만": 9500,
     "1": 1333370,
     "2": 1258420,
     "3": 1089450,
     "4": 1059450,
     "5": 1029450,
     "6": 999450,
     "7": 969450,
     "8": 939450,
     "9": 909450,
     "10": 879450,
     "11": 849450,
     "Column14": 829450
    },
    {
     "이상": 9500,
     "미만": 9520,
     "1": 1340190,
     "2": 1265210,
     "3": 1093820,
     "4": 1063820,
     "5": 1033820,
     "6": 1003820,
     "7": 973820,
     "8": 943820,
     "9": 913820,
     "10": 883820,
     "11": 853820,
     "Column14": 833820
    },
    {
     "이상": 9520,
     "미만": 9540,
     "1": 1347020,
     "2": 1272000,
     "3": 1098190,
     "4": 1068190,
     "5": 1038190,
     "6": 1008190,
     "7": 978190,
     "8": 948190,
     "9": 918190,
     "10": 888190,
     "11": 858190,
     "Column14": 838190
    },
    {
     "이상": 9540,
     "미만": 9560,
     "1": 1353840,
     "2": 1278790,
     "3": 1102560,
     "4": 1072560,
     "5": 1042560,
     "6": 1012560,
     "7": 982560,
     "8": 952560,
     "9": 922560,
     "10": 892560,
     "11": 862560,
     "Column14": 842560
    },
    {
     "이상": 9560,
     "미만": 9580,
     "1": 1360670,
     "2": 1285580,
     "3": 1106920,
     "4": 1076920,
     "5": 1046920,
     "6": 1016920,
     "7": 986920,
     "8": 956920,
     "9": 926920,
     "10": 896920,
     "11": 866920,
     "Column14": 846920
    },
    {
     "이상": 9580,
     "미만": 9600,
     "1": 1367490,
     "2": 1292370,
     "3": 1111290,
     "4": 1081290,
     "5": 1051290,
     "6": 1021290,
     "7": 991290,
     "8": 961290,
     "9": 931290,
     "10": 901290,
     "11": 871290,
     "Column14": 851290
    },
    {
     "이상": 9600,
     "미만": 9620,
     "1": 1374320,
     "2": 1299160,
     "3": 1115660,
     "4": 1085660,
     "5": 1055660,
     "6": 1025660,
     "7": 995660,
     "8": 965660,
     "9": 935660,
     "10": 905660,
     "11": 875660,
     "Column14": 855660
    },
    {
     "이상": 9620,
     "미만": 9640,
     "1": 1381140,
     "2": 1305950,
     "3": 1120030,
     "4": 1090030,
     "5": 1060030,
     "6": 1030030,
     "7": 1000030,
     "8": 970030,
     "9": 940030,
     "10": 910030,
     "11": 880030,
     "Column14": 860030
    },
    {
     "이상": 9640,
     "미만": 9660,
     "1": 1387970,
     "2": 1312740,
     "3": 1124400,
     "4": 1094400,
     "5": 1064400,
     "6": 1034400,
     "7": 1004400,
     "8": 974400,
     "9": 944400,
     "10": 914400,
     "11": 884400,
     "Column14": 864400
    },
    {
     "이상": 9660,
     "미만": 9680,
     "1": 1394790,
     "2": 1319530,
     "3": 1128760,
     "4": 1098760,
     "5": 1068760,
     "6": 1038760,
     "7": 1008760,
     "8": 978760,
     "9": 948760,
     "10": 918760,
     "11": 888760,
     "Column14": 868760
    },
    {
     "이상": 9680,
     "미만": 9700,
     "1": 1401620,
     "2": 1326320,
     "3": 1133130,
     "4": 1103130,
     "5": 1073130,
     "6": 1043130,
     "7": 1013130,
     "8": 983130,
     "9": 953130,
     "10": 923130,
     "11": 893130,
     "Column14": 873130
    },
    {
     "이상": 9700,
     "미만": 9720,
     "1": 1408440,
     "2": 1333110,
     "3": 1137500,
     "4": 1107500,
     "5": 1077500,
     "6": 1047500,
     "7": 1017500,
     "8": 987500,
     "9": 957500,
     "10": 927500,
     "11": 897500,
     "Column14": 877500
    },
    {
     "이상": 9720,
     "미만": 9740,
     "1": 1415270,
     "2": 1339900,
     "3": 1141870,
     "4": 1111870,
     "5": 1081870,
     "6": 1051870,
     "7": 1021870,
     "8": 991870,
     "9": 961870,
     "10": 931870,
     "11": 901870,
     "Column14": 881870
    },
    {
     "이상": 9740,
     "미만": 9760,
     "1": 1422090,
     "2": 1346690,
     "3": 1146240,
     "4": 1116240,
     "5": 1086240,
     "6": 1056240,
     "7": 1026240,
     "8": 996240,
     "9": 966240,
     "10": 936240,
     "11": 906240,
     "Column14": 886240
    },
    {
     "이상": 9760,
     "미만": 9780,
     "1": 1428920,
     "2": 1353480,
     "3": 1150600,
     "4": 1120600,
     "5": 1090600,
     "6": 1060600,
     "7": 1030600,
     "8": 1000600,
     "9": 970600,
     "10": 940600,
     "11": 910600,
     "Column14": 890600
    },
    {
     "이상": 9780,
     "미만": 9800,
     "1": 1435740,
     "2": 1360270,
     "3": 1154970,
     "4": 1124970,
     "5": 1094970,
     "6": 1064970,
     "7": 1034970,
     "8": 1004970,
     "9": 974970,
     "10": 944970,
     "11": 914970,
     "Column14": 894970
    },
    {
     "이상": 9800,
     "미만": 9820,
     "1": 1442570,
     "2": 1367060,
     "3": 1159340,
     "4": 1129340,
     "5": 1099340,
     "6": 1069340,
     "7": 1039340,
     "8": 1009340,
     "9": 979340,
     "10": 949340,
     "11": 919340,
     "Column14": 899340
    },
    {
     "이상": 9820,
     "미만": 9840,
     "1": 1449390,
     "2": 1373850,
     "3": 1163710,
     "4": 1133710,
     "5": 1103710,
     "6": 1073710,
     "7": 1043710,
     "8": 1013710,
     "9": 983710,
     "10": 953710,
     "11": 923710,
     "Column14": 903710
    },
    {
     "이상": 9840,
     "미만": 9860,
     "1": 1456220,
     "2": 1380640,
     "3": 1168080,
     "4": 1138080,
     "5": 1108080,
     "6": 1078080,
     "7": 1048080,
     "8": 1018080,
     "9": 988080,
     "10": 958080,
     "11": 928080,
     "Column14": 908080
    },
    {
     "이상": 9860,
     "미만": 9880,
     "1": 1463040,
     "2": 1387430,
     "3": 1172440,
     "4": 1142440,
     "5": 1112440,
     "6": 1082440,
     "7": 1052440,
     "8": 1022440,
     "9": 992440,
     "10": 962440,
     "11": 932440,
     "Column14": 912440
    },
    {
     "이상": 9880,
     "미만": 9900,
     "1": 1469870,
     "2": 1394220,
     "3": 1176810,
     "4": 1146810,
     "5": 1116810,
     "6": 1086810,
     "7": 1056810,
     "8": 1026810,
     "9": 996810,
     "10": 966810,
     "11": 936810,
     "Column14": 916810
    },
    {
     "이상": 9900,
     "미만": 9920,
     "1": 1476690,
     "2": 1401010,
     "3": 1181180,
     "4": 1151180,
     "5": 1121180,
     "6": 1091180,
     "7": 1061180,
     "8": 1031180,
     "9": 1001180,
     "10": 971180,
     "11": 941180,
     "Column14": 921180
    },
    {
     "이상": 9920,
     "미만": 9940,
     "1": 1483520,
     "2": 1407800,
     "3": 1185550,
     "4": 1155550,
     "5": 1125550,
     "6": 1095550,
     "7": 1065550,
     "8": 1035550,
     "9": 1005550,
     "10": 975550,
     "11": 945550,
     "Column14": 925550
    },
    {
     "이상": 9940,
     "미만": 9960,
     "1": 1490340,
     "2": 1414590,
     "3": 1189920,
     "4": 1159920,
     "5": 1129920,
     "6": 1099920,
     "7": 1069920,
     "8": 1039920,
     "9": 1009920,
     "10": 979920,
     "11": 949920,
     "Column14": 929920
    },
    {
     "이상": 9960,
     "미만": 9980,
     "1": 1497170,
     "2": 1421380,
     "3": 1194280,
     "4": 1164280,
     "5": 1134280,
     "6": 1104280,
     "7": 1074280,
     "8": 1044280,
     "9": 1014280,
     "10": 984280,
     "11": 954280,
     "Column14": 934280
    },
    {
     "이상": 9980,
     "미만": 10000,
     "1": 1503990,
     "2": 1428170,
     "3": 1198650,
     "4": 1168650,
     "5": 1138650,
     "6": 1108650,
     "7": 1078650,
     "8": 1048650,
     "9": 1018650,
     "10": 988650,
     "11": 958650,
     "Column14": 938650
    }
]