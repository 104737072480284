import React from 'react';
import style from './Notice.module.css';

const Notice = () => {
  return (
    <div className={style.notice}>
      공지
    </div>
  );
};

export default Notice;