// 각종 유틸리티 함수

/**
 * 시간 문자열을 HH:MM 형식으로 포맷팅하는 함수
 * @param {string} value - 사용자가 입력한 시간 문자열
 * @returns {string} - 포맷팅된 시간 문자열
 */
export const formatTimeInput = (value) => {
  // 숫자와 콜론(:)만 허용
  const sanitizedValue = value.replace(/[^0-9:]/g, '');
  
  // 입력 패턴에 따라 포맷팅
  let formattedValue = sanitizedValue;
  
  // 숫자만 입력한 경우 (콜론 없음)
  if (!sanitizedValue.includes(':')) {
    // 1-2자리 숫자를 입력한 경우 (시간)
    if (sanitizedValue.length <= 2) {
      formattedValue = sanitizedValue;
    } 
    // 3-4자리 숫자를 입력한 경우 (시간+분)
    else if (sanitizedValue.length <= 4) {
      const hours = sanitizedValue.substring(0, 2);
      const minutes = sanitizedValue.substring(2);
      formattedValue = `${hours}:${minutes}`;
    }
  } 
  // 이미 콜론이 있는 경우
  else {
    const [hours, minutes] = sanitizedValue.split(':');
    // 시간만 입력되었거나 분이 입력 중인 경우
    formattedValue = `${hours}:${minutes || ''}`;
  }
  
  // 시간 값 검증 (24시간 형식)
  if (formattedValue.includes(':')) {
    const [hours, minutes] = formattedValue.split(':');
    if (hours.length === 2 && parseInt(hours) > 23) {
      formattedValue = `23:${minutes || ''}`;
    }
    if (minutes.length === 2 && parseInt(minutes) > 59) {
      formattedValue = `${hours}:59`;
    }
  } else if (formattedValue.length === 2 && parseInt(formattedValue) > 23) {
    formattedValue = '23';
  }
  
  return formattedValue;
};

/**
 * 시간 문자열이 유효한 형식인지 검증하는 함수
 * @param {string} timeString - 검증할 시간 문자열
 * @returns {boolean} - 유효 여부
 */
export const isValidTimeFormat = (timeString) => {
  if (!timeString) return false;
  
  // HH:MM 형식인지 확인
  const timeRegex = /^([0-1]?[0-9]|2[0-3]):([0-5][0-9])$/;
  return timeRegex.test(timeString);
};

/**
 * 두 시간 사이의 분 차이를 계산하는 함수
 * @param {string} startTime - 시작 시간 (HH:MM 형식)
 * @param {string} endTime - 종료 시간 (HH:MM 형식)
 * @returns {number} - 분 단위 차이 (종료 시간이 시작 시간보다 이전이면 다음 날로 계산)
 */
export const calculateTimeDifference = (startTime, endTime) => {
  if (!isValidTimeFormat(startTime) || !isValidTimeFormat(endTime)) {
    return 0;
  }
  
  const [startHours, startMinutes] = startTime.split(':').map(Number);
  const [endHours, endMinutes] = endTime.split(':').map(Number);
  
  let minutesDiff = (endHours * 60 + endMinutes) - (startHours * 60 + startMinutes);
  
  // 종료 시간이 시작 시간보다 이전인 경우 (예: 23:00 ~ 01:00) 다음 날로 계산
  if (minutesDiff < 0) {
    minutesDiff += 24 * 60;
  }
  
  return minutesDiff;
};