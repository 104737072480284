import React, { useState, useEffect } from 'react';
import { doc, getDoc, setDoc, updateDoc, collection } from 'firebase/firestore';
import { db } from '../../../../api/firebase/firebaseConfig';
import { getAuth } from 'firebase/auth';
import styles from './EvaluationModalContent.module.css';
import CustomerManagement from './components/CustomerManagement';
import AgentSuggestions from './components/AgentSuggestions';
import BusinessReport from './components/BusinessReport';
import Loading from '../../../modals/Loading';
import { useAgentContext } from '../../../../context/AgentContextProvider';
import TabMenu from '../../../modals/TabMenu';

const EvaluationModalContent = () => {
  const [activeTab, setActiveTab] = useState('고객관리');
  const [customerData, setCustomerData] = useState([]);
  const [agentSuggestions, setAgentSuggestions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const auth = getAuth();
  
  // 탭 메뉴 정의
  const tabs = ['고객관리', '에이전트 제안', '경영 보고서'];
  
  const { updateTabContext, updateDataContext } = useAgentContext();
  
  // 탭 변경 핸들러
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };
  
  // 탭 변경 시 컨텍스트 업데이트
  useEffect(() => {
    updateTabContext(activeTab, getTabDisplayName(activeTab));
  }, [activeTab]);
  
  // 데이터 로드 시 컨텍스트 업데이트
  useEffect(() => {
    if (customerData.length > 0) {
      updateDataContext('customers', {
        count: customerData.length,
        categories: getCustomerCategories(customerData),
        summary: `총 ${customerData.length}명의 고객 데이터`
      });
    }
  }, [customerData]);
  
  useEffect(() => {
    if (agentSuggestions.length > 0) {
      updateDataContext('suggestions', {
        count: agentSuggestions.length,
        categories: getSuggestionCategories(agentSuggestions),
        summary: `${agentSuggestions.length}개의 에이전트 제안`
      });
    }
  }, [agentSuggestions]);
  
  // 탭 표시명 가져오기
  const getTabDisplayName = (tabId) => {
    return tabId; // 이미 한글로 저장되어 있으므로 그대로 반환
  };
  
  // 고객 카테고리 추출
  const getCustomerCategories = (customers) => {
    const categories = {};
    customers.forEach(customer => {
      const category = customer.category || '미분류';
      categories[category] = (categories[category] || 0) + 1;
    });
    return categories;
  };
  
  // 제안 카테고리 추출
  const getSuggestionCategories = (suggestions) => {
    const categories = {};
    suggestions.forEach(suggestion => {
      const category = suggestion.category || '기타';
      categories[category] = (categories[category] || 0) + 1;
    });
    return categories;
  };
  
  // 데이터 로드
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        if (!auth.currentUser) {
          console.error('로그인이 필요합니다.');
          setIsLoading(false);
          return;
        }
        
        // 고객 데이터 불러오기
        const customerDocRef = doc(db, 'customers', auth.currentUser.uid);
        const customerDoc = await getDoc(customerDocRef);
        
        if (customerDoc.exists()) {
          setCustomerData(customerDoc.data().customerList || []);
        } else {
          // 문서가 없으면 생성
          await setDoc(customerDocRef, { customerList: [] });
          setCustomerData([]);
        }
        
        // 에이전트 제안 데이터 불러오기
        const agentSuggestionsDocRef = doc(db, 'agentSuggestions', auth.currentUser.uid);
        const agentSuggestionsDoc = await getDoc(agentSuggestionsDocRef);
        
        if (agentSuggestionsDoc.exists()) {
          // agentSuggestions 컬렉션에 데이터가 있으면 사용
          const data = agentSuggestionsDoc.data();
          if (data && data.suggestionList) {
            setAgentSuggestions(data.suggestionList);
          } else {
            setAgentSuggestions([]);
          }
        } else {
          // 없으면 빈 배열로 초기화
          await setDoc(agentSuggestionsDocRef, { 
            suggestionList: [],
            lastUpdated: new Date().toISOString()
          });
          setAgentSuggestions([]);
        }
      } catch (error) {
        console.error('데이터 로딩 실패:', error);
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchData();
  }, [auth.currentUser]);
  
  // 고객 데이터 업데이트
  const updateCustomerData = async (newCustomerData) => {
    setIsLoading(true);
    try {
      const customerDocRef = doc(db, 'customers', auth.currentUser.uid);
      
      // 문서 존재 여부 확인
      const docSnap = await getDoc(customerDocRef);
      
      if (docSnap.exists()) {
        // 문서가 존재하면 업데이트
        await updateDoc(customerDocRef, {
          customerList: newCustomerData
        });
      } else {
        // 문서가 없으면 생성
        await setDoc(customerDocRef, {
          customerList: newCustomerData
        });
      }
      
      setCustomerData(newCustomerData);
    } catch (error) {
      console.error('고객 데이터 업데이트 실패:', error);
    } finally {
      setIsLoading(false);
    }
  };
  
  // 에이전트 제안 업데이트
  const updateAgentSuggestions = async (newSuggestions) => {
    setIsLoading(true);
    try {
      const suggestionsDocRef = doc(db, 'agentSuggestions', auth.currentUser.uid);
      
      // 저장할 데이터 구조
      const dataToSave = {
        suggestionList: newSuggestions,
        lastUpdated: new Date().toISOString()
      };
      
      // 기존 코드와 다르게, 매번 문서를 덮어쓰기로 처리 (setDoc 사용)
      await setDoc(suggestionsDocRef, dataToSave);
      
      setAgentSuggestions(newSuggestions);
    } catch (error) {
      console.error('에이전트 제안 업데이트 실패:', error);
      alert('제안 업데이트 중 오류 발생: ' + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  // 고객관리 탭 렌더링
  const renderCustomerTab = () => {
    return (
      <CustomerManagement 
        customerData={customerData}
        updateCustomerData={updateCustomerData}
      />
    );
  };
  
  // 에이전트 제안 탭 렌더링
  const renderAgentSuggestionsTab = () => {
    return (
      <AgentSuggestions 
        suggestions={agentSuggestions}
        updateSuggestions={updateAgentSuggestions}
      />
    );
  };
  
  // 경영 보고서 탭 렌더링
  const renderBusinessReportTab = () => {
    return (
      <BusinessReport 
        customerData={customerData}
        suggestions={agentSuggestions}
      />
    );
  };

  const renderContent = () => {
    if (isLoading) {
      return <Loading message="데이터 불러오는 중..." />;
    }
    
    let content;
    switch (activeTab) {
      case '고객관리':
        content = renderCustomerTab();
        break;
      case '에이전트 제안':
        content = renderAgentSuggestionsTab();
        break;
      case '경영 보고서':
        content = renderBusinessReportTab();
        break;
      default:
        content = null;
    }
    
    // 이미 각 컴포넌트가 자체적으로 스타일링되어 있으므로 추가 컨테이너 불필요
    return (
      <div className={styles.evaluationContent}>
        {content}
      </div>
    );
  };

  return (
    <div className={styles.evaluationModalContent}>
      <TabMenu
        tabs={tabs}
        activeTab={activeTab}
        onTabChange={handleTabChange}
      >
        {renderContent()}
      </TabMenu>
    </div>
  );
};

export default EvaluationModalContent;