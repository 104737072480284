import React, { useState } from 'react';
import { getBizPhone } from '../../../../api/get/getBizPhone';
import styles from './BizPhone.module.css';

// 사업자 알뜰폰 가격비교 페이지
const BizPhone = () => {
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isInitialState, setIsInitialState] = useState(true); // 초기 상태 추가
  const [filters, setFilters] = useState({
    provider: '',
    maxPrice: ''
  });

  // 데이터 가져오기
  const bizPhoneData = async () => {
    try {
      setLoading(true);
      setIsInitialState(false); // 검색 시작 시 초기 상태 해제
      const response = await getBizPhone(filters);
      setPlans(response.data.data);
      setError(null);
    } catch (err) {
      setError('데이터를 불러오는 중 오류가 발생했습니다.');
      console.error('데이터 로딩 오류:', err);
    } finally {
      setLoading(false);
    }
  };

  // 필터 변경 핸들러
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters(prev => ({
      ...prev,
      [name]: value
    }));
  };

  // 필터 적용 핸들러 (찾기 버튼)
  const applyFilters = () => {
    bizPhoneData();
  };

  return (
    <div className={styles.bizPhoneContent}>
      <h1 className={styles.bizPhoneTitle}>사업자 알뜰폰 요금제 비교</h1>
      
      {/* 필터 섹션 */}
      <div className={styles.bizPhoneFilters}>
        <div className={styles.filterItem}>
          <label>통신사</label>
          <select 
            name="provider" 
            value={filters.provider} 
            onChange={handleFilterChange}
          >
            <option value="">전체</option>
            <option value="SK알뜰폰">SK알뜰폰</option>
            <option value="KT알뜰폰">KT알뜰폰</option>
            <option value="LG알뜰폰">LG알뜰폰</option>
          </select>
        </div>
        
        <div className={styles.filterItem}>
          <label>최대 가격</label>
          <input 
            type="number" 
            name="maxPrice" 
            value={filters.maxPrice} 
            onChange={handleFilterChange}
            placeholder="최대 가격"
          />
        </div>
        
        <button className={styles.filterButton} onClick={applyFilters}>
          분석하기
        </button>
      </div>
      
      {/* 로딩 및 에러 처리 */}
      {loading && <div className={styles.loading}>로딩 중...</div>}
      {error && <div className={styles.errorMessage}>{error}</div>}
      
      {/* 초기 상태 메시지 */}
      {isInitialState && !loading && (
        <div className={styles.initialMessage}>
          원하시는 조건을 선택하고 찾기 버튼을 눌러주세요.
        </div>
      )}
      
      {/* 요금제 목록 */}
      {!loading && !error && !isInitialState && (
        <div className={styles.plansContainer}>
          {plans.length === 0 ? (
            <div className={styles.noPlans}>조건에 맞는 요금제가 없습니다.</div>
          ) : (
            plans.map(plan => (
              <div key={plan.id} className={styles.planCard}>
                <div className={styles.planHeader}>
                  <h3>{plan.planName}</h3>
                  <span className={styles.provider}>{plan.provider}</span>
                </div>
                <div className={styles.planDetails}>
                  <div className={styles.detailItem}>
                    <span className={styles.label}>데이터</span>
                    <span className={styles.value}>{plan.data}</span>
                  </div>
                  <div className={styles.detailItem}>
                    <span className={styles.label}>통화</span>
                    <span className={styles.value}>{plan.call}</span>
                  </div>
                  <div className={styles.detailItem}>
                    <span className={styles.label}>문자</span>
                    <span className={styles.value}>{plan.sms}</span>
                  </div>
                </div>
                <div className={styles.planBenefits}>
                  <h4>혜택</h4>
                  <ul>
                    {plan.benefits.map((benefit, index) => (
                      <li key={index}>{benefit}</li>
                    ))}
                  </ul>
                </div>
                <div className={styles.planPrice}>
                  <span className={styles.priceValue}>{plan.price.toLocaleString()}</span>
                  <span className={styles.priceUnit}>원/월</span>
                </div>
                <button className={styles.applyButton}>신청하기</button>
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );
};

export default BizPhone;
