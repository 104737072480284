import React, { useState } from 'react';
import styles from './EmployeeList.module.css';

const EmployeeList = ({ employees, selectedEmployee, isLoading, onSelectEmployee, onAddNewEmployee }) => {
  const [searchTerm, setSearchTerm] = useState('');
  
  // 검색어에 맞는 직원 필터링
  const filteredEmployees = employees.filter(employee => 
    employee.name?.includes(searchTerm) || 
    employee.position?.includes(searchTerm)
  );
  
  return (
    <div className={styles.employeeListSection}>
      <div className={styles.employeeListHeader}>
        <h3>직원 목록</h3>
        <button className={styles.addEmployeeBtn} onClick={onAddNewEmployee}>
          직원 등록
        </button>
      </div>
      
      <div className={styles.searchBar}>
        <input
          type="text"
          placeholder="직원명, 직위 검색..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <span className={styles.searchIcon}>🔍</span>
      </div>
      
      {isLoading ? (
        <div className={styles.loading}>
          <div className={styles.spinner}></div>
          <div>로딩 중...</div>
        </div>
      ) : (
        <div className={styles.employeeList}>
          <h3 className={styles.employeeListTitle}>등록된 직원 목록 ({filteredEmployees.length}명)</h3>
          {filteredEmployees.length === 0 ? (
            <div className={styles.noEmployees}>등록된 직원이 없습니다.</div>
          ) : (
            filteredEmployees.map(employee => (
              <div 
                key={employee.id} 
                className={`${styles.employeeItem} ${selectedEmployee && selectedEmployee.id === employee.id ? styles.selected : ''}`}
                onClick={() => onSelectEmployee(employee)}
              >
                <div className={styles.employeeInfo}>
                  <div className={styles.employeeName}>{employee.name}</div>
                  <div className={styles.employeePosition}>{employee.position || '직위 미지정'}</div>
                </div>
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );
};

export default EmployeeList; 