import React from 'react';
import styles from './PhotoBook.module.css';

// 사진첩 컴포넌트
export default function PhotoBook() {
  return (
    <div className={styles.diaryPhotobook}>
      <div className={styles.diaryPhotobookHeader}>
        <div className={`${styles.diarySticker} ${styles.stickerHeart}`}></div>
        <h2>나의 사진첩</h2>
        <div className={`${styles.diarySticker} ${styles.stickerStar}`}></div>
      </div>
      
      <div className={styles.diaryPhotobookGrid}>
        {Array.from({length: 8}, (_, i) => (
          <div key={i} className={styles.diaryPhotoItem}>
            <div className={styles.diaryPhotoFrame}>
              <div className={styles.diaryPhotoPlaceholder}>
                <span>이미지 {i+1}</span>
              </div>
            </div>
            <div className={styles.diaryPhotoTape}></div>
            <div className={styles.diaryPhotoCaption}>
              <span>사진 설명</span>
            </div>
          </div>
        ))}
      </div>
      
      <div className={styles.diaryPhotobookPagination}>
        <button className={`${styles.diaryPaginationButton} ${styles.prevButton}`}>
          <span>이전</span>
        </button>
        <span className={styles.paginationNumber}>1 / 1</span>
        <button className={`${styles.diaryPaginationButton} ${styles.nextButton}`}>
          <span>다음</span>
        </button>
      </div>
    </div>
  );
} 