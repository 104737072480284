import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Home from './pages/home/Home';
import Footer from './components/layout/Footer';
import AuthTabs from './components/auth/AuthTabs';
import { AuthProvider, useAuth } from './hooks/useAuth';
import AttendanceCheck from './components/services/attendance/AttendanceCheck';
import Privacy from './components/layout/Privacy';
import Terms from './components/layout/Terms';
import './App.css';

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <div className="appContainer">
          <div className="appBackground">
            <div className="star"></div>
            <div className="star"></div>
            <div className="star"></div>
            <div className="star"></div>
            <div className="star"></div>
            <div className="star"></div>
            <div className="star"></div>
            <div className="star"></div>
            <div className="star"></div>
            <div className="star"></div>
            <div className="login-content">
              <Routes>
                <Route
                  path="/"
                  element={
                    <ProtectedRoute>
                      <Home />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/auth"
                  element={
                    <AuthRoute>
                      <AuthTabs />
                    </AuthRoute>
                  }
                />
                {/* 근무지 정보가 포함된 출석 체크 라우트 추가 */}
                <Route
                  path="/attendance/:token/:employeeId/:workplaceName"
                  element={<AttendanceCheck />}
                />
                {/* 개인정보 처리방침 및 이용약관 라우트 추가 */}
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/terms" element={<Terms />} />
              </Routes>
            </div>
          </div>
          <Footer />
        </div>
      </Router>
    </AuthProvider>
  );
};

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? children : <Navigate to="/auth" replace />;
};

const AuthRoute = ({ children }) => {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? <Navigate to="/" replace /> : children;
};

export default App;