import React, { useState, useRef, useEffect } from 'react';
import Draggable from 'react-draggable';
import './MainAgent.css';
import { postAiAgent } from '../../api/post/postAiAgent';
import { useAgentContext } from '../../context/AgentContextProvider';

const MainAgent = () => {
  // 기존 상태 관리
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [fullResponse, setFullResponse] = useState('');
  const draggableRef = useRef(null);
  const messagesContainerRef = useRef(null);
  
  // 효과 관리
  const [typingText, setTypingText] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  
  // 컨텍스트 관련 상태
  const [hasContextualHelp, setHasContextualHelp] = useState(false);
  const { getContextData } = useAgentContext();

  // 메시지가 추가될 때마다 스크롤 함수 실행
  useEffect(() => {
    scrollToBottom();
  }, [messages, isLoading, typingText]);

  // 타이핑 효과 구현
  useEffect(() => {
    if (isTyping && fullResponse) {
      const timeout = setTimeout(() => {
        if (typingText.length < fullResponse.length) {
          setTypingText(fullResponse.substring(0, typingText.length + 1));
        } else {
          setIsTyping(false);
          setFullResponse('');
          setMessages(prevMessages => [...prevMessages.slice(0, -1), 
            { text: typingText, isUser: false }]);
        }
      }, 30);
      
      return () => clearTimeout(timeout);
    }
  }, [typingText, fullResponse, isTyping]);

  // 부드러운 스크롤 함수
  const scrollToBottom = () => {
    if (messagesContainerRef.current) {
      const container = messagesContainerRef.current;
      const isScrolledToBottom = 
        container.scrollHeight - container.clientHeight <= container.scrollTop + 50;
      
      if (isScrolledToBottom || messages.length === 0 || isLoading || isTyping) {
        setTimeout(() => {
          container.scrollTo({
            top: container.scrollHeight,
            behavior: 'smooth'
          });
        }, 100);
      }
    }
  };

  // 도움말 요청 처리 함수
  const requestContextualHelp = async () => {
    const contextData = getContextData();
    
    if (!contextData.activeModal) {
      startTypingEffect("현재 메인 화면을 보고 계시네요. 어떤 도움이 필요하신가요?");
      return;
    }
    
    setIsLoading(true);
    
    try {
      const response = await postAiAgent({
        prompt: `${contextData.activeModal.name} 화면에 대한 도움말을 제공해주세요.`,
        context: contextData,
        isContextualHelp: true
      });
      
      if (response?.data?.message) {
        setIsLoading(false);
        startTypingEffect(response.data.message);
        setHasContextualHelp(true);
      }
    } catch (error) {
      console.error('도움말 요청 오류:', error);
      setIsLoading(false);
      startTypingEffect('죄송합니다. 도움말을 가져오는 중 오류가 발생했습니다.');
    }
  };

  // 타이핑 효과 시작 함수
  const startTypingEffect = (text) => {
    setFullResponse(text);
    setTypingText('');
    setIsTyping(true);
    setMessages(prevMessages => [...prevMessages, { text: '', isUser: false, isTyping: true }]);
  };

  // 메시지 전송 함수
  const handleSendMessage = async () => {
    if (inputMessage.trim() === '') return;
    
    setMessages([...messages, { text: inputMessage, isUser: true }]);
    setInputMessage('');
    setIsLoading(true);
    
    try {
      const payload = {
        prompt: inputMessage,
        history: messages.map(msg => ({
          role: msg.isUser ? 'user' : 'assistant',
          content: msg.text
        }))
      };
      
      if (hasContextualHelp) {
        payload.context = getContextData();
      }
      
      const response = await postAiAgent(payload);
      
      if (response?.data?.message) {
        setIsLoading(false);
        startTypingEffect(response.data.message);
      }
    } catch (error) {
      console.error('AI 응답 오류:', error);
      setIsLoading(false);
      startTypingEffect('죄송합니다. 응답을 받는 중 오류가 발생했습니다.');
    }
  };

  // Enter 키로 메시지 전송
  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  return (
    <Draggable nodeRef={draggableRef} bounds="parent" cancel=".textarea, .sendButton, .helpButton">
      <div ref={draggableRef} className="chatBox">
        <div className="helpButtonContainer">
          <button 
            className="helpButton" 
            onClick={requestContextualHelp}
            disabled={isLoading || isTyping}
          >
            <span role="img" aria-label="help">❓</span> 도움말
          </button>
        </div>
        
        <div ref={messagesContainerRef} className="messagesContainer">
          {messages.map((message, index) => (
            <div key={index} className={`messageRow ${message.isUser ? 'userMessageRow' : 'aiMessageRow'}`}>
              <div className={message.isUser ? 'userMessage' : 'aiMessage'}>
                <p>{message.text}</p>
              </div>
            </div>
          ))}
          
          {isLoading && (
            <div className="messageRow aiMessageRow">
              <div className="aiMessage">
                <p>응답 중...</p>
              </div>
            </div>
          )}
          
          {isTyping && (
            <div className="messageRow aiMessageRow">
              <div className="aiMessage typingMessage">
                <p>{typingText}<span className="typingCursor">|</span></p>
              </div>
            </div>
          )}
        </div>
        
        <div className="inputContainer">
          <textarea
            className="textarea"
            value={inputMessage}
            onChange={(e) => setInputMessage(e.target.value)}
            onKeyPress={handleKeyPress}
            placeholder="문의하기"
            disabled={isLoading || isTyping}
          />
          <button 
            className="sendButton" 
            onClick={handleSendMessage}
            disabled={isLoading || isTyping}
          >
            전송
          </button>
        </div>
      </div>
    </Draggable>
  );
};

export default MainAgent;